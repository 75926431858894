import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SaveHotkeyDirective } from './save-hotkey.directive';

@NgModule({
	declarations: [SaveHotkeyDirective],
	exports: [SaveHotkeyDirective],
	imports: [CommonModule],
})
export class SaveHotkeyModule {}
