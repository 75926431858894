import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DebounceClickDirective } from './debounce-click.directive';

@NgModule({
	declarations: [DebounceClickDirective],
	exports: [DebounceClickDirective],
	imports: [CommonModule],
})
export class DebounceClickModule {}
