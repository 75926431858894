/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { LitigationBeneficiaryVO } from '../model/models';
import { LitigationBenefitDetailVO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LitigationBenefitResourceService {

    protected basePath = 'https://dev.eeoc.gov/litigation';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Creates benefits for a new or existing Beneficiary
     * Creates benefits for a new or existing Beneficiary
     * @param caseId 
     * @param litigationBeneficiaryVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBenefitsForBeneficiary(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LitigationBeneficiaryVO>;
    public createBenefitsForBeneficiary(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LitigationBeneficiaryVO>>;
    public createBenefitsForBeneficiary(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LitigationBeneficiaryVO>>;
    public createBenefitsForBeneficiary(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling createBenefitsForBeneficiary.');
        }
        if (litigationBeneficiaryVO === null || litigationBeneficiaryVO === undefined) {
            throw new Error('Required parameter litigationBeneficiaryVO was null or undefined when calling createBenefitsForBeneficiary.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LitigationBeneficiaryVO>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits`,
            litigationBeneficiaryVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a list of benefits
     * Delete a list of benefits
     * @param caseId 
     * @param benefitid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBenefits(caseId: number, benefitid: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteBenefits(caseId: number, benefitid: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteBenefits(caseId: number, benefitid: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteBenefits(caseId: number, benefitid: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling deleteBenefits.');
        }
        if (benefitid === null || benefitid === undefined) {
            throw new Error('Required parameter benefitid was null or undefined when calling deleteBenefits.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (benefitid) {
            benefitid.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'benefitid');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete all benefits for a beneficiary
     * Delete all benefits for a beneficiary
     * @param caseId 
     * @param beneficiaryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling deleteBenefitsForBeneficiary.');
        }
        if (beneficiaryId === null || beneficiaryId === undefined) {
            throw new Error('Required parameter beneficiaryId was null or undefined when calling deleteBenefitsForBeneficiary.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits/${encodeURIComponent(String(beneficiaryId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete all benefits for a beneficiary and resolution
     * Delete all benefits for a beneficiary and resolution
     * @param caseId 
     * @param resolutionId 
     * @param beneficiaryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling deleteBenefitsForBeneficiaryAndResolution.');
        }
        if (resolutionId === null || resolutionId === undefined) {
            throw new Error('Required parameter resolutionId was null or undefined when calling deleteBenefitsForBeneficiaryAndResolution.');
        }
        if (beneficiaryId === null || beneficiaryId === undefined) {
            throw new Error('Required parameter beneficiaryId was null or undefined when calling deleteBenefitsForBeneficiaryAndResolution.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits/resolution/${encodeURIComponent(String(resolutionId))}/beneficiary/${encodeURIComponent(String(beneficiaryId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Beneficiaries and the benefits for a case
     * Get all Beneficiaries and the benefits for a case
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBeneficiariesAndBenefitsForCase(caseId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LitigationBeneficiaryVO>>;
    public getAllBeneficiariesAndBenefitsForCase(caseId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LitigationBeneficiaryVO>>>;
    public getAllBeneficiariesAndBenefitsForCase(caseId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LitigationBeneficiaryVO>>>;
    public getAllBeneficiariesAndBenefitsForCase(caseId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getAllBeneficiariesAndBenefitsForCase.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LitigationBeneficiaryVO>>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Beneficiaries and the benefits for a resolution in a case
     * Get all Beneficiaries and the benefits for a resolution in a case
     * @param caseId 
     * @param resolutionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBeneficiariesAndBenefitsForResolution(caseId: number, resolutionId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LitigationBeneficiaryVO>>;
    public getAllBeneficiariesAndBenefitsForResolution(caseId: number, resolutionId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LitigationBeneficiaryVO>>>;
    public getAllBeneficiariesAndBenefitsForResolution(caseId: number, resolutionId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LitigationBeneficiaryVO>>>;
    public getAllBeneficiariesAndBenefitsForResolution(caseId: number, resolutionId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getAllBeneficiariesAndBenefitsForResolution.');
        }
        if (resolutionId === null || resolutionId === undefined) {
            throw new Error('Required parameter resolutionId was null or undefined when calling getAllBeneficiariesAndBenefitsForResolution.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LitigationBeneficiaryVO>>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits/resolution/${encodeURIComponent(String(resolutionId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Beneficiaries (without benefits) for a case
     * Get all Beneficiaries for a case. This can be used when benefits are not needed
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBeneficiariesForCase(caseId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LitigationBeneficiaryVO>>;
    public getAllBeneficiariesForCase(caseId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LitigationBeneficiaryVO>>>;
    public getAllBeneficiariesForCase(caseId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LitigationBeneficiaryVO>>>;
    public getAllBeneficiariesForCase(caseId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getAllBeneficiariesForCase.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LitigationBeneficiaryVO>>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/beneficiaries`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the benefits for a Beneficiary of a Litigation case
     * Get all the benefits for a Beneficiary of a Litigation case
     * @param caseId 
     * @param beneficiaryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LitigationBeneficiaryVO>;
    public getBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LitigationBeneficiaryVO>>;
    public getBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LitigationBeneficiaryVO>>;
    public getBenefitsForBeneficiary(caseId: number, beneficiaryId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getBenefitsForBeneficiary.');
        }
        if (beneficiaryId === null || beneficiaryId === undefined) {
            throw new Error('Required parameter beneficiaryId was null or undefined when calling getBenefitsForBeneficiary.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LitigationBeneficiaryVO>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits/beneficiary/${encodeURIComponent(String(beneficiaryId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Beneficiary Metadata for a beneficiary of a case
     * Update the Beneficiary Metadata for a beneficiary of a case. Benefits are not updated
     * @param caseId 
     * @param litigationBeneficiaryVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBeneficiaryMetadataForCase(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LitigationBeneficiaryVO>;
    public updateBeneficiaryMetadataForCase(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LitigationBeneficiaryVO>>;
    public updateBeneficiaryMetadataForCase(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LitigationBeneficiaryVO>>;
    public updateBeneficiaryMetadataForCase(caseId: number, litigationBeneficiaryVO: LitigationBeneficiaryVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling updateBeneficiaryMetadataForCase.');
        }
        if (litigationBeneficiaryVO === null || litigationBeneficiaryVO === undefined) {
            throw new Error('Required parameter litigationBeneficiaryVO was null or undefined when calling updateBeneficiaryMetadataForCase.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationBeneficiaryVO>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/beneficiaries`,
            litigationBeneficiaryVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the benefits for a beneficiary and Resolution
     * Update the benefits for a beneficiary and Resolution. Only send changed benefits (add, update, delete)
     * @param caseId 
     * @param resolutionId 
     * @param beneficiaryId 
     * @param litigationBenefitDetailVO 
     * @param numBenefitted 
     * @param litigationChargePartyAsscId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, litigationBenefitDetailVO: Array<LitigationBenefitDetailVO>, numBenefitted?: number, litigationChargePartyAsscId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, litigationBenefitDetailVO: Array<LitigationBenefitDetailVO>, numBenefitted?: number, litigationChargePartyAsscId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, litigationBenefitDetailVO: Array<LitigationBenefitDetailVO>, numBenefitted?: number, litigationChargePartyAsscId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateBenefitsForBeneficiaryAndResolution(caseId: number, resolutionId: number, beneficiaryId: number, litigationBenefitDetailVO: Array<LitigationBenefitDetailVO>, numBenefitted?: number, litigationChargePartyAsscId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling updateBenefitsForBeneficiaryAndResolution.');
        }
        if (resolutionId === null || resolutionId === undefined) {
            throw new Error('Required parameter resolutionId was null or undefined when calling updateBenefitsForBeneficiaryAndResolution.');
        }
        if (beneficiaryId === null || beneficiaryId === undefined) {
            throw new Error('Required parameter beneficiaryId was null or undefined when calling updateBenefitsForBeneficiaryAndResolution.');
        }
        if (litigationBenefitDetailVO === null || litigationBenefitDetailVO === undefined) {
            throw new Error('Required parameter litigationBenefitDetailVO was null or undefined when calling updateBenefitsForBeneficiaryAndResolution.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (numBenefitted !== undefined && numBenefitted !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>numBenefitted, 'numBenefitted');
        }
        if (litigationChargePartyAsscId !== undefined && litigationChargePartyAsscId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>litigationChargePartyAsscId, 'litigationChargePartyAsscId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/benefits/resolution/${encodeURIComponent(String(resolutionId))}/beneficiary/${encodeURIComponent(String(beneficiaryId))}`,
            litigationBenefitDetailVO,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
