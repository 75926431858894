<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom margin-bottom-20">
	<h5 class="arc-h5" matDialogTitle>
		{{ data.header }}
	</h5>
	<div class="col-sm-2 text-right margin-right-10">
		<app-close-mat-dialog (closeDialog)="onClose()"></app-close-mat-dialog>
	</div>
</div>

<mat-dialog-content class="mat-typography-custom-title min-width-400 border-bottom padding-top-10">
	<div
		class="arc-subtitle-1 text-with-newlines remove-outline margin-left-20 margin-right-20"
		*ngIf="data.question"
		tabindex="0"
		[innerHTML]="data.question"
		[attr.aria-label]="data.question"
	></div>

	<label *ngIf="data?.textAreaLabel" class="arc-subtitle-1 padding-left-right-20 margin-bottom-20">
		{{ data?.textAreaLabel }}
	</label>

	<mat-form-field
		*ngIf="data.textarea"
		style="width: 90%"
		class="margin-left-20 margin-right-20 input-full-width"
		appearance="outline"
	>
		<mat-label>Enter Reason</mat-label>
		<textarea
			matInput
			cdkTextareaAutosize
			[maxLength]="data?.textAreaMaxLength || 1500"
			spellcheck="true"
			[(ngModel)]="data.reason"
			rows="5"
			placeholder="Enter Reason"
			required
			id="enter-reason-text"
		>
		</textarea>
		<div class="text-right text-area-count">
			{{ data?.reason?.length || 0 }} / {{ data?.textAreaMaxLength || 1500 }}
		</div>
	</mat-form-field>
	<mat-form-field
		*ngIf="data.showOptions"
		style="width: 90%"
		class="margin-left-20 margin-right-20 input-full-width"
		appearance="outline"
	>
		<mat-label>Select Reason</mat-label>
		<mat-select placeholder="Select Reason" id="reasonOption" [(ngModel)]="data.reason" required>
			<mat-option *ngFor="let option of options" [value]="option">
				{{ option }}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="blue-gb margin-20" *ngIf="data?.confirmationMessage">
		<mat-checkbox
			[(ngModel)]="confirmed"
			name="accept"
			class="checkbox-min-width margin-checkbox text-center mr-2"
			id="confirmation-checkbox"
		>
			<span class="arc-subtitle-1 p-2">{{ data?.confirmationMessage }}</span>
		</mat-checkbox>
	</div>

	<div class="grey-gb margin-20 d-flex flex-column" *ngIf="data?.information">
		<div class="col-12 padding-0 d-flex justify-content-start align-items-center">
			<mat-icon *ngIf="data?.actionType === 'Non-disclosable'">lock</mat-icon>
			<mat-icon *ngIf="data?.actionType === 'Disclosable'">lock_open</mat-icon>
			<span class="arc-subtitle-1"
				><strong>{{ data?.actionType }}</strong>
			</span>
		</div>
		<span class="arc-subtitle-1 margin-top-20">{{ data?.information }}</span>
	</div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" class="margin-10 padding-0">
	<div>
		<button
			type="submit"
			mat-button
			id="cancel-unit-change-confirmation-btn"
			[mat-dialog-close]="null"
			color="primary"
			*ngIf="data.secondButton && data.header === 'Unit Change Confirmation'"
		>
			Cancel
		</button>
		<button
			type="submit"
			mat-button
			color="primary"
			id="{{ data.secondButton ? data.secondButton?.toUpperCase() : 'cancel' }}-modal-btn"
			[mat-dialog-close]="false"
			aria-label="{{ data.secondButton ? data.secondButton?.toUpperCase() : 'cancel' }}"
		>
			{{ data.secondButton ? data.secondButton?.toUpperCase() : 'Cancel' }}
		</button>
	</div>
	<button
		type="button"
		class="margin-right-10"
		mat-flat-button
		[color]="data.color || 'primary'"
		aria-label="{{ data.button }}"
		[mat-dialog-close]="data.reason ? data.reason : true"
		[disabled]="disableContinueButton()"
		id="{{ data.button }}-modal-btn"
	>
		{{ data.button?.toUpperCase() }}
	</button>
</mat-dialog-actions>
