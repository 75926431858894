import { createAction, props } from "@ngrx/store"
export const GET_APPEAL_DATA = '[appeal page] data';
export const APPEAL_DATA_SUCCESS = '[appeal page] success';
export const APPEAL_DATA__FAIL = '[appeal page] fail';
export const GET_RESOLUTION_DATA = '[resolution page] data';
export const RESOLUTION_DATA_SUCCESS = '[resolution page] success';
export const RESOLUTION_DATA__FAIL = '[resolution page] fail';
export const GET_DEFENDANT_DATA = '[defendant page] data';
export const DEFENDANT_DATA_SUCCESS = '[defendant page] success';
export const DEFENDANT_DATA_FAIL = '[defendant page] fail';
export const GET_APPEAL_STATUS = '[appeal status] data';
export const APPEAL_STATUS_SUCCESS = '[appeal status] success';
export const APPEAL_STATUS_FAIL = '[appeal status] fail';
export const GET_COURT_INFORMATION = '[court information] data';
export const COURT_INFORMATION_SUCCESS = '[court information] success';
export const COURT_INFORMATION_FAIL = '[court information] fail';
export const GET_APPEAL_SHARED_CODE = '[appeal shared] data';
export const APPEAL_SHARED_CODE_SUCCESS = '[appeal shared] success';
export const APPEAL_SHARED_CODE_FAIL = '[appeal shared] fail';





export const appealData = createAction(
    GET_APPEAL_DATA,
    props<{ payload }>()
);


export const appealDataSuccess = createAction(APPEAL_DATA_SUCCESS, props<{ appealData: any }>());
export const appealDataFail = createAction(
    APPEAL_DATA__FAIL,
    props<{ message: string }>()
);

export const resolutionData = createAction(
    GET_RESOLUTION_DATA,
    props<{ payload }>()
);


export const resolutionDataSuccess = createAction(RESOLUTION_DATA_SUCCESS, props<{ resolutionData: any }>());
export const resolutionDataFail = createAction(
    RESOLUTION_DATA__FAIL,
    props<{ message: string }>()
);

export const defendantData = createAction(
    GET_DEFENDANT_DATA,
    props<{ payload }>()
);


export const defendantDataSuccess = createAction(DEFENDANT_DATA_SUCCESS, props< {defendantData: any }>());
export const defendantDataFail = createAction(
    DEFENDANT_DATA_FAIL,
    props<{ message: string }>()
);

export const courtInformation = createAction(
    GET_COURT_INFORMATION
);

export const courtInformationSuccess = createAction(COURT_INFORMATION_SUCCESS, props< { courtInformation: any }>());
export const courtInformationFail = createAction(
    COURT_INFORMATION_FAIL,
    props<{ message: string }>()
);


export const appealStatus = createAction(
    GET_APPEAL_STATUS
);

export const appealStatusSuccess = createAction(APPEAL_STATUS_SUCCESS, props< { appealStatus: any }>());
export const appealStatusFail = createAction(
    APPEAL_STATUS_FAIL,
    props<{ message: string }>()
);

export const appealSharedCode = createAction(
    GET_APPEAL_SHARED_CODE
);

export const appealSharedCodeSuccess = createAction(APPEAL_SHARED_CODE_SUCCESS, props< { appealSharedCode: any }>());
export const appealSharedCodeFail = createAction(
    APPEAL_SHARED_CODE_FAIL,
    props<{ message: string }>()
);

export const dummyAction = createAction('[dummyAction]');
