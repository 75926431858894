import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LookUpDataTypes } from '../shared/common-structures/app.constants';
import { ChargeSharedCode } from '../shared/common-structures/shared-code';
import { Utils } from '../shared/utility/utils';
import {
	PortalResourceService,
	PrepaAssessmentResourceService,
	PrepaClosureResourceService,
	PrepaLookupDataResourceService,
	PrepaOfficeResourceService,
} from './gen';
import { StatuteBasisIssueResourceService } from './gen/api/statuteBasisIssueResource.service';
import { CaseReceivedTypeVO, CategoryAreaVO, LookupData, SharedZipCode, StatuteVO } from './gen/model/models';
import { Role, RoleResourceService } from './UserManagement';

@Injectable({
	providedIn: 'root',
})
export class SharedCodeService {
	sharedCodes$: any = {};
	sharedPchpCodes$: any = {};
	chargeSharedCodes$: any = {};
	rolesDict$: Observable<Role[]>;
	sbiCodes$: Observable<StatuteVO[]>;

	constructor(
		private http: HttpClient,
		private lookUpServ: PrepaLookupDataResourceService,
		private officeServ: PrepaOfficeResourceService,
		private accessmentServ: PrepaAssessmentResourceService,
		private umRoleServ: RoleResourceService,
		private statuteBasisIssueServ: StatuteBasisIssueResourceService,
		private closureServ: PrepaClosureResourceService,
		private portalServ: PortalResourceService
	) {}

	/**
	 * @description Call lookup service to get shared codes
	 * @param domain
	 */
	getSharedCodes(
		domain,
		officeCode?: string,
		officeType?: 'PRIVATE' | 'FEPA' | 'DEFERRAL' | 'ACCOUNTABILITY',
		includeObsolete?:boolean
	): Observable<LookupData[]> {
		if (domain === LookUpDataTypes.OFFICE) {
			return this.getOffices(officeCode, officeType, includeObsolete);
		}
		if (!this.sharedCodes$[domain]) {
			this.sharedCodes$[domain] = this.lookUpServ.getLookupDataByDomain(domain).pipe(
				map((res) => {
					res = res ? _.sortBy(res, (o) => o?.description?.toLowerCase()) : [];
					return res;
				}),
				shareReplay(1),
				catchError((error) => of(error))
			);
		}
		return this.sharedCodes$[domain];
	}

	private getOffices(officeCode?: string, officeType?: 'PRIVATE' | 'FEPA' | 'DEFERRAL' | 'ACCOUNTABILITY', includeObsolete?:boolean) {
		return this.officeServ.getOffices(officeCode, officeType, null, includeObsolete);
	}

	/**
	 * @description get the area info by zip code. Area info includes zip,city,state,county.
	 * @param zip
	 */
	public getAreaInfoByZip(zip: string): Observable<SharedZipCode[]> {
		if (!zip || zip.length < 4) {
			return of(null);
		}
		return this.lookUpServ.getLocationDataByZipCode(zip).pipe(map((res) => (res ? res : []))) as Observable<
			SharedZipCode[]
		>;
	}

	public getAreaInfoByUSPSService(zip: string): Observable<any[]> {
		const url = `${environment.empdb}/address-lookup/zip-code/${zip}`;
		if (!zip || zip.length < 4) {
			return of(null);
		}
		return this.http.get(url).pipe(map((res) => (res ? res : []))) as Observable<
			SharedZipCode[]
		>;
	}

	/**
	 * @description get received by shared codes
	 */
	getReceivedTypeSharedCodes(includeObsolete?: boolean): Observable<CaseReceivedTypeVO[]> {
		return this.lookUpServ.getReceivedTypeSharedCodes(includeObsolete).pipe(
			map((res) => (res ? res : [])),
			catchError((error) => of(error))
		);
	}

	getPchpCategoryByArea(categoryArea: string, officeCode?: string): Observable<CategoryAreaVO> {
		if (!this.sharedPchpCodes$[categoryArea]) {
			this.sharedPchpCodes$[categoryArea] = this.accessmentServ.getCategoryArea(categoryArea, officeCode).pipe(
				map((res) => (res ? res : [])),
				shareReplay(1),
				catchError((error) => of(error))
			);
		}
		return this.sharedPchpCodes$[categoryArea];
	}

	getDistrictOffice(accountabilityOfficeCode: string): Observable<LookupData> {
		return this.officeServ.getDistrictOffice(accountabilityOfficeCode);
	}

	/**
	 * @description gets roles by domain id from umws
	 */
	getRoles(): Observable<Role[]> {
		if (!this.rolesDict$) {
			this.rolesDict$ = this.umRoleServ.getRoles().pipe(
				map((res) => (res ? res : [])),
				shareReplay(1),
				catchError((error) => [])
			);
		}
		return this.rolesDict$ as Observable<Role[]>;
	}

	/**
	 * @description get a list of all allegations
	 */
	getAllegationSBI(): Observable<StatuteVO[]> {
		if (!this.sbiCodes$) {
			this.sbiCodes$ = this.statuteBasisIssueServ.getStatuteBasisIssueCombination().pipe(
				map((res) => (res ? res : [])),
				shareReplay(1),
				catchError((error) => [])
			);
		}
		return this.sbiCodes$;
	}

	/**
	 * @description get a list of all project codes for any case
	 */
	getProjectCodeDetails(): Observable<any> {
		return this.lookUpServ.getLookupDataByDomain('PROJ');
	}

	getChargeClosureReasons(isFepa?): Observable<Array<LookupData>> {
		return this.closureServ.getChargeClosureReasons(isFepa);
	}

	/**
	 * @description get a list of all status codes for any case
	 */
	 getStatusCodeDetails(): Observable<any> {
		return this.lookUpServ.getLookupDataByDomain('STS');
	}

	/**
	 * @description get a list of all roles for any litigation case
	 */
	 getLitRoleDetails(): Observable<any> {
		return this.lookUpServ.getLookupDataByDomain('LAR');
	}

	public setInvestigatingAgency(cases: Array<number>): Observable<boolean> {
		return this.portalServ.setInvestigatingAgency(cases);
	}
}
