import { Injectable } from '@angular/core';
import  moment from 'moment';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { concatMap, delay, map, mergeMap, retry, takeUntil, timeout } from 'rxjs/operators';
import { CaseDetailV2 } from '../shared/common-structures/case-detail';
import { Utils } from '../shared/utility/utils';
import { LoadingService } from './loading/loading.service';
import { CaseRequestParam, LitigationRequestParam, PrepaSearchResourceService } from './SearchDataWebService';
import { LitigationSearchResourceService } from './SearchDataWebService/api/litigationSearchResource.service';
export interface WsCasesResponseInterface {
	dataRecords: CaseDetailV2[];
	totalNumberOfResults: number;
}

@Injectable({
	providedIn: 'root',
})
export class SearchDataService {
  isLoading = false;
	unaggregatableFields = [
		'craReviewStatus',
		'craAssignedToUser',
		'caChargeAssignmentId',
		'caAssignedTo',
		'caFirstName',
		'caLastName',
		'caAssignmentEndedOn',
	];
	scrollId = null;
	temppageSize = 500;
	public results$: BehaviorSubject<Array<CaseDetailV2>> = new BehaviorSubject([]);
	public destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(private _loaderService: LoadingService,
		private searchDataServ: PrepaSearchResourceService,
		private litSearchDataServ: LitigationSearchResourceService
		) { }

	private deleteScrollId(scrollId: string) {
    this._loaderService.isLoading=false;
		this.searchDataServ.deleteScrollId(scrollId).subscribe(
			(_) => {},
			(err) => {
				throw err;
			}
		);
	}

	/**
	 * @description v2 returns object
	 * @param payload
	 */
	 public searchCaseDetailV2(payload: CaseRequestParam, sortRequired = true): Observable<any> {
		if (payload.caAssignedTo) payload['caAssignmentEndedOn'] = 'NULL';
		return this.searchDataServ.searchCaseDetail2(payload, 0, 500, null, 'N', null, 'response').pipe(
			timeout(20000),
			retry(2),
			map((rs: any) => {
				const totalNumberOfResults = Number(rs.headers.get('totalNumberOfResults'));
				const raw = rs.body || [];
				this.processCasesV2(raw, sortRequired);
				raw.totalNumberOfResults = totalNumberOfResults;
				return raw;
			})
		);
	}

	/**
	 * @description call search data service to get all data with several calls.
	 * Utilize server side pagination and make parallel calls to achieve better performance.
	 * @param payload
	 */

	public searchCaseDetails(
		payload: CaseRequestParam,
		pageIndex = 0,
		pageSize = 10
	): Observable<WsCasesResponseInterface> {
		if (pageIndex === 0 && this.scrollId) {
			this.deleteScrollId(this.scrollId);
			this.scrollId = null;
		}
		if (payload.caAssignedTo) payload['caAssignmentEndedOn'] = 'NULL';
		return this.searchDataServ.searchCaseDetail2(payload, pageIndex, pageSize, this.scrollId, 'Y', null, 'response').pipe(
			timeout(20000),
			mergeMap((rs: any) => {
				// get scrollid and totalNumberOfResults from header
				const totalNumberOfResults = Number(rs.headers.get('totalNumberOfResults'));
				this.scrollId = rs.headers.get('scrollid');
				const raw = rs.body || [];
				this.processCasesV2(raw);
				return of({
					dataRecords: raw,
					totalNumberOfResults: totalNumberOfResults,
				});
			})
		);
	}

	public searchCaseDetailPag(officeCode: string, isPrivate: boolean, extraParams = {}, params = {}) {
		this._loaderService.show();
		this.results$.next([]);
    this._loaderService.isLoading=true;
		let payload: CaseRequestParam = {
			isExactMatch: 'Y',
			ciIsFepa: isPrivate ? 'N' : 'Y',
		};
		if (Object.keys(params).length > 0) {
			payload = params;
		}
		if (officeCode && officeCode !== 'ALL') payload['ciAcctOfficeCode'] = officeCode;
		if (Object.keys(extraParams)?.length) payload = { ...payload, ...extraParams };
		if (payload.caAssignedTo || payload.ciUnassigned === 'N') payload['caAssignmentEndedOn'] = 'NULL';
		let startPosition = 0;
		return this.searchDataServ.searchCaseDetail2(payload, startPosition, this.temppageSize, null, 'Y', null, 'response').pipe(
			timeout(20000),
			// Use mergeMap to deal with inner subscriptions. Note that mergeMap needs to return an observable.
			map((rs) => {
				// get scrollid and totalNumberOfResults from header
				const scrollId = rs.headers.get('scrollid');
				const totalNumberOfResults = Number(rs.headers.get('totalNumberOfResults'));
				// current raw data is only the first page of the full result
				let allCases: CaseDetailV2[] = rs.body || [];
				// update start position of next page
				startPosition = startPosition + allCases.length;
				if (startPosition < totalNumberOfResults) {
					// generate requests to get get all other pages
					const requests = [];
					while (startPosition < totalNumberOfResults) {
						requests.push(
							this.searchDataServ.searchCaseDetail2(payload, startPosition, this.temppageSize, scrollId, 'Y')
						);
						startPosition = startPosition + this.temppageSize;
					}
					from(requests)
						.pipe(concatMap((request) => request.pipe(delay(1000))))
						.pipe(takeUntil(this.destroy$))
						.subscribe((res: CaseDetailV2[]) => {
							allCases = [...(allCases || []), ...(res || [])];
							if (allCases?.length === totalNumberOfResults) {
								if (scrollId) this.deleteScrollId(scrollId);
								this.processCasesV2(allCases);
								this.results$.next(allCases);
							}
						});

					return this.results$;
				} else {
					// first page already have all the data. aggregate & process & return results.
					// also needs to delete scroll id.
					this.destroy$.next(true);
					if (scrollId) this.deleteScrollId(scrollId);
					this.processCasesV2(allCases);
					this.results$.next(allCases);
				}
			})
		);
	}
	public generateDasBoardNrtsUploaded(status: string, officeCode: string, docType: string, includeEventCode: string, excludeEventCode: string, ciClosureDate: string) {
		return this.searchDataServ.generateDasBoardNrtsUploaded(status, officeCode, docType, includeEventCode, excludeEventCode, ciClosureDate);

	}

	public searchCaseDetailDocuments(officeCode: string, isPrivate: boolean, queryObject: any) {
		let ciAcctOfficeCode;
		if (officeCode && officeCode !== 'ALL')  ciAcctOfficeCode= officeCode;
		return this.searchDataServ.getChargesByIncludeDocTypesExcludeDocType( ciAcctOfficeCode, queryObject.ciStatusCode,queryObject.includeDoctype, queryObject.excludeDoctype, queryObject.eventcode);
	}

	public searchLitigationCaseDetailPag(officeCode: string, extraParams = {}, params = {}) {
		this._loaderService.show();
		this.results$.next([]);
    this._loaderService.isLoading=true;
		let payload: LitigationRequestParam = {
		};
		if (Object.keys(params).length > 0) {
			payload = params;
		}
		if (officeCode && officeCode !== 'ALL') payload['lcAcctOfficeCode'] = officeCode;
		if (Object.keys(extraParams)?.length) payload = { ...payload, ...extraParams };
		let startPosition = 0;
		return this.litSearchDataServ.searchLitigation(payload, startPosition, this.temppageSize, null, 'Y', 'response').pipe(
			timeout(20000),
			// Use mergeMap to deal with inner subscriptions. Note that mergeMap needs to return an observable.
			map((rs) => {
				// get scrollid and totalNumberOfResults from header
				const scrollId = rs.headers.get('scrollid');
				const totalNumberOfResults = Number(rs.headers.get('totalNumberOfResults'));
				// current raw data is only the first page of the full result
				let allCases: CaseDetailV2[] = rs.body || [];
				// update start position of next page
				startPosition = startPosition + allCases.length;
				if (startPosition < totalNumberOfResults) {
					// generate requests to get get all other pages
					const requests = [];
					while (startPosition < totalNumberOfResults) {
						requests.push(
							this.litSearchDataServ.searchLitigation(payload, startPosition, this.temppageSize, scrollId, 'Y')
						);
						startPosition = startPosition + this.temppageSize;
					}
					from(requests)
						.pipe(concatMap((request) => request.pipe(delay(1000))))
						.pipe(takeUntil(this.destroy$))
						.subscribe((res: CaseDetailV2[]) => {
							allCases = [...(allCases || []), ...(res || [])];
							if (allCases?.length === totalNumberOfResults) {
								this._loaderService.hide();
								if (scrollId) this.deleteScrollId(scrollId);
								this.processLitigationCases(allCases);
								this.results$.next(allCases);
							}
						});

					return this.results$;
				} else {
					// first page already have all the data. aggregate & process & return results.
					// also needs to delete scroll id.
					this.destroy$.next(true);
					this._loaderService.hide();
					if (scrollId) this.deleteScrollId(scrollId);
					this.processLitigationCases(allCases);
					this.results$.next(allCases);
				}
			})
		);
	}

	public searchCaseDetailsPagNew(officeCode: string, isPrivate: boolean, extraParams = {}) {
		this.results$.next([]);
    this._loaderService.isLoading=true;
		const data$ = new Subject();
		const oldDate = moment().subtract(24, 'months').format('MM-DD-YYYY');
		forkJoin(
			this.searchCaseDetailPagPart(officeCode, isPrivate, 'eq:01-01-1970', extraParams),
			this.searchCaseDetailPagPart(officeCode, isPrivate, `gte:${oldDate}`, extraParams)
		).subscribe((res: any) => {
			res = [...res[0], ...res[1]];
			res = res.filter(
				(x: CaseDetailV2) =>
					x.ciStatusCode != 'CLOSED' ||
					x.chargeAllegations.length == 0 ||
					x.chargeAllegations.some((y) => y.alIsFederalAllegation === 'Y')
			);
			this.results$.next(res);
			this._loaderService.hide();
			data$.next(true);
			data$.complete();
		});
		return data$;
	}
	public searchCaseDetailPagPart(officeCode: string, isPrivate: boolean, dateStr: string, extraParams = {}) {
		let payload: CaseRequestParam = {
			isExactMatch: 'Y',
			ciIsFepa: isPrivate ? 'N' : 'Y',
			fcrEeocrespondedOn: dateStr,
		};
		if (officeCode && officeCode !== 'ALL') payload['ciAcctOfficeCode'] = officeCode;
		if (Object.keys(extraParams)?.length) payload = { ...payload, ...extraParams };
		if (payload.caAssignedTo || payload.ciUnassigned === 'N') payload['caAssignmentEndedOn'] = 'NULL';
		let startPosition = 0;
		const data$ = new Subject();
		const destroy$: Subject<boolean> = new Subject<boolean>();
		let req = this.searchDataServ
			.searchCaseDetail2(payload, startPosition, this.temppageSize, null, 'Y', null, 'response')
			.pipe(
				timeout(20000),
				// Use mergeMap to deal with inner subscriptions. Note that mergeMap needs to return an observable.
				map((rs) => {
					// get scrollid and totalNumberOfResults from header
					const scrollId = rs.headers.get('scrollid');
					const totalNumberOfResults = Number(rs.headers.get('totalNumberOfResults'));
					// current raw data is only the first page of the full result
					let allCases: CaseDetailV2[] = rs.body || [];
					// update start position of next page
					startPosition = startPosition + allCases.length;
					if (startPosition < totalNumberOfResults) {
						// generate requests to get get all other pages
						const requests = [];
						while (startPosition < totalNumberOfResults) {
							requests.push(
								this.searchDataServ.searchCaseDetail2(payload, startPosition, this.temppageSize, scrollId, 'Y')
							);
							startPosition = startPosition + this.temppageSize;
						}
						from(requests)
							.pipe(concatMap((request) => request.pipe(delay(1000))))
							.pipe(takeUntil(destroy$))
							.subscribe((res: CaseDetailV2[]) => {
								allCases = [...(allCases || []), ...(res || [])];
								if (allCases?.length === totalNumberOfResults) {
									if (scrollId) this.deleteScrollId(scrollId);
									this.processCasesV2(allCases);
									data$.next(allCases);
									data$.complete();
								}
							});
					} else {
						// first page already have all the data. aggregate & process & return results.
						// also needs to delete scroll id.
						destroy$.next(true);
						if (scrollId) this.deleteScrollId(scrollId);
						this.processCasesV2(allCases);
						data$.next(allCases);
						data$.complete();
					}
				})
			); //
		setTimeout(() => {
			req.subscribe(
				(res) => {},
				(err) => {
					data$.next([]);
					data$.complete();
				}
			);
		}, 250);
		return data$;
	}
	public searchLogDetail(payload) {
		return this.searchDataServ.searchLogDetail(payload);
	}

	/**
	 * @description data clean & pre-process
	 * @param cases
	 */
	public processCasesV2(cases: CaseDetailV2[], sortRequired = true) {
		cases.forEach((item) => {
			if (item?.chargeAssignments?.length) {
				const activeAssignments = item.chargeAssignments.filter(
					(i) => i.caAssignmentEndedOn == null || i.caAssignmentEndedOn == undefined
				);
				item.chargeAssignments = activeAssignments;
			}
			if (item.ciClosureDate) item.ciClosureAge = Utils.calculateAge(item.ciClosureDate);
			item.urgentPriorityFlag = item.ciIsUrgent === 'Y' ? (this.isUrgent(item) ? 1 : this.isUntimely(item) ? 2 : 3) : 4;
		});
		if (sortRequired) {
			cases.sort(function ascUrgentScoreFlag(a, b) {
				return a.urgentPriorityFlag - b.urgentPriorityFlag;
			});
		}
    this._loaderService.hide();

	}

	public processLitigationCases(cases: any[], sortRequired = false) {
		cases.forEach((item) => {
			if (item?.litigationAssignments?.length) {
				item.assignmentNames = item.litigationAssignments.map(x=>`${x.laFirstName} ${x.laLastName} (${Utils.calculateAge(x.laAssignedOn)})`);
				item.assignmentNames.sort((a,b)=>a>b?-1:1);
				//item.assignmentAges = item.litigationAssignments.map(x=>Utils.calculateAge(x.laAssignedOn));
			}
			item.lcStatusAge = Utils.calculateAge(item.lcStatusUpdatedOnDate)

		});
		if (sortRequired) {
			cases.sort(function ascUrgentScoreFlag(a, b) {
				return a.urgentPriorityFlag - b.urgentPriorityFlag;
			});
		}
	}

	public isUrgent(row: CaseDetailV2): boolean {
		return (
			row.ciIsUrgent === 'Y' &&
			row.ciFilingDeadlineDate &&
			!row.ciFormalizationDate &&
			this.calculateDateDiffDays(new Date(), new Date(row.ciFilingDeadlineDate)) >= 0
		);
	}
	public isUntimely(row: CaseDetailV2): boolean {
		return (
			row.ciIsUrgent === 'Y' &&
			!row.ciFormalizationDate &&
			(!row.ciFilingDeadlineDate || this.calculateDateDiffDays(new Date(), new Date(row.ciFilingDeadlineDate)) < 0)
		);
	}

	// This method is used to calcuate exact days difference between two dates
	calculateDateDiffDays(startDate: Date, endDate: Date): number {
		let diff = 0;
		// making sure none of the dates are null
		if (startDate && endDate) {
			const oneDay = 1000 * 60 * 60 * 24;

			// First format the date by removing any timestamp for calculation to be easy.
			const startDateFormatted = new Date(moment(startDate).format('MM-DD-YYYY'));
			const endDateFormatted = new Date(moment(endDate).format('MM-DD-YYYY'));

			// A day in UTC always lasts 24 hours (unlike in other time formats)
			const start = Date.UTC(endDateFormatted.getFullYear(), endDateFormatted.getMonth(), endDateFormatted.getDate());
			const end = Date.UTC(
				startDateFormatted.getFullYear(),
				startDateFormatted.getMonth(),
				startDateFormatted.getDate()
			);

			// so it's safe to divide by 24 hours
			diff = (start - end) / oneDay;
		}
		return diff;
	}

      /**
     * @param officeCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
       public getUnservedNoc(officeCode: string): Observable<any> {
        return this.searchDataServ.getUnservedNoc(officeCode);
       }

	searchLitigation(reqParam: LitigationRequestParam): Observable<any> {
		return this.litSearchDataServ.searchLitigation(reqParam);
	}


}