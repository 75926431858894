import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.css'],
	encapsulation: ViewEncapsulation.None, // Use the native Shadow DOM to encapsulate our CSS
})
export class LoadingComponent implements OnDestroy {
	_template = `
      <div class="sc-circle">
        <div class="sc-circle1 sc-child"></div>
        <div class="sc-circle2 sc-child"></div>
        <div class="sc-circle3 sc-child"></div>
        <div class="sc-circle4 sc-child"></div>
        <div class="sc-circle5 sc-child"></div>
        <div class="sc-circle6 sc-child"></div>
        <div class="sc-circle7 sc-child"></div>
        <div class="sc-circle8 sc-child"></div>
        <div class="sc-circle9 sc-child"></div>
        <div class="sc-circle10 sc-child"></div>
        <div class="sc-circle11 sc-child"></div>
        <div class="sc-circle12 sc-child"></div>
      </div>`;

	/**
	 * @description Loading text
	 * @type {string}
	 *
	 */
	_loadingText = '';

	/**
	 * @description Defines threhold for not to diplay if time is less than 500ms
	 * @type {number}
	 */
	_threshold = 5;

	/**
	 * @description Defines z-index property of the loading text
	 * @type {number}
	 *
	 */
	_zIndex = 9999;

	/**
	 * @description Sets z-index for input text
	 *
	 */
	@Input()
	public set zIndex(value: number) {
		this._zIndex = value;
	}

	/**
	 * @description returns z-index for input text
	 * @readonly
	 * @type {number}
	 *
	 */
	public get zIndex(): number {
		return this._zIndex;
	}

	/**
	 * @description Accepts custom template
	 *
	 */
	@Input()
	public set template(value: string) {
		this._template = value;
	}

	/**
	 * @description Gives the current template
	 * @readonly
	 * @type {string}
	 *
	 */
	public get template(): string {
		return this._template;
	}

	/**
	 * @description Accepts loading text string
	 *
	 */
	@Input()
	public set loadingText(value: string) {
		this._loadingText = value;
	}

	/**
	 * @description Gives loading text
	 * @readonly
	 * @type {string}
	 *
	 */
	public get loadingText(): string {
		return this._loadingText;
	}

	/**
	 * @description Accepts external threshold
	 *
	 */
	@Input()
	public set threshold(value: number) {
		this._threshold = value;
	}

	/**
	 * @description
	 * @readonly
	 * @type {number}
	 *
	 */
	public get threshold(): number {
		return this._threshold;
	}

	/**
	 * Subscription
	 * @type {Subscription}
	 *
	 */
	subscription: Subscription;

	/**
	 * @description Show/hide spinner
	 *
	 */
	showSpinner = false;

	/**
	 * Constructor
	 * @param {LoadingService} spinnerService Spinner Service
	 *
	 */
	constructor(private spinnerService: LoadingService) {
		this.createServiceSubscription();
	}

	/**
	 * Destroy function
	 *
	 */
	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
	/**
	 * Create service subscription
	 *
	 */
	createServiceSubscription() {
		let timer: any;

		this.subscription = this.spinnerService.getMessage().subscribe((show) => {
			if (show) {
				if (timer) {
					return;
				}

				timer = setTimeout(
					function () {
						timer = null;
						this.showSpinner = show;
					}.bind(this),
					this.threshold
				);
			} else {
				clearTimeout(timer);
				timer = null;
				this.showSpinner = false;
			}
		});
	}
}
