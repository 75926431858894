export class Alert {
	type: AlertType;
	message: string;
	header?: string;
}

export enum AlertType {
	Success,
	Error,
	Info,
	Warning,
	Deactivation,
}
