import { AlertService } from '../../../shared/ims-alerts/ims-alerts.service';
import { Document } from './document';

export class UserType {
	static readonly Private = 'PRIVATE';
	static readonly Fepa = 'FEPA';
}

class ActionType {
	static readonly Upload = 'UPLOAD';
	static readonly Edit = 'EDIT';
}

export class DocTypes {
	static readonly NOC = 'Notice of Charge';
	static readonly COD = 'Charge of Discrimination';
	static readonly AmendedCOD = 'Amended Charge of Discrimination';
	static readonly POS = 'Position Statement';
	static readonly POS_REDACT = 'Position Statement - Redacted';
	static readonly POS_ATTMNT_NON_CONF = 'Position Statement Attachments - Non-Confidential';
	static readonly POS_ATTMNT_NON_CONF_REDACT = 'Position Statement Attachments - Non-Confidential (Redacted)';
	static readonly SUPPL_POS = 'Supplemental Position Statement';
	static readonly SUPPL_POS_REDACT = 'Supplemental Position Statement-Redacted';
	static readonly RFI = 'Request for Information';
	static readonly ResponseRFI = 'Response to Request for Information';
	static readonly SDS = 'Sensitive Data Set';
	static readonly FORM212A = 'Charge Transmittal (Form 212-A)';
}

const UniqueDocumentTypes = {
	FEPA: [DocTypes.COD],
	PRIVATE: [DocTypes.COD, DocTypes.NOC, DocTypes.POS],
};

export class DocumentsUtil {
	static validateEdit(docEdit: Document, docList: Document[], appName: string, alrtServ: AlertService) {
		const otherDocuments = docList.filter((d) => d.documentNodeId !== docEdit.documentNodeId);
		if (this.duplicateCheck(docEdit, docList, appName)) {
			alrtServ.error('There can only be one ' + docEdit.documentType + '.');
			return false;
		}
		// cannot upload Ameneded COD before COD
		if (docEdit.documentType === DocTypes.AmendedCOD) {
			const COD = otherDocuments.find((d) => d.documentType === DocTypes.COD);
			if (!COD) {
				alrtServ.error('Please upload Charge of Discrmination before uploading the Amended Charge of Discrimination.');
				return false;
			}
		}
		// cannot upload POS Redacted before POS
		if (docEdit.documentType === DocTypes.POS_REDACT) {
			const POS = otherDocuments.find((d) => d.documentType === DocTypes.POS);
			if (!POS) {
				alrtServ.error('Please upload Position Statement before uploading Position Statement Redacted.');
				return false;
			}
		}

		if (docEdit.documentType === DocTypes.SUPPL_POS || docEdit.documentType === DocTypes.SUPPL_POS_REDACT) {
			const positionStatement = otherDocuments.find((d) => d.documentType === DocTypes.POS);
			const posRedacted = otherDocuments.find((d) => d.documentType === DocTypes.POS_REDACT);
			if (!positionStatement && !posRedacted) {
				alrtServ.error('Please upload Position Statement before uploading Supplemental Position Statement');
				return false;
			}
		}

		if (docEdit.documentType === DocTypes.RFI && !this.validRFIDocType(docEdit, ActionType.Edit)) {
			alrtServ.error('Request for Information should be in pdf format.');
			return false;
		}
		/* if (docEdit.documentType === DocTypes.RFI) {
      const requestForInformationDocs: Document[] = otherDocuments.filter(d => d.documentType === DocTypes.RFI);
      const responseToReqForInfoDocs: Document[] = otherDocuments.filter(d => d.documentType === DocTypes.ResponseRFI);
      if (requestForInformationDocs.length > responseToReqForInfoDocs.length) {
        alrtServ.error('Cannot upload another Request for Information Document. ' +
          'when the response to the previous request for Information Document is pending');
        return false;
      }
    }*/
		return true;
	}

	static validateDelete(docDelete: Document, docList: Document[], appName: string, alrtServ: AlertService) {
		// cannot delete POS before deleting POS Redacted
		if (docDelete.documentType === DocTypes.POS) {
			const otherDocuments = docList.filter((d) => d.documentNodeId !== docDelete.documentNodeId);
			const posRedacted = otherDocuments.find((d) => d.documentType === DocTypes.POS_REDACT);
			if (posRedacted) {
				alrtServ.error('Please delete Position Statement Redacted before deleting Position Statement.');
				return false;
			}
		}
		return true;
	}

	static duplicateCheck(doc: Document, docList: Document[], userType) {
		if (UniqueDocumentTypes[userType].indexOf(doc.documentType) >= 0) {
			const duplicateDoc = docList.find((d) => d.documentType === doc.documentType);
			if (duplicateDoc) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	static validRFIDocType(doc: Document, action: any) {
		if (action === ActionType.Edit) {
			// const mimeType = mime.lookup(doc.fileName);
			// return mimeType === 'application/pdf';
			return doc.fileName.includes('.pdf');
		}
	}

	static validateCaseStatus(status: any, alrtServ: AlertService) {
		if (!status.formalizationDate) {
			alrtServ.error('The case has not been formalized.');
			return;
		}
	}
}
