/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AmicusAppealCreation } from '../model/models';
import { AppealAssignmentVO } from '../model/models';
import { AppealRecommendation } from '../model/models';
import { AppealResolution } from '../model/models';
import { DefensiveAppealCreation } from '../model/models';
import { EnforcementAppealCreation } from '../model/models';
import { LitigationAppealVO } from '../model/models';
import { PlaintiffVO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LitigationAppealResourceService {

    protected basePath = 'https://dev.eeoc.gov/litigation';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Close an Appeal
     * Close an Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param closeRsnCode 
     * @param closeRsnTxt 
     * @param closedDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public closeAppeal(appealId: number, closeRsnCode?: string, closeRsnTxt?: string, closedDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public closeAppeal(appealId: number, closeRsnCode?: string, closeRsnTxt?: string, closedDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public closeAppeal(appealId: number, closeRsnCode?: string, closeRsnTxt?: string, closedDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public closeAppeal(appealId: number, closeRsnCode?: string, closeRsnTxt?: string, closedDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling closeAppeal.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (closeRsnCode !== undefined && closeRsnCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closeRsnCode, 'closeRsnCode');
        }
        if (closeRsnTxt !== undefined && closeRsnTxt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closeRsnTxt, 'closeRsnTxt');
        }
        if (closedDate !== undefined && closedDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closedDate, 'closedDate');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/close`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create an Amicus Appeal
     * Create an Amicus Appeal
     * @param amicusAppealCreation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAmicusAppeal(amicusAppealCreation: AmicusAppealCreation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public createAmicusAppeal(amicusAppealCreation: AmicusAppealCreation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public createAmicusAppeal(amicusAppealCreation: AmicusAppealCreation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public createAmicusAppeal(amicusAppealCreation: AmicusAppealCreation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (amicusAppealCreation === null || amicusAppealCreation === undefined) {
            throw new Error('Required parameter amicusAppealCreation was null or undefined when calling createAmicusAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/amicus-appeal`,
            amicusAppealCreation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add new assignees to a Litigation Appeal
     * Add new assignees to a Litigation Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public createAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public createAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public createAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling createAssignmentsForAppeal.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling createAssignmentsForAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/assignment`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Defensive Appeal
     * Create a Defensive Appeal
     * @param defensiveAppealCreation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDefensiveAppeal(defensiveAppealCreation: DefensiveAppealCreation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public createDefensiveAppeal(defensiveAppealCreation: DefensiveAppealCreation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public createDefensiveAppeal(defensiveAppealCreation: DefensiveAppealCreation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public createDefensiveAppeal(defensiveAppealCreation: DefensiveAppealCreation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (defensiveAppealCreation === null || defensiveAppealCreation === undefined) {
            throw new Error('Required parameter defensiveAppealCreation was null or undefined when calling createDefensiveAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/defensive-appeal`,
            defensiveAppealCreation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create an Enforcement Appeal for a Litigation Case
     * Create an Enforcement Appeal for a Litigation Case
     * @param caseId 
     * @param enforcementAppealCreation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEnforcementAppeal(caseId: number, enforcementAppealCreation: EnforcementAppealCreation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public createEnforcementAppeal(caseId: number, enforcementAppealCreation: EnforcementAppealCreation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public createEnforcementAppeal(caseId: number, enforcementAppealCreation: EnforcementAppealCreation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public createEnforcementAppeal(caseId: number, enforcementAppealCreation: EnforcementAppealCreation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling createEnforcementAppeal.');
        }
        if (enforcementAppealCreation === null || enforcementAppealCreation === undefined) {
            throw new Error('Required parameter enforcementAppealCreation was null or undefined when calling createEnforcementAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LitigationAppealVO>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/enf-appeal`,
            enforcementAppealCreation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update plaintiffs to an existing Defensive Appeal
     * Update plaintiffs to an existing Defensive Appeal
     * @param appealId 
     * @param plaintiffVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<Array<PlaintiffVO>>;
    public createPlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<Array<PlaintiffVO>>>;
    public createPlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<Array<PlaintiffVO>>>;
    public createPlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling createPlaintiffsDefensiveAppeal.');
        }
        if (plaintiffVO === null || plaintiffVO === undefined) {
            throw new Error('Required parameter plaintiffVO was null or undefined when calling createPlaintiffsDefensiveAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<PlaintiffVO>>(`${this.configuration.basePath}/v1/appeals/defensive-appeal/${encodeURIComponent(String(appealId))}/plaintiffs`,
            plaintiffVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an Appeal
     * Delete an Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppeal(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public deleteAppeal(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public deleteAppeal(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public deleteAppeal(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling deleteAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Mapping of Appellate Court to District Court
     * Get a HashMap of Appellate Court Id to List of District Court Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCourtMappings(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<{ [key: string]: Array<number>; }>;
    public getAllCourtMappings(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<{ [key: string]: Array<number>; }>>;
    public getAllCourtMappings(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<{ [key: string]: Array<number>; }>>;
    public getAllCourtMappings(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<{ [key: string]: Array<number>; }>(`${this.configuration.basePath}/v1/appeals/courtmapping`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an Appeal
     * Get an Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppeal(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public getAppeal(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public getAppeal(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public getAppeal(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling getAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get assignments for a Litigation Appeal
     * Get assignments for a Litigation Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssignmentsForAppeal(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<Array<AppealAssignmentVO>>;
    public getAssignmentsForAppeal(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<Array<AppealAssignmentVO>>>;
    public getAssignmentsForAppeal(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<Array<AppealAssignmentVO>>>;
    public getAssignmentsForAppeal(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling getAssignmentsForAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AppealAssignmentVO>>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/assignment`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Enforcement Appeals for a Litigation Case
     * Get all Enforcement Appeals for a Litigation Case
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEnforcementAppealsForCase(caseId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<Array<LitigationAppealVO>>;
    public getEnforcementAppealsForCase(caseId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<Array<LitigationAppealVO>>>;
    public getEnforcementAppealsForCase(caseId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<Array<LitigationAppealVO>>>;
    public getEnforcementAppealsForCase(caseId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getEnforcementAppealsForCase.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LitigationAppealVO>>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/enf-appeal`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update plaintiffs to an existing Defensive Appeal
     * Update plaintiffs to an existing Defensive Appeal
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlaintiffsDefensiveAppeal(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<Array<PlaintiffVO>>;
    public getPlaintiffsDefensiveAppeal(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<Array<PlaintiffVO>>>;
    public getPlaintiffsDefensiveAppeal(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<Array<PlaintiffVO>>>;
    public getPlaintiffsDefensiveAppeal(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling getPlaintiffsDefensiveAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PlaintiffVO>>(`${this.configuration.basePath}/v1/appeals/defensive-appeal/${encodeURIComponent(String(appealId))}/plaintiffs`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Resolution details for an Appeal
     * Delete Resolution details for an Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAppealResolution(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public removeAppealResolution(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public removeAppealResolution(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public removeAppealResolution(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling removeAppealResolution.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/resolution`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove assignees from a Litigation Appeal
     * Remove assignees from a Litigation Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param assignees 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAssignmentsForAppeal(appealId: number, assignees: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeAssignmentsForAppeal(appealId: number, assignees: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeAssignmentsForAppeal(appealId: number, assignees: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeAssignmentsForAppeal(appealId: number, assignees: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling removeAssignmentsForAppeal.');
        }
        if (assignees === null || assignees === undefined) {
            throw new Error('Required parameter assignees was null or undefined when calling removeAssignmentsForAppeal.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (assignees) {
            assignees.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'assignees');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/assignment`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove assignee from all Appeal Assignments
     * Remove assignee from all Appeal Assignments
     * @param assignee 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAssignmentsforAssignee(assignee: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public removeAssignmentsforAssignee(assignee: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public removeAssignmentsforAssignee(assignee: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public removeAssignmentsforAssignee(assignee: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (assignee === null || assignee === undefined) {
            throw new Error('Required parameter assignee was null or undefined when calling removeAssignmentsforAssignee.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/appeals/assignment/${encodeURIComponent(String(assignee))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reopen an Appeal
     * Reopen an Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reopenAppeal(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public reopenAppeal(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public reopenAppeal(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public reopenAppeal(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling reopenAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/reopen`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restore an Appeal
     * Restore an Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreAppeal(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public restoreAppeal(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public restoreAppeal(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public restoreAppeal(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling restoreAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/restore`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set recommendation information for a Litigation Appeal
     * Set recommendation information for a Litigation Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param appealRecommendation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAppealRecommendation(appealId: number, appealRecommendation: AppealRecommendation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public setAppealRecommendation(appealId: number, appealRecommendation: AppealRecommendation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public setAppealRecommendation(appealId: number, appealRecommendation: AppealRecommendation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public setAppealRecommendation(appealId: number, appealRecommendation: AppealRecommendation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling setAppealRecommendation.');
        }
        if (appealRecommendation === null || appealRecommendation === undefined) {
            throw new Error('Required parameter appealRecommendation was null or undefined when calling setAppealRecommendation.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/recommendation`,
            appealRecommendation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Resolution details for a Litigation Appeal
     * Set Resolution details for a Litigation Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param appealResolution 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAppealResolution(appealId: number, appealResolution: AppealResolution, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public setAppealResolution(appealId: number, appealResolution: AppealResolution, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public setAppealResolution(appealId: number, appealResolution: AppealResolution, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public setAppealResolution(appealId: number, appealResolution: AppealResolution, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling setAppealResolution.');
        }
        if (appealResolution === null || appealResolution === undefined) {
            throw new Error('Required parameter appealResolution was null or undefined when calling setAppealResolution.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/resolution`,
            appealResolution,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets assignees to a Litigation Appeal
     * Sets assignees to a Litigation Appeal. Removes assignments as necessary (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public setAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public setAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public setAssignmentsForAppeal(appealId: number, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling setAssignmentsForAppeal.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling setAssignmentsForAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/assignment`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing Amicus Appeal
     * Update an existing Amicus Appeal
     * @param appealId 
     * @param litigationAppealVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAmicusAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public updateAmicusAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public updateAmicusAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public updateAmicusAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateAmicusAppeal.');
        }
        if (litigationAppealVO === null || litigationAppealVO === undefined) {
            throw new Error('Required parameter litigationAppealVO was null or undefined when calling updateAmicusAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/amicus-appeal/${encodeURIComponent(String(appealId))}`,
            litigationAppealVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Appeal Created On Date update for an existing Litigation Appeal
     * Appeal Created On Date update for an existing Litigation Appeal
     * @param appealId 
     * @param createdOnDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppealCreatedOnDate(appealId: number, createdOnDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public updateAppealCreatedOnDate(appealId: number, createdOnDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public updateAppealCreatedOnDate(appealId: number, createdOnDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public updateAppealCreatedOnDate(appealId: number, createdOnDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateAppealCreatedOnDate.');
        }
        if (createdOnDate === null || createdOnDate === undefined) {
            throw new Error('Required parameter createdOnDate was null or undefined when calling updateAppealCreatedOnDate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdOnDate !== undefined && createdOnDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdOnDate, 'createdOnDate');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/createdOnDate`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fiscal year update for an existing Litigation Appeal
     * Fiscal year update for an existing Litigation Appeal
     * @param appealId 
     * @param appealFY 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppealFiscalYear(appealId: number, appealFY: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public updateAppealFiscalYear(appealId: number, appealFY: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public updateAppealFiscalYear(appealId: number, appealFY: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public updateAppealFiscalYear(appealId: number, appealFY: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateAppealFiscalYear.');
        }
        if (appealFY === null || appealFY === undefined) {
            throw new Error('Required parameter appealFY was null or undefined when calling updateAppealFiscalYear.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (appealFY !== undefined && appealFY !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>appealFY, 'appealFY');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/fiscalYear`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Closure reason update for an existing Litigation Appeal
     * Closure reason update for an existing Litigation Appeal
     * @param appealId 
     * @param closeRsnTxt 
     * @param closedDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppealcloseRsn(appealId: number, closeRsnTxt: string, closedDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public updateAppealcloseRsn(appealId: number, closeRsnTxt: string, closedDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public updateAppealcloseRsn(appealId: number, closeRsnTxt: string, closedDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public updateAppealcloseRsn(appealId: number, closeRsnTxt: string, closedDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateAppealcloseRsn.');
        }
        if (closeRsnTxt === null || closeRsnTxt === undefined) {
            throw new Error('Required parameter closeRsnTxt was null or undefined when calling updateAppealcloseRsn.');
        }
        if (closedDate === null || closedDate === undefined) {
            throw new Error('Required parameter closedDate was null or undefined when calling updateAppealcloseRsn.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (closeRsnTxt !== undefined && closeRsnTxt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closeRsnTxt, 'closeRsnTxt');
        }
        if (closedDate !== undefined && closedDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closedDate, 'closedDate');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/closeRsn`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing Defensive Appeal
     * Update an existing Defensive Appeal
     * @param appealId 
     * @param litigationAppealVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDefensiveAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public updateDefensiveAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public updateDefensiveAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public updateDefensiveAppeal(appealId: number, litigationAppealVO: LitigationAppealVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateDefensiveAppeal.');
        }
        if (litigationAppealVO === null || litigationAppealVO === undefined) {
            throw new Error('Required parameter litigationAppealVO was null or undefined when calling updateDefensiveAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/defensive-appeal/${encodeURIComponent(String(appealId))}`,
            litigationAppealVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing Enforcement Appeal for a Litigation Case
     * Update an existing Enforcement Appeal for a Litigation Case
     * @param caseId 
     * @param appealId 
     * @param litigationAppealVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEnforcementAppeal(caseId: number, appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public updateEnforcementAppeal(caseId: number, appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public updateEnforcementAppeal(caseId: number, appealId: number, litigationAppealVO: LitigationAppealVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public updateEnforcementAppeal(caseId: number, appealId: number, litigationAppealVO: LitigationAppealVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling updateEnforcementAppeal.');
        }
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateEnforcementAppeal.');
        }
        if (litigationAppealVO === null || litigationAppealVO === undefined) {
            throw new Error('Required parameter litigationAppealVO was null or undefined when calling updateEnforcementAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<LitigationAppealVO>(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/enf-appeal/${encodeURIComponent(String(appealId))}`,
            litigationAppealVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update plaintiffs to an existing Defensive Appeal
     * Update plaintiffs to an existing Defensive Appeal
     * @param appealId 
     * @param plaintiffVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<Array<PlaintiffVO>>;
    public updatePlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<Array<PlaintiffVO>>>;
    public updatePlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<Array<PlaintiffVO>>>;
    public updatePlaintiffsDefensiveAppeal(appealId: number, plaintiffVO: Array<PlaintiffVO>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updatePlaintiffsDefensiveAppeal.');
        }
        if (plaintiffVO === null || plaintiffVO === undefined) {
            throw new Error('Required parameter plaintiffVO was null or undefined when calling updatePlaintiffsDefensiveAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Array<PlaintiffVO>>(`${this.configuration.basePath}/v1/appeals/defensive-appeal/${encodeURIComponent(String(appealId))}/plaintiffs`,
            plaintiffVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets assignees to a Litigation Appeal
     * Sets assignees to a Litigation Appeal. Removes assignments as necessary (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRecommendationForAppeal(appealId: number, requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public setRecommendationForAppeal(appealId: number, requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public setRecommendationForAppeal(appealId: number, requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public setRecommendationForAppeal(appealId: number, requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling setAssignmentsForAppeal.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling setAssignmentsForAppeal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/recommendation`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Remove assignees from a Litigation Appeal
     * Remove assignees from a Litigation Appeal (Enforcement, Amicus, Defensive)
     * @param appealId 
     * @param assignees 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public removeRecommendationForAppeal(appealId: number, recommendationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
        public removeRecommendationForAppeal(appealId: number, recommendationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
        public removeRecommendationForAppeal(appealId: number, recommendationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
        public removeRecommendationForAppeal(appealId: number, recommendationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
            if (appealId === null || appealId === undefined) {
                throw new Error('Required parameter appealId was null or undefined when calling removeRecommendationForAppeal.');
            }
            if (recommendationId === null || recommendationId === undefined) {
                throw new Error('Required parameter recommendationId was null or undefined when calling removeRecommendationForAppeal.');
            }
    
            let queryParameters = new HttpParams({encoder: this.encoder});
    
            let headers = this.defaultHeaders;
    
            let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
            if (httpHeaderAcceptSelected === undefined) {
                // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                ];
                httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
            if (httpHeaderAcceptSelected !== undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
    
            let responseType: 'text' | 'json' = 'json';
            if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
                responseType = 'text';
            }

            return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/recommendation/${encodeURIComponent(String(recommendationId))}`,
                {
                    params: queryParameters,
                    responseType: <any>responseType,
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }
}
