import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { AppNotfoundComponent } from './shared/app-notfound/app-notfound.component';

const routes: Routes = [
	{
		path: 'search',
		loadChildren: () => import('./features/modules/search/search.module').then((m) => m.SearchModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./features/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'sbi-combination',
		loadChildren: () =>
			import('./features/modules/custom-fepa-sbi/custom-fepa-sbi.module').then((m) => m.CustomFepaSbiModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'employer-representatives',
		loadChildren: () =>
			import('./features/modules/employer-representatives/employer-representatives.module').then(
				(m) => m.EmployerRepresentativesModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'documents',
		loadChildren: () => import('./features/modules/documents/documents.module').then((m) => m.DocumentsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'case-details/:caseId/:type',
		loadChildren: () => import('./features/modules/case-details/case-details.module').then((m) => m.CaseDetailsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'case-details/:caseId',
		loadChildren: () => import('./features/modules/case-details/case-details.module').then((m) => m.CaseDetailsModule),
		canActivate: [AuthGuard],
	},

	{
		path: 'dual-filing',
		loadChildren: () => import('./features/modules/dual-filing/dual-filing.module').then((m) => m.DualFilingModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'sltp-contracts',
		loadChildren: () =>
			import('./features/modules/sltp-contracts/sltp-contracts.module').then((m) => m.SltpContractsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'user-management',
		loadChildren: () =>
			import('./features/modules/user-management/user-management.module').then((m) => m.UserManagementModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'new-inquiry',
		loadChildren: () => import('./features/modules/new-inquiry/new-inquiry.module').then((m) => m.NewInquiryModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'new-cases',
		loadChildren: () => import('./features/modules/new-case/new-case.module').then((m) => m.NewCaseModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'iig-new-inquiry',
		loadChildren: () =>
			import('./features/modules/iig-new-inquiry/igg-new-inquiry.module').then((m) => m.IIGNewInquiryModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'systemic-investigation',
		loadChildren: () => import('./features/modules/systemic/systemic.module').then((m) => m.SystemicModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'digital-file-cabinets',
		loadChildren: () =>
			import('./features/modules/digital-file-cabinets/digital-file-cabinets.module').then(
				(m) => m.DigitalFileCabinetsModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'class-case-groups',
		loadChildren: () =>
			import('./features/modules/class-case-groups/class-case-groups.module').then((m) => m.ClassCaseGroupsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'guidance',
		loadChildren: () => import('./features/modules/arc-guidance/arc-guidance.module').then((m) => m.ArcGuidanceModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'template-management',
		loadChildren: () =>
			import('./features/modules/template-management/template-management.module').then(
				(m) => m.TemplateManagementModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'reports',
		loadChildren: () => import('./features/modules/reports/reports.module').then((m) => m.ReportsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'appeals',
		loadChildren: () => import('./features/modules/new-appeals/new-appeals.module').then((m) => m.NewAppealsModule),
		canActivate: [AuthGuard],
	},
	{ path: 'error', component: AppNotfoundComponent },
	{ path: '', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
    initialNavigation: isInIframe() ? 'disabled' : undefined
}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

export function isInIframe() {
	return window !== window.parent && !window.opener;
}
