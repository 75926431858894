import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class AppValidators {
	/**
	 * @description a phone number validator, passes when the input phone number string is valid or empty.
	 */
	countryCode: any;

	validateHomePhoneNumber(): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (!c.value) {
				return null;
			}
			if (/^[0-9.()-\s]*$/.test(c.value)) return null;
			else return { validatePhoneNumber: { valid: false } };
		};
	}

	validatePhoneNumber(): ValidatorFn {
		return (c: AbstractControl): ValidationErrors | null => {
			if (!c.value) {
				return null;
			}
			if (!/^[0-9.()-\s]*$/.test(c.value)) return { validatePhoneNumber: { valid: false } };
			if (this.countryCode === 'USA') {
				return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(c.value)
					? null
					: { validatePhoneNumber: { valid: false } };
			} else {
				if (c.value.length > 9 && c.value.length < 15) {
					return null;
				} else {
					return { validatePhoneNumber: { valid: false } };
				}
			}
		};
	}

	confirmInput(matchTo: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
				? null
				: { confirmInput: { valid: false } };
		};
	}

	/**
	 * @description validate the input with a regular expression
	 * @param regex
	 * @param error
	 */
	patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (!control.value) {
				return null;
			}
			const valid = regex.test(control.value);
			return valid ? null : error;
		};
	}
}
