import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
/**
 * @author Sasi Kesani
 */
export class LoadingService {
	/**
	 * @description spinners BehaviorSubject
	 * @type {BehaviorSubject<any>}
	 * @memberof StrLoadingService
	 */
	public spinnerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  isLoading = false;
	/**
	 * Creates an instance of Ng4LoadingSpinnerService.
	 * @memberof StrLoadingService
	 */
	constructor() {}

	/**
	 * To show spinner
	 * @memberof StrLoadingService
	 */
	show() {
		this.spinnerSubject.next(true);
	}

	/**
	 * To hide spinner
	 * @memberof StrLoadingService
	 */
	hide() {
    if(!this.isLoading){
		  this.spinnerSubject.next(false);
    }
	}

	getMessage(): Observable<any> {
		return this.spinnerSubject.asObservable();
	}
}
