import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
	selector: 'TimeOutMessage',
	template: ` <span>Your session will be time out in {{ countdown }} seconds.</span> `,
	styles: [],
})
export class TimeOutMessageComponent implements OnInit, OnDestroy {
	countdown: number;
	timer$: Subscription;

	/**
	 * @description count down for 2 mins
	 */
	ngOnInit() {
		this.countdown = 120;
		this.timer$ = interval(1000).subscribe(() => {
			if (this.countdown > 0) this.countdown -= 1;
		});
	}

	/**
	 * @description unsubscribe to observable
	 */
	ngOnDestroy(): void {
		if (this.timer$) {
			this.timer$.unsubscribe();
		}
	}
}
