import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateDocumentsComponent } from './private-documents/private-documents.component';

const documentRoutes: Routes = [
	{ path: 'private', component: PrivateDocumentsComponent },
];

@NgModule({
	imports: [RouterModule.forChild(documentRoutes)],
})
export class DocumentsRoutingModule {}
