import { litigationIntialState, privateIntialState } from './search.state';
import { createReducer, on } from '@ngrx/store';
import { litigationSearchSuccess, privateSearchSuccess } from './search.actions';

const _litigationSearchReducer = createReducer(litigationIntialState,
    on(litigationSearchSuccess, (state, action) => {
        return {
            ...state,
            litigationSearchData: action.litigationSearchData
        };
    })
);

export const LITIGATION_SEARCH_STATE_NAME = "litigationSearchFeatureKey";

export function litigationSearchReducer(state, action) {
    return _litigationSearchReducer(state, action);
}

const _privateSearchReducer = createReducer(privateIntialState,
    on(privateSearchSuccess, (state, action) => {
        return {
            ...state,
            privateSearchData: action.privateSearchData
        }
    })

);

export const PRIVATE_SEARCH_STATE_NAME = "privateSearchFeatureKey";

export function privateSearchReducer(state, action) {
    return _privateSearchReducer(state, action);
}

