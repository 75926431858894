const ArrayUtils = {
	search,
	removeBySplicing,
	includesAny,
};

/**
 * @description returns the index of selected element in an array of elements
 * @param element that needs to be found
 * @param myArray array of elements
 */
function search(element, myArray) {
	/*  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].prop === element.prop) {
      return i;
    }
  }*/
	return myArray.indexOf(element); // 1
}

/**
 * @description removes an element from array of elements by splicing
 * @param index of the element that needs to be deleted
 * @param myArray array of elements
 * @param deleteCount number of elements to be deleted from index
 */
function removeBySplicing(index, myArray, deleteCount) {
	return myArray.splice(index, deleteCount);
}

/**
 * @description returns true if array contains any member of includesArray
 * @param array
 * @param includesArray
 */
function includesAny(array, includesArray) {
	return array.some((element, index, array) => includesArray.includes(element));
}

export { ArrayUtils };
