import { environment } from 'src/environments/environment';
import { CustomDynamicFormInterface } from './custom-dynamic-form-interface';
import { LookupData } from 'src/app/services/gen';


export const AppOathTokenCredential = environment.AppOathTokenCredential;

export const USER_GUIDE = {
	DEV: {
		PRIVATE_DOC: '6ca1a4fd-f76e-4016-843b-220940043ba9',
		FEPA_DOC: 'b4d82a86-534b-4005-a268-6c01d482ed8f',
		INQUIRY: '74a0181b-f238-4054-a1d9-67b575308538',
		DUAL_FILE: '95b9d0ae-765c-479b-a468-7e6875899bf3',
	},
	TEST: {
		PRIVATE_DOC: '5a0fa983-3592-4ee9-ae3b-a8d3be24ef47',
		FEPA_DOC: 'ead362bb-e3e5-42d4-bcf3-ec2cabb65ed8',
		INQUIRY: '33b3fadd-a205-47c1-83b3-fb2682140556',
		DUAL_FILE: '5f77cea7-4891-452e-8fb5-ba79ffbcc2b1',
	},
	LOCAL: {
		PRIVATE_DOC: '5a0fa983-3592-4ee9-ae3b-a8d3be24ef47',
		FEPA_DOC: 'ead362bb-e3e5-42d4-bcf3-ec2cabb65ed8',
		INQUIRY: '33b3fadd-a205-47c1-83b3-fb2682140556',
		DUAL_FILE: '5f77cea7-4891-452e-8fb5-ba79ffbcc2b1',
	},
	UAT: {
		PRIVATE_DOC: '39d8bbd8-6ce0-47a0-8f9c-b7c6bdc73c02',
		FEPA_DOC: 'a934f98e-989b-4ba8-9a39-95044f5e9e79',
		INQUIRY: 'f29b34c4-46ed-4de2-896e-989b9ff0ae0d',
		DUAL_FILE: '2e6447ae-bc2e-4aae-8684-57da529e2ba4',
	},
	PROD: {
		PRIVATE_DOC: '39d8bbd8-6ce0-47a0-8f9c-b7c6bdc73c02',
		FEPA_DOC: 'a934f98e-989b-4ba8-9a39-95044f5e9e79',
		INQUIRY: 'f29b34c4-46ed-4de2-896e-989b9ff0ae0d',
		DUAL_FILE: '06eee83b-9831-4eb7-8fbe-fe1357e9276c',
	},
};

export const OnlyCharactersWithSpaceRegex: RegExp = /^[a-zA-Z-\s\/']+$/;

export const OnlyCharactersRegex: RegExp = /^[a-zA-Z]+$/;

// error constants
export const EMLRespondentError = `Couldn't able to get the respondent from Employer Master List. Please try again later or
              contact system administrator`;
export const ChargesListError = `Charges List loading failed, please contact the system administrator.`;

export const UpdateCaseError =
	'An error occurred while deleting case. Please check with system administrator. Error code: ';

export const service_error = `An error occurred while processing your request, please contact the system administrator.`;

export const SaveEmployerError = `An error occurred while saving the employer. Please check with system administrator.`;

export enum ArcStatusCodes {
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	DENIED = 'DENIED',
	PENDING = 'PENDING',
	REMOVED = 'REMOVED',
	INELIGIBLE = 'INELIGIBLE',
}

export enum DocumentStatuses {
	PENDING = 'Pending',
	OVERDUE = 'Overdue',
	RECEIVED = 'Received',
	PENDINGRELEASED = 'PendingReleased',
}

export enum DashboardView {
	UNASSIGNED_INQUIRIES = 'UNASSIGNED_INQUIRIES',
	ASSIGNED_INQUIRIES = 'ASSIGNED_INQUIRIES',
	CHARGES_BY_PCHP = 'CHARGES_BY_PCHP',
	UNSERVED_NOC = 'UNSERVED_NOC',
	CASES_BY_STAGE = 'CASES_BY_STAGE',
	STAFF_ASSIGNMENTS = 'STAFF_ASSIGNMENTS',
	STAFF_CLOSURE = 'STAFF_CLOSURE',
	MY_CASES = 'MY_CASES',
}

export enum DashboardScreen {
	CASE_DASHBOARD,
	ADR_DASHBOARD,
	FEPA_CREDIT_DASHBOARD,
	FEPA_DASHBOARD,
	LEGAL_REVIEW_DASHBOARD,
	SYSTEMIC_DASHBOARD,
	FOIA_DASHBOARD,
	APPEALS_DASHBOARD,
}

export enum DashboardViewV2 {
	WORK_PENDING = 'WORK_PENDING',
	WORK_ASSIGNED = 'WORK_ASSIGNED',
	HQ_REVIEW = 'HQ_REVIEW',
	PENDING_REQUESTS = 'PENDING_REQUESTS',
	REVIEW_REQUESTS = 'REVIEW_REQUESTS',
	NEEDS_MY_APPROVAL = 'NEEDS_MY_APPROVAL',
	CHARGES_BY_PCHP = 'CHARGES_BY_PCHP',
	UNSERVED_NOC = 'UNSERVED_NOC',
	CASES_BY_STAGE = 'CASES_BY_STAGE',
	CLOSED_CASES = 'CLOSED_CASES',
	CHARGING_PARTY_RFI = 'CHARGING_PARTY_RFI',
	RESPONDENT_RFI = 'RESPONDENT_RFI',
	RFI_EXT_REQ_REC = 'RFI_EXT_REQ_REC',
	POSITION_STATEMENT = 'POSITION_STATEMENT',
	POSITION_STATEMENT_REBUTTAL = 'POSITION_STATEMENT_REBUTTAL',
	PS_EXT_REQ_REC = 'PS_EXT_REQ_REC',
	NRTS = 'NRTS',
	INCOMING_CASE_TRANSFERS = 'INCOMING_CASE_TRANSFERS',
	OUTGOING_CASE_TRANSFERS = 'OUTGOING_CASE_TRANSFERS',
	ATTORNEY_EFILE = 'ATTORNEY_EFILE',
	LITIGATION_PRIORITY = 'LITIGATION_PRIORITY',
	MY_CASES = 'MY_CASES',
	CAUSE_REVIEWS = 'CAUSE_REVIEWS',
	ATTORNEY_EFILE_VERIFIED = 'ATTORNEY_EFILE_VERIFIED',
	ATTORNEY_EFILE_UNVERIFIED = 'ATTORNEY_EFILE_UNVERIFIED',
	ATTORNEY_EFILE_UNVERIFIED_AC = 'ATTORNEY_EFILE_UNVERIFIED_AC',
	DIGITAL_FILE_CABINET = 'DIGITAL_FILE_CABINET',
	CLASS_CASE_GROUPS = 'CLASS_CASE_GROUPS',
	SENT_TO_DOJ = 'SENT_TO_DOJ',
	NRTS_RELEASE_PENDING = 'NRTS_RELEASE_PENDING',
	EPA = 'EPA'
}

export enum DocumentEvents {
	POS_STMT_ADDED = 'POS_STMT_ADDED',
	POS_STMT_RECEIVED = 'POS_STMT_RECEIVED',
	POS_STMT_REQUESTED = 'POS_STMT_REQUESTED',
	POS_STMT_RELEASED = 'POS_STMT_RELEASED',
	RELEASE_DOC = 'RELEASE_DOC'
}

export const FoiaRequestsEvents = {
	FOIA_REQUESTED: {
		includes: ['FOIA_REQUESTED'],
		excludes: ['FOIA_DENIED_FULL', 'FOIA_GRANTED_FULL', 'FOIA_PARTIAL_GRN_DEN', 'FOIA_DECISION_APPEAL'],
	},
	FOIA_GRANTED_FULL: {
		includes: ['FOIA_GRANTED_FULL'],
		excludes: ['FOIA_DENIED_FULL', 'FOIA_PARTIAL_GRN_DEN', 'FOIA_DECISION_APPEAL'],
	},
	FOIA_PARTIAL_GRN_DEN: {
		includes: ['FOIA_PARTIAL_GRN_DEN'],
		excludes: ['FOIA_GRANTED_FULL', 'FOIA_DENIED_FULL', 'FOIA_DECISION_APPEAL'],
	},
	FOIA_DENIED_FULL: {
		includes: ['FOIA_DENIED_FULL'],
		excludes: ['FOIA_GRANTED_FULL', 'FOIA_DENIED_FULL', 'FOIA_PARTIAL_GRN_DEN', 'FOIA_DECISION_APPEAL'],
	},
	FOIA_DECISION_APPEAL: {
		includes: ['FOIA_DECISION_APPEAL'],
		excludes: ['FOIA_GRANTED_FULL', 'FOIA_DENIED_FULL', 'FOIA_PARTIAL_GRN_DEN'],
	},
};

export const AcoInvestigateIntentId = 6573;
export const DcoInvestigateIntentId = 6578;
export const DcoWaivedIntentId = 6579;

export const DashboardDocTypes = ['RFICP', 'RFIRES', 'PRVPOS', 'REBUTPOSSTMNT', 'PRVCLOSNOT'];

export const SelectedCasesDisplayColumns = {
	UNASSIGNED_INQUIRIES: ['select', 'ciIsUrgent', 'ciChargeNumber', 'displayName', 'rspName', 'ciiInterviewBeginTime'],
	ASSIGNED_INQUIRIES: [
		'select',
		'ciIsUrgent',
		'ciChargeNumber',
		'displayName',
		'rspName',
		'caFirstName',
		'ciiInterviewBeginTime',
	],
	// NEW_ONLINE : ['ciChargeNumber', 'displayName', 'rspName', 'ciStatusDescription', 'ciInitialInquiryDate', 'ciFormalizationDate'],
	// NEW_OFFLINE : ['ciChargeNumber', 'displayName', 'rspName', 'ciStatusDescription', 'ciInitialInquiryDate', 'ciFormalizationDate'],
	// OPEN_INQUIRIES : ['ciIsUrgent', 'ciChargeNumber', 'displayName', 'rspName', 'ciiInterviewBeginTime'],
	CHARGES_BY_PCHP: ['select', 'ciChargeNumber', 'displayName', 'rspName', 'scvCategoryValueCode', 'caFirstName'],
	UNSERVED_NOC: [
		'select',
		'ciChargeNumber',
		'displayName',
		'rspName',
		'scvCategoryValueCode',
		'ciAutomatedNocDisabled',
		'ciFormalizationDate',
	],
	CASES_BY_STAGE: ['select', 'ciChargeNumber', 'displayName', 'rspName', 'caFirstName'],
	STAFF_ASSIGNMENTS: [
		'select',
		'ciChargeNumber',
		'ciStatusDescription',
		'ciInitialInquiryDate',
		'ciFormalizationDate',
		'celEventCreatedOn',
	],
	STAFF_CLOSURE: ['select', 'ciChargeNumber', 'displayName', 'rspName', 'ciClosureDate'],
	MY_CASES: ['ciChargeNumber', 'displayName', 'rspName', 'ciInitialInquiryDate'],
};

export const PchpCategoryIdArray = [2527, 2528, 2529, 2530, 2534];
export const PchpCategoryCodeArray = [
	'A - Cause is likely',
	'A2 - Cause is likely but litigation is unlikely',
	'SA - Cause is likely and has Strategic Significance',
	'B - Additional information is needed',
	'C - Cause is unlikely',
];

export enum SourceSystem {
	OFFLINE_INQUIRY = 'OFLNEINQRY',
}

export enum LookUpDataTypes {
	STATE = 'STA',
	PREFIX = 'NAMPR',
	SUFFIX = 'NAMSU',
	COUNTRY = 'CNTRY',
	SEX = 'SEX',
	LNG = 'LNG',
	RACE = 'RAC',
	LANGUAGE = 'LNG',
	EMPSIZE = 'EMS',
	EMLSTATE = 'EMLSTATE',
	INSTTYPE = 'EMT',
	NATIONAL_ORIGIN = 'NAT',
	ADDITIONAL_CONTACTS = 'ADR',
	OFFICE = 'office',
	SOURCE_OF_COMPLAINT = 'SOU',
	COMMUNICATION_METHOD = 'CMT',
	FEDERAL_AGENCY_CODE = 'FAC',
	FED_REFERRAL_TRANSFER_CODE = 'FRT',
	ACCOUNTABILITY_OFFICE_CODE = 'ACO',
	STAFF_FUNCTION = 'FUN',
	STATUS_ID = 'STS',
	LITIGATION_STATUS_ID = 'LCS',
	CHARGE_TYPE_CODE = 'CAE',
	RESOLUTION_TYPE_CODE = 'LIT_RSTLN_TYPE',
	PROCESSING_CATEGORY = 'CAE',
	MEDIATION = 'PMED',
	RECEIVED_BY = 'RCVBY',
	RECEIVED_FROM = 'RCVFROM',
	RECEIVING_OFFICE_INTENT = 'INTENTACO',
	DEFERRAL_OFFICE_INTENT = 'INTENTDFO',
	MEDIATION_STATUS = 'MEDIATION_STS',
	MEDIATION_REPLY = 'MEDIATION_REPLY',
	MEDIATION_TYPE = 'MEDIATION_TYPE',
	DECLINE_REASON = 'MEDIATION_DECLINED_RSN',
	ENFORCEMENT_REASON = 'PMED_NOLNGERELGBL',
	DOMAIN = 'ASGRS',
	CAUSE_FINDING = 'CAUSFIND',
	CONCILIATION_CONFERENCE_TYPE = 'CONCILIATION_CONF_TYPE',
	CONCILIATION_PROPOSAL_RESPONSE_RSP = 'CONCIL_PROP_RESP_REPDNT',
	CONCILIATION_PROPOSAL_RESPONSE_CP = 'CONCIL_PROP_RESP_CP',
	COMMUNICATION_DOMAIN_CONTACTED = 'CNTCT',
	COMMUNICATION_DOMAIN_PURPOSE = 'CNTCT_PURPOSE',
	ONSITE_ACTIVITY = 'ONSITE_ACTIVITY',
	SYSTEMIC_TYPE = 'SYSTEMIC_TYPE',
	SYSTEMIC_STATUS = 'SYSTEMIC_STATUS',
	SYSTEMIC_COORDINATION_STATUS = 'SYSTEMIC_CRDN_STS',
	BENEFICIARY_TYPES = 'BENEFICIARY_TYPE',
	FEPA_CREDIT = 'FEPA_CREDIT',
	FEPA_CREDIT_REQUEST = 'FEPA_CREDIT_REQ',
	FEPA_CREDIT_RVW = 'FEPA_CREDIT_RVW',
	SWR_REQ_BY = 'SWR_REQ_BY',
	RECOMMENDATION_DETERMINATION_STATUS = 'LIT_RVW_STATUS',
	EEOC_CREDIT_RES_STATUS = 'EEOC_CREDIT_RES_STATUS',
	FEPA_CREDIT_REQ_STATUS = 'FEPA_CREDIT_REQ_STATUS',
	FEPA_CREDIT_REQ = 'FEPA_CREDIT_REQ',
	COMPLIANCE_OUTCM = 'COMPLIANCE_OUTCM',
	LIT_ADDED_TYPE = 'LIT_ADDED_TYPE',
	COMPLIANCENOTESTYPE = 'COMP',
	NOTETYPE = 'NOTETYPE',
	TRANSFER_STATUS = 'TRANSFER_STATUS',
	CASE_GROUP = 'CASE_GROUP',
	STAGE = 'STAGE',
	LIT_CASE_TYPE = 'LCT',
	LIT_LITIGATION_TYPE = 'LLT',
	LIT_REASON = 'LRC',
	LIT_COURT_TYPE = 'LCO',
	LIT_RCV_STATUS = 'LIT_RVW_STATUS',
	LIT_AUTHORITY_STATUS = 'LAU',
	LIT_JUSRY_VERDICT = 'JURYVERDICT',
	LIT_RESOLUTIONS_TYPE = 'LIT_RSTLN_TYPE',
	LIT_FAV_ORDER_TYPE = 'FAV_ORDER_TYPE',
	LIT_UNFAV_ORDER_TYPE = 'UNFAV_ORDER_TYPE',
	LIT_POST_RESOLUTIONS_TYPE = 'LIT_POST_RSTLN_TYPE',
	LIT_PETITION_DSN_TYPE = 'LIT_PETITION_DSN_TYPE',
	LIT_NOTE_TYPE = 'LIT_NOTETYPE',
	NO_INTAKE_INTR_RSN = 'NOINTAKE_INTVRW_RSN',
}

export const FepaCreditStatusLookup = {
	code: 'FEPA_CREDIT',
	description: 'Fepa Credit',
	domain: 'FEPA_CREDIT',
	id: 8266,
	viewableByFepa: 'Y',
};

export enum LookUpStatusCode {
	INQUIRY_SUBMITTED = 'INQUIRY_SUBMITTED',
	INQUIRY_CLOSED = 'INQUIRY_CLOSED',
	CLOSED = 'CLOSED',
	CHARGE_FILED = 'CHARGE_FILED',
	INVESTIGATION = 'INVESTIGATION',
	INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
	CHARGE_PREPARED = 'CHARGE_PREPARED',
	ELIGIBLE = 'ELIGIBLE',
	MEDIATION_SCHEDULED = 'MEDIATION_SCHEDULED',
	CLOSED_MEDIATION_RESOLUTION = 'CLOSED_MEDIATION_RESOLUTION',
	CLOSED_MEDIATED_SETTLEMENT = 'CLOSED_MEDIATED_SETTLEMENT',
	REFERRED_TO_ENFORCEMENT = 'REFERRED_TO_ENFORCEMENT',
	NO_LONGER_ELIGIBLE_FOR_ADR = 'NO_LONGER_ELIGIBLE_FOR_ADR',
	COMPLIANCE_MONITORING = 'COMPLIANCE_MONITORING',
	CONCILIATION = 'CONCILIATION',
	AGREED_TO_MEDIATE = 'AGREED_TO_MEDIATE',
	NO_LONGER_ELIGIBLE = 'NO_LONGER_ELIGIBLE',
	FEPA_CREDIT = 'FEPA_CREDIT',
	LITIGATION_DETERMINATION = 'LITIGATION_DETERMINATION',
}


export enum LookUpLitigationStatusCode {
	DELETE = 'DELETED',
	CLOSED = 'CLOSED',
	LITIGATION = 'LITIGATION',
}

export const ClosedStatuses = [
	'INQUIRY_CLOSED',
	'CLOSED',
	'CLOSED_MEDIATION_RESOLUTION',
	'CLOSED_MEDIATED_SETTLEMENT',
	'COMPLIANCE_MONITORING',
];

export const NonClosedStatuses = [
	'INQUIRY_SUBMITTED',
	'CHARGE_FILED',
	'INVESTIGATION',
	'INTERVIEW_SCHEDULED',
	'CHARGE_PREPARED',
	'ELIGIBLE',
	'MEDIATION_SCHEDULED',
	'REFERRED_TO_ENFORCEMENT',
	'CONCILIATION',
	'AGREED_TO_MEDIATE',
	'NO_LONGER_ELIGIBLE',
];

export enum NonClosedStages {
	ADR = 'ADR',
	ENFORCEMENT = 'ENFORCEMENT',
	INTAKE = 'INTAKE',
}
export const NonLegalReviewTypes = ['HQ', 'SUPERVISOR', 'WITHDRAWAL'];
export const ChargeReviewDesc = 'Charge Review';
export const FcReqStatusCodes = ['A', 'R', 'S', 'X'];
export const FcEeocRspStatusCodes = ['AP', 'IN', 'RJ', 'RV'];

export const LookUpStatusCodeCharge: string[] = [
	LookUpStatusCode.CHARGE_FILED,
	LookUpStatusCode.ELIGIBLE,
	LookUpStatusCode.AGREED_TO_MEDIATE,
	LookUpStatusCode.MEDIATION_SCHEDULED,
	LookUpStatusCode.REFERRED_TO_ENFORCEMENT,
	LookUpStatusCode.NO_LONGER_ELIGIBLE,
	LookUpStatusCode.INVESTIGATION,
	LookUpStatusCode.CONCILIATION,
];

export const LookUpStateName = [
{ 
	code: 'AL',
	description: 'Alabama',
},
{ 
	code: 'AK',
	description: 'Alaska',
},
{
    code: 'AZ',
	description: 'Arizona',
}, 
{ 
	code: 'AR',
	description: 'Arkansas',
},
{ 
	code: 'CA',
	description: 'California',
},
{ 
	code: 'CO',
	description: 'Colorado',
},
{ 
	code: 'CT',
	description: 'Connecticut',
},
{ 
	code: 'DE',
	description: 'Delaware',
},
{ 
	code: 'DC',
	description: 'District of Columbia',
},
{ 
	code: 'FL',
	description: 'Florida',
},
{ 
	code: 'GA',
	description: 'Georgia',
},
{ 
	code: 'HI',
	description: 'Hawaii',
},
{ 
	code: 'ID',
	description: 'Idaho',
},
{ 
	code: 'IL',
	description: 'Illinois',
},
{ 
	code: 'IN',
	description: 'Indiana',
},
{ 
	code: 'IA',
	description: 'Iowa',
},
{ 
	code: 'KS',
	description: 'Kansas',
},
{ 
	code: 'KY',
	description: 'Kentucky',
},
{ 
	code: 'LA',
	description: 'Louisiana',
},
{ 
	code: 'ME',
	description: 'Maine',
},
{ 
	code: 'MD',
	description: 'Maryland',
},
{ 
	code: 'MA',
	description: 'Massachusetts',
},
{ 
	code: 'MI',
	description: 'Michigan',
},
{ 
	code: 'MN',
	description: 'Minnesota',
},
{ 
	code: 'MS',
	description: 'Mississippi',
},
{ 
	code: 'MO',
	description: 'Missouri',
},
{ 
	code: 'MT',
	description: 'Montana',
},
{ 
	code: 'NE',
	description: 'Nebraska',
},
{ 
	code: 'NV',
	description: 'Nevada',
},
{ 
	code: 'NH',
	description: 'New Hampshire',
},
{ 
	code: 'NJ',
	description: 'New Jersey',
},
{ 
	code: 'NM',
	description: 'New Mexico',
},
{ 
	code: 'NY',
	description: 'New York',
},
{ 
	code: 'NC',
	description: 'North Carolina',
},
{ 
	code: 'ND',
	description: 'North Dakota',
},
{ 
	code: 'OH',
	description: 'Ohio',
},
{ 
	code: 'OK',
	description: 'Oklahoma',
},
{ 
	code: 'OR',
	description: 'Oregon',
},
{ 
	code: 'PA',
	description: 'Pennsylvania',
},
{ 
	code: 'RI',
	description: 'Rhode Island',
},
{ 
	code: 'SC',
	description: 'South Carolina',
},
{ 
	code: 'SD',
	description: 'South Dakota',
},
{ 
	code: 'TN',
	description: 'Tennessee',
},
{ 
	code: 'TX',
	description: 'Texas',
},
{ 
	code: 'UT',
	description: 'Utah',
},
{ 
	code: 'VT',
	description: 'Vermont',
},
{ 
	code: 'VA',
	description: 'Virginia',
},
{ 
	code: 'WA',
	description: 'Washington',
},
{ 
	code: 'WV',
	description: 'West Virginia',
},
{ 
	code: 'WI',
	description: 'Wisconsin',
},
{ 
	code: 'MH',
	description: 'Marshall Islands',
},
{ 
	code: 'AA',
	description: 'Armed Forces the Americas',
},
{ 
	code: 'AE',
	description: 'Armed Forces Europe',
},
{ 
	code: 'AP',
	description: 'Armed Forces Pacific',
},
]

export const LookUpStatusCodeChargeEEOC: string[] = [
	LookUpStatusCode.CHARGE_FILED,
	LookUpStatusCode.ELIGIBLE,
	LookUpStatusCode.AGREED_TO_MEDIATE,
	LookUpStatusCode.MEDIATION_SCHEDULED,
	LookUpStatusCode.REFERRED_TO_ENFORCEMENT,
	LookUpStatusCode.NO_LONGER_ELIGIBLE,
	LookUpStatusCode.INVESTIGATION,
	LookUpStatusCode.CONCILIATION,
	LookUpStatusCode.COMPLIANCE_MONITORING
];

export const LookUpStatusCodeClosed: string[] = [
	LookUpStatusCode.CLOSED,
	LookUpStatusCode.INQUIRY_CLOSED,
	LookUpStatusCode.CLOSED_MEDIATED_SETTLEMENT,
	LookUpStatusCode.CLOSED_MEDIATION_RESOLUTION,
	LookUpStatusCode.COMPLIANCE_MONITORING,
];

export const LookUpStatusCodeInquiry: string[] = [LookUpStatusCode.INQUIRY_SUBMITTED, LookUpStatusCode.CHARGE_PREPARED];

export const CPRFIDOCCODE = 'RFICP';
export const RSPRFIDOCCODE = 'RFIRES';
export const LEGACYRSPRFIDOCCODE = 'PRVRFI';
export const RSPRESRFIDOCCODE = 'RESRSPTORFI';
export const LEGACYRSPRESRFIDOCCODE = 'RFURSPNC';
export const RSPRFIEXTDOCCODE = 'RESRFIEXTREQ';
export const LEGACYRSPRFIEXTDOCCODE = 'RFIEXTREQ';
export const NRTSRFIDOCCODE = 'PRVCLOSNOT';
export const LEGACYNRTSRFIDOCCODE = 'PRVCLOSNOT';
export const POSSTATEREQDOCCODE = 'RFPSTMNT';
export const LEGACYPOSSTATEREQDOCCODE = 'CPRQPOS';
export const POSSTATEDOCCODE = 'PRVPOS';
export const LEGACYPOSSTATEDOCCODE = 'PRVPOS';
export const PSRFIEXTDOCCODE = 'POSRFTEXTREQ';
export const LEGACYPSRFIEXTDOCCODE = 'EXTREQPOSRFT';
export const POSSTATEREBDOCCODE = 'REBUTPOSSTMNT';
export const LEGACYPOSSTATEREBDOCCODE = 'PRVRSPPOSSTMT';

export const RspRFIFilterQueryObjects = {
	Pending: [
		{
			status: DocumentStatuses.PENDING,
			documentType: RSPRFIDOCCODE,
			respDocType: RSPRESRFIDOCCODE,
		},
		{
			status: DocumentStatuses.PENDING,
			documentType: LEGACYRSPRFIDOCCODE,
			respDocType: LEGACYRSPRESRFIDOCCODE,
		},
	],
	Received: [
		{
			status: DocumentStatuses.RECEIVED,
			documentType: RSPRESRFIDOCCODE,
		},
		{
			status: DocumentStatuses.RECEIVED,
			documentType: LEGACYRSPRESRFIDOCCODE,
		},
	],
};

export const RspRFIExtFilterQueryObjects = {
	Received: [
		{
			status: DocumentStatuses.RECEIVED,
			documentType: RSPRFIEXTDOCCODE,
		},
		{
			status: DocumentStatuses.RECEIVED,
			documentType: LEGACYRSPRFIEXTDOCCODE,
		},
	],
};

export const PsFilterQueryObjects = {
	Pending: [
		{
			status: DocumentStatuses.PENDING,
			documentType: POSSTATEREQDOCCODE,
			respDocType: POSSTATEDOCCODE,
		},
		{
			status: DocumentStatuses.PENDING,
			documentType: LEGACYPOSSTATEREQDOCCODE,
			respDocType: LEGACYPOSSTATEDOCCODE,
		},
	],
	Received: [
		{
			status: DocumentStatuses.RECEIVED,
			documentType: POSSTATEDOCCODE,
		},
		{
			status: DocumentStatuses.RECEIVED,
			documentType: LEGACYPOSSTATEDOCCODE,
		},
	],
};

export const PendingRequestFilterQueryObjects = {
	eventcode: 'UPLOAD_DOC',
	includeDoctype: ['RFNRGHTSUE', 'REQFORWTHDRL154'],
	excludeDoctype: 'PRVCLOSNOT'

}

export const NRTSRequestFilterQueryObjects = {
	excludeEventCode: 'RELEASE_DOC',
	includeDoctype: null,
	docType: 'PRVCLOSNOT',

}


export const PsRFIExtFilterQueryObjects = {
	Received: [
		{
			status: DocumentStatuses.RECEIVED,
			documentType: PSRFIEXTDOCCODE,
		},
		{
			status: DocumentStatuses.RECEIVED,
			documentType: LEGACYPSRFIEXTDOCCODE,
		},
	],
};

export const RebuttalFilterQueryObjects = {
	Pending: [
		{
			status: DocumentStatuses.PENDING,
			documentType: POSSTATEDOCCODE,
			respDocType: POSSTATEREBDOCCODE,
		},
		{
			status: DocumentStatuses.PENDING,
			documentType: LEGACYPOSSTATEDOCCODE,
			respDocType: LEGACYPOSSTATEREBDOCCODE,
		},
	],
	Received: [
		{
			status: DocumentStatuses.RECEIVED,
			documentType: POSSTATEREBDOCCODE,
		},
		{
			status: DocumentStatuses.RECEIVED,
			documentType: LEGACYPOSSTATEREBDOCCODE,
		},
	],
};

export enum PchpCategoryArea {
	PSEP = 'PSEP',
	DCP = 'DCP',
	PTOPIC = 'PTOPIC',
	PCHP = 'PCHP',
	CATEG = 'CAE',
	PCHP_A = 'PCSF-A',
	PCHP_SA = 'PCSF-SA',
	PCHP_A2 = 'PCSF-A2',
	PCHP_B = 'PCSF-B',
	PCHP_C = 'PCSF-C',
	PCHP_OTHER = 'PCSF-OTHER',
	JUSTIF = 'PCHP-ASSES',
}

export enum AssessmentApprovalType {
	PRE_APPROVAL = '"PREAPPROVAL"',
	FINAL_APPROVAL = '"FINALAPPROVAL"',
}

export const SepStrategicImpact = ['KA', 'KB', 'KC'];

// comfirmation dialog
export const MISSING_EMAIL = {
	header: 'Missing Email',
	question: 'The email is empty. Do you wish to save without an email address?',
	button: 'Continue',
	textarea: false,
};

export const CHANGE_EMAIL = {
	header: 'Change Email',
	question: 'Would you also like ARC to make this change to other charges that have a charging party with the same email address?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_LEAVE = {
	header: 'Changes are not saved.',
	question: 'Are you sure you want to leave? Your changes will not be saved.',
	button: 'Continue',
	buttonColor: 'warn',
	textarea: false,
};

export const VERIFY_SBI_COMBINATION = {
	header: 'Changes are not saved.',
	question: 'Are you sure you want to continue without adding basis for all the selected Statutes.',
	button: 'Continue',
	buttonColor: 'warn',
	textarea: false,
};

export const PRIVACY_ACT_CERTIFICATION = {
	header: 'Privacy Act Certification',
	question:
		'I confirm that I need access (read only) to the requested records to perform my official EEOC duties.  When my need for access to the requested records has been satisfied, I confirm that I will promptly terminate the assignment of such records to myself in ARC.',
	button: 'Confirm',
	buttonColor: 'warn',
	textarea: false,
};

export const RECOMMENDATION_WITHDRAW = {
	header: 'Withdraw Litigation Recommendation',
	question: 'You are about to withdraw this Litigation Recommendation. Continue?',
	button: 'Confirm',
	buttonColor: 'warn',
	textarea: false,
};

export const DETERMINATION_WITHDRAW = {
	header: 'Withdraw Litigation Determination',
	question: 'You are about to withdraw this Litigation Determination. Continue?',
	button: 'Confirm',
	buttonColor: 'warn',
	textarea: false,
};

export const RECOMMENDATION_HOLD = {
	header: 'Hold Litigation recommendation',
	question: 'You are about to hold this Litigation Recommendation. Continue?',
	button: 'Confirm',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_DEFORMALIZE = {
	header: 'DEFORMALIZE CHARGE',
	question: 'You are about to reset the case Status to Charge Prepared. Continue?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const UPDATE_INTENT = {
	header: 'Update Deferral Office Intent',
	question: 'Are you sure you want to update Deferral Office Intent.',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_OPEN_CASE = {
	header: 'Open Case',
	question: 'Are you sure you want to open/restore this Case?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_CLOSE = {
	header: 'Close Case',
	question: 'Are you sure you want to close this Case?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_DELETE = {
	header: 'Delete Case',
	question: 'Are you sure you want to remove this Case from ARC Litigation & Appeals?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_CLOSE_APPEAL = {
	header: 'Close Appeal',
	question: 'Are you sure you want to close the Appeal?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_REOPEN_APPEAL = {
	header: 'Reopen Appeal',
	question: 'Are you sure you want to reopen the Appeal?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_REOPEN_ENF_APPEAL = {
	header: 'Remove Resolution',
	question:
		'You are about to remove this Resolution. If the appealed Case had any Benefits, you may need to add them back',
	button: 'GO TO BENEFITS',
	secondButton: 'NOT NOW',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_REMOVE_RESOL_ENF_APPEAL = {
	header: 'Remove Resolution',
	question:
		'Removing resolution will remove benefits. If the Case had benefits, you may need to add them back. Continue?',
	button: 'GO TO BENEFITS',
	secondButton: 'NOT NOW',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_DELETE_APPEAL = {
	header: 'Delete Appeal',
	question: 'Are you sure you want to delete the Appeal?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_RESTORE_APPEAL = {
	header: 'Restore Appeal',
	question: 'Are you sure you want to restore the Appeal?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_REMOVE_APPEAL_RCMND = {
	header: 'Remove Recommendation',
	question: 'Are you sure you want to remove recommendation appeal data?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_REMOVE_APPEAL_RESOLUTION = {
	header: 'Remove Resolution',
	question: 'Are you sure you want to remove resolution appeal data?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const UPDATE_INTENT_RECEIVING = {
	header: 'Update Receiving Office Intent',
	question:
		'Are you sure you want to update Receiving Office Intent and it will clear the Deferral Office Intent as well.',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'warn',
	textarea: false,
};

export const CONFIRM_ASSESSMENT_APPROVAL = {
	header: 'Assigning Approver',
	question: 'You are about to assign this charge for assessment approval. Continue?',
	button: 'Yes',
	textarea: false,
};

export const CONFIRM_BENEFIT_DELETE = {
	header: 'Remove Benefit',
	question: 'Are you sure you want to delete benefit(s)?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_TEMPLATE_DELETE = {
	header: 'Remove Template',
	question: 'Are you sure you want to remove template?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_COD_DRAFT_MISSING = {
	header: 'COD Draft',
	question:
		'You must generate and upload a Charge of Discrimination-draft before you can upload a Charge of Discrimination',
	button: 'Go To Charge Finalization',
	secondButton: 'Cancel',
	textarea: false,
};

export const ADD_NAICS_CODE = {
	header: 'Add Naics',
	question:
		'You must select a NAICS Category for the Respondent before you can close a charge. Would you like to select one now??',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};
export const CONFIRM_REVIEW_DELETION = {
	header: 'Reviews',
	question: 'Are you sure you want to Delete the review?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};
export const CONFIRM_REVIEW_APPROVAL = {
	header: 'Reviews',
	question: 'Are you sure you want to Approve the review?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};
export const CONFIRM_REVIEW_DENIAL = {
	header: 'Reviews',
	question: 'Are you sure you want to Deny the review?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};
export const CONFIRM_CLOSURE_REVIEW_APPROVAL = {
	header: 'Closure Review',
	question:
		'You are about to close this charge. Doing so will notify the appropriate parties of the closure. Continue?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};
export const CONFIRM_CLOSURE_REVIEW_DENIAL = {
	header: 'Closure Review',
	question: 'You are about to deny the closure review. Continue?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};
export const CONFIRM_SELF_APPROVAL = {
	header: 'Self-Approval',
	question: 'Are you authorized to self-approve this charge assessment?',
	button: 'Approve',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_RETRACT_ASSESSMENT = {
	header: 'Retract Assessment',
	question: 'You have not approved this PCHP assessment. Are you sure you want to continue?',
	button: 'Yes',
	textarea: false,
};

export const CONFIRM_REMOVE_EXISTING_OFFICE = {
	header: 'Remove Office Confirmation',
	question: '',
	button: 'Remove',
	buttonColor: 'success',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_REMOVE_ROLE = {
	header: 'Remove Role(s)',
	// question:
	// 	'Removing this Role will remove all case assignments to the Role holder within the Access Office. Continue?',
	//	question: 'Enter reason for removing the role',
	button: 'Remove',
	secondButton: 'No',
	textarea: true,
};

export const CONFIRM_CHANGE_SUPERVISOR_ROLE_UNIT = {
	header: 'Unit Change Confirmation',
	question: 'Changing this unit will remove the supervisory relationship to non-supervisory role holders in the unit.',
	button: 'Continue',
	secondButton: '',
	textarea: false,
};

export const CONFIRM_ROLE_CASEASSIGNMENTS_REMOVAL = {
	header: 'Case Assignments Removal Confirmation',
	question: 'Do you want to also remove the case assignments to this Role holder as well as their Role?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_APPROVE_ASSESSMENT = {
	header: 'Approve Assessment',
	question:
		'You are about to approve this charge assessment. Is this the final assessment approval?\n(Click on the backdrop to cancel.)',
	button: 'Approve',
	secondButton: 'No',
	textarea: false,
};

export const CONFIRM_CANCEL = {
	header: 'Confirm Cancellation',
	question: 'Are you sure you want to cancel ? Your changes will be aborted.',
	button: 'Yes',
	buttonColor: 'warn',
};

export const CONFIRM_NEW_INQUIRY = {
	header: 'Confirm New Inquiry Creation',
	question: 'Do you want to email the Notice of New Inquiry Submission to PCP?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_NEW_INQUIRY1 = {
	header: 'Confirm New Inquiry Creation',
	question: 'Are you sure you want to create a new inquiry?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_CREDIT_REVOKE_REOPEN = {
	header: 'Revoking Credit',
	question: 'Credit has been requested for this charge. Reopening it will revoke the Credit Request. Continue?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_CREDIT_APPEAL = {
	header: 'Appeal Credit',
	question: 'Are you sure you want to appeal for credit?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_CHARGE_SUSPEND = {
	header: 'Suspend Case',
	question: 'Would you like to suspend this charge?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_CONSOLIDATE_CASES = {
	header: 'CONSOLIDATE CASE',
	question: '',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_REMOVE_ASSOCIATED_CASES = {
	header: 'REMOVE ASSOCIATED CHARGES',
	question: '',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_CREDIT_REVOKE = {
	header: 'Revoking Credit',
	question: 'Are you sure you want to revoke the credit?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};

export const CONFIRM_TO_CLEAR_CP = {
	header: 'Charging Party Information',
	question: 'Are you sure you want to clear the charging party information?',
	button: 'Yes',
	buttonColor: 'success',
	secondButton: 'No',
};
export const CONFIRM_UPDATE_INQUIRY = {
	header: 'Confirm Update Inquiry',
	question: 'Are you sure you want to continue update inquiry?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_APPROVE_ACCOUNTS = {
	header: 'Confirm Approve Accounts',
	question: 'Are you sure you want to Approve the Account?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_NEW_INQUIRY_CANCEL = {
	header: 'Confirm New Inquiry Cancellation',
	question: 'Are you sure you want to clear New Inquiry creation?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_SAVE_OFFLINE_CASE = {
	header: 'Confirm Save Case Information',
	question: 'Are you sure you want to save case information?',
	button: 'Yes',
	buttonColor: 'success',
};

export const MEDIATION_INITIATION = {
	header: 'Confirm Alternative Dispute Resolution ',
	question: `You are about to move this charge to ADR. Want to continue?`,
	button: 'Yes',
	buttonColor: 'warn',
};

export const PENDING_ACCOUNTS = {
	header: '',
	question: '',
	button: '',
	color: '',
	secondButton: 'Cancel',
	textarea: false,
	reason: '',
};

export const DENIAL_REASON = {
	header: 'Reason for Denial',
	button: 'Save',
	textarea: true,
	reason: '',
};
export const DEACTIVATE_REASON = {
	header: 'Reason for Removal',
	button: 'Remove Role',
	textarea: true,
	reason: '',
};
export const PRIOR_FISCAL_YEAR_REASON = {
	header: 'Prior Fiscal Year Entry',
	textAreaLabel:
		'You have entered a date from a previous Fiscal Year. To continue, you must provide a reason why an inquiry submitted in a previous Fiscal Year is being entered in the current Fiscal Year.',
	button: 'Save',
	textarea: true,
	textAreaMaxLength: 500,
	reason: '',
};

export const MISSING_EML_CONNECTION = {
	header: 'Missing Employer Master List record',
	question: `The respondent doesn't have an EML record. Do you want to link now?`,
	button: 'Yes',
	textarea: false,
};

export const SEND_TO_ACCT_OFFICE = {
	header: 'Ready to Send?',
	question: `You cannot modify your reply after you send it. Are you sure you want to submit your reply now?`,
	button: 'Send',
	textarea: false,
};

export const RESEND_DOCUMENT = {
	header: 'RESEND EMAIL',
	question: 'Are you sure you want to resend the email? ',
	button: 'Send',
	textarea: false,
};

export const RELEASE_DOCUMENT = {
	header: 'RELEASE DOCUMENT',
	question:
		'The NRTS has not been released to the Charging Party and Respondent. Do you want to release it now?',
	button: 'Yes',
	secondButton: 'No',
	textarea: false,
};

export const DELETE_ADDITIONAL_ADDRESS = {
	header: 'Delete Additional Address',
	question: 'Are you sure you want to delete this Additional Address?',
	button: 'Delete',
	textarea: false,
};

export const DELETE_ADDITIONAL_RESPONDENT = {
	header: 'Delete Additional Address',
	question: 'Are you sure you want to delete this Additional Respondent?',
	button: 'Delete',
	textarea: false,
};

export const DELINK_RESPONDENT = {
	header: 'Delink Respondent',
	question: 'Are you sure you want to delink the Respondent?',
	button: 'Delink',
	textarea: false,
};

export const DELETE_EMP_CONTACT = {
	header: 'Delete Contact',
	question: 'Are you sure you want to delete this contact?',
	button: 'Delete',
	buttonColor: 'warn',
};

export enum ClosedInquiry {
	RefAgency = 'CLOSURE_REFFEDAGNCY',
	RefLocal = 'CLOSURE_REFSTLOCAL',
	RefOther = 'CLOSURE_REFOTHER',
	Other = 'CLOSURE_OTHER',
	Error = 'CLOSURE_ERR',
	DuplicateCase = 'CLOSURE_DUPLICATECASE',
}

export const CONFIRM_RELEASE_DOCUMENT = {
	header: 'Release Document',
	question: 'You are about to make this document accessible to the following parties: ',
	confirmationMessage: 'I confirm that I would like to release this document.',
	button: 'Release Document',
	textarea: false,
};

export const CONFIRM_RECALL_DOCUMENT = {
	header: 'Recall Document',
	question: 'Please enter the reason that you are recalling this document:',
	button: 'Recall Document',
	buttonColor: 'warn',
	textarea: true,
	textAreaMaxLength: 140,
	reason: '',
};

export const CONFIRM_DELETE_DOCUMENT = {
	header: 'Delete Document',
	question: 'Please enter the reason that you are deleting this document:',
	button: 'Delete Document',
	textarea: false,
	showOptions: true,
	textAreaMaxLength: 140,
	reason: '',
};

export const CONFIRM_RETRACT_DOC = {
	header: 'RETRACT DOCUMENT',
	question: 'Please enter the reason that you are retracting this document:',
	button: 'Retract Document',
	textarea: false,
	showOptions: true,
	textAreaMaxLength: 140,
	reason: '',
};

export const DELETE_REOPEN_INQUIRY = {
	header: 'Re-open Inquiry',
	question: 'You are about to re-open this inquiry. Do you want to continue?',
	button: 'Reopen',
	textarea: false,
};

export const CONFIRM_REOPEN_ALLEGATION = {
	header: 'Re-open Allegation',
	question: 'This Allegation has benefits. If you reopen it, the benefits will be deleted. Continue?',
	button: 'Reopen',
	textarea: false,
};

export const CONFIRM_DELETE_NOTES = {
	header: 'Delete Note',
	question: 'Are you sure you want to delete note?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'success',
};

export const CONFIRM_SELECT_NONMB = {
	header: 'TER BENEFIT',
	question: 'Is this TER Benefit?',
	button: 'Yes',
	secondButton: 'No',
	buttonColor: 'success',
};

export const CONFIRM_SAVE_SYSTEMIC_CASE = {
	header: 'Save systemic case',
	question: 'Are you sure you want to save systemic case?',
	button: 'Yes',
	textarea: false,
};

export const CONFIRM_REMOVE_SYSTEMIC_CASE = {
	header: 'Confirm Remove Systemic Case(s)',
	question: 'Are you sure you want to remove selected systemic case(s)?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_DELETE_SYSTEMIC_CASE = {
	header: 'Confirm Close Systemic Case(s)',
	question: 'Are you sure you want to close selected systemic case(s)?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_BENEFITS = {
	header: 'REMOVE BENEFITS',
	question: 'Are you sure you want to remove selected beneficiary and associated benefits?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_TRIAL_INFORMATION = {
	header: 'REMOVE TRIAL INFORMATION',
	question: 'Are you sure you want to remove Trial Information?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_COMPLAINT_DATE = {
	header: 'REMOVE COMPLAINT DATE',
	question:
		'Deleting the Complaint Filing date will delete any additional Litigation Information that has been entered. Continue?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_FILE_APPLICATION_DATE = {
	header: 'REMOVE FILE APPLICATION DATE',
	question:
		'Deleting the File Application date will delete any additional Litigation Information that has been entered. Continue?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_PETITION_DATE = {
	header: 'REMOVE PETITION TO ENFORCE CONSENT DECREE',
	question: 'You are about to remove Monitoring details. Continue?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_RESOLUTIONS = {
	header: 'REMOVE RESOLUTION',
	question: 'Are you sure you want to delete Resolution(s)?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_POST_RESOLUTIONS = {
	header: 'REMOVE POST-RESOLUTION',
	question: 'Are you sure you want to delete Post-Resolution(s)?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_POST_PETITION_RESOLUTIONS = {
	header: 'REMOVE POST-RESOLUTION',
	question:
		'You are deleting the last Petition for Post Judgment Relief filing. This will also delete any Post-Judgment Relief information from the Case. Continue?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_PETITIONS = {
	header: 'REMOVE PETITION TO ENFORCE CONSENT DECREE',
	question: 'Are you sure you want to delete Petition?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_SUBSCRIBE_SYSTEMIC_CASE = {
	header: 'Confirm Subscribe Systemic Case(s)',
	question: 'Are you sure you want to subscribe to selected systemic case(s)?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_UNSUBSCRIBE_SYSTEMIC_CASE = {
	header: 'Confirm Unsubscribe Systemic Case(s)',
	question: 'Are you sure you want to unsubscribe to selected systemic case(s)?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_REMOVE_CASES_FROM_SYSTEMIC = {
	header: 'Confirm Remove Case(s) from Systemic Case',
	question: 'Are you sure you want to remove selected case(s) from systemic case?',
	button: 'Yes',
	buttonColor: 'success',
};

export const CONFIRM_SAVE_ADR: CustomDynamicFormInterface = {
	header: 'Confirm Save ADR information',
	question: 'Are you sure you want to save ADR information?',
	primaryButton: 'Yes',
	primaryButtonColor: 'success',
};

export const CONFIRM_CREATE_NEW_FEPA_USER = {
	header: 'Create New FEPA User',
	question: 'No matches found. Do you wish to create a new FEPA user?',
	button: 'Yes',
	textarea: false,
};

export const ADD_NEW_DFC: CustomDynamicFormInterface = {
	header: 'Add Digital File Cabinet',
	formFields: [
		{
			key: 'folderName',
			controlType: 'textbox',
			label: 'Enter Digital File Cabinet Name',
			maxLength: 75,
			required: true,
			validators: null,
			errorMessage: 'You must enter a Digital File Cabinet Name',
		},
		{
			key: 'description',
			controlType: 'textarea',
			label: 'Add Description',
			required: true,
			validators: null,
			errorMessage: 'You must enter a description',
		},
		{
			key: 'office',
			controlType: 'dropdown',
			label: 'Select Office',
			required: true,
			validators: null,
			errorMessage: 'You must select an office',
		},
	],
	primaryButton: 'ADD',
};

export const EDIT_DFC: CustomDynamicFormInterface = {
	header: 'Edit Digital File Cabinet',
	formFields: [
		{
			key: 'folderName',
			controlType: 'textbox',
			label: 'Enter Digital File Cabinet Name',
			required: true,
			validators: null,
			errorMessage: 'You must enter a Digital File Cabinet Name',
		},
		{
			key: 'description',
			controlType: 'textarea',
			label: 'Add Description',
			required: true,
			validators: null,
			errorMessage: 'You must enter a description',
		},
		{
			key: 'office',
			controlType: 'dropdown',
			label: 'Select Office',
			required: true,
			validators: null,
			errorMessage: 'You must select an office',
		},
	],
	primaryButton: 'UPDATE',
};

export const REMOVE_DFC: CustomDynamicFormInterface = {
	header: 'Remove Digital File Cabinet(s)',
	question:
		'Are you sure you would like to remove the selected file cabinets(s)?<br/><br/>The file cabinet(s) will be deleted and the attached cases will no longer be associated with the selected file cabinet(s).',
	primaryButton: 'REMOVE',
	primaryButtonColor: 'warn',
};

export const ADD_DFC_CHARGES: CustomDynamicFormInterface = {
	header: 'Add to File Cabinet',
	formFields: [
		{
			key: 'folder',
			controlType: 'dropdown',
			label: 'Select File Cabinet',
			required: true,
			validators: null,
			errorMessage: 'You must select a File Cabinet',
		},
	],
	question:
		'Add case(s) to a case file cabinet below. If you do not see the digital file cabinet that you would like to add cases to, you may add it on the Digital File Cabinet page.',
	primaryButton: 'ADD TO FILE CABINET',
};

export const MOVE_DFC_CHARGES: CustomDynamicFormInterface = {
	header: 'Move to File Cabinet',
	formFields: [
		{
			key: 'folder',
			controlType: 'dropdown',
			label: 'Select File Cabinet',
			required: true,
			validators: null,
			errorMessage: 'You must select a File Cabinet',
		},
	],
	question: 'Select the file cabinet that you would like to move the selected case(s) to:',
	primaryButton: 'MOVE TO FILE CABINET',
};

export const MANAGE_DFC_CHARGE: CustomDynamicFormInterface = {
	header: 'Manage Digital File Cabinet',
	formFields: [
		{
			key: 'folder',
			controlType: 'dropdown',
			label: 'Select File Cabinet',
			required: true,
			validators: null,
			errorMessage: 'You must select a File Cabinet',
		},
	],
	question:
		"You may add or update this case's digital file cabinet below. If you do not see the file cabinet that you would like to use, you may add it on the Digital File Cabinets page.",
	primaryButton: 'UPDATE',
	secondaryButton: 'REMOVE FROM FILE CABINET',
	secondaryButtonColor: 'warn',
};

export const REMOVE_DFC_CHARGES: CustomDynamicFormInterface = {
	header: 'Remove From File Cabinet',
	question: 'Are you sure you would like to remove the selected case(s) from their file cabinet(s)',
	primaryButton: 'REMOVE',
	primaryButtonColor: 'warn',
};

export const ADD_NEW_CCG: CustomDynamicFormInterface = {
	header: 'Add Class Case Group',
	formFields: [
		{
			key: 'className',
			controlType: 'textbox',
			label: 'Enter Class Name',
			maxLength: 75,
			required: true,
			validators: [{ name: 'maxLength', value: 75 }],
			errorMessage: 'You must enter a valid class name',
		},
		{
			key: 'description',
			controlType: 'textarea',
			label: 'Add Description',
			required: true,
			validators: null,
			errorMessage: 'You must enter a description',
		},
	],
	primaryButton: 'ADD',
};

export const EDIT_CCG: CustomDynamicFormInterface = {
	header: 'Edit Class Case Group',
	formFields: [
		{
			key: 'className',
			controlType: 'textbox',
			label: 'Enter Class Name',
			maxLength: 75,
			required: true,
			validators: [{ name: 'maxLength', value: 75 }],
			errorMessage: 'You must enter a valid class name',
		},
		{
			key: 'description',
			controlType: 'textarea',
			label: 'Add Description',
			required: true,
			validators: null,
			errorMessage: 'You must enter a description',
		},
	],
	primaryButton: 'UPDATE',
};

export const REMOVE_CCG: CustomDynamicFormInterface = {
	header: 'Remove Class Case Group(s)',
	question:
		'Are you sure you would like to remove the selected class case group(s)?<br/><br/>The class case groups will be deleted and the attached cases will no longer be associated with the selected class case groups.',
	primaryButton: 'REMOVE',
	primaryButtonColor: 'warn',
};

export const ADD_CCG_CHARGES: CustomDynamicFormInterface = {
	header: 'Add to Class Case Group',
	formFields: [
		{ key: 'classGroup', controlType: 'dropdown', label: 'Select Class Case Group', required: true, validators: null },
	],
	question:
		'Add Case(s) to the Class Case Group below. If you do not see the Class Case Group that you would like to add cases to, you may add it on the Class Case Groups page.',
	primaryButton: 'ADD TO CLASS CASE GROUP',
};

export const MOVE_CCG_CHARGES: CustomDynamicFormInterface = {
	header: 'Move to Class Case Group',
	formFields: [
		{ key: 'classGroup', controlType: 'dropdown', label: 'Select Class Case Group', required: true, validators: null },
	],
	question: 'Select the class case group that you would like to move the selected case(s) to:',
	primaryButton: 'MOVE TO CLASS CASE GROUP',
};

export const MANAGE_CCG_CHARGE: CustomDynamicFormInterface = {
	header: 'Manage Class Case Group',
	formFields: [
		{ key: 'classGroup', controlType: 'dropdown', label: 'Select Class Case Group', required: true, validators: null },
	],
	question:
		'You may add or update this class case group below. If you do not see the group that you would like to use, you may add it on the Class Case Groups page.',
	primaryButton: 'UPDATE',
	secondaryButton: 'REMOVE FROM CLASS CASE GROUP',
	secondaryButtonColor: 'warn',
};

export const REMOVE_CCG_CHARGES: CustomDynamicFormInterface = {
	header: 'Remove From Class Case Group',
	question: 'Are you sure you would like to remove the selected cases from class case group(s)',
	primaryButton: 'REMOVE',
	primaryButtonColor: 'warn',
};

// API URL's
export enum LoginUserInfo {
	getAppSecurityToken = 'v1/portal/intake/inquiry/token',
}

export enum AdtnlAddressType {
	CP_Representative = 'CPR',
	CP_Legal_Rep = 'CLR',
	Resp_Contact_Info = 'RPC',
	Resp_legal_Info = 'RLR',
	// These are remaining relationship types we are not using those for now. Future reference.
	Additional_CP = 'ACP',
	Additional_Resp = 'ARP',
	Agency_Head = 'AGH',
	CP_Contact_Info = 'CPC',
	Local_Mediation_Contact = 'LMC',
	National_Mediation_Contact = 'NMC',
	Resp_Contact_Local = 'RPL',
	Resp_Contact_National = 'RPN',
	Section83_Contact_CP = '83C',
	Section83_Contact_Other = '83O',
	Section83_Contact_Resp = '83R',
}

export const RespondentChangesStyleForIms = {
	nameDifference: false,
	addressDifference: false,
	cityDifference: false,
	countryDifference: false,
	stateDifference: false,
	zipDifference: false,
	phoneDifference: false,
	faxDifference: false,
	emailDifference: false,
	extensionDifference: false,
	employeeSizeDifference: false,
	institutionTypeDifference: false,
	unitNameDifference: false,
	unitNumberDifference: false,
	hqNameDifference: false,
	hqNumbrDifference: false,
};

export const PRIVATE_SUPERVISOR_ID: string = 'e852c461-0ae6-431e-a5a8-531d9288eb66';
export const PRIVATE_STAFF_ID: string = 'da209c8d-29b4-498b-835e-48ea4f3b2b45';

export const CaseStatusColor = {
	INQUIRY_CLOSED: 'grey',
	CLOSED: 'grey',
	INQUIRY_SUBMITTED: 'green',
	CHARGE_PREPARED: 'green',
	CHARGE_FILED: 'green',
};

export const MissingAllegationMessage =
	'Before you complete the Particulars for this charge, please make sure you have identified at least ' +
	'one allegation in the IMS Allegation tab. ' +
	'For ADEA allegations, also be sure to identify the Charging Party’s date of birth, and for EPA allegations, ' +
	'also be sure to identify the Charging Party’s sex.';

export const RecalTypes: Array<string> = [
	'Position Statement',
	'Position Statement - Redacted',
	'Supplemental Position Statement',
	'Position Statement Attachments - Non-Confidential',
	'Position Statement Attachments - Non-Confidential (Redacted)',
	'Supplemental Position Statement-Redacted',
];

export const ReleaseTypes: Array<string> = [
	'PRVPOS',
	'PRVRFI',
	'RFICP',
	'AMDLTRDTR',
	'AMDTWOPARTYLTRDTR',
	'PRVLOD',
	'LTRDTRM',
	'RECODIS',
	'SETAGRMNT',
	'TWOPRTYCONCAGRMNT',
	'TWOPRTYLTRDTRM',
	'TWOPRTYLSETAGRMNT',
	'OTHJURIDOC',
	'POSSTREDAC',
	'POSSTNONCFATCHREDAC',
	'SUPPOSSTMNTREDAC',
	'DETPETRVKMODSPNA',
	'PRVPOSATTRDCTD',
	'FRM136',
	'STMTSUPPOS',
	'CONAGRMNT',
	'CONPRPSL',
	'NOTCONFAIL',
	'NOTREOPNCON',
	'RFIRES',
	'SETCORCPAT',
	'SUBPOSSTMNT',
	'STMTPSANC',
];

export const SensitiveTypes = ['RESPSENSTVDATASET', 'THRDPYSENSITIVEDATA', 'RESPSENSITIVEDATA'];
export const GlobalUserRoleCode = 'GLOBAL_USER';
export const ComplianceMonitorRoleCode = 'PRIVATE_COMPLIANCE_MONITOR';
export const SupervisorRoleCode = 'PRIVATE_SUPERVISOR';
export const StaffRoleCode = 'PRIVATE_STAFF';
export const EmlAdminRoleCode = 'PRIVATE_EML_ADMIN';
export const OFPAdminRoleCode = 'PRIVATE_OFP_ADMIN';
export const OGCAdminRoleCode = 'PRIVATE_OGC_ADMIN';
export const AttorneyRoleCode = 'PRIVATE_ATTORNEY';
export const AttorneySupervisorRoleCode = 'PRIVATE_ATTORNEY_SPRVSR';
export const AppaletteAttorneyRoleCode = 'LIT_APLETE_ATTORNEY';
export const PrivateReadOnlyRole = 'PRIVATE_READ_ONLY';
export const PrivateInspectorGeneral = 'PRIVATE_INSP_GEN';
export const AppaletteAttorneySupervisorRoleCode = 'LIT_APLETE_SUPERVISOR';
export const LegalSupportRole = 'LIT_LEGAL_SUPPORT';
export const AdrAdminRoleCode = 'PRIVATE_ADR_ADMIN';
export const AdrCoordinatorRoleCode = 'PRIVATE_ADR_COORDINATOR';
export const AdrStaffMediatorRoleCode = 'PRIVATE_ADR_STAFF_MEDIATOR';
export const AdrNonStaffMediatorRoleCode = 'PRIVATE_ADR_NON_STAFF_MEDIATOR';
export const SystemicCoordinatorRoleCode = 'PRIVATE_SYSTEMIC_COORDINATOR';
export const SystemicAdminRoleCode = 'PRIVATE_SYSTEMIC_ADMIN';
export const SltpAdminRoleCode = 'PRIVATE_STATE_LOCAL_ADMIN';
export const SltpCoordinatorRoleCode = 'PRIVATE_STATE_LOCAL_COORDINATOR';
export const RegistrationApproverRoleCode = 'PRIVATE_RGSTRTN_APPROVER';
export const RegistrationManagerRoleCode = 'PRIVATE_RGSTRTN_MANAGER';
export const DelegatedReviewerRoleCode = 'PRIVATE_DELEGATED_REVIEWER';
export const FoiaAdminRoleCode = 'PRIVATE_FOIA_ADMIN';
export const TemplateAdminRoleCode = 'PRIVATE_TEMPLATE_ADMIN';
export const TemplateManagerRoleCode = 'PRIVATE_TEMPLATE_MANAGER';
export const CommissionerRoleCode = 'PRIVATE_COMMISSIONER';

export const FepaRegistrationApproverRoleCode = 'FEPA_RGSTRTN_APPROVER';
export const FepaRegistrationManagerRoleCode = 'FEPA_RGSTRTN_MANAGER';
export const FepaStaffRoleCode = 'FEPA_STAFF';
export const FepaSupervisorRoleCode = 'FEPA_SUPERVISOR';
export const FepaGatewaySupervisorRoleCode = 'FEPA_GATEWAY_SUPERVISOR';
export const FepaTemplateManagerRoleCode = 'FEPA_TEMPLATE_MANAGER';

export const DashboardScreenAccessRoleHolders = [
	AdrStaffMediatorRoleCode,
	AdrNonStaffMediatorRoleCode,
	AdrCoordinatorRoleCode,
	AdrAdminRoleCode,
	StaffRoleCode,
	FepaStaffRoleCode,
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	AttorneySupervisorRoleCode,
	FepaGatewaySupervisorRoleCode,
	SltpCoordinatorRoleCode,
	SltpAdminRoleCode,
	AttorneyRoleCode,
	AttorneySupervisorRoleCode,
	FoiaAdminRoleCode,
	OFPAdminRoleCode,
	OGCAdminRoleCode,
	CommissionerRoleCode,
	GlobalUserRoleCode,
	AppaletteAttorneySupervisorRoleCode,
	AppaletteAttorneyRoleCode,
];

export const SearchScreenAccessRoleHolders = [
	AdrStaffMediatorRoleCode,
	AdrCoordinatorRoleCode,
	AdrAdminRoleCode,
	LegalSupportRole,
	StaffRoleCode,
	FepaStaffRoleCode,
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	AttorneySupervisorRoleCode,
	FepaGatewaySupervisorRoleCode,
	SltpCoordinatorRoleCode,
	SltpAdminRoleCode,
	AttorneyRoleCode,
	AttorneySupervisorRoleCode,
	FoiaAdminRoleCode,
	OFPAdminRoleCode,
	OGCAdminRoleCode,
	SystemicAdminRoleCode,
	CommissionerRoleCode,
	GlobalUserRoleCode,
	AppaletteAttorneySupervisorRoleCode,
	AppaletteAttorneyRoleCode,
	PrivateReadOnlyRole,
	PrivateInspectorGeneral
];

export const EmlScreenAccessRoleHolders = [
	// AdrStaffMediatorRoleCode,
	// AdrCoordinatorRoleCode,
	// AdrAdminRoleCode,
	StaffRoleCode,
	FepaStaffRoleCode,
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	AttorneySupervisorRoleCode,
	FepaGatewaySupervisorRoleCode,
	// SltpCoordinatorRoleCode,
	// SltpAdminRoleCode,
	AttorneyRoleCode,
	AttorneySupervisorRoleCode,
	OFPAdminRoleCode,
	OGCAdminRoleCode,
	EmlAdminRoleCode,
	SystemicAdminRoleCode,
	GlobalUserRoleCode,
];

export const UserManagementScreenAccessRoleHolders = [
	RegistrationManagerRoleCode,
	RegistrationApproverRoleCode,
	FepaRegistrationApproverRoleCode,
	FepaRegistrationManagerRoleCode,
	GlobalUserRoleCode,
];

export const DualFilingScreenAccessRoleHolders = [
	StaffRoleCode,
	FepaStaffRoleCode,
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	AttorneySupervisorRoleCode,
	FepaGatewaySupervisorRoleCode,
	SltpCoordinatorRoleCode,
	SltpAdminRoleCode,
	GlobalUserRoleCode,
];

export const NewInquiryScreenAccessRoleHolders = [
	// AdrStaffMediatorRoleCode,
	// AdrCoordinatorRoleCode,
	// AdrAdminRoleCode,
	StaffRoleCode,
	FepaStaffRoleCode,
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	AttorneySupervisorRoleCode,
	FepaGatewaySupervisorRoleCode,
	// SltpCoordinatorRoleCode,
	// SltpAdminRoleCode,
	AttorneyRoleCode,
	AttorneySupervisorRoleCode,
	GlobalUserRoleCode,
];

export const NewCaseScreenAccessRoleHolders = [
	AttorneySupervisorRoleCode,
	AttorneyRoleCode,
	OGCAdminRoleCode,
	LegalSupportRole,
	GlobalUserRoleCode,
];

export const ReportsRoleHolders = [OGCAdminRoleCode, GlobalUserRoleCode];

export const SystemicScreenAccessRoleHolders = [
	// AdrStaffMediatorRoleCode,
	// AdrCoordinatorRoleCode,
	// AdrAdminRoleCode,
	StaffRoleCode,
	FepaStaffRoleCode,
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	AttorneySupervisorRoleCode,
	FepaGatewaySupervisorRoleCode,
	SltpCoordinatorRoleCode,
	SltpAdminRoleCode,
	AttorneyRoleCode,
	AttorneySupervisorRoleCode,
	SystemicCoordinatorRoleCode,
	SystemicAdminRoleCode,
	OGCAdminRoleCode,
	GlobalUserRoleCode,
];

export const SltpScreenAccessRoleHolders = [SltpAdminRoleCode, SltpCoordinatorRoleCode, GlobalUserRoleCode];

export const TemplateManagementScreenAccessRoleHolders = [
	TemplateAdminRoleCode,
	TemplateManagerRoleCode,
	FepaTemplateManagerRoleCode,
];

export const DashboardViews = {
	adr: [AdrAdminRoleCode, AdrCoordinatorRoleCode, AdrStaffMediatorRoleCode, AdrNonStaffMediatorRoleCode],
	fepaCredit: [SltpAdminRoleCode, SltpCoordinatorRoleCode],
	fepa: [FepaSupervisorRoleCode, FepaStaffRoleCode, FepaRegistrationApproverRoleCode],
	legal: [AttorneySupervisorRoleCode, AttorneyRoleCode, OGCAdminRoleCode],
	systemic: [SystemicAdminRoleCode, SystemicCoordinatorRoleCode],
};

export const GlobalAdminRoleCodes = [
	AdrAdminRoleCode,
	CommissionerRoleCode,
	EmlAdminRoleCode,
	FoiaAdminRoleCode,
	OFPAdminRoleCode,
	OGCAdminRoleCode,
	SltpAdminRoleCode,
	SystemicAdminRoleCode,
	TemplateAdminRoleCode,
	PrivateReadOnlyRole,
	PrivateInspectorGeneral
];

export const LegalRoleCodes = [AttorneyRoleCode, AttorneySupervisorRoleCode];
export const OgcAppealsAssignmentCodes = [
	AppaletteAttorneyRoleCode,
	AppaletteAttorneySupervisorRoleCode,
	OGCAdminRoleCode,
];
export const AppalletteSuprAssignmentCodes = [AppaletteAttorneyRoleCode, AppaletteAttorneySupervisorRoleCode];
export const NonFEPARoles = [
	SltpAdminRoleCode,
	SltpCoordinatorRoleCode,
	'PRIVATE_TEMPLATE_MANAGER',
	'PRIVATE_TEMPLATE_ADMIN',
	'PRIVATE_IIG_SUBMITTER',
	ComplianceMonitorRoleCode,
	OGCAdminRoleCode,
	OFPAdminRoleCode,
];
export const ReviewRoleHolders = [
	AttorneySupervisorRoleCode,
	AttorneyRoleCode,
	SupervisorRoleCode,
	'DELEGATED_REVIEWER',
	AdrCoordinatorRoleCode,
];
export const SltpRoleHolders = [SltpAdminRoleCode, SltpCoordinatorRoleCode];
export const SystemicManagerRoleHolders = [SystemicAdminRoleCode, SystemicCoordinatorRoleCode, SupervisorRoleCode];
export const LitigationManagerRoleHolders = [AttorneyRoleCode, AttorneySupervisorRoleCode, OGCAdminRoleCode];
export const DigitalFileCabinetRoleHolders = [
	SupervisorRoleCode,
	AdrCoordinatorRoleCode,
	SystemicCoordinatorRoleCode,
	SltpCoordinatorRoleCode,
];
export const RegistrationUserRoleCodes = [
	RegistrationManagerRoleCode,
	RegistrationApproverRoleCode,
	FepaRegistrationApproverRoleCode,
	FepaRegistrationManagerRoleCode,
];

export const DigitalFileCabinetCasesRoleHolders = [
	SupervisorRoleCode,
	FepaSupervisorRoleCode,
	DelegatedReviewerRoleCode,
	AdrCoordinatorRoleCode,
	SystemicCoordinatorRoleCode,
	SltpCoordinatorRoleCode,
];

export const ROLEHOLDERSFUNCTIONS = [
	{
		roleCode: AdrCoordinatorRoleCode,
		functionsList: ['ADR'],
		minValue: 0,
		maxValue: 9,
	},
	{
		roleCode: AdrStaffMediatorRoleCode,
		functionsList: ['ADR'],
		minValue: 1,
		maxValue: 9,
	},
	{
		roleCode: AdrNonStaffMediatorRoleCode,
		functionsList: ['ADR'],
		minValue: 1,
		maxValue: 9,
	},
	{
		roleCode: AttorneyRoleCode,
		functionsList: ['LEGAL'],
		minValue: 1,
		maxValue: 9,
	},
	{
		roleCode: AttorneySupervisorRoleCode,
		functionsList: ['LEGAL'],
		minValue: 0,
		maxValue: 9,
	},
	{
		roleCode: LegalSupportRole,
		functionsList: ['LEGAL'],
		minValue: 0,
		maxValue: 9,
	},
	{
		roleCode: StaffRoleCode,
		functionsList: ['INTAKE', 'ENFORCEMENT'],
		minValue: 1,
		maxValue: 9,
	},
	{
		roleCode: SupervisorRoleCode,
		functionsList: ['INTAKE', 'ENFORCEMENT'],
		minValue: 0,
		maxValue: 9,
	},
	{
		roleCode: AppaletteAttorneyRoleCode,
		functionsList: ['APPELLATE'],
		minValue: 1,
		maxValue: 3,
	},
	{
		roleCode: AppaletteAttorneySupervisorRoleCode,
		functionsList: ['APPELLATE'],
		minValue: 0,
		maxValue: 3,
	},
];

export interface IBreadCrumbInterface {
	label: string;
	url: string;
}

export const SubpoenaInfoText =
	'In the event a respondent is unwilling to cooperate in the evidence-gathering process, the agency may issue a Subpoena to obtain the information or action it requested.';

export const ScrollStrategy = {
	enable: () => {
		if (document?.body?.style) document.body.style.overflow = 'hidden';
	},
	disable: () => {
		if (document?.body?.style) document.body.style.overflow = 'auto';
	},
	attach: (a) => { },
};

export const StandardTemplateHoverText =
	'Templates which are available to all EEOC offices and are managed by OFP Administrator role holders.';
export const LocalTemplateHoverText =
	'Templates which are available to designated offices and are managed by Template Manager role holders.';

export enum TransferStatusCodeLookup {
	PENDING = 'P',
	REJECTED = 'R',
	APPROVED = 'A',
}

export const DisclosableMessage =
	"A document (or other data) that may be provided to a party in response to that party's request for information under the Freedom of Information Act (FOIA) or Section 83 of U.S. EEOC Compliance Manual.";
export const NonDisclosableMessage =
	"A document (or other data) that is considered to be 'confidential' and cannot be provided to a party in response to that party's request for information under the Freedom of Information Act (FOIA) or Section 83 of the U.S. EEOC Compliance Manual";

export const FepaUsersSuspensionReasonCodes = ['HPH', 'HPL'];
export const EeocUsersSuspensionReasonCodes = ['O', 'SH', 'SHGD', 'HPL'];
export const UnknownRaceItemCodes = ['N', 'U', 'Z'];
export const FepaOutcomesRequiredStatusCodes = ['C', 'R'];
export const FepaClosureReasonRequiredStatusCodes = ['H'];
export const Reasons = [
	'ADR_RSLTNS_1',
	'ADR_RSLTNS_2',
	'RSLTNS_1',
	'RSLTNS_2',
	'RSLTNS_3',
	'RSLTNS_4',
	'WTDALS_1',
	'LGL_2',
	'HADF',
	'DWNRTS_2'
];

export const OtherMediationUnsuccessfulCode = ['IMP-OTHER', 'NOTHLD-OTHER'];
export const AdrStaffAssignmentReason = {
	id: 7565,
	code: 'MEDIATOR_ASSIGNED',
	domain: 'PMED',
	description: 'A mediator has been assigned to the charge',
	shortName: 'A mediator has been assigned to the charge',
	viewableByFepa: false,
};
export interface UserManagementUserTypeInterface {
	type: string;
	userType: string;
	displayLabel: string;
}
export const UserManagementUserTypes = [
	{ type: 'PRIVATE', userType: 'Member', displayLabel: 'EEOC' },
	{ type: 'FEPA', userType: 'Guest', displayLabel: 'FEPA' },
];

export const minimumDate = new Date(1960, 0, 1);

export const momentTimezones = {
	AST: 'Canada/Atlantic',
	CST: 'America/Chicago',
	'CST-INDIANA': 'America/New_York',
	'CST-MICH': 'America/New_York',
	EST: 'America/New_York',
	'EST-INDIANA': 'America/Denver',
	HST: 'Pacific/Honolulu',
	MST: 'America/Denver',
	'MST-AZ': 'America/Denver',
	PST: 'America/Los_Angeles',
	'PST-NEW': 'America/Los_Angeles',
	AKST: 'America/Anchorage',
};

export const PublicPortalReceivedByCode = 'PPRTL';
export const FepaPortalReceivedByCode = 'FEPA';
export const EfileVerifiedCode = 'EFPT';
export const EfileUnverifiedCode = 'EFUPT';
export const obsoleteReceivedFromCodes = ['RCVFRM-TC', 'RCVFRM-HUDTVIII', 'RCVFRM-NP', 'RCVFRM-OTH'];
export const EnvMaps = {
	LOCAL: 'Localhost',
	DEV: 'Development',
	TEST: 'Testing',
	UAT: 'UAT Testing',
	TRAIN: 'Training',
};

export const TEMPLATE_CITATION = ['Section 706(f)(1)', 'Section 706(f)(2)'];
export const TEMPLATE_REQUESTFROM = ['Charging Party', 'Charging Party Attorney'];
export const TEMPLATE_ATTACHMENTS = ['Original Charge', 'Amended Charge', 'Cause Determination'];
export const LITIGATION_REASON_CODES = ['ES', 'LD', 'TR'];

export const SWR_PRIORITY_ISSUE: LookupData = {
	id: 8985,
	domain: 'SWR_REQ_BY',
	description: 'Priority Issue',
	code: 'L'
}