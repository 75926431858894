import { createAction, props } from "@ngrx/store"
export const LITIGATION_SEARCH = '[litigation search page] search';
export const LITIGATION_SEARCH_SUCCESS = '[litigation search page] search start';
export const LITIGATION__SEARCH_FAIL = '[litigation search page] search fail';
export const PRIVATE_SEARCH = '[search page] search';
export const PRIVATE_SEARCH_SUCCESS = '[search page] search start';
export const PRIVATE__SEARCH_FAIL = '[search page] search fail';


export const litigationSearch = createAction(
    LITIGATION_SEARCH,
    props<{ payload }>()
);

export const privateSearch = createAction(
    PRIVATE_SEARCH,
    props<{ payload}>()
);

export const privateSearchSuccess = createAction(PRIVATE_SEARCH_SUCCESS, props<{ privateSearchData: any }>());
export const litigationSearchSuccess = createAction(LITIGATION_SEARCH_SUCCESS, props<{ litigationSearchData: any }>());
export const litigationSearchFail = createAction(
    PRIVATE__SEARCH_FAIL,
    props<{ message: string }>()
);
export const privateSearchFail = createAction(
    PRIVATE__SEARCH_FAIL,
    props<{ message: string }>()
);
