import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ScrollStrategy } from '../common-structures/app.constants';
import { Alert, AlertType } from '../common-structures/ims-alerts';
import { StringUtils } from '../utility/stringUtils';
import { AlertService } from './ims-alerts.service';

@Component({
	selector: 'ims-alerts',
	templateUrl: './ims-alerts.component.html',
	styleUrls: ['./ims-alerts.component.scss'],
})
export class ImsAlertsComponent implements OnInit, OnDestroy {
	public displayMessage: string;
	public alertType: string;
	public alertSize: string;
	public matIcon: string;
	public header: string;
	private dialogRef: MatDialogRef<any>;
	private observer$ = new BehaviorSubject(true);
	@ViewChild('alertMessageTemplate', { static: true }) alertMessageTemplate: TemplateRef<any>;
	constructor(private alertServ: AlertService, public dialog: MatDialog) {}

	ngOnInit() {
		this.alertServ
			.getAlert()
			.pipe(takeWhile(() => this.observer$.value))
			.subscribe((inQueueMessages) => {
				if (!this.dialogRef && inQueueMessages?.length) this.getAlertMsg(inQueueMessages[0]);
			});
	}

	getAlertMsg(alert: Alert) {
		if (!alert) {
			return;
		}
		this.header = alert.header;
		this.displayMessage = this.breakMessage(alert.message);
		this.alertSize = this.displayMessage?.length > 70 ? 'large' : 'small';

		switch (alert.type) {
			case AlertType.Success:
				this.alertType = 'success';
				this.matIcon = 'check';
				this.alertMessage();
				break;
			case AlertType.Error:
				this.alertType = 'error';
				this.matIcon = 'block';
				this.alertMessage();
				break;
			case AlertType.Info:
				this.alertType = 'info';
				this.matIcon = 'info';
				this.alertMessage();
				break;
			case AlertType.Warning:
				this.alertType = 'warning';
				this.matIcon = 'warning';
				this.alertMessage();
				break;
			case AlertType.Deactivation:
				this.alertType = 'error';
				this.matIcon = 'power_settings_new';
				this.alertMessage();
				break;
		}
	}

	private alertMessage = () => {
		this.dialogRef = this.dialog.open(this.alertMessageTemplate, {
			disableClose: false,
			scrollStrategy: ScrollStrategy,
		});
		if (this.alertSize === 'small') {
			setTimeout(() => {
				this.dialogRef?.close();
			}, 3000);
		}
		this.dialogRef.afterClosed().subscribe((_) => {
			this.dialogRef = null;
			this.alertServ?.inQueueMessages?.shift();
			this.alertServ?.subject?.next(this.alertServ?.inQueueMessages as Alert[]);
		});
	};

	/**
	 * @description break the message by algorithm if any long word exists
	 * @Note css soultion is prefered but I couldn't find one.
	 */
	breakMessage(msg: string): string {
		const rs = [];
		const wordArray = (msg || '').toString().split(' ');
		if (!wordArray?.length) return;
		wordArray?.forEach((w) => {
			if (w.length <= 30) {
				rs.push(w);
			} else {
				rs.push(StringUtils.breakString(w, 30));
			}
		});
		return rs.join(' ');
	}

	ngOnDestroy(): void {
		this.observer$.next(false);
	}
}
