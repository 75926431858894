import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { TimeOutMessageComponent } from '../time-out-message.component';

@Component({
	selector: 'app-idle',
	templateUrl: './app-idle.component.html',
	styleUrls: ['./app-idle.component.css'],
})
export class AppIdleComponent implements OnInit, OnDestroy {
	onIdleEnd$: Subscription;
	onTimeout$: Subscription;
	onIdleStart$: Subscription;

	constructor(
		private idle: Idle,
		private snackBar: MatSnackBar,
		private cmnServ: CommonService,
		private router: Router
	) {
		// START ********** handling token timeout  *********
		//idle behavior: if idle for 3 hours, show timeout count down message for 2 min.
		// If no action detected in straight 32 mins, redirect to the session timeout page.
		// idle timeout is the time until idle countdown in seconds
		idle.setIdle(3 * 60 * 60);
		// timeout is the countdown time in seconds
		idle.setTimeout(120);
		// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		// idle interrupted
		this.onIdleEnd$ = idle.onIdleEnd.subscribe(() => {
			this.snackBar.dismiss();
		});
		// idle time out
		this.onTimeout$ = idle.onTimeout.subscribe(() => {
			localStorage.clear();
			sessionStorage.clear();
			this.snackBar.dismiss();
			this.router.navigateByUrl('/');
			this.cmnServ.onLogout.next(true);
		});
		// triggers when countdown starts
		this.onIdleStart$ = idle.onIdleStart.subscribe(() => {
			this.snackBar.openFromComponent(TimeOutMessageComponent);
		});

		this.idle.watch();
		// END ********** handling token timeout  *********
	}

	ngOnInit() {}

	/**
	 * @description unsubscribe to observable
	 */
	ngOnDestroy(): void {
		if (this.onTimeout$) {
			this.onTimeout$.unsubscribe();
		}
		if (this.onIdleEnd$) {
			this.onIdleEnd$.unsubscribe();
		}
		if (this.onIdleStart$) {
			this.onIdleStart$.unsubscribe();
		}
	}
}
