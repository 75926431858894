<div *ngIf="data.from === 'default'">
	<form>
		<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom margin-bottom-20">
			<h5 class="arc-h5 margin-20" matDialogTitle>
				{{ data.title }}
			</h5>
			<div class="col-sm-2 text-right">
				<app-close-mat-dialog (closeDialog)="dialogRef.close()"></app-close-mat-dialog>
			</div>
		</div>

		<mat-dialog-content class="border-bottom padding-top-10">
			<div class="arc-subtitle-1">{{ data.question }}</div>
		</mat-dialog-content>

		<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="margin-10 padding-10">
			<button mat-button type="button" appDebounceClick (debounceClick)="makeSelection(false)" id="doc-dlg-no-btn">No</button>
			<button mat-flat-button type="button" color="primary" appDebounceClick (debounceClick)="makeSelection(true)" id="doc-dlg-yes-btn">
				Yes
			</button>
		</mat-dialog-actions>
	</form>
</div>

<div *ngIf="data.from === 'resend'">
	<div style="width: 564px">
		<form>
			<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom">
				<h5 class="arc-h5 margin-20" matDialogTitle>Are you sure to re-send the selected Document?</h5>
				<div class="col-sm-2 text-right">
					<app-close-mat-dialog (closeDialog)="dialogRef.close()"></app-close-mat-dialog>
				</div>
			</div>

			<mat-dialog-content class="border-bottom padding-20">
				<mat-form-field class="input-full-width" appearance="outline">
					<mat-label>Resending Reason</mat-label>
					<textarea
						spellcheck="true"
						matInput
						style="width: 500px"
						id="doc-dlg-resendingReason"
						matTextareaAutosize
						matAutosizeMinRows="6"
						placeholder="Enter reason for resending..."
						maxlength="1000"
						[formControl]="emailReason"
					></textarea>
					<mat-hint align="end">{{ emailReason.value.length }} / 1000</mat-hint>
				</mat-form-field>
			</mat-dialog-content>
			<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="margin-10 padding-10">
				<button mat-button color="primary" type="button" title="cancel resend" appDebounceClick (debounceClick)="makeSelection(false)" id="doc-dlg-cancel-resend-btn">
					Cancel
				</button>
				<button
					mat-flat-button
					type="button"
					color="primary"
					id="doc-dlg-resend-btn"
					title="resend email"
					[disabled]="emailReason.invalid"
					appDebounceClick
					(debounceClick)="sendEmailReason(emailReason.value)"
				>
					Resend
				</button>
			</mat-dialog-actions>
		</form>
	</div>
</div>

<div *ngIf="data.from === 'edit'">
	<div style="width: 600px">
		<form [formGroup]="editDocForm">
			<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom">
				<h3 class="arc-h3 margin-20" matDialogTitle>Edit Document Information</h3>
				<div class="col-sm-2 text-right">
					<app-close-mat-dialog (closeDialog)="dialogRef.close()"></app-close-mat-dialog>
				</div>
			</div>

			<mat-dialog-content class="border-bottom padding-20">
				<label class="arc-subtitle-1 padding-right-20 margin-bottom-20">
					You may update the following document information:
				</label>

				<mat-form-field appearance="outline">
					<mat-label>Document Title</mat-label>
					<input matInput [disabled]="isCodType" placeholder="Document Title" maxlength="1000" formControlName="title" />
					<mat-hint class="error-message" *ngIf="editDocForm.get('title').hasError('required')">
						Please enter a document title
					</mat-hint>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Select File Type</mat-label>
					<mat-select
						[disabled]="isCodType" 
						(selectionChange)="onDocumentTypeSelect($event.value)"
						name="docTypeChange"
						formControlName="documentType"
						id="doc-dlg-documentType"
					>
						<mat-option *ngFor="let fileType of data.documentTypes" [value]="fileType.documentCode">
							{{ fileType.documentDescription }}
						</mat-option>
					</mat-select>
					<mat-hint class="error-message" *ngIf="editDocForm.get('documentType').hasError('required')">
						File Type is required!
					</mat-hint>
				</mat-form-field>
				<mat-form-field appearance="outline" *ngIf="!isSystemic">
					<mat-label>Select Folder</mat-label>
					<mat-select
						[disabled]="isCodType"  (selectionChange)="onFolderSelect($event.value)" name="tabTypeChange" formControlName="tabType" id="doc-dlg-tabTypeChange">
						<mat-option *ngFor="let tabType of data.categoryTypes" [value]="tabType.value">
							{{ tabType.value }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Received Date</mat-label>
					<input
						matInput
						[matDatepicker]="picker3"
						id="doc-dlg-relatedDate"
						formControlName="relatedDate"
						placeholder="Received Date"
						[min]="minDate"
						[max]="maxDate"
					/>
					<mat-datepicker-toggle matSuffix [for]="picker3" id="received-date-picker"></mat-datepicker-toggle>
					<mat-datepicker #picker3></mat-datepicker>
					<mat-hint class="error-message" *ngIf="editDocForm.get('relatedDate').hasError('required')">
						Received Date is required!
					</mat-hint>
				</mat-form-field>
				<mat-form-field *ngIf="enableResponseDueDate" appearance="outline">
					<mat-label>Response Due Date</mat-label>
					<input
						matInput
						[matDatepicker]="picker4"
						id="doc-dlg-responseDueDate"
						formControlName="responseDueDate"
						placeholder="Response Due Date"
						[min]="minDate"
					/>
					<mat-datepicker-toggle matSuffix [for]="picker4" id="response-due-date-picker"></mat-datepicker-toggle>
					<mat-datepicker #picker4></mat-datepicker>
					<mat-hint class="error-message" *ngIf="editDocForm.get('responseDueDate').hasError('required')">
						Response Due Date is Required!
					</mat-hint>
				</mat-form-field>
			</mat-dialog-content>
			<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="margin-10 padding-10">
				<button mat-button color="primary" title="cancel" [mat-dialog-close]="false" id="doc-dlg-cancel-btn">Cancel</button>
				<button
					mat-flat-button
					color="primary"
					title="save"
					id="doc-dlg-update-btn"
					[disabled]="editDocForm.invalid || !isChanged()"
					(click)="onUpdate(saveConfirmation)"
				>
					Update
				</button>
			</mat-dialog-actions>
		</form>
	</div>
</div>

<div *ngIf="data.from === 'fepa-email'">
	<div style="width: 564px">
		<form [formGroup]="emailForm">
			<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom margin-bottom-20">
				<h3 class="arc-h3 margin-20" matDialogTitle>Are you sure to re-send the selected Document?</h3>
				<div class="col-sm-2 text-right">
					<app-close-mat-dialog (closeDialog)="dialogRef.close()"></app-close-mat-dialog>
				</div>
			</div>

			<mat-dialog-content class="border-bottom padding-20">
				<mat-form-field>
					<mat-select multiple name="docTypeChange" formControlName="emailList" id="doc-dlg-docTypeChange">
						<mat-option *ngFor="let fileType of data.emailList" [value]="fileType">
							{{ fileType }}
						</mat-option>
					</mat-select>
					<mat-hint class="error-message" *ngIf="emailForm.get('emailList').hasError('required')">
						Please select file type
					</mat-hint>
				</mat-form-field>
				<label>
					<p class="mat-dialog-title">Attached Documents</p>
					<p>
						<span *ngFor="let doc of data.data; let l = last">{{ doc.fileName }} {{ l !== true ? ', ' : '' }}</span>
					</p>
				</label>
				<mat-form-field class="input-full-width" appearance="outline">
					<mat-label>Additional Information</mat-label>
					<textarea
						spellcheck="true"
						matInput
						placeholder="Enter additional Information if needed..."
						id="doc-dlg-emailText"
						style="width: 500px"
						matTextareaAutosize
						matAutosizeMinRows="6"
						maxlength="1000"
						formControlName="emailText"
					></textarea>
					<mat-hint align="end">{{ emailForm.get('emailText').value.length }} / 1000</mat-hint>
				</mat-form-field>
			</mat-dialog-content>
			<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="margin-10 padding-10">
				<button mat-button color="primary" type="button" title="cancel resend" appDebounceClick (debounceClick)="makeSelection(false)" id="doc-dlg-actions-cancel-btn">
					Cancel
				</button>
				<button
					mat-flat-button
					type="button"
					color="primary"
					id="doc-dlg-actions-resendEmail-btn"
					title="resend email"
					[disabled]="emailForm.invalid"
					appDebounceClick
					(debounceClick)="sendFepaEmailForm(emailForm.value)"
				>
					Send
				</button>
			</mat-dialog-actions>
		</form>
	</div>
</div>

<ng-template #saveConfirmation>
	<div class="pb-2 pt-2">
		<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom">
			<h3 class="arc-h2 margin-20" matDialogTitle>Update Received On</h3>
			<div class="col-sm-2 text-right">
				<app-close-mat-dialog 	(closeDialog)="onCancel()"></app-close-mat-dialog>
			</div>
		</div>
		<mat-dialog-content>
				<div class="p-4">
					<div class="row">
						<div class="col-md-12 col-xm-12 align-items-center">
							Changing this date will change the charge’s Filed On date but not the date the Notice of Charge was issued (if it has been issued). Continue?
						</div>
					</div>
				</div>
		</mat-dialog-content>

		<mat-divider></mat-divider>
		<mat-dialog-actions class="space-between">
			<div>
				<button id="intake-interview-cancelButton" mat-button color="primary" class="text-left ml-2" 	(click)="onCancel()">
					CANCEL
				</button>
			</div>
			<div class="text-right p-3">
				<button
					id="intake-interview-save-btn"
					class="text-right ml-2"
					mat-flat-button
					color="primary"
					(click)="onCancel(true)"
				>
					CONFIRM
				</button>
			</div>
		</mat-dialog-actions>
	</div>
</ng-template>
