import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Utils } from '../shared/utility/utils';
import { CommonService } from './common.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard  {
	constructor(private cmnServ: CommonService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const token = this.cmnServ.getOauthTokenBehaviorSubject().getValue();
		if (token && Utils.notNullCondition(token.access_token)) {
			return true;
		} else {
			this.cmnServ.fromUrl = state.url;
			return false;
		}
	}
}
