/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AppealCourtFilingArgumentVO } from '../model/models';
import { AppealCourtFilingBriefVO } from '../model/models';
import { AppealCourtFilingDetail } from '../model/models';
import { AppealCourtFilingPetitionVO } from '../model/models';
import { AppealCourtFilingVO } from '../model/models';
import { LitigationAppealVO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LitigationAppealCourtFilingResourceService {

    protected basePath = 'https://dev.eeoc.gov/litigation';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a court filing Oral Argument
     * Add a court filing Oral Argument
     * @param appealId 
     * @param appealCourtFilingArgumentVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingArgumentVO>;
    public addCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingArgumentVO>>;
    public addCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingArgumentVO>>;
    public addCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling addCourtFilingArgument.');
        }
        if (appealCourtFilingArgumentVO === null || appealCourtFilingArgumentVO === undefined) {
            throw new Error('Required parameter appealCourtFilingArgumentVO was null or undefined when calling addCourtFilingArgument.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AppealCourtFilingArgumentVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-argument`,
            appealCourtFilingArgumentVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a court filing Brief
     * Add a court filing Brief
     * @param appealId 
     * @param appealCourtFilingBriefVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingBriefVO>;
    public addCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingBriefVO>>;
    public addCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingBriefVO>>;
    public addCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling addCourtFilingBrief.');
        }
        if (appealCourtFilingBriefVO === null || appealCourtFilingBriefVO === undefined) {
            throw new Error('Required parameter appealCourtFilingBriefVO was null or undefined when calling addCourtFilingBrief.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AppealCourtFilingBriefVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-brief`,
            appealCourtFilingBriefVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a court filing Petition
     * Add a court filing Petition
     * @param appealId 
     * @param appealCourtFilingPetitionVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingPetitionVO>;
    public addCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingPetitionVO>>;
    public addCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingPetitionVO>>;
    public addCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling addCourtFilingPetition.');
        }
        if (appealCourtFilingPetitionVO === null || appealCourtFilingPetitionVO === undefined) {
            throw new Error('Required parameter appealCourtFilingPetitionVO was null or undefined when calling addCourtFilingPetition.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AppealCourtFilingPetitionVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-petition`,
            appealCourtFilingPetitionVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Appeal Court Filing details along with briefs, oral arguments and Petitions
     * Delete Appeal Court Filing details along with briefs, oral arguments and Petitions
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCourtFiling(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public deleteCourtFiling(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public deleteCourtFiling(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public deleteCourtFiling(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling deleteCourtFiling.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/courtfile`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a court filing Oral Argument
     * Delete a court filing Oral Argument
     * @param appealId 
     * @param argumentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCourtFilingArgument(appealId: number, argumentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteCourtFilingArgument(appealId: number, argumentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteCourtFilingArgument(appealId: number, argumentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteCourtFilingArgument(appealId: number, argumentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling deleteCourtFilingArgument.');
        }
        if (argumentId === null || argumentId === undefined) {
            throw new Error('Required parameter argumentId was null or undefined when calling deleteCourtFilingArgument.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-argument/${encodeURIComponent(String(argumentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a court filing Brief
     * Delete a court filing Brief
     * @param appealId 
     * @param briefId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCourtFilingBrief(appealId: number, briefId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteCourtFilingBrief(appealId: number, briefId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteCourtFilingBrief(appealId: number, briefId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteCourtFilingBrief(appealId: number, briefId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling deleteCourtFilingBrief.');
        }
        if (briefId === null || briefId === undefined) {
            throw new Error('Required parameter briefId was null or undefined when calling deleteCourtFilingBrief.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-brief/${encodeURIComponent(String(briefId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a court filing Petition
     * Delete a court filing Petition
     * @param appealId 
     * @param petitionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCourtFilingPetition(appealId: number, petitionId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteCourtFilingPetition(appealId: number, petitionId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteCourtFilingPetition(appealId: number, petitionId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteCourtFilingPetition(appealId: number, petitionId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling deleteCourtFilingPetition.');
        }
        if (petitionId === null || petitionId === undefined) {
            throw new Error('Required parameter petitionId was null or undefined when calling deleteCourtFilingPetition.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-petition/${encodeURIComponent(String(petitionId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Appeal Court Filing details along with briefs, oral arguments and Petitions
     * Get a Appeal Court Filing details along with briefs, oral arguments and Petitions
     * @param appealId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCourtFiling(appealId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingVO>;
    public getCourtFiling(appealId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingVO>>;
    public getCourtFiling(appealId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingVO>>;
    public getCourtFiling(appealId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling getCourtFiling.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AppealCourtFilingVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/courtfile`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a court filing Oral Argument
     * Modify a court filing Oral Argument
     * @param appealId 
     * @param appealCourtFilingArgumentVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingArgumentVO>;
    public modifyCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingArgumentVO>>;
    public modifyCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingArgumentVO>>;
    public modifyCourtFilingArgument(appealId: number, appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling modifyCourtFilingArgument.');
        }
        if (appealCourtFilingArgumentVO === null || appealCourtFilingArgumentVO === undefined) {
            throw new Error('Required parameter appealCourtFilingArgumentVO was null or undefined when calling modifyCourtFilingArgument.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<AppealCourtFilingArgumentVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-argument`,
            appealCourtFilingArgumentVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a court filing Petition
     * Modify a court filing Petition
     * @param appealId 
     * @param appealCourtFilingPetitionVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingPetitionVO>;
    public modifyCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingPetitionVO>>;
    public modifyCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingPetitionVO>>;
    public modifyCourtFilingPetition(appealId: number, appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling modifyCourtFilingPetition.');
        }
        if (appealCourtFilingPetitionVO === null || appealCourtFilingPetitionVO === undefined) {
            throw new Error('Required parameter appealCourtFilingPetitionVO was null or undefined when calling modifyCourtFilingPetition.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<AppealCourtFilingPetitionVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-petition`,
            appealCourtFilingPetitionVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Appeal Court Filing for an appeal
     * Create a Appeal Court Filing for an appeal
     * @param appealId 
     * @param appealCourtFilingDetail 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCourtFilingDetail(appealId: number, appealCourtFilingDetail: AppealCourtFilingDetail, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<LitigationAppealVO>;
    public setCourtFilingDetail(appealId: number, appealCourtFilingDetail: AppealCourtFilingDetail, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<LitigationAppealVO>>;
    public setCourtFilingDetail(appealId: number, appealCourtFilingDetail: AppealCourtFilingDetail, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<LitigationAppealVO>>;
    public setCourtFilingDetail(appealId: number, appealCourtFilingDetail: AppealCourtFilingDetail, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling setCourtFilingDetail.');
        }
        if (appealCourtFilingDetail === null || appealCourtFilingDetail === undefined) {
            throw new Error('Required parameter appealCourtFilingDetail was null or undefined when calling setCourtFilingDetail.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LitigationAppealVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/courtfile`,
            appealCourtFilingDetail,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a court filing Brief
     * Update a court filing Brief
     * @param appealId 
     * @param appealCourtFilingBriefVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<AppealCourtFilingBriefVO>;
    public updateCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpResponse<AppealCourtFilingBriefVO>>;
    public updateCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<HttpEvent<AppealCourtFilingBriefVO>>;
    public updateCourtFilingBrief(appealId: number, appealCourtFilingBriefVO: AppealCourtFilingBriefVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json'}): Observable<any> {
        if (appealId === null || appealId === undefined) {
            throw new Error('Required parameter appealId was null or undefined when calling updateCourtFilingBrief.');
        }
        if (appealCourtFilingBriefVO === null || appealCourtFilingBriefVO === undefined) {
            throw new Error('Required parameter appealCourtFilingBriefVO was null or undefined when calling updateCourtFilingBrief.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<AppealCourtFilingBriefVO>(`${this.configuration.basePath}/v1/appeals/${encodeURIComponent(String(appealId))}/court-filing-brief`,
            appealCourtFilingBriefVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
