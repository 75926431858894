import { sharedReducer } from "./shared/shared.reducer";
import { SHARED_STATE_NAME } from "./shared/shared.selector";
import { SharedState } from "./shared/shared.state";
import { litigationSearchState, privateSearchState } from "../../app/features/modules/search/search-state/search.state";
import { litigationSearchReducer, privateSearchReducer, PRIVATE_SEARCH_STATE_NAME, LITIGATION_SEARCH_STATE_NAME } from "../features/modules/search/search-state/search.reducer";
import { appealDataReducer, appealStatusStateReducer, APPEAL_STATE_NAME, APPEAL_STATUS_NAME, defendantDataReducer, resolutionDataReducer, RESOLUTION_STATE_NAME, DEFENDANT_STATE_NAME, courtInformationStateReducer, COURT_INFORMATION, APPEAL_SHARED, appealSharedCodeStateReducer } from "../features/modules/case-details/case-details-state/case-details.reducer";
import { appealDataState, appealSharedCodeState, appealStatusState, courtInformationState, defendantDataState, resolutionDataState } from "../features/modules/case-details/case-details-state/case-details.state";
import { litigationSharedCodeState, litigationNewInquiryState, litigationDistrictOfficesState } from "../features/modules/new-case/new-case-state/new-case.state";
import { litigationSharedCodeReducer, LITIGATION_SHARED_CODE, litigationNewInquiryReducer, LITIGATION_NEW_INQUIRY, LITIGATION_DISTRICT_OFFICES, litigationDistrictOfficesReducer } from "../features/modules/new-case/new-case-state/new-case.reducer";


export interface AppState {
    [SHARED_STATE_NAME]: SharedState;
    [PRIVATE_SEARCH_STATE_NAME]: privateSearchState;
    [LITIGATION_SEARCH_STATE_NAME]: litigationSearchState;
    [APPEAL_STATE_NAME]: appealDataState;
    [RESOLUTION_STATE_NAME]: resolutionDataState;
    [DEFENDANT_STATE_NAME]: defendantDataState;
    [APPEAL_STATUS_NAME]: appealStatusState;
    [COURT_INFORMATION]: courtInformationState;
    [APPEAL_SHARED]: appealSharedCodeState;
    [LITIGATION_SHARED_CODE]: litigationSharedCodeState;
    [LITIGATION_NEW_INQUIRY]: litigationNewInquiryState;
    [LITIGATION_DISTRICT_OFFICES]: litigationDistrictOfficesState;

}

export const appReducer = {
    [SHARED_STATE_NAME]: sharedReducer,
    [PRIVATE_SEARCH_STATE_NAME]: privateSearchReducer,
    [LITIGATION_SEARCH_STATE_NAME]: litigationSearchReducer,
    [APPEAL_STATE_NAME]: appealDataReducer,
    [RESOLUTION_STATE_NAME]: resolutionDataReducer,
    [DEFENDANT_STATE_NAME]: defendantDataReducer,
    [APPEAL_STATUS_NAME]: appealStatusStateReducer,
    [COURT_INFORMATION]: courtInformationStateReducer,
    [APPEAL_SHARED]: appealSharedCodeStateReducer,
    [LITIGATION_SHARED_CODE]: litigationSharedCodeReducer,
    [LITIGATION_NEW_INQUIRY]: litigationNewInquiryReducer,
    [LITIGATION_DISTRICT_OFFICES]: litigationDistrictOfficesReducer,

};