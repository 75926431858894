import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ActivityLogVO } from 'src/app/services/EmailWebService';
import { BaseCase, ChargeDeformalizationVO, DeferralInfoVO, DocumentTypeVO, LookupData, PrepaCaseResourceService } from 'src/app/services/gen';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { SharedCodeService } from 'src/app/services/shared-code.service';
import { PersonName } from 'src/app/services/UserManagement';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../services/common.service';
import { LoadingService } from '../../../../services/loading/loading.service';
import {
	CONFIRM_RECALL_DOCUMENT,
	CONFIRM_RELEASE_DOCUMENT,
	CONFIRM_RETRACT_DOC,
	DisclosableMessage,
	FepaSupervisorRoleCode,
	LookUpStatusCode,
	NonDisclosableMessage,
	RESEND_DOCUMENT,
	ScrollStrategy,
	SensitiveTypes,
	SupervisorRoleCode,
} from '../../../../shared/common-structures/app.constants';
import { AlertService } from '../../../../shared/ims-alerts/ims-alerts.service';
import { ArrayUtils } from '../../../../shared/utility/arrayUtils';
import { StringUtils } from '../../../../shared/utility/stringUtils';
import { Utils } from '../../../../shared/utility/utils';
import { CaseDetailsService } from '../../case-details/case-details.service';
import { DocumentDialogComponent } from '../../case-details/template-library/document-dialog/document-dialog.component';
import { CaseStatus, DocTypes, Document, FileToUpload } from '../document';
import { DocumentsConfig, FepaDocumentsConfig, PrivateDocumentsConfig } from '../documents.config';
import { DocumentsService } from '../documents.service';
import { DocumentsUtil, UserType } from '../documents.util';

export const MY_FORMATS = {
	parse: {
		dateInput: 'l',
	},
	display: {
		dateInput: 'l',
		monthYearLabel: 'l',
		dateA11yLabel: 'LLLL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'private-documents',
	templateUrl: './private-documents.component.html',
	styleUrls: ['./private-documents.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class PrivateDocumentsComponent implements OnInit, OnDestroy {
	@ViewChild('retractTmpl', { static: true }) retractTmpl: TemplateRef<any>;
	@Input() chargeSystemicCaseDetailsResponse: any[] = [];
	systemicCaseOptions: any[] = [];
	@Input() hasSystemicUpload = false;
	@Input() readOnlyAccess = false;
	@Input() isSystamic: boolean = false;
	@Input() documents: any[] = [];
	@Input() userSelectedOfficeCode: string;
	@Input() caseDetail: BaseCase;
	@Input() assignedStaffArray: any[] = [];
	@Input() allegationsCount: number = 0;
	@Input() readOnlyCharge: boolean;
	@Input() isAdrRoleHolder: boolean;
	@Input() isCaseManager: boolean = false;
	@Input() showSensitiveOption: boolean = false;
	@Input() deferralOffice: DeferralInfoVO;
	@Input() disableRelease: boolean = false;
	@Input() hasDocumentLogDownloadPermission = false;
	@Input() statusesResponse: LookupData[] = [];
	@Input() deformalizationReasons: any[] = [];
	@Output() onChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() onFiledOnDateChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() goToFinalizationTab: EventEmitter<string> = new EventEmitter<string>();
	stdTemplates: string[] = ['CODCL', 'CODUCL', 'TRANSCASEDOJ', 'TRANSCASEDOJFORNRTS'];
	readOnly: boolean;
	isCodDraftExist: boolean = false;
	adHocFields: any = {};
	adHocFieldsList: any[] = [];
	adHocFieldsIndexByField: any = {};
	maxFileSize = 1610612736; // bytes (1.5GB)
	invalidFileTypes = ['exe', 'msi', 'gwi', 'lnk'];
	isRequestForInfoValid = true;
	selectedSystemicCaseId: any;
	selectedSystemicCase: any;
	tabIndex: number = 0;
	parent: any = this;
	docs: Document[];
	autoGeneratedIncludedDocTypes: DocumentTypeVO[] = [];
	autoGeneratedExcludedDocTypes: DocumentTypeVO[] = [];
	categoryTypes: any;
	counts: any = { all: 0, a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0, h: 0, i: 0, m: 0, j: 0, k: 0, l: 0, n: 0, p: 0, o: 0 };
	caseStatus: CaseStatus = { isShareDocsToFEPA: false };
	chargeOfDiscriminationDocument: Document;
	CODUnperfectedExist: Document;
	requestForInformationDocs: Document[];
	responseToReqForInfoDocs: Document[];
	positionStatement: Document;
	posRedacted: Document;
	noticeOfChargeDocument: Document;
	relatedDateToBeEdited: Date;
	documentBeforeEdit: Document = {};
	documentAfterEdit: Document;
	documentToResend: Document;
	documentDialogRef: MatDialogRef<DocumentDialogComponent>;
	isPrivate: boolean;
	documentsConfig: any;
	emailNotes: ActivityLogVO[];
	// subscriptions
	observer$ = new BehaviorSubject(true);
	hasClosureReason: boolean = false;
	isTemplateAdmin: boolean = false;
	isDisclosable: boolean = false;
	dialogRef: MatDialogRef<any>;
	allDocuments: Document[];
	docSubscription: Subscription;
	documentTypes: any[] = [];
	allDocumentTypes: any[] = [];
	replaceDoc: Document;

	replaceForm: any = {
		reason: '',
		name: '',
		filesToUpload: [],
	};
	replaceDialogRef: MatDialogRef<any>;
	replaceReasons: any[] = [];
	rspLoginInfo: any[] = [];
	hasParticulars: boolean = false;
	public getDescriptionByValue = Utils.getDescriptionByValue;

	/**
	 * @description injects necessary dependencies and retrives caseNumber from commonparams
	 * @param: cmnServ: to get common params
	 * @param: alrtServ
	 * @param: docServ
	 * @param: loader
	 * @param: dialog
	 */
	constructor(
		public dialog: MatDialog,
		private router: Router,
		public cmnServ: CommonService,
		private alrtServ: AlertService,
		private docServ: DocumentsService,
		private imsLoader: LoadingService,
		private csDtlsServ: CaseDetailsService,
		private sharedCodeServ: SharedCodeService,
		private myMonitoringService: MyMonitoringService,
		private caseServ: PrepaCaseResourceService,
	) { }
	isNumaric() {
		let officeCode = this.caseDetail.accountabilityOffice.code || '';
		return officeCode && officeCode.replace(/[^0-9]/g, '').length === officeCode.length;
	}
	ngOnChanges(changes) {
		if (changes.documents) {
			this.updateChargeStatus(changes.documents);
			this.onDocumentsChange();
		}
		if (changes.chargeSystemicCaseDetailsResponse) {
			this.systemicCaseOptions = this.chargeSystemicCaseDetailsResponse.filter(
				(x) => x?.systemicCase?.status?.code == 'IN'
			);
			this.selectedSystemicCaseId = this.systemicCaseOptions[0]?.systemicCase?.systemicCaseId;
			this.selectedSystemicCase = this.systemicCaseOptions[0]?.systemicCase;
		}
	}
	ngOnInit() {
		this.imsLoader.hide();
		this.docServ?.documentsChange$?.next({});
		this.isPrivate = this.caseDetail?.isFepa === false;

		let officeCode = this.caseDetail?.accountabilityOffice?.code || '';
		let isPrivateDocs = officeCode ? this.isNumaric() : this.isPrivate;
		this.documentsConfig = isPrivateDocs ? PrivateDocumentsConfig : FepaDocumentsConfig;
		this.getDocsAndTypes();
		this.getSharedCode();
		this.getRspLoggedInDetails();
		this.getChargeParticular();

		// Log page view to app insights
		this.myMonitoringService.logPageView('Documents Tab', window.location.href);
	}

	getRspLoggedInDetails() {
		this.csDtlsServ.getEventLogsByCaseIdAndEventCode(this.caseDetail.chargeInquiryId, 'RSP_LOG_IN').subscribe({
			next: (res) => this.rspLoginInfo = res,
			error: (error) => this.alrtServ.error('Error occurred while getting activity for respondent.', error),
		})
	}
	onSystemicCaseChange(systemicCaseId) {
		this.selectedSystemicCaseId = systemicCaseId;
		this.selectedSystemicCase = this.chargeSystemicCaseDetailsResponse
			.find(caseDetail => caseDetail.systemicCaseId === systemicCaseId)?.systemicCase;
	}


	updateChargeStatus(docs) {
		const { previousValue, currentValue } = docs || {};

		// Check if every document in the previousValue is NOT one of the specified DocTypes
		if (previousValue?.every(doc => ![DocTypes.COD, DocTypes.COD_FORM5A, DocTypes.PCOD].includes(doc.documentType))) {
			// Check if ANY document in the currentValue is one of the specified DocTypes
			if (currentValue?.some(doc => [DocTypes.COD, DocTypes.COD_FORM5A, DocTypes.PCOD].includes(doc.documentType))) {
				this.onChange.emit(true);
			}
		}
	}


	getDocsAndTypes() {
		this.imsLoader.show();
		this.docServ.getDocumentAllTypes().subscribe(
			(res) => {
				this.allDocumentTypes = res;
				this.documentTypes = res.filter((x) => !x.isObsolete);
				this.onDocumentsChange();
				this.imsLoader.hide();
			},
			(error) => {
				this.imsLoader.hide();
				this.alrtServ.error('Error happened while getting documents. Please contact help desk.');
			}
		);
	}

	onDocumentsChange() {
		if (this.documentTypes.length) this.documentTypes = _.sortBy(this.documentTypes, ['documentDescription']);
		this.autoGeneratedIncludedDocTypes = [...this.documentTypes];
		const autoGeneratedExcludedDocTypes = [...this.documentTypes].filter((element) => !element.isAutoGenerated);
		const docs = this.applyTypes(this.documents || []);
		this.processDocumentsAndCategoryTypes([...autoGeneratedExcludedDocTypes]);
		this.processDocuments(docs);
		if (!docs) this.docServ.setDocumentsObservable({ key: this.caseDetail?.chargeNumber, value: [] });
		else this.docServ.setDocumentsObservable({ key: this.caseDetail?.chargeNumber, value: docs });
	}

	/**
	 * @description calls a web service to get list of document types. Manipulates the HTTP response to get
	 * document and unique category values.
	 */
	processDocumentsAndCategoryTypes(autoGeneratedExcludedDocTypes) {
		const tabArray = [];
		this.autoGeneratedExcludedDocTypes = autoGeneratedExcludedDocTypes.filter(
			(x) =>
				(!x.isMediation || (x.isMediation && this.isAdrRoleHolder)) &&
				((x.isUploadableByEeoc && this.isPrivate) || (x.isUploadableByFepa && !this.isPrivate)) &&
				(this.showSensitiveOption || (x.documentCode != 'THRDPYSENSITIVEDATA' && x.documentCode != 'RESPSENSTVDATASET'))
		);
		this.autoGeneratedExcludedDocTypes = this.autoGeneratedExcludedDocTypes.filter((element: DocumentTypeVO) => {
			if (!tabArray.includes(element.documentTab?.description)) tabArray.push(element.documentTab?.description);
			if (
				Utils.notNullCondition(element.documentTab) &&
				(this.isAdrRoleHolder || !element.documentTab?.description?.includes('ADR'))
			)
				return this.readOnly ? SensitiveTypes.includes(element.documentCode) : element;
		});
		this.categoryTypes = tabArray.map((item) => {
			return { value: item || '', tab: (item || '').split(':')[0] };
		});
	}

	toggleDocumentDisclosable(doc: any) {
		const actionType = doc.isDisclosable ? 'Non-disclosable' : 'Disclosable';
		const ToggleDisclosableConfirmation = {
			header: `Make ${actionType}`,
			question: `Are you sure you would like to make this document <b> ${actionType} </b> ?`,
			actionType: actionType,
			information: actionType === 'Non-disclosable' ? NonDisclosableMessage : DisclosableMessage,
			button: `Make ${actionType}`,
			textarea: false,
		};
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: ToggleDisclosableConfirmation,
			width: '40%',
			disableClose: true,
			scrollStrategy: ScrollStrategy,
			id: 'toggle-disclosable-confirmation-dialog',
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.imsLoader.show();
					this.docServ
						.updateDocumentParams(
							doc,
							this.documentsConfig.domain,
							this.cmnServ.userToken,
							this.userSelectedOfficeCode,
							undefined,
							undefined,
							!doc.isDisclosable,
							this.caseDetail?.chargeNumber
						)
						.subscribe(
							(res) => {
								this.imsLoader.hide();
								if (!_.isEmpty(document)) {
									this.alrtServ.success(`${doc.fileName} has been made ${actionType}`);
								}
								this.getDocuments();
							},
							(error) => {
								this.imsLoader.hide();
								if (error.status === 422) this.alrtServ.error(error?.error?.message);
								else {
									this.alrtServ.error(
										'Encountered an error publishing the document. Please check with system administrator'
									);
									this.getDocuments();
								}
							}
						);
				}
			});
	}

	confirmPublishDocument(doc: any, enableSendToFepa: boolean) {
		const type = { ...CONFIRM_RELEASE_DOCUMENT };
		const docTypes = [DocTypes.POS, DocTypes.POS_REDACT, DocTypes.SUPPL_POS, DocTypes.SUPPL_POS_REDACT];
		if (docTypes.includes(doc.documentType)) {
			type.question += '<br/><br/> Charging Party (CP)';
		} else {
			if (doc.availableTo?.includes('CP')) type.question += '<br/><br/> Charging Party (CP)';
			if (doc.availableTo?.includes('RSP')) type.question += '<br/><br/> Respondent (RSP)';
			if (enableSendToFepa && !doc.relationshipId && doc.availableTo?.includes('FEPA'))
				type.question += '<br/><br/> Fepa';
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: type,
			disableClose: true,
			scrollStrategy: ScrollStrategy,
			id: 'confirm-publish-document',
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.publishDocument(doc);
				}
			});
	}

	confirmRecallDocument(doc: Document) {
		const _data = { ...CONFIRM_RECALL_DOCUMENT };
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: _data,
			disableClose: true,
			width: '50%',
			scrollStrategy: ScrollStrategy,
			id: 'recall-confirmation-dialog',
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.recallDocument(doc, result);
				}
			});
	}

	recallDocument(doc: any, reason: string) {
		this.imsLoader.show();
		doc.isPublished = false;
		this.docServ
			.updateDocumentParams(
				doc,
				this.documentsConfig.domain,
				this.cmnServ.userToken,
				this.userSelectedOfficeCode,
				'N',
				reason,
				undefined,
				this.caseDetail?.chargeNumber
			)
			.subscribe(
				(document) => {
					this.imsLoader.hide();
					if (!_.isEmpty(document)) {
						this.alrtServ.success(document.fileName + ' has been recalled.');
					}
					this.getDocuments();
				},
				(error) => {
					this.imsLoader.hide();
					if (error.status === 422) this.alrtServ.error(error?.error);
					else
						this.alrtServ.error('Encountered an error recalling the document. Please check with system administrator');
				}
			);
	}

	public applyTypes(docs) {
		let typesCode = {};
		this.allDocumentTypes.forEach((type) => {
			typesCode[type.documentCode] = type;
		});
		docs.forEach((doc) => {
			let type = typesCode[doc.documentType] || {};
			doc.type = type;
			// ignore the time zone while displaying Received Date
			if (doc.relatedDate !== null) {
				doc.relatedDate = moment.utc(doc.relatedDate).format('MM/DD/YYYY');
			}
			if (doc.createdDate !== null) {
				doc.createdDate = moment.utc(doc.createdDate).format('MM/DD/YYYY');
			}
			if (doc.lastModifiedDate !== null) {
				doc.lastModifiedDate = moment.utc(doc.lastModifiedDate).format('MM/DD/YYYY');
			}
			doc.isSendEnable =
				['PRVNOC', 'CPCLOSURELTR', 'RESPCLOSURELTR'].includes(doc.documentType) &&
				(doc.documentType === DocTypes.NOC || moment().isAfter(moment(doc.createdDate).add(7, 'd')));
			doc.isMediation = type.isMediation;
			doc.isRetainedMediation = type.isRetainedMediation;
		});
		return docs.filter((x) => !this.isNumaric() || this.isPrivate || x.type.isDownloadableByFepa);
	}

	public processDocuments(docs) {
		this.docs = docs;
		if (this.docs?.length) {
			this.assignParams(this.docs);
			this.getDocumentCounts(this.docs);

			// check if there are any docs of type COD. Assuming only one COD exists
			this.chargeOfDiscriminationDocument = this.docs.find((value) => value.documentType === DocTypes.COD);
			this.CODUnperfectedExist = this.docs.find((value) => value.documentType === DocTypes.PCOD);
			// check if there are any docs of type NOC. Assuming only one NOC exists
			this.noticeOfChargeDocument = this.docs.find((value) => value.documentType === 'PRVNOCUNPRFCTDTOPRFCTD');
			this.requestForInformationDocs = this.docs.filter(
				(value) => value.documentType === 'RFICP' || value.documentType === 'RFIRES'
			);
			this.responseToReqForInfoDocs = this.docs.filter(
				(value) => value.documentType === 'RESRSPRFI' || value.documentType === 'CPRFI'
			);
			// assuming only one "Position Statemnt" exists
			this.positionStatement = this.docs.find((value) => value.documentType === DocTypes.POS);
			// assuming only one "Position Statemnt Redacted" exists
			this.posRedacted = this.docs.find((value) => value.documentType === DocTypes.POS_REDACT);
		}
	}

	getDocuments(docid = null) {
		this.docServ.documentsChange$.next({ mode: true, docid });
	}

	publishDocument(doc: any) {
		doc.isPublished = true;
		this.imsLoader.show();
		this.docServ
			.updateDocumentParams(
				doc,
				this.documentsConfig.domain,
				this.cmnServ.userToken,
				this.userSelectedOfficeCode,
				'Y',
				undefined,
				undefined,
				this.caseDetail?.chargeNumber
			)
			.subscribe(
				(res) => {
					this.imsLoader.hide();
					if (res && !_.isEmpty(document)) {
						this.alrtServ.success(res.fileName + ' has been published.');
					}
					this.getDocuments();
				},
				(error) => {
					this.alrtServ.error(
						error?.error?.message ||
						'Encountered an error publishing the document. Please check with system administrator'
					);
					this.imsLoader.hide();
					this.getDocuments();
				}
			);
	}


	/**
	 * showing the edit dialog
	 * @param: doc
	 */
	showRowEditDialog(doc: any) {
		this.documentBeforeEdit = doc;
		this.documentAfterEdit = _.cloneDeep(doc);
		const data = {
			from: 'edit',
			categoryTypes: this.categoryTypes,
			data: doc,
			documentTypes: this.autoGeneratedExcludedDocTypes,
			caseDetails: this.caseDetail,
			documents: this.documents
		};
		this.documentDialogRef = this.dialog.open(DocumentDialogComponent, {
			data,
			disableClose: true,
			scrollStrategy: ScrollStrategy,
			id: 'edit-document-dialog',
		});
		this.documentDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((res) => {
				if (res) {
					this.documentAfterEdit.title = res.title;
					this.documentAfterEdit.fileName = StringUtils.renamefile(this.documentAfterEdit.fileName, res.title);
					this.documentAfterEdit.documentType = res.documentType;
					this.documentAfterEdit.tabType = res.tabType === 'null' ? null : res.tabType;
					this.documentAfterEdit.relatedDate = res.relatedDate
						? moment(new Date(res.relatedDate)).format('MM-DD-YYYY')
						: undefined;
					this.documentAfterEdit.responseDueDate = res.responseDueDate
						? moment(new Date(res.responseDueDate)).format('MM-DD-YYYY')
						: undefined;
					this.relatedDateToBeEdited = res.relatedDate;
					this.saveEditedDocument();
				}
			});
	}

	/**
	 * saving the edited document if dialog returns yes
	 */
	saveEditedDocument() {
		if (DocumentsUtil.validateEdit(this.documentAfterEdit, this.docs, UserType.Private, this.alrtServ)) {
			// change the isPublished to false if the document type is either POS or POS redacted
			if (
				this.documentAfterEdit.documentType === DocTypes.POS ||
				this.documentAfterEdit.documentType === DocTypes.POS_REDACT ||
				this.documentAfterEdit.documentDescription === DocTypes.RFI ||
				this.documentAfterEdit.documentDescription === DocTypes.POS_ATTMNT_NON_CONF ||
				this.documentAfterEdit.documentType === DocTypes.POS_ATTMNT_NON_CONF_REDACT
			) {
				this.documentAfterEdit.isPublished = false;
			}
			// flag to send to ecm to add entry in activity log
			this.imsLoader.show();
			this.docServ
				.editDocument(
					this.documentAfterEdit,
					this.documentsConfig.domain,
					this.cmnServ.userToken,
					this.userSelectedOfficeCode,
					this.caseDetail?.chargeNumber
				)
				.subscribe(
					(res) => {
						if (!_.isEmpty(res)) {
							// using lodash _.isEmpty method to check if the object is empty
							this.alrtServ.success('Document has been edited.');
							this.getDocuments();
							if (res.documentDescription === DocTypes.RFI) {
								this.updateRfiResponseDueDate(res);
							}
							if (res?.documentType === DocTypes.COD) {
								this.updateFiledOnDate();
							}
						}
						this.imsLoader.hide();
					},
					(error) => {
						this.imsLoader.hide();
						if (error.status === 422) this.alrtServ.error(error?.error);
						else
							this.alrtServ.error(
								'Encountered an error updating the document properties. Please check with system administrator.'
							);
					}
				);
		}
	}

	updateFiledOnDate() {
		const formalizationDate = moment(this.documentAfterEdit?.relatedDate, 'MM/DD/YYYY').toISOString();
		this.caseServ.updateCaseProperties(this.caseDetail?.chargeInquiryId, {
			formalizationDate: formalizationDate,
		}).subscribe(
			(res) => {
				this.csDtlsServ.caseChanged.emit(res);
				this.onFiledOnDateChange.emit(true);

			},
			(error) => {
				if (error.status === 422) {
					this.alrtServ.error(error?.error, error);
				} else {
					this.alrtServ.error(
						'An error occurred while processing your request, please contact the system administrator.', error
					);
				}
			}
		);
	}

	/**
	 * showing ResendDialog
	 */
	showResendDialog(doc: Document) {
		if (doc.documentType == DocTypes.NOC) {
			this._showResendDialogWithReason(doc);
		} else {
			this._showResendDialogWithConfirmation(doc);
		}
	}
	_showResendDialogWithConfirmation(doc: Document) {
		this.documentToResend = _.clone(doc);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: RESEND_DOCUMENT,
			width: '40%',
			disableClose: true,
			scrollStrategy: ScrollStrategy,
			id: 'resend-document-confirmation-dialog',
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((res) => {
				if (res) this.reSendEmail(doc);
			});
	}
	_showResendDialogWithReason(doc: Document) {
		this.documentToResend = _.clone(doc);
		const data = { from: 'resend' };
		this.documentDialogRef = this.dialog.open(DocumentDialogComponent, {
			data,
			disableClose: true,
			scrollStrategy: ScrollStrategy,
			id: 'resend-document-reason-dialog',
		});
		this.documentDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((resendEmailReason) => {
				if (resendEmailReason) {
					this.reSendNoc(resendEmailReason);
				}
			});
	}

	/**
	 * re-sending the email if dialog returns yes
	 */
	reSendNoc(resendEmailReason: string) {
		this.imsLoader.show();
		this.docServ.reSendNoticeOfCharge(this.caseDetail.chargeInquiryId, resendEmailReason).subscribe(
			(response) => {
				this.imsLoader.hide();
				if (response) {
					this.onChange.emit(true);
					this.alrtServ.success('Notice of charge has been emailed successfully.');
				} else this.alrtServ.error('An error occurred while sending notice of charge.');
			},
			(error) => {
				this.imsLoader.hide();
				if (error.status === 422) this.alrtServ.error(error?.error);
				else this.alrtServ.error('An error occurred while sending notice of charge.');
			}
		);
	}

	reSendEmail(doc: Document) {
		this.imsLoader.show();
		this.csDtlsServ.reSendClosureEmail(this.caseDetail.chargeInquiryId, doc.documentType).subscribe(
			(response) => {
				this.imsLoader.hide();
				if (response)
					this.alrtServ.success(
						`Email has been Successfully sent to ${doc.documentType == 'CPCLOSURELTR' ? 'Charging Party' : 'Respondent'
						}`
					);
				else
					this.alrtServ.error(
						`An error occurred while sending email to ${doc.documentType == 'CPCLOSURELTR' ? 'Charging Party' : 'Respondent'
						}`
					);
			},
			(error) => {
				this.imsLoader.hide();
				if (error.status === 422) this.alrtServ.error(error?.error);
				else
					this.alrtServ.error(
						`An error occurred while sending email to ${doc.documentType == 'CPCLOSURELTR' ? 'Charging Party' : 'Respondent'
						}`
					);
			}
		);
	}


	updateRfiResponseDueDate(doc) {
		const payload = {
			documentId: doc.documentNodeId,
			respondent: this.caseDetail?.respondent,
			responseDueOn: new Date(doc.responseDueDate),
			documentType: {
				documentCode: doc.documentType,
			},
		};
		this.docServ.updateRfiResponseDueDate(this.caseDetail?.chargeInquiryId, payload).subscribe(
			(_) => { },
			(error) =>
				this.alrtServ.error('Error happened while updating response due date. Please check with system administrator.')
		);
	}

	/**
	 * @description call string utils' concatName to return a full name.
	 * @param person any
	 */
	concatName(person: PersonName): string {
		return StringUtils.concatName(person.lastName, person.middleInitial, person.firstName);
	}

	public hasSupervisor = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(SupervisorRoleCode) ||
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(FepaSupervisorRoleCode);

	/**
	 * @description check is file updatable
	 */
	fileNotUpdatable(file): boolean {
		const docType = this.autoGeneratedIncludedDocTypes.find((item) => item.documentCode === file.documentType);
		const isCodType = ['PRVCOD', 'PRVAMNDCOD', 'PRVCODNONPERF'].includes(file.documentType);
		this.isCodDraftExist = this.documents.some(doc => doc.documentType === DocTypes.COD_DRAFT || doc.documentType === DocTypes.COD) && file?.documentType === DocTypes.INTERVIEW_NOTES;
		const result = !isCodType ? !(
			docType &&
			docType.isUpdateable &&
			!file.relationShipId &&
			!file.isPublished &&
			(!['POSSTREDAC', 'PRVPOS', 'STMTPSANC'].includes(file.documentType) || !this.checkIsReleased())
		) : this.hasSupervisor() ? false : true;
		if (file?.documentType === DocTypes.INTERVIEW_NOTES) {
			return this.isCodDraftExist;
		} else {
			return result;
		}
	}

	checkIsReleased() {
		return this.documents.some((x) => ['POSSTREDAC', 'PRVPOS'].includes(x.documentType) && x.isPublished);
	}

	/**
	 *
	 * @param document
	 * checks for document is autopublished which cannot be released or recalled
	 * @returns boolean
	 */
	public isDocumentAutoPublished(document): boolean {
		return (
			document.documentType === 'PRVCODDRAFT' ||
			document?.type?.isAutoPublished
		);
	}

	/**
	 *
	 * @param document
	 * checks for document is published by user
	 * @returns boolean
	 */
	public isDocumentPublished(document): boolean {
		return document?.isPublished;
	}

	public hasRecall(document): boolean {
		return (
			this.isPrivate && document.isPublished && document.createdByFullName.toLowerCase() !== 'publicportal'
		);
	}

	private assignParams(docs: Document[]) {
		const docTypes = [DocTypes.POS, DocTypes.POS_REDACT, DocTypes.SUPPL_POS, DocTypes.SUPPL_POS_REDACT];
		if (docs && docs.length > 0) {
			docs.forEach((item) => {
				if (item.documentType) {
					if (Utils.isEmptyCondition(item.tabType)) {
						item.tabType = item.type?.documentTab?.description || '';
					}
					let posType = docTypes.includes(item.documentType);
					if (Utils.isEmptyCondition(item.availableTo)) {
						const availableTo = [];
						if (this.isPrivate && !this.caseDetail.isFepa && item.type?.isDownloadableByChargingParty)
							availableTo.push('CP');
						if (this.isPrivate && !this.caseDetail.isFepa && item.type?.isDownloadableByRespondent)
							availableTo.push('RSP');
						if (item.relationShipId)
							this.isPrivate && !this.caseDetail.isFepa ? availableTo.push('FEPA') : availableTo.push('EEOC');

						if (!availableTo.includes('CP') && docTypes.includes(item.documentType)) {
							availableTo.push('CP');
						}
						item.availableToTitle = '';
						if (availableTo?.includes('RSP')) item.availableToTitle = 'Respondent (RSP)';
						if (availableTo?.includes('CP')) item.availableToTitle = 'Charging Party (CP)';
						item.availableTo = availableTo.join(', ');
					}
				}
			});
		}
	}

	private getDocumentCounts(allDocs: Document[]) {
		let docs = allDocs.filter(
			(x) =>
				!x.isMediation &&
				!x.isRetainedMediation &&
				(this.showSensitiveOption ||
					(x.documentType !== 'Sensitive Data Set' &&
						x.documentType != 'THRDPYSENSITIVEDATA' &&
						x.documentType != 'RESPSENSTVDATASET'))
		);
		const isChargeClosed = this.caseDetail?.status?.code?.toUpperCase()?.includes('CLOSED');
		for (const key in this.counts) {
			const tabName = `TAB-${key}`.toUpperCase();
			this.counts[key] = (key == 'm' ? allDocs : docs).filter((item) => item.tabType.includes(tabName))?.length || 0;
		}

		['k', 'j', 'l', 'h', 'i', 'n', 'm'].forEach(key => {
			const tabName = `TAB-${key}`.toUpperCase();
			this.counts[key] = allDocs.filter((item) => item.tabType.includes(tabName) && ((isChargeClosed && item.isMediation && item.isRetainedMediation) ||
				(!isChargeClosed && (item.isMediation || item.isRetainedMediation))))?.length || 0;
		})


		this.counts.p = allDocs.filter(
			(x) =>
				(isChargeClosed && x.isMediation && x.isRetainedMediation) ||
				(!isChargeClosed && (x.isMediation || x.isRetainedMediation))
		).length;

		this.counts.a = allDocs.filter((x) =>(x.tabType === 'TAB-A: Field Office Work Product')).length;

		this.counts.o = allDocs.filter((x) =>(x.documentType === 'COMPMONT' && x.tabType === 'TAB-O: Compliance Monitoring')).length;

		this.counts.all = docs.filter((value) => value.documentType !== 'RETRACTCHRGDOC')?.length;
	}

	/**
	 * @description unsubscribe
	 */
	ngOnDestroy() {
		this.docServ.setDocumentsObservable({ key: this.caseDetail?.chargeNumber, value: [] });
		this.observer$.next(false);
	}

	displayFn = (item) => {
		if (typeof item === 'string' || item instanceof String) {
			return item;
		}
		if (item) {
			return item.templateName;
		}
	};

	onContactChange(name) {
		let staff = this.assignedStaffArray.find((x) => x.name == name);
		if (staff) {
			this.adHocFields['eeocContactName'] = staff.name;
			this.adHocFields['eeocContactPhone'] = staff.phone;
		}
	}

	label(field) {
		return field[0].toUpperCase() + field.substr(1).replace(/([A-Z])/g, ' $1');
	}

	handleTabEvent(data: string) {
		this.goToFinalizationTab.emit(data);
	}

	openReplaceDialog(row): void {
		this.replaceDoc = row;
		const isDocTypePRVCODorAMND = ['PRVCOD', 'PRVAMNDCOD'].includes(row.documentType);
		const isStatusInquiryOrPrepared = ['INQUIRY_SUBMITTED', 'CHARGE_PREPARED'].includes(this.caseDetail?.status?.code);
		const hasCod = this.documents.some(value => value.documentType === DocTypes.COD);
		const hasChargeFiledStatus = this.caseDetail.status?.code === LookUpStatusCode.CHARGE_FILED;

		if (isDocTypePRVCODorAMND && !isStatusInquiryOrPrepared) {
			this.retractDoc(row);
			return;
		}

		this.showConfirmDialog(hasCod, hasChargeFiledStatus, row);
	}

	private showConfirmDialog(hasCod: boolean, hasChargeFiledStatus: boolean, row): void {
		this.dialog.open(ConfirmDialogComponent, {
			data: CONFIRM_RETRACT_DOC,
			width: '450px',
			disableClose: true,
			scrollStrategy: ScrollStrategy,
		}).afterClosed().subscribe(res => {
			if (!res) return;

			const isDocTypeForm5AorPCOD = [DocTypes.COD_FORM5A, DocTypes.PCOD].includes(row.documentType);
			const shouldDeformalize = isDocTypeForm5AorPCOD && !hasCod && !this.caseDetail?.nocServiceDate && hasChargeFiledStatus;

			if (shouldDeformalize) {
				this.saveDeformalizeCharge(res);
			} else {
				this.onReplaceFile(false, res);
			}
		});
	}


	saveDeformalizeCharge(res: string): void {
		const statusCode = this.hasParticulars ? LookUpStatusCode.CHARGE_PREPARED : LookUpStatusCode.INQUIRY_SUBMITTED;
		const status = this.statusesResponse.find(x => x.code === statusCode);
		const reason = this.deformalizationReasons.find(x => x.code === 'O');

		const postData: ChargeDeformalizationVO = {
			status,
			reason,
			otherReason: res,
		};

		this.imsLoader.show();
		this.csDtlsServ.deFormalizeCharge(this.caseDetail.chargeInquiryId, postData).subscribe({
			next: (response) => {
				this.imsLoader.hide();
				this.alrtServ.success('Charge has been deformalized successfully');
				this.onChange.emit(true);
			},
			error: (error) => this.handleError(error)
		});
	}

	private handleError(error: any): void {
		this.imsLoader.hide();
		const message = error.status === 422 ? error?.error : 'Error occurred while deformalizing the charge';
		this.alrtServ.error(message, error);
	}


	getChargeParticular() {
		this.csDtlsServ.getChargeParticular(this.caseDetail.chargeInquiryId)
			.pipe(
				tap(res => this.hasParticulars = Boolean(res)),
				catchError(error => {
					// Directly alerting from catchError and then returning a harmless observable to conclude the stream
					this.alrtServ.error('There was an error while getting charge particulars.');
					return of(null);
				})
			)
			.subscribe()
	}

	retractDoc(row) {
		this.replaceDialogRef = this.dialog.open(this.retractTmpl, {
			data: '',
			width: '450px',
			disableClose: true,
			scrollStrategy: ScrollStrategy,
		});
		this.replaceDialogRef.afterClosed().subscribe((res) => {
			this.replaceForm = {
				reason: '',
				name: '',
				filesToUpload: [],
			};
		});
	}
	onReplaceFile(replace = true, reason?) {
		const payLoad: Document = _.cloneDeep(this.replaceDoc);
		payLoad.documentType = 'RETRACTCHRGDOC';
		payLoad.notes = this.replaceForm.reason ? this.replaceForm.reason : reason;
		//  payLoad.fileName = this.replaceForm.name;
		payLoad.relatedDate = payLoad.relatedDate
			? moment(payLoad.relatedDate, 'MM/DD/YYYY').format('MM-DD-YYYY')
			: undefined;
		delete payLoad.responseDueDate;
		this.imsLoader.show();
		this.docServ
			.editDocument(
				payLoad,
				this.documentsConfig.domain,
				this.cmnServ.userToken,
				this.userSelectedOfficeCode,
				this.caseDetail?.chargeNumber
			)
			.subscribe(
				(res) => {
					if (!_.isEmpty(res) && replace) {
						this.uploadReplacedFile();
					} else {
						this.getDocuments();
						this.imsLoader.hide();
					}
				},
				(error) => {
					this.imsLoader.hide();
				}
			);
	}
	uploadReplacedFile() {
		const payLoad: Document = _.cloneDeep(this.replaceDoc);

		const fileToUpload = this.replaceForm.filesToUpload[0];
		const userFullName = this.cmnServ.userToken.userFullName;
		const additionalInfo = 'CASE';
		const encryption = undefined;
		this.docServ
			.uploadDocument(
				DocumentsConfig.appname,
				this.documentsConfig.domain,
				this.cmnServ.userToken.userEmail,
				this.cmnServ.userToken.userFirstName,
				this.cmnServ.userToken.userLastName,
				this.userSelectedOfficeCode,
				userFullName,
				fileToUpload.file,
				payLoad.documentType,
				payLoad.tabType,
				fileToUpload.name,
				this.caseDetail?.chargeNumber,
				this.cmnServ.userToken.user_name,
				moment(new Date(fileToUpload.relatedDate)).format('MM-DD-YYYY'),
				encryption,
				additionalInfo,
				this.isPrivate ? 'N' : 'Y',
				!fileToUpload.isDisclosable,
				!this.isPrivate
					? 'N'
					: this.autoGeneratedIncludedDocTypes?.find((item) => item.documentCode === fileToUpload.doctype)
						?.isAutoPublished
						? 'Y'
						: 'N'
			)
			.subscribe(
				(res: any) => {
					this.imsLoader.hide();
					this.alrtServ.success(res.title + ' has been uploaded.');
					this.replaceDialogRef.close();
					this.getDocuments();
				},
				(error) => {
					this.imsLoader.hide();
				}
			);
	}
	getSharedCode() {
		this.sharedCodeServ.getSharedCodes('RETRACTED_REASONS').subscribe((res) => {
			this.replaceReasons = res;
		});
	}

	onCancel() {
		this.replaceDialogRef.close();
	}

	/**
	 * @description reads the contents of the file uploaded and makes an array of files uploaded
	 * @param: e emitted while uploading a file
	 */
	uploadFile2(e, drag: boolean = false) {
		const files = drag ? e : e.target.files;
		for (let file of files) {
			if (Utils.notNullCondition(file)) {
				const fileExtension = StringUtils.getFileExtension(file.name);
				this.isRequestForInfoValid = true;
				if (
					ArrayUtils.search(fileExtension, this.invalidFileTypes) === -1 &&
					file.size < this.maxFileSize &&
					file.name.lastIndexOf('.') >= 0
				) {
					const fr = new FileReader();
					const curTime = new Date();
					const curDate = new Date(curTime.getFullYear(), curTime.getMonth(), curTime.getDate());
					fr.readAsText(file);
					fr.addEventListener('load', () => {
						this.replaceForm.name = file.name;
						const file_: FileToUpload = {
							file: file,
							name: file.name,
							filename: file.name,
							size: file.size / 1000 + ' KB',
							uploadedDate: file.timeStamp,
							content: fr.result,
							doctype: '',
							description: '',
							tabtype: '',
							docExt: fileExtension,
							relatedDate: curDate,
							typeInvalid: false,
							filteredDocumentTypes: _.cloneDeep(this.autoGeneratedExcludedDocTypes),
						};
						this.replaceForm.filesToUpload.push(file_);
					});
				} else {
					this.alrtServ.error('Failed to open file. Unsupported file type or exceed maximum size limit.');
				}
			}
		}
		if (!drag) {
			e.target.value = null;
		}
	}

	tabChange(event) {
		this.tabIndex = event.index;
	}
}
