import { createAction, props } from "@ngrx/store"
export const GET_LITIGATION_SHARED_CODE = '[litigation shared] data';
export const LITIGATION_SHARED_CODE_SUCCESS = '[litigation shared] success';
export const LITIGATION_SHARED_CODE_FAIL = '[litigation shared] fail';
export const GET_LITIGATION_NEW_INQUIRY_DATA = '[litigation new inquiry] data';
export const LITIGATION_NEW_INQUIRY_DATA_SUCCESS = '[litigation new inquiry] success';
export const LITIGATION_NEW_INQUIRY_DATA_FAIL = '[litigation new inquiry] fail';
export const GET_LITIGATION_DISTRICT_OFFICES_DATA = '[litigation disctrict offices] data';
export const LITIGATION_DISTRICT_OFFICES_SUCCESS = '[litigation district offices] success';
export const LITIGATION_DISTRICT_OFFICES_FAIL = '[litigation district offices] fail';


export const litigationSharedCode = createAction(
    GET_LITIGATION_SHARED_CODE,
);
export const litigationSharedCodeSuccess = createAction(LITIGATION_SHARED_CODE_SUCCESS, props<{ litigationSharedCode: any }>());
export const litigationSharedCodeFail = createAction(
    LITIGATION_SHARED_CODE_FAIL,
    props<{ message: string }>()
);

export const litigationNewInquiryData = createAction(
    GET_LITIGATION_NEW_INQUIRY_DATA,
    props<{ formValue }>()
);
export const litigationNewInquiryDataSuccess = createAction(LITIGATION_NEW_INQUIRY_DATA_SUCCESS, props<{ litigationNewInquiryData: any }>());
export const litigationNewInquiryDataFail = createAction(
    LITIGATION_NEW_INQUIRY_DATA_FAIL,
    props<{ message: string }>()
);

export const litigationDistrictOfficesData = createAction(
    GET_LITIGATION_DISTRICT_OFFICES_DATA,
    props<{ payload }>()
);
export const litigationDistrictOfficesSuccess = createAction(LITIGATION_DISTRICT_OFFICES_SUCCESS, props<{ litigationDistrictOfficesData: any }>());
export const litigationDistrictOfficesFail = createAction(
    LITIGATION_DISTRICT_OFFICES_FAIL,
    props<{ message: string }>()
);

export const dummyAction = createAction('[dummyAction]');
