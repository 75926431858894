import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { DateTimePickerComponent } from '../../documents/private-documents/pickers/date-time-picker.component';
import { AppDragDropDirective } from './app-drag-drop.directive';
import { DocumentDialogComponent } from './document-dialog/document-dialog.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { TemplateLibraryComponent } from './template-library.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';

@NgModule({
    declarations: [
        TemplateLibraryComponent,
        DateTimePickerComponent,
        UploadDocumentsComponent,
        DocumentListComponent,
        DocumentDialogComponent,
        AppDragDropDirective,
    ],
    imports: [CommonModule, SharedMaterialModule, FormsModule],
    exports: [
        TemplateLibraryComponent,
        DateTimePickerComponent,
        UploadDocumentsComponent,
        DocumentListComponent,
        DocumentDialogComponent,
    ]
})
export class TemplateLibraryModule {}
