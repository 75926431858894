import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MyMonitoringService {
	appInsights: ApplicationInsights;

	constructor(private router: Router, private loc: Location) {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.appInsights.instrumentationKey,
				enableAutoRouteTracking: true, // option to log all route changes
				autoTrackPageVisitTime: true, // the time a user spends on each page
				enableUnhandledPromiseRejectionTracking: true, // unhandled promise rejections will be autocollected and reported as a JavaScript error
			},
		});
		this.appInsights.loadAppInsights();
		this.appInsights.trackPageView();
		this.createRouterSubscription();
	}

	logPageView(name?: string, url?: string) {
		// option to call manually
		this.appInsights.trackPageView({
			name: name,
			uri: url,
		});
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		this.appInsights.trackEvent({ name: name }, properties);
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		this.appInsights.trackMetric({ name: name, average: average }, properties);
	}

	logException(exception: Error, severityLevel?: number) {
		this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		this.appInsights.trackTrace({ message: message }, properties);
	}

	/**
	 * @description Subscription to router events
	 */
	private createRouterSubscription() {
		// Listen for when the navigation has ended
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			/*
			 * Grab the pathname of the URL, split it into an array, capitalize the
			 * first letter of each word, and join it back into a string
			 */
			const pageArr = location.pathname.replace('/', '').replace(/-/g, ' ').split(' ');
			if (pageArr[0].length > 0) {
				const pageCaseArr = pageArr.map((name) => name[0].toUpperCase() + name.substring(1));
				const pageName = pageCaseArr.join(' ') + ' page';

				// Log it as a page view
				this.logPageView(pageName, event.urlAfterRedirects);
			}
		});
	}
}
