/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CaseRequestParam } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrepaSearchResourceService {

    protected basePath = 'https://dev.eeoc.gov/searchws';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param scrollId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteScrollId(scrollId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<boolean>;
    public deleteScrollId(scrollId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<boolean>>;
    public deleteScrollId(scrollId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<boolean>>;
    public deleteScrollId(scrollId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (scrollId === null || scrollId === undefined) {
            throw new Error('Required parameter scrollId was null or undefined when calling deleteScrollId.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<boolean>(`${this.configuration.basePath}/prepa/v1/casedetail/${encodeURIComponent(String(scrollId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param status 
     * @param officeCode 
     * @param doctype 
     * @param respdoctype 
     * @param startPosition 
     * @param recordsPerPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateDasBoardLeftDocumentCategory(status: string, officeCode: string, doctype: string, respdoctype?: string, startPosition?: number, recordsPerPage?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<object>>;
    public generateDasBoardLeftDocumentCategory(status: string, officeCode: string, doctype: string, respdoctype?: string, startPosition?: number, recordsPerPage?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<object>>>;
    public generateDasBoardLeftDocumentCategory(status: string, officeCode: string, doctype: string, respdoctype?: string, startPosition?: number, recordsPerPage?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<object>>>;
    public generateDasBoardLeftDocumentCategory(status: string, officeCode: string, doctype: string, respdoctype?: string, startPosition?: number, recordsPerPage?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling generateDasBoardLeftDocumentCategory.');
        }
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling generateDasBoardLeftDocumentCategory.');
        }
        if (doctype === null || doctype === undefined) {
            throw new Error('Required parameter doctype was null or undefined when calling generateDasBoardLeftDocumentCategory.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (doctype !== undefined && doctype !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>doctype, 'doctype');
        }
        if (respdoctype !== undefined && respdoctype !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>respdoctype, 'respdoctype');
        }

        let headers = this.defaultHeaders;
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<object>>(`${this.configuration.basePath}/prepa/v2/report/dashboard/left/status/${encodeURIComponent(String(status))}/office-code/${encodeURIComponent(String(officeCode))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param status 
     * @param officeCode 
     * @param docType 
     * @param includeEventCode 
     * @param excludeEventCode 
     * @param ciClosureDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateDasBoardNrtsUploaded(status: string, officeCode: string, docType: string, includeEventCode?: string, excludeEventCode?: string, ciClosureDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<object>>;
    public generateDasBoardNrtsUploaded(status: string, officeCode: string, docType: string, includeEventCode?: string, excludeEventCode?: string, ciClosureDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<object>>>;
    public generateDasBoardNrtsUploaded(status: string, officeCode: string, docType: string, includeEventCode?: string, excludeEventCode?: string, ciClosureDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<object>>>;
    public generateDasBoardNrtsUploaded(status: string, officeCode: string, docType: string, includeEventCode?: string, excludeEventCode?: string, ciClosureDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling generateDasBoardNrtsUploaded.');
        }
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling generateDasBoardNrtsUploaded.');
        }
        if (docType === null || docType === undefined) {
            throw new Error('Required parameter docType was null or undefined when calling generateDasBoardNrtsUploaded.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (includeEventCode !== undefined && includeEventCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeEventCode, 'includeEventCode');
        }
        if (excludeEventCode !== undefined && excludeEventCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeEventCode, 'excludeEventCode');
        }
        if (ciClosureDate !== undefined && ciClosureDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ciClosureDate, 'ciClosureDate');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<object>>(`${this.configuration.basePath}/prepa/v2/report/dashboard/status/${encodeURIComponent(String(status))}/office-code/${encodeURIComponent(String(officeCode))}/doc-type/${encodeURIComponent(String(docType))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param officeCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateDasBoardRight(officeCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public generateDasBoardRight(officeCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public generateDasBoardRight(officeCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public generateDasBoardRight(officeCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling generateDasBoardRight.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/prepa/v2/report/dashboard/right/${encodeURIComponent(String(officeCode))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ciAcctOfficeCode 
     * @param includeStatus 
     * @param includeDoctype 
     * @param excludeDoctype 
     * @param includeEventcode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChargesByIncludeDocTypesExcludeDocType(ciAcctOfficeCode: string, includeStatus: string, includeDoctype?: string, excludeDoctype?: string, includeEventcode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public getChargesByIncludeDocTypesExcludeDocType(ciAcctOfficeCode: string, includeStatus: string, includeDoctype?: string, excludeDoctype?: string, includeEventcode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public getChargesByIncludeDocTypesExcludeDocType(ciAcctOfficeCode: string, includeStatus: string, includeDoctype?: string, excludeDoctype?: string, includeEventcode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public getChargesByIncludeDocTypesExcludeDocType(ciAcctOfficeCode: string, includeStatus: string, includeDoctype?: string, excludeDoctype?: string, includeEventcode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ciAcctOfficeCode === null || ciAcctOfficeCode === undefined) {
            throw new Error('Required parameter ciAcctOfficeCode was null or undefined when calling getChargesByIncludeDocTypesExcludeDocType.');
        }
        if (includeStatus === null || includeStatus === undefined) {
            throw new Error('Required parameter includeStatus was null or undefined when calling getChargesByIncludeDocTypesExcludeDocType.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ciAcctOfficeCode !== undefined && ciAcctOfficeCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ciAcctOfficeCode, 'ciAcctOfficeCode');
        }
        if (includeStatus !== undefined && includeStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeStatus, 'include-status');
        }
        if (includeDoctype !== undefined && includeDoctype !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeDoctype, 'include-doctype');
        }
        if (excludeDoctype !== undefined && excludeDoctype !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeDoctype, 'exclude-doctype');
        }
        if (includeEventcode !== undefined && includeEventcode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeEventcode, 'include-eventcode');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v1/casedetail-activitylog`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param reportType 
     * @param officeCode 
     * @param startPosition 
     * @param recordsPerPage 
     * @param comprssion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFolderNameCaseFolderChargeIdByAcctOffice(reportType: 'ADMIN_CLOSURE' | 'READY_FOR_PCHP' | 'VERIFIED_FILTER' | 'UNVERIFIED_FILTER' | 'UNVERIFIED_AWAITING_SIG_FILTER', officeCode: string, startPosition?: number, recordsPerPage?: number, comprssion?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public getFolderNameCaseFolderChargeIdByAcctOffice(reportType: 'ADMIN_CLOSURE' | 'READY_FOR_PCHP' | 'VERIFIED_FILTER' | 'UNVERIFIED_FILTER' | 'UNVERIFIED_AWAITING_SIG_FILTER', officeCode: string, startPosition?: number, recordsPerPage?: number, comprssion?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public getFolderNameCaseFolderChargeIdByAcctOffice(reportType: 'ADMIN_CLOSURE' | 'READY_FOR_PCHP' | 'VERIFIED_FILTER' | 'UNVERIFIED_FILTER' | 'UNVERIFIED_AWAITING_SIG_FILTER', officeCode: string, startPosition?: number, recordsPerPage?: number, comprssion?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public getFolderNameCaseFolderChargeIdByAcctOffice(reportType: 'ADMIN_CLOSURE' | 'READY_FOR_PCHP' | 'VERIFIED_FILTER' | 'UNVERIFIED_FILTER' | 'UNVERIFIED_AWAITING_SIG_FILTER', officeCode: string, startPosition?: number, recordsPerPage?: number, comprssion?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (reportType === null || reportType === undefined) {
            throw new Error('Required parameter reportType was null or undefined when calling getFolderNameCaseFolderChargeIdByAcctOffice.');
        }
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling getFolderNameCaseFolderChargeIdByAcctOffice.');
        }

        let headers = this.defaultHeaders;
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }
        if (comprssion !== undefined && comprssion !== null) {
            headers = headers.set('comprssion', String(comprssion));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v2/report/dashboard/efile/attorney/report/${encodeURIComponent(String(reportType))}/office-code/${encodeURIComponent(String(officeCode))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param officeCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFolderNameCaseFolderChargeIdByAcctOffice1(officeCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public getFolderNameCaseFolderChargeIdByAcctOffice1(officeCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public getFolderNameCaseFolderChargeIdByAcctOffice1(officeCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public getFolderNameCaseFolderChargeIdByAcctOffice1(officeCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling getFolderNameCaseFolderChargeIdByAcctOffice1.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/prepa/v2/report/dashboard/left/cabinet/office-code/${encodeURIComponent(String(officeCode))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param officeCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnservedNoc(officeCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public getUnservedNoc(officeCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public getUnservedNoc(officeCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public getUnservedNoc(officeCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling getUnservedNoc.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/prepa/v2/report/dashboard/unserved-noc/${encodeURIComponent(String(officeCode))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param reqParam 
     * @param startPosition 
     * @param recordsPerPage 
     * @param scrollId 
     * @param paginationIntent 
     * @param sortBy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCaseDetail2(reqParam: CaseRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public searchCaseDetail2(reqParam: CaseRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public searchCaseDetail2(reqParam: CaseRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public searchCaseDetail2(reqParam: CaseRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (reqParam === null || reqParam === undefined) {
            throw new Error('Required parameter reqParam was null or undefined when calling searchCaseDetail2.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reqParam !== undefined && reqParam !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reqParam, 'reqParam');
        }

        let headers = this.defaultHeaders;
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }
        if (scrollId !== undefined && scrollId !== null) {
            headers = headers.set('scrollId', String(scrollId));
        }
        if (paginationIntent !== undefined && paginationIntent !== null) {
            headers = headers.set('paginationIntent', String(paginationIntent));
        }
        if (sortBy !== undefined && sortBy !== null) {
            headers = headers.set('sortBy', String(sortBy));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v2/casedetail`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ciChargeInquiryId 
     * @param startPosition 
     * @param recordsPerPage 
     * @param scrollId 
     * @param paginationIntent 
     * @param sortBy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCaseDetailByList(ciChargeInquiryId: string, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public searchCaseDetailByList(ciChargeInquiryId: string, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public searchCaseDetailByList(ciChargeInquiryId: string, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public searchCaseDetailByList(ciChargeInquiryId: string, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, sortBy?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ciChargeInquiryId === null || ciChargeInquiryId === undefined) {
            throw new Error('Required parameter ciChargeInquiryId was null or undefined when calling searchCaseDetailByList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ciChargeInquiryId !== undefined && ciChargeInquiryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ciChargeInquiryId, 'ciChargeInquiryId');
        }

        let headers = this.defaultHeaders;
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }
        if (scrollId !== undefined && scrollId !== null) {
            headers = headers.set('scrollId', String(scrollId));
        }
        if (paginationIntent !== undefined && paginationIntent !== null) {
            headers = headers.set('paginationIntent', String(paginationIntent));
        }
        if (sortBy !== undefined && sortBy !== null) {
            headers = headers.set('sortBy', String(sortBy));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v2/casedetail/list`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param parameters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLogDetail(parameters: { [key: string]: object; }, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public searchLogDetail(parameters: { [key: string]: object; }, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public searchLogDetail(parameters: { [key: string]: object; }, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public searchLogDetail(parameters: { [key: string]: object; }, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parameters === null || parameters === undefined) {
            throw new Error('Required parameter parameters was null or undefined when calling searchLogDetail.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (parameters !== undefined && parameters !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>parameters, 'parameters');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v1/eventlog`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param listOfIncludeEventCode 
     * @param listOfExcludeEventCode 
     * @param ciAcctOfficeCode 
     * @param sortBy 
     * @param startPosition 
     * @param recordsPerPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLogDetailWithEventCodes(listOfIncludeEventCode: Array<string>, listOfExcludeEventCode: Array<string>, ciAcctOfficeCode?: string, sortBy?: string, startPosition?: number, recordsPerPage?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public searchLogDetailWithEventCodes(listOfIncludeEventCode: Array<string>, listOfExcludeEventCode: Array<string>, ciAcctOfficeCode?: string, sortBy?: string, startPosition?: number, recordsPerPage?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public searchLogDetailWithEventCodes(listOfIncludeEventCode: Array<string>, listOfExcludeEventCode: Array<string>, ciAcctOfficeCode?: string, sortBy?: string, startPosition?: number, recordsPerPage?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public searchLogDetailWithEventCodes(listOfIncludeEventCode: Array<string>, listOfExcludeEventCode: Array<string>, ciAcctOfficeCode?: string, sortBy?: string, startPosition?: number, recordsPerPage?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (listOfIncludeEventCode === null || listOfIncludeEventCode === undefined) {
            throw new Error('Required parameter listOfIncludeEventCode was null or undefined when calling searchLogDetailWithEventCodes.');
        }
        if (listOfExcludeEventCode === null || listOfExcludeEventCode === undefined) {
            throw new Error('Required parameter listOfExcludeEventCode was null or undefined when calling searchLogDetailWithEventCodes.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ciAcctOfficeCode !== undefined && ciAcctOfficeCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ciAcctOfficeCode, 'ciAcctOfficeCode');
        }

        let headers = this.defaultHeaders;
        if (sortBy !== undefined && sortBy !== null) {
            headers = headers.set('sortBy', String(sortBy));
        }
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/prepa/v1/eventlog/include-event-code/${encodeURIComponent(String(listOfIncludeEventCode))}/exclude-event-code/${encodeURIComponent(String(listOfExcludeEventCode))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
