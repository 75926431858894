import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookUpStatusCode, ScrollStrategy, minimumDate } from 'src/app/shared/common-structures/app.constants';
import { DocTypes } from '../../../documents/document';
import  moment from 'moment';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from 'src/app/shared/utility/utils';

export const MY_FORMATS = {
	parse: {
		dateInput: 'l',
	},
	display: {
		dateInput: 'l',
		monthYearLabel: 'l',
		dateA11yLabel: 'LLLL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'document-dialog',
	templateUrl: './document-dialog.component.html',
	styleUrls: ['./document-dialog.component.css'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class DocumentDialogComponent implements OnInit {
	editDocForm: UntypedFormGroup;
	initForm: any;
	emailForm: UntypedFormGroup;
	minDate = minimumDate;
	maxDate: Date = new Date();
	documentTypes: any;
	enableResponseDueDate = false;
	isSystemic: boolean = false;
	hasForm5A: boolean = false;
	emailReason = new UntypedFormControl('', [Validators.required]);
	isCodType = false;
	confirmRef: MatDialogRef<any>;
	constructor(
		public dialogRef: MatDialogRef<DocumentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialog: MatDialog,
		private fb: UntypedFormBuilder,
		private el: ElementRef,
	) {
		this.createForm();
	}

	ngOnInit() {
		if (this.data.from === 'edit') {
			 this.hasForm5A = this.data.documents.some(x => x.documentType === DocTypes.FORM5A_CP);
			if(this.hasForm5A || this.data.caseDetails?.status?.code !== LookUpStatusCode.INQUIRY_SUBMITTED) {
				this.data.documentTypes = this.data.documentTypes.filter(x => x.documentCode !== DocTypes.FORM5A_CP)
			}
			this.documentTypes = [...this.data.documentTypes];
			this.isCodType = ['PRVCOD','PRVAMNDCOD','PRVCODNONPERF'].includes(this.data.data.documentType);		
			this.getFormField('title').setValue(this.data.data.title);
			this.getFormField('documentType').setValue(this.data.data.documentType);
			this.getFormField('tabType').setValue(this.data.data.tabType || this.getTabType(this.data.data.documentType));
			this.getFormField('relatedDate').setValue(
				this.data.data.relatedDate ? new Date(this.data.data.relatedDate) : null
			);
			if(this.isCodType){
				this.getFormField('title').disable();
				this.getFormField('documentType').disable();
				this.getFormField('tabType').disable();
				this.minDate = Utils.toDate(this.data?.caseDetails?.initialInquiryDate);
			}
			if (this.data.data.documentDescription === DocTypes.RFI) {
				this.enableResponseDueDate = true;
				this.editDocForm.addControl(
					'responseDueDate',
					new UntypedFormControl(
						this.data.data.responseDueDate ? new Date(this.data.data.responseDueDate) : null,
						Validators.required
					)
				);
				this.editDocForm.get('relatedDate').clearValidators();
			}
			this.initForm = this.editDocForm.getRawValue();
		}

		if (this.data.from === 'fep-email') {
			this.getFormField('emailList').setValue(this.data.emailList);
		}
	}

	isChanged(){
		const currentValue = this.editDocForm.getRawValue();
		const result = Object.keys(this.initForm).some(k => {
		  if (k === 'relatedDate') {
			return moment(this.initForm[k]).format('MM-DD-YYYY') !== moment(currentValue[k]).format('MM-DD-YYYY');
		  } 
		  return this.initForm[k] !== currentValue[k];
		});
		return result;
	}

	onFolderSelect(value) {
		this.getFormField('documentType').setValue('');
		this.data.documentTypes = this.documentTypes.filter((type) => type.documentTab?.description === value);
	}

	onDocumentTypeSelect(docType) {
		const selectedDocumentType = this.data.documentTypes.filter((item) => item.documentCode === docType);
		this.getFormField('tabType').setValue(selectedDocumentType[0]?.documentTab?.description);
	}
	/**
	 * crating the form
	 */
	createForm() {
		this.editDocForm = this.fb.group({
			title: new UntypedFormControl('', Validators.required),
			documentType: new UntypedFormControl('', Validators.required),
			tabType: new UntypedFormControl(''),
			relatedDate: new UntypedFormControl('', Validators.required),
		});

		this.emailForm = this.fb.group({
			emailList: new UntypedFormControl('', Validators.required),
			emailText: new UntypedFormControl(''),
		});
	}

	makeSelection(confirm: any) {
		this.dialogRef.close(confirm);
	}

	sendEmailReason(reason) {
		this.dialogRef.close(reason);
	}

	sendFepaEmailForm(editForm) {
		this.dialogRef.close(editForm);
	}

	private getTabType(docType: string): string {
		if (docType) {
			const docTypeObject = this.data.documentTypes.find((item) => item.documentCode === docType);
			return docTypeObject ? docTypeObject.categoryName : null;
		} else {
			return null;
		}
	}

	getFormField(field: string): any {
		return this.editDocForm.get(field);
	}

	onUpdate(template){
		if(this.data.data.documentType === DocTypes.COD) {
		this.confirmRef = this.dialog.open(template, {
			disableClose: true,
			width: '70%',
			scrollStrategy: ScrollStrategy,
			id: 'update-confirmation-dialog',
		});
		this.confirmRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.dialogRef.close(this.editDocForm.getRawValue());
				}
			});
		} else {
			this.dialogRef.close(this.editDocForm.getRawValue());
		}
		
	}

	onCancel(value?){
		this.confirmRef.close(value)
	}
}
