import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class CustomMatSortHeaderIntl {
	// Global enumerated list of db names and their human readable label equivalents
	labelEnum = {
		ciIsUrgent: 'Urgent/Untimely',
		ciChargeNumber: 'EEOC Number',
		ciFepaChargeNumber: 'FEPA Number',
		cpFirstName: 'Charging Party', // Using for CP Name, Charging party and Charging Party (PCP/CP)
		rspName: 'Respondent',
		ciStatusDescription: 'Status',
		ciStatusDescriptionAge: 'Status (Status Age)',
		cpCreatedOn: 'Received Date',
		determinationDate: 'Determination Date',
		ciAcctOfficeDescription: 'Accountability Office', // Using for Accountable office and Accountability office
		ciFormalizationDate: 'Formalization Date Filed On', // Using for Filed on and Formulation Date
		scvCategoryValueCode: 'PCHP Category',
		ciInitialInquiryDate: 'Status Age/Received on', // Using for Status age and Received on
		formalizationDate: 'Charge Age/Receipt Date', // Using for Charge age and receipt date
		ciiInterviewBeginTime: 'Appt Time/Type',
		unitNo: 'Unit Assignee/Number',
		celEventCreatedOn: 'Last Activity Date',
		ciClosureDate: 'Closure Date',
		ciClosureAge: 'Closure Age',
		ciclosurereasontext: 'Closure Reason',
		ciAutomatedNocDisabled: 'SWAT',
		originationOffice: 'Origination Office',
		destinationOffice: 'Destination Office',
		incomingPendingActions: 'Actions',
		incomingRejectedActions: 'Actions',
		outgoingPendingActions: 'Actions',
		rejectedActions: 'Actions',
		craReviewTypeDesc: 'Review Type',
		psStmntStatus: 'PS Statement Status',
		craAssignedOn: 'Charge Review Assigned On',
		craCreatedOn: 'Charge Review Created On',
		craReviewStatus: 'Status',
		craAssignedToUser: 'Charge Review Assigned to',
		statusDesc: 'Status',
		allegationNumber: 'ID',
		originatingAllegationBasis: 'Originating Allegation Basis',
		originatingAllegationIssue: 'Originating Allegation Issue',
		statuteDesc: 'Statute',
		basisDesc: 'Basis',
		issueDesc: 'Issue',
		firstAllegedDate: 'First Alleged Date',
		lastAllegedDate: 'Latest Alleged Date',
		causeFinding: 'Cause Findings',
		updateCauseFinding: 'Update Cause Findings',
		continuingActionInd: 'Cont. Act.',
		edit: 'Actions',
		outcome: 'Compliance Outcomes',
		createdBy: 'Created By',
		createdOn: 'Posted/Created On/Request Date/Date/Date Added ', // Used for Posted, Created on, Request Date, Date, and Date added
		contacted: 'Contacted',
		purpose: 'Purpose',
		options: 'Actions',
		subpoenaId: 'Subpoena ID',
		servedOn: 'Subpoena Issued',
		compiledOn: 'Subpoena Satisfied',
		updatedBy: 'Updated By',
		updatedOn: 'Updated On',
		posted: 'Posted',
		subject: 'Subject',
		note: 'Note',
		assignedOn: 'Date Requested',
		userName: 'Approver',
		approvalType: 'Approval Type',
		reviewDescription: 'Review Type',
		reviewStatus: 'Status',
		comment: 'Comments',
		actions: 'Actions',
		aprAssignedOn: 'Date Requested',
		aprApprovalType: 'Approval Type',
		aprReviewDescription: 'Review Type',
		aprComment: 'Comments',
		apractions: 'Actions',
		expandedDetail: 'Enter Comment',
		folderName: 'Name',
		charges: '# of Cases',
		description: 'Description',
		shortName: 'Short Name',
		code: 'Code',
		statuteDes: 'Statute',
		basisDes: 'Basis',
		issueDes: 'Issue',
		chargeAssignments: 'Assigned to',
		caseNumber: 'EEOC Number',
		fepaCaseNumber: 'FEPA Number',
		eeocOffice: 'EEOC Reporting Office',
		chargeAssignedTo: 'Assigned To (FEPA)',
		cpName: 'Charging Party',
		fepaRequestType: 'FEPA Request',
		eeocResponse: 'EEOC Response',
		eeocResponseDesc: 'EEOC Credit Response',
		ciStatusAge: 'Age',
		craReviewTypeAssignment: 'Review Description(Staff Assigned)',
		accessOffice: 'Access Office',
		title: 'Document Title',
		documentDescription: 'Document Type',
		tabtype: 'Category',
		createdByFullName: 'Uploaded By',
		relatedDate: 'Doc/Rcvd Date',
		createdDate: 'Uploaded Date',
		availableTo: 'Available To',
		nonDisclosable: 'Non-disclosable',
		logDate: 'Copied Date',
		eeocNumber: 'Charge Number',
		fepaNumber: 'FEPA Number',
		fepaOffice: 'FEPA Office', // Using for FEPA office and Accountable FEPA Office
		docType: 'Document Type',
		sendAction: 'Action',
		saveAction: 'Action',
		deferralOfficeIntentSelect: 'Deferral Office Intent',
		deferralOfficeIntent: 'Deferral Office Intent',
		chargeNumber: 'EEOC Number',
		fepaChargeNumber: 'FEPA Number',
		accountabilityOfficeCode: 'Receiving Office',
		deferralOfficeCode: 'Deferral Office',
		accountableOfficeIntent: 'Receiving Office Intent',
		accountableOfficeIntentSelect: 'Receiving Office Intent',
		chargeOfDiscrimination: 'COD',
		accountabilityStaff: 'Receiving Office Staff',
		deferralStaff: 'Deferral Office Staff',
		respondentName: 'Respondent',
		closed: 'Status',
		form212a: 'Dual-Filing Notice',
		acknowledgmentDate: 'Acknowledgement Date',
		officeCodes: 'Local/National',
		addressLine1: 'Address',
		city: 'City/State/Zip',
		email: 'Email',
		type: 'Type',
		intersectional: 'Intersectional',
		caFirstName: 'Assigned To/Assignee details', // This covers Assignee details, assigned to and Staff assigned
		ciIsFepa: 'FEPA',
		ciAge: 'Status Age (days)',
		rspAddressLine1: 'Respondent Address',
		alBasis: 'Basis',
		alIssue: 'Issue',
		systemicCaseName: 'Systemic Case Name',
		systemicCaseStatus: 'Systemic Status',
		'basis-issue': 'Basis',
		eventDisplayText: 'Event',
		ter: 'TER',
		isCoOwner: 'Co Owner',
		personName: 'Name',
		emailAddress: 'Email',
		officeCode: 'Office Code',
		officeName: 'Office Name',
		action: 'Action',
		beName: 'Name',
		beAddressLine1: 'Address',
		beCity: 'City',
		beState: 'State',
		beZip: 'Zip Code',
		numOfEmployee: 'Employee Size',
		alStatuteShortName: 'Statute',
		alBasisShortName: 'Basis',
		alIssueShortName: 'Issue',
		pchpAssessments: 'PCHP',
		eventDate: 'Date',
		staffName: 'Name',
		authorName: 'Author',
		emailTo: 'Email To',
		caseName: 'Case Name',
		status: 'Status',
		respondents: 'Respondent Name',
		statute: 'Statute',
		basis: 'Basis',
		issue: 'Issue',
		owner: 'Case Owner',
		office: 'Office', // Covers both Owener's office and Physical Office values
		numberOfCharges: '# of Cases',
		subscribed: 'Subscribed',
		coordinationStatus: 'Systemic Status',
		contractFiscalYear: 'Contracted Year',
		contractedFepaOffice: 'Contracted FEPA Office',
		contractingEEOCOffice: 'Contracting EEOC Office',
		certifiedAcceptance: 'Certified Acceptance',
		completedIntakes: 'Contracted Intakes',
		completedResolutions: 'Contracted Resolutions',
		creditedIntakes: 'Credited Intakes',
		creditedResolutions: 'Credited Resolutions',
		associatedCases: 'Associated Cases',
		templateName: 'Template Name',
		templateDescription: 'Description',
		firstName: 'First Name',
		lastName: 'Last Name',
		mail: 'Email',
		workPhone: 'Work Phone',
		assignedUser: 'Assigned User',
		name: 'Name',
		roleAssignmentsType: 'Access Office',
		descriptionType: 'Access Role',
		requestedBy: 'Requested By',
		declinedBy: 'Declined By',
		unit: 'Unit',
		userTimeZone: 'Time zone',
		approvedBy: 'Approved By',
		reviewedDate: 'Approval Date',
		userFullName: 'Completed By/User Name', // This covers Completed by and User Name
		completedByFullName: 'Completed By',
	};
	changes: Subject<void> = new Subject<void>();
	sortButtonLabel(id: string) {
		const label = this.labelEnum[id] || id;
		return `Change sorting for the ${label} column`;
	}
}
