import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { ContentManagementResourceService } from 'src/app/services/EcmWebService';
import { ActivityLogVO, EmailResourceService } from 'src/app/services/EmailWebService';
import {
	DocumentProperties,
	DocumentTypeVO,
	DownloadRequestVO,
	PrepaCaseResourceService,
	PrepaDocumentResourceService,
	RespondentResponseDueVO,
} from 'src/app/services/gen';
import { ImsDocumentRequest } from 'src/app/services/gen/model/imsDocumentRequest';
import { TemplateRequestVO, TemplateResourceService } from 'src/app/services/TemplateMangementWebService';
import { TokenUserInfo } from 'src/app/shared/common-structures/token-user-info';
import { environment } from 'src/environments/environment';
import { Utils } from '../../../shared/utility/utils';
import { AllegationsConfig } from '../case-details/case-details.config';
import { Allegation } from '../case-details/case-details.model';
import { Document } from './document';
import { DocumentsConfig, PrivateDocumentsConfig } from './documents.config';
import { KeyValue } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class DocumentsService {
	private documentsBehaviorSubject = new BehaviorSubject<KeyValue<string, Document[]>>(null);
	documentTypes$: Observable<Array<DocumentTypeVO>>;
	documentsConfig = DocumentsConfig;
	documentsChange$: Subject<any> = new Subject<any>();
	constructor(
		private http: HttpClient,
		private cs: CommonService,
		private contentManagementServ: ContentManagementResourceService,
		private prepaDocServ: PrepaDocumentResourceService,
		private emailws: EmailResourceService,
		private templateServ: TemplateResourceService,
		private prepaCaseResource: PrepaCaseResourceService
	) {}

	/**
	 * @description returns doc params as observable
	 */
	getDocumentsObservable() {
		return this.documentsBehaviorSubject.asObservable();
	}

	/**
	 * @description subscribes to next emited value of doc
	 * @params params
	 */
	setDocumentsObservable(params: KeyValue<string, Document[]>) {
		this.documentsBehaviorSubject.next(params);
	}

	/**
	 * @description get all types of documents
	 */
	getDocumentTypes(): Observable<Array<DocumentTypeVO>> {
		if (!this.documentTypes$) {
			this.documentTypes$ = this.prepaDocServ.getDocumentTypes(true).pipe(
				map((res) => (res ? res : [])),
				shareReplay(1)
			);
		}
		return this.documentTypes$;
	}

	/**
	 * @description get all types of documents
	 */
	getDocumentAllTypes(): Observable<Array<DocumentTypeVO>> {
		if (!this.documentTypes$) {
			this.documentTypes$ = this.prepaDocServ.getDocumentTypes(true, true).pipe(
				map((res) => (res ? res : [])),
				shareReplay(1)
			);
		}
		return this.documentTypes$;
	}

	getDocuments(caseNumber: string, domain: string, docid?: string): Observable<any> {
		const params: any = {
			appname: DocumentsConfig.appname,
			domain: domain,
			foldername: caseNumber,
		};
		if (docid) {
			params.docid = docid;
		}
		return this.contentManagementServ.findDocuments(params);
	}

	/**
	 *
	 * @param appname
	 * @param domain
	 * @param useremail
	 * @param userfirstname
	 * @param userlastname
	 * @param userFullName
	 * @param content
	 * @param doctype
	 * @param tabtype
	 * @param filename
	 * @param caseNumber
	 * @param userName
	 * @param prirelatedatestr
	 * @param encryption
	 * @param additionalinfo - case folder param "additionalinfo" = "CASE" (Doc goes to Case folder) or null(Doc goes to Adr folder)
	 * @param isFepa
	 */
	uploadDocument(
		appname: string,
		domain: string,
		useremail: string,
		userfirstname: string,
		userlastname: string,
		accountabilityOfficeCode: string,
		userFullName: string,
		content: Blob,
		doctype: string,
		tabtype: string,
		filename: string,
		caseNumber: string,
		userName: string,
		prirelatedatestr: string,
		encryption: string,
		additionalinfo: string,
		isFepa = 'N',
		isDisclosable: boolean,
		isPublished: string
	): Observable<HttpResponse<object>> {
		return this.contentManagementServ.addDocument(
			appname,
			domain,
			useremail,
			userfirstname,
			userlastname,
			accountabilityOfficeCode,
			undefined,
			undefined,
			undefined,
			doctype,
			undefined,
			filename,
			caseNumber,
			isFepa,
			true,
			isPublished,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			additionalinfo,
			isDisclosable,
			userFullName,
			undefined,
			undefined,
			content,
			undefined,
			undefined,
			undefined,
			undefined,
			encryption,
			undefined,
			undefined,
			undefined,
			undefined,
			prirelatedatestr,
			undefined,
			filename,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			caseNumber,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			caseNumber,
			undefined,
			undefined,
			userName,
			'response'
		);
	}

	downloadZip(docId: string, domain, userName, islogactivity?): Observable<HttpResponse<object>> {
		const params = {
			appname: DocumentsConfig.appname,
			username: userName,
			domain: domain,
			docid: docId,
			islogactivity
		};
		return this.contentManagementServ.downloadMultipleDocument(params, 'response');
	}

	downloadFile(docId, domain, userName, caseNumber?, islogactivity?): Observable<HttpResponse<Blob>> {
		const params = {
			appname: DocumentsConfig.appname,
			domain: domain,
			username: userName,
			docid: docId,
			foldername: caseNumber,
			islogactivity
		};
		return this.contentManagementServ.downloadDocument(params, 'response');
	}

	/**
	 * @description Fetches all the information with get call
	 */
	getCommonDocWithUrl(url): any {
		return this.http.get(url);
	}

	copyDocumentToFEPAOREEOC(
		documentNodeId: string,
		destination: string,
		domain: string,
		userDetails: TokenUserInfo,
		headerConfig: any,
		acctoffice: string
	): any {
		const url = environment.ecmurl + DocumentsConfig.copyDocumentUrl + '/' + documentNodeId.replace(';1.0', '');
		const headers = new HttpHeaders();
		// headers.set('Content-Type', 'multipart/form-data');

		const body = new FormData();
		body.append('appname', DocumentsConfig.appname);
		body.append('domain', domain);
		body.append('destinationfoldername', destination);
		// use fepa.user to copy to EEOC  and staffOracleUserId is to write to activity log  '--' seperates the two usernames
		body.append('username', domain === 'PRIVATE' ? 'fepa.user--' + userDetails.user_name : userDetails.user_name);
		body.append('useremail', userDetails.userEmail);
		body.append('userfirstname', userDetails.userFirstName);
		body.append('userlastname', userDetails.userLastName);
		body.append('appuserfullname', userDetails.userFullName);
		body.append('acctoffice', acctoffice);
		return this.http.put(url, body, { headers });
	}

	// urlParmas is used when we would like to send additional path paramets like docid
	deleteDocument(docId: string, deleteReason: string): Observable<object> {
		return this.contentManagementServ.deleteDocument(docId, null, deleteReason);
	}

	updateDocumentParams(
		doc: any,
		domain: string,
		userDetails: TokenUserInfo,
		accountabilityOfficeCode: string,
		ispub: string,
		recallReason: string,
		isDisclosable: boolean,
		caseNumber: string
	): Observable<any> {
		return this.contentManagementServ.updateDocument(
			doc.documentNodeId.split(';')[0],
			DocumentsConfig.appname,
			domain,
			userDetails.userEmail,
			userDetails.userFirstName,
			userDetails.userLastName,
			accountabilityOfficeCode,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			ispub,
			recallReason,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			isDisclosable,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			caseNumber,
			undefined,
			caseNumber,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			caseNumber,
			undefined,
			undefined,
			userDetails.user_name
		);
	}

	editDocument(
		doc: any,
		domain: string,
		userDetails: TokenUserInfo,
		accountabilityOfficeCode: string,
		caseNumber: string
	): Observable<any> {
		return this.contentManagementServ.updateDocument(
			doc.documentNodeId.split(';')[0],
			DocumentsConfig.appname,
			domain,
			userDetails.userEmail,
			userDetails.userFirstName,
			userDetails.userLastName,
			accountabilityOfficeCode,
			undefined,
			undefined,
			undefined,
			doc.documentType,
			undefined,
			doc.fileName,
			undefined,
			undefined,
			undefined,
			undefined,
			doc.notes,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			doc.relatedDate,
			undefined,
			doc.title,
			doc.responseDueDate,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			caseNumber,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			doc.tabType,
			undefined,
			undefined,
			undefined,
			caseNumber,
			undefined,
			undefined,
			userDetails.user_name
		);
	}

	/*
  Observable is not a promise-compatible return type for async function
*/
	getDocumentByTypes(caseNumber: string, domain: any, docType: string, staffOracleUserId): Observable<any> {
		caseNumber = Utils.caseNumberConverter(caseNumber);
		const params: any = {
			appname: DocumentsConfig.appname,
			domain: domain,
			userName: staffOracleUserId,
			foldername: caseNumber,
			doctype: docType,
		};
		return this.contentManagementServ.findDocuments(params);
	}



	/**
	 *
	 * @param document
	 * @param params
	 */
	uploadCOD(document: Blob, params: ImsDocumentRequest): Observable<DocumentProperties> {
		return this.prepaDocServ.uploadImsDocument(document, params);
	}

	uploadTemplateDocument(document: Blob, params: TemplateRequestVO): Observable<any> {
		return this.templateServ.uploadTemplate(document, params);
	}

	updateRfiResponseDueDate(
		caseId: number,
		respondentResponseDueVO: RespondentResponseDueVO
	): Observable<RespondentResponseDueVO> {
		return this.prepaDocServ.updateRfiResponseDueDate(caseId, respondentResponseDueVO);
	}

	getEmailNotesForCaseNumber(caseNumber: string): Observable<Array<ActivityLogVO>> {
		return this.emailws.getEmailNotesForCaseNumber(caseNumber);
	}

	getTemplates(domain, office?: string) {
		return this.templateServ.getTemplates('PRIVATE', office);
	}

	downloadDocument(caseId: number, downloadRequestVO: DownloadRequestVO) {
		return this.prepaDocServ.downloadDocument(caseId, downloadRequestVO);
	}

	public reSendNoticeOfCharge(chargeInquiryId: number, reason: string) {
		return this.prepaCaseResource.reSendNoticeOfCharge(chargeInquiryId, reason);
	}
}
