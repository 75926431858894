import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { CommonService } from 'src/app/services/common.service';
import {PrepaAngularUiErrorLogResourceService} from 'src/app/services/gen/api/prepaAngularUiErrorLogResource.service'
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
	userFullName: string;
	userEmail: string;
	errorRes: {};
	userId: string;
	constructor(private myMonitoringService: MyMonitoringService,
				private injector: Injector,
				private cmnServ: CommonService,
				private errorLogServ: PrepaAngularUiErrorLogResourceService
		) {
		super();
	}
	//parse the error stack
	private parseErrorStack(stack: string): any[]{
		if(!stack) return null;

		const stackLines = stack.split('\n').map(line => line.trim());
		stackLines.shift();
		const parsedStack = stackLines.map(line =>{
			const match = line.match(/at\s+(.+?)\s+\((.*?):(\d+)\)/)
			if(match) {
				const [functionName, fileName, lineNumber, columnNumber] = match;
				return { functionName, fileName, lineNumber: parseInt(lineNumber), columnNumber: parseInt(columnNumber) }
			}
			return {raw : line}
		})
		return parsedStack
	}
	//function to get the request headers
	private getRequestHeaders(rejection: any): HttpHeaders {
		if (rejection && rejection?.request && rejection?.request.headers instanceof HttpHeaders){
			return rejection?.request?.headers;
		}
	}
	//function to get response data
	private getResponseData(rejection: any):any {
		if(rejection && rejection?.response && rejection?.response.data){
			return rejection.response.data;
		}
		return 'No response data available'
	}
	//main method 
	handleError(error: any): void {
		if(error){
			this.cmnServ.getUserDetails().subscribe((res)=>{
				this.userId = res?.userId,
				this.userFullName = res?.userFullName;
				this.userEmail = res?.userEmail;
			});
			const url = error?.url || "No URL Found"
			const request = error?.rejection?.request || "No Request Info available";
			const headers = this.getRequestHeaders(error?.rejection) || "No Header Data Available";
			const response = this.getResponseData(error?.rejection) || "No Response Data Available";
			this.myMonitoringService?.logException(error); // Manually log exception
			
			const router = this.injector.get(Router);
			const route = router?.routerState?.snapshot?.url ;
			this.errorRes = {
				details: error || "No error Details Found",
				userName: this.userFullName || "User Name Not Found",
				userEmail: this.userEmail || "User Email Not Found",
				userID: this.userId || "User ID not Found", 
				route: route || "No routes found",
				message : error?.message || "No Error Message Found",
				stack:this.parseErrorStack(error?.stack) || "No Error Stack found",
				url: url,
				req: request,
				header: headers || "No headers Found",
				res: response || "No response Available",
			};

			const chunkFailedMessage = /Loading chunk [\d]+ failed/;
			if (error instanceof HttpErrorResponse) {
				this.errorRes = {
					details: error?.error || "No Error Details found",
					userName: this.userFullName || "No username Found",
					userEmail: this.userEmail || "No User Email Found",
					userID: this.userId || "No User ID found",
					route: route,
					message : error?.message || "No Error Message Found",
					status: error?.status || "No error Status available",
					url: url,
					req: request,
					header: headers || "No Header data Available",
					res: response || "No response Data"
				};       	  
			}
			//angular errorlog service 
			this.errorLogServ.logAngularUIErrors(this.errorRes ? JSON.stringify(this.errorRes) : "No Details Found").subscribe((res)=>{
				if (res) console.log("Error Successfully Captured");
			},
			(error)=>{
				console.error('Couldnt capture the error log')
			})

			if (chunkFailedMessage.test(error.message)) {
				// window.location.reload();
				alert('Failed to load the application. Please close this window and try to access again.');
				throw error;
			} else {
				throw error;
			}
		}
	}

}
