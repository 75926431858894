/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { EventLogFilterParams, EventLogVO } from '../model/models';
import { BASE_PATH } from '../variables';

@Injectable({
	providedIn: 'root',
})
export class EventLogResourceService {
	protected basePath = 'https://dev.eeoc.gov/UserManagement';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(
		protected httpClient: HttpClient,
		@Optional() @Inject(BASE_PATH) basePath: string,
		@Optional() configuration: Configuration
	) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}

	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === 'object') {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
				} else {
					throw Error('key may not be null if value is Date');
				}
			} else {
				Object.keys(value).forEach(
					(k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
				);
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error('key may not be null if value is not object or array');
		}
		return httpParams;
	}

	/**
	 * Get User Management Event Logs for a user
	 * Get User Management Event Logs for a user
	 * @param userDetailId
	 * @param eventCodes
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getEventLogsByuserDetailId(
		userDetailId: string,
		eventCodes?: Array<string>,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<Array<EventLogVO>>;
	public getEventLogsByuserDetailId(
		userDetailId: string,
		eventCodes?: Array<string>,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<HttpResponse<Array<EventLogVO>>>;
	public getEventLogsByuserDetailId(
		userDetailId: string,
		eventCodes?: Array<string>,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<HttpEvent<Array<EventLogVO>>>;
	public getEventLogsByuserDetailId(
		userDetailId: string,
		eventCodes?: Array<string>,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<any> {
		if (userDetailId === null || userDetailId === undefined) {
			throw new Error('Required parameter userDetailId was null or undefined when calling getEventLogsByuserDetailId.');
		}

		let queryParameters = new HttpParams({ encoder: this.encoder });
		if (eventCodes) {
			eventCodes.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters, <any>element, 'eventCodes');
			});
		}

		let headers = this.defaultHeaders;

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<EventLogVO>>(
			`${this.configuration.basePath}/users/${encodeURIComponent(String(userDetailId))}/events`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress,
			}
		);
	}

	/**
	 * Get User Management Event Logs for the logged in user
	 * Get User Management Event Logs for the logged in user
	 * @param filterParams
	 * @param pageable
	 * @param eventCodes
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getEventLogsForLoggedInUser(
		filterParams: EventLogFilterParams,
		pageable: object,
		eventCodes?: Array<string>,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<Array<EventLogVO>>;
	public getEventLogsForLoggedInUser(
		filterParams: EventLogFilterParams,
		pageable: object,
		eventCodes?: Array<string>,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<HttpResponse<Array<EventLogVO>>>;
	public getEventLogsForLoggedInUser(
		filterParams: EventLogFilterParams,
		pageable: object,
		eventCodes?: Array<string>,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<HttpEvent<Array<EventLogVO>>>;
	public getEventLogsForLoggedInUser(
		filterParams: EventLogFilterParams,
		pageable: object,
		eventCodes?: Array<string>,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json' }
	): Observable<any> {
		if (filterParams === null || filterParams === undefined) {
			throw new Error(
				'Required parameter filterParams was null or undefined when calling getEventLogsForLoggedInUser.'
			);
		}
		if (pageable === null || pageable === undefined) {
			throw new Error('Required parameter pageable was null or undefined when calling getEventLogsForLoggedInUser.');
		}

		let queryParameters = new HttpParams({ encoder: this.encoder });
		if (eventCodes) {
			eventCodes.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters, <any>element, 'eventCodes');
			});
		}
		if (filterParams !== undefined && filterParams !== null) {
			queryParameters = this.addToHttpParams(queryParameters, <any>filterParams, 'filterParams');
		}
		if (pageable !== undefined && pageable !== null) {
			queryParameters = this.addToHttpParams(queryParameters, <any>pageable, 'pageable');
		}

		let headers = this.defaultHeaders;

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<EventLogVO>>(`${this.configuration.basePath}/user/events`, {
			params: queryParameters,
			responseType: <any>responseType,
			withCredentials: this.configuration.withCredentials,
			headers: headers,
			observe: observe,
			reportProgress: reportProgress,
		});
	}
}
