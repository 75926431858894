import  moment from 'moment';
import { DocumentTypeVO } from 'src/app/services/gen';
import _date = moment.unitOfTime._date;

export class Document {
	fileName?: string;
	documentNodeId?: string;
	documentType?: string;
	createdDate?: string;
	lastModifiedDate?: string;
	title?: string;
	createdBy?: string;
	lastModifiedBy?: string;
	notes?: string;
	isPublishable?: boolean;
	isPublished?: boolean;
	docReleasedTo?: string;
	isSecure?: string;
	isDisclosable?: boolean;
	createByLastName?: string;
	createdByFirstName?: string;
	tabType?: string;
	subCategory?: string;
	relatedDate?: string;
	eeocTrackingNumber?: string;
	agencyTrackingNumber?: string;
	complainantName?: string;
	fedRelatedDate?: string;
	year?: string;
	agnecy?: string;
	accountabilityOfficeCode?: string;
	encryptionType?: string;
	createdByFullName?: string;
	modifiedByFullName?: string;
	viewableBy?: string;
	checked?: boolean;
	allowEmail?: boolean;
	allowDownload?: boolean;
	allowEdit?: boolean;
	allowDelete?: boolean;
	availableTo?: string;
	availableToTitle?: string;
	relationShipId?: string;
	deleteReason?: boolean;
	agency?: string;
	createdByAlfresco?: string;
	docCode?: string;
	lastModifiedByAlfresco?: string;
	releaseToIndicator?: boolean;
	version?: string;
	documentDescription?: string;
	responseDueDate?;
	isMediation?: boolean;
	isRetainedMediation?: boolean;
	type?: DocumentTypeVO;
	isSendEnable?: boolean;
}

export class FileToUpload {
	filename?: string;
	name?: string;
	file: File;
	doctype: string;
	description?: string;
	tabtype?: string;
	relatedDate?: any;
	content?: any;
	docExt?: any;
	size?: any;
	value?: any;
	uploadedDate?: any;
	typeInvalid?: boolean;
	isDisclosable?: boolean;
	filteredDocumentTypes?: DocumentTypeVO[];
}
export class SelectedAction {
	code?: string;
	id?: number;
	name?: string;
}

export class CaseStatus {
	isRespondentLoggedIn?: boolean;
	isFormalized?: boolean;
	isCategoryApproved?: boolean;
	isPortalInquiry?: boolean;
	isDeferralOfficeDefined?: boolean;
	isDeferralOfficeIntendToInvestigate?: boolean;
	isShareDocsToFEPA?: boolean;
	shareDocsToEEOC?: boolean;
}

export class DocTypes {
	static readonly NOC = 'PRVNOC';
	static readonly INTERVIEW_NOTES = 'INTKINTNOTES';
	static readonly COD_DRAFT = 'PRVCODDRAFT';
	static readonly COD = 'PRVCOD';
	static readonly PCOD = 'PRVCODNONPERF';
	static readonly ACOD_DRAFT = 'AMDCODDRAFT';
	static readonly AmendedCOD = 'PRVAMNDCOD';
	static readonly POS = 'PRVPOS';
	static readonly POS_REDACT = 'POSSTREDAC';
	static readonly POS_ATTMNT_NON_CONF = 'STMTPSANC';
	static readonly POS_ATTMNT_NON_CONF_REDACT = 'POSSTNONCFATCHREDAC';
	static readonly SUPPL_POS = 'SUBPOSSTMNT';
	static readonly SUPPL_POS_REDACT = 'SUPPOSSTMNTREDAC';
	static readonly RFI = 'Request for Information';
	static readonly ResponseRFI = 'Response to Request for Information';
	static readonly SDS = 'Sensitive Data Set';
	static readonly FORM212A = 'NOTOFDUALFILING';
	static readonly SWR = 'SUBWGTRVW';
	static readonly SWR_DRAFT = 'SUBWGTRVWDFT';
	static readonly COD_FORM5A = 'PRVCODFRM5A';
	static readonly FORM5A_CP = 'FRM5ATOPCP';
}

export class DocTabHeader {
	static readonly ALL = 'All Documents';
	static readonly A = 'TAB-A: Field Office Work Product';
	static readonly B = 'TAB-B: Jurisdictional Items';
	static readonly C = "TAB-C: Charging Party's/Complainant's Evidence";
	static readonly D = "TAB-D: Respondent's Evidence";
	static readonly E = 'TAB-E: Other Evidence/Miscellaneous Materials';
	static readonly N = 'TAB-N: ADR Draft Documents';
	static readonly F = 'TAB-F: Conciliation Documents';
	static readonly G = 'TAB-G: Legal Review Documents';
	static readonly H = 'TAB-H: ADR Mediation';
	static readonly I = 'TAB-I: Draft Documents';
	static readonly J = 'TAB-J: Mediation Case File Documents';
	static readonly K = 'TAB-K: Confidential Documents';
	static readonly L = 'TAB-L: Mediator Notes';
    static readonly M = 'TAB-M: Other ADR Documents'
	static readonly P = 'TAB-P: All ADR Documents';
	static readonly O = 'TAB-O: Compliance Monitoring';
}

export class DocTabs {
	static readonly ALL = 'All Documents';
	static readonly A = 'A-Field Office Work Product';
	static readonly B = 'B-Jurisdictional Items';
	static readonly C = "C-Charging Party's Evidence";
	static readonly D = "D-Respondent's Evidence";
	static readonly E = 'E-Miscellaneous';
	static readonly N = 'N-ADR Draft Documents';
	static readonly F = 'F-Conciliation Documents';
	static readonly P = 'All ADR Documents';
	static readonly G = 'G-Legal Review Documents';
	static readonly H = 'H-Mediation Documents';
	static readonly I = 'I-Draft Documents';
	static readonly J = 'J-Mediation Case File Documents';
	static readonly K = 'K-Mediation Confidential Documents';
	static readonly L = 'L-Mediator Notes';
    static readonly M = 'M-Other ADR Documents';
	static readonly O = 'O-Compliance Monitoring Documents';
}

export const POSEmailType = {
	POS_UPLOAD: 'posUpload',
	POS_RELEASE: 'posRelease',
	SUP_POS_RELEASE: 'supRelease',
	POS_RECALL: 'posRecall',
};
