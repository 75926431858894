<div class="text-center">
	<div class="service-error">
		<mat-icon class="icon">info</mat-icon>
		<h3 class="arc-h3"><strong>Service Error</strong></h3>

		<span
			>We're sorry for the problem. Please try to log in again. If the problem persists, contact your supervisor or tech
			help desk.</span
		>
		<div class="margin-top-30">
			<button
				class="log-out-button"
				type="button"
				mat-flat-button
				color="primary"
				appDebounceClick
				(debounceClick)="logout()"
			>
				Logout
			</button>
		</div>
	</div>
</div>
