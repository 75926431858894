import { SelectionModel } from '@angular/cdk/collections';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { BaseCase } from 'src/app/services/gen';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SharedCodeService } from 'src/app/services/shared-code.service';
import {
	AttorneySupervisorRoleCode,
	CONFIRM_DELETE_DOCUMENT,
	FepaSupervisorRoleCode,
	GlobalUserRoleCode,
	OFPAdminRoleCode,
	AdrStaffMediatorRoleCode,
	RecalTypes,
	ScrollStrategy,
	service_error,
	SupervisorRoleCode,
	LookUpStatusCode,
} from 'src/app/shared/common-structures/app.constants';
import { CaseSummary } from 'src/app/shared/common-structures/case-summary';
import { TokenUserInfo } from 'src/app/shared/common-structures/token-user-info';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { AlertService } from 'src/app/shared/ims-alerts/ims-alerts.service';
import { Utils } from 'src/app/shared/utility/utils';
import { CaseStatus, DocTabHeader, DocTabs, DocTypes } from '../../../documents/document';
import { PrivateDocumentsConfig } from '../../../documents/documents.config';
import { DocumentsService } from '../../../documents/documents.service';
import { DocumentsUtil, UserType } from '../../../documents/documents.util';
import { CaseDetailsService } from '../../case-details.service';
import { DocumentDialogComponent } from '../document-dialog/document-dialog.component';
import * as ExcelJS from 'exceljs';

@Component({
	selector: 'app-document-list',
	templateUrl: './document-list.component.html',
	styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
	@Input() parent: any;
	@Input() systemicCaseId: any;
	@Input() isSystemic: boolean = false;
	@Input() systemicCase: any;
	@Input() displayColumns: string[] = [
		'select',
		'title',
		'documentDescription',
		'tabType',
		'createdByFullName',
		'relatedDate',
		'createdDate',
		'availableTo',
		'nonDisclosable',
		'actions',
	];
	isBusy = true;
	initialSelection = [];
	allowMultiSelect = true;
	@Input() actions: boolean = false;
	@Input() documentTypes: any[] = [];
	selection = new SelectionModel<any>(this.allowMultiSelect, this.initialSelection);
	@Input() tabHeading;
	@Input() domain;
	@Input() userSelectedOfficeCode: string;
	@Input() caseDetail: BaseCase;
	@Input() rspLoginInfo: any[] = [];
	@Output() onInit: EventEmitter<any> = new EventEmitter<any>();
	userDetailsSubscription: Subscription;
	docSubscription: Subscription;
	userDetails: TokenUserInfo;
	allDocuments: any[];
	allDocumentsA: any[];
	allDocumentsB: any[];
	allDocumentsC: any[];
	allDocumentsD: any[];
	allDocumentsE: any[];
	allDocumentsF: any[];
	hasRole: boolean = false;
	maxFileSize = 1610612736; // bytes (1.5GB)
	isFileSizeExceedsMaxSize: boolean = false;;
	hasRetractRole: boolean = false;
	hasRetractRoleForADR: boolean = false;
	readOnly: boolean;
	@ViewChild('spinner', { static: true }) spinner: ElementRef;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	filters: any = {};
	imsLoader;

	dataSource: MatTableDataSource<CaseSummary> = new MatTableDataSource();
	documentDialogRef: MatDialogRef<DocumentDialogComponent>;
	caseStatus: CaseStatus;
	recallTypes = RecalTypes;

	enableSendToFepa: boolean = false;
	isPrivateUser: boolean = false;
	hasDeleteRight: boolean = false;
	hasInspectorGeneralRole: boolean = false;
	ariaResultAlert: number;

	/**
	 * @description injects necessary dependencies. Fetches common params and user details.
	 * @param: cmnServ services to get common params and user details
	 * @param: docServ web services related to all the documents
	 * @param: loader to hide/show app spinner
	 * @param: alrtServ to show alerts
	 * @param: renderer2
	 * @param: dialog
	 * @param: el
	 * @param: parent
	 */
	constructor(
		private _cdrRef: ChangeDetectorRef,
		public cmnServ: CommonService,
		private docServ: DocumentsService,
		private loader: LoadingService,
		private alrtServ: AlertService,
		private renderer2: Renderer2,
		public dialog: MatDialog,
		private el: ElementRef,
		private caseDetailsServ: CaseDetailsService,
		private sharedCodeServ: SharedCodeService
	) {
		this.imsLoader = this.loader;
		this.userDetailsSubscription = this.cmnServ.getUserDetails().subscribe((userInfo) => {
			this.userDetails = userInfo;
			//this.readOnly = userInfo.readOnly === 'Y'; //Temporarily commented
		});
		this.caseStatus = {
			isShareDocsToFEPA: false,
		};
	}

	ngOnChanges(changes) {
		if (changes.systemicCaseId) {
			this.getSystemicDocumentsById(this.systemicCaseId);
		}
		// commented out in case future use
		// if (changes.accountabilityOfficeCode) {
		// 	this.hasDeleteRight = (this.userDetails.authorities || []).some(x => x.toLowerCase().includes('ofp_admin') || x.toLowerCase().includes(`_supervisor:${this.accountabilityOfficeCode}`));
		// }
	}
	/**
	 * @description fetches all the documents based on the domain and filter/maps the documents according to its type.
	 */
	ngOnInit() {
		this.setupMatTable();
		if (!this.isSystemic) {
			this.hasRole =
				this.isSupervisor() ||
				this.hasGlobal() ||
				this.hasOFPRole() ||
				(!this.isCommissionerRoleHolder() && this.isCaseAssignedStaff());
			this.hasRetractRole = this.hasSupervisor() || this.hasOFPRole();
			this.hasRetractRoleForADR = (this.parent?.isAdrRoleHolder && !this.hasADRStaffMediator());
			this.isPrivateUser = this.caseDetail?.isFepa === false;
			this.hasInspectorGeneralRole = this.cmnServ.hasPrivateInspectorGeneralRole();
			this.getDocuments();
			this.setDeferralInfo();
		} else {
			this.getSystemicDocuments();
		}
	}

	/**
	 * @description setup filter logic
	 * instead of sending pairs like "column: filterValue", now filters is an object that holds multiple key-value pairs.
	 */
	ngAfterViewInit() {
		this._cdrRef.detectChanges();
		this.dataSource.filterPredicate = (data, filters) => {
			const rs = this.displayColumns.map((item) => {
				if (Utils.isNullCondition(filters[item])) {
					return true;
				}
				// get data from rows
				const rowValue = data[item] ? data[item] : '';
				const filterValue = filters[item];
				if (item === 'relatedDate' || item === 'createdDate') {
					const date = new Date(rowValue);
					const dateString = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
					return dateString.includes(filterValue);
				}
				return rowValue.toLowerCase().includes(filterValue.toLowerCase());
			});
			return rs.every(Boolean);
		};
	}

	transferDocuments() {
		if (!this.selection.selected || this.selection.selected.length === 0) {
			this.alrtServ.warn('Please select at least one document.');
			return;
		}
		if (
			this.selection.selected.every((x) => {
				return !(this.parent.autoGeneratedIncludedDocTypes.find((n) => n.documentCode === x.documentType) || {})[
					this.isPrivateUser ? 'isTransferableToFepa' : 'isTransferableFromFepa'
				];
			})
		) {
			this.alrtServ.warn(`The selected documents are not available to ${this.isPrivateUser ? 'FEPA' : 'EEOC'}`);
			return;
		}

		const data = {
			title: `Send Document(s) to ${this.isPrivateUser ? 'FEPA' : 'EEOC'}`,
			from: 'default',
			question:
				'When you send a document, the office receiving it will be able to view it. Are you sure you want to send the selected document(s) now?',
		};
		this.documentDialogRef = this.dialog.open(DocumentDialogComponent, {
			data,
			disableClose: true,
			width: '30%',
			scrollStrategy: ScrollStrategy,
			id: 'document-dialog',
		});
		this.documentDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((res) => {
				if (res) {
					this.validateBulkCopy(this.selection.selected);
				}
			});
	}

	private validateBulkCopy(docList: any[]) {
		DocumentsUtil.validateCaseStatus(this.caseDetail, this.alrtServ);
		// documents should be manually shared only if isShareDocsToFEPA is true and case is formalized
		if (this.caseDetail.formalizationDate) {
			const forqJoinRequestObject = [];
			const copyDocList: string[] = [];
			for (let index = 0; index < docList.length; index++) {
				forqJoinRequestObject.push(
					this.docServ.copyDocumentToFEPAOREEOC(
						docList[index].documentNodeId,
						this.caseDetail?.chargeNumber,
						this.isPrivateUser ? 'FEPA' : 'PRIVATE',
						this.userDetails,
						PrivateDocumentsConfig,
						this.parent?.deferralOffice?.deferralOfficeCode?.code
					)
				);
				copyDocList.push(docList[index].title);
			}
			this.copyBulkDocumentsToFEPA(forqJoinRequestObject, copyDocList);
		}
	}

	private copyBulkDocumentsToFEPA(forkJoinRequsts: any[], docList: string[]) {
		this.loader.show();
		forkJoin(forkJoinRequsts).subscribe(
			(res) => {
				this.loader.hide();
				res.map((v: any, k: number) => {
					if (!_.isEmpty(v)) {
						// check empty object using lodash
						this.alrtServ.success(v.title + ` is sent to ${this.isPrivateUser ? 'FEPA' : 'EEOC'} office.`);
					} else {
						// TODO:check status from response
						// Note: Backend validating document exists in EEOC office is a necessity.
						this.alrtServ.info(docList[k] + ` previously sent to ${this.isPrivateUser ? 'FEPA' : 'EEOC'}  Office.`);
					}
				});
				this.selection.clear();
				this.getLatestDocs();
			},
			(error) => {
				this.loader.hide();
				if (error.status === 422) {
					this.alrtServ.error(error?.error);
				} else {
					this.alrtServ.error(
						`Encountered an error while sending documents to ${this.isPrivateUser ? 'FEPA' : 'EEOC'} office.`
					);
				}
			}
		);
	}

	public isCommissionerRoleHolder = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode)?.length === 1 &&
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode)?.some((x) => x.indexOf('COMMISSIONER') >= 0);

	isCaseAssignedStaff = (): boolean => {
		let assigneeArray: string[] = [];
		if (this.caseDetail && this.caseDetail.chargeAssignments && this.caseDetail.chargeAssignments.length) {
			this.caseDetail.chargeAssignments.map((item) => {
				assigneeArray.push(item.userAssigned.userDetailId);
			});
		}
		return assigneeArray.includes(this.userDetails?.userId);
	};

	isNumaric() {
		let officeCode = this.caseDetail.accountabilityOffice.code || '';
		return officeCode && officeCode.replace(/[^0-9]/g, '').length === officeCode.length;
	}
	getSystemicDocuments(dealy = 10) {
		this.isBusy = true;
		setTimeout(() => {
			if (this.systemicCase && this.systemicCase.systemicCaseId) {
				this.docServ.getDocuments(this.systemicCase.systemicCaseId, 'SYSTEMIC').subscribe(
					(res) => {
						this.setTableData(res);
					},
					(err) => {
						this.loader.hide();
					}
				);
			} else {
				this.setTableData([]);
			}
		}, dealy);
	}
	setTableData(res) {
		this.allDocuments = res;
		this.dataSource.data = res || [];
		this.dataSource._updateChangeSubscription();
		this.onInit.emit(res);
		this.isBusy = false;
	}
	getSystemicDocumentsById(systemicCaseId) {
		this.systemicCase = { systemicCaseId };
		this.getSystemicDocuments();
	}
	getDocuments() {
		this.docSubscription = this.docServ.getDocumentsObservable().subscribe(
			(privateDocs) => {
				if (Utils.notNullCondition(privateDocs) && (privateDocs.key === this.caseDetail?.chargeNumber)) {
					this.allDocuments = privateDocs.value as Document[];
					let assignedUser = this.isCaseAssignedStaff();
					let showSensitive =
						(this.userDetails.authorities || []).filter(
							(x) =>
								assignedUser ||
								['ROLE_PRIVATE_OFP_ADMIN', 'ROLE_PRIVATE_SYS_ADMIN'].includes(x.split(':')[0]) ||
								[
									`ROLE_PRIVATE_SYSTEMIC_COORDINATOR:${this.userSelectedOfficeCode}`,
									`ROLE_PRIVATE_SUPERVISOR:${this.userSelectedOfficeCode}`,
								].includes(x)
						).length > 0;
					if (Utils.notNullCondition(this.allDocuments)) {
						this.allDocuments = this.allDocuments.filter(
							(x) =>
								this.parent.showSensitiveOption ||
								(x.documentType != 'THRDPYSENSITIVEDATA' &&
									x.documentType != 'RESPSENSTVDATASET' &&
									x.documentType !== 'Sensitive Data Set')
						);
						this.allDocuments.map((doc) => {
							if (doc.relationShipId) {
								if (doc.releaseToIndicator) {
									doc.releaseToIndicator = doc.releaseToIndicator + this.isPrivateUser ? ', FEPA' : ', EEOC';
								} else {
									doc.releaseToIndicator = this.isPrivateUser ? 'FEPA' : 'EEOC';
								}
							}
						});
					}
					let filteredData = this.allDocuments.filter((x) => !x.isMediation);
					const docTypes = [DocTypes.POS, DocTypes.POS_REDACT, DocTypes.SUPPL_POS, DocTypes.SUPPL_POS_REDACT];
					const hasRedact = filteredData.some((x) => x.documentType == DocTypes.POS_REDACT);
					filteredData.forEach((row) => {
						let posType = docTypes.includes(row.documentType);
						if (
							row &&
							row.availableTo &&
							((posType && row?.availableTo.includes('RSP')) ||
								row.createdByFullName.toLowerCase().includes('respondent'))
						) {
							row.availableTo = row.availableTo.replace(', RSP', '');
						}
						if (row && row.documentType == DocTypes.POS && !row.isPublished) {
							row.availableTo = row?.availableTo
								?.split(',')
								.map((x) => x.trim())
								.filter((x) => x != 'CP')
								.join(', ');
						}
					});

					const isChargeClosed = this.caseDetail?.status?.code?.toUpperCase()?.includes('CLOSED');
					switch (this.tabHeading) {
						case DocTabs.ALL:
							this.dataSource.data = filteredData.filter((value) => value.documentType !== 'RETRACTCHRGDOC');
							break;
						case DocTabs.A:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.A);
							break;
						case DocTabs.B:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.B);
							break;
						case DocTabs.C:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.C);
							break;
						case DocTabs.D:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.D);
							break;
						case DocTabs.E:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.E);
							break;
						case DocTabs.P:
							this.dataSource.data = this.allDocuments.filter(
								(x) => 
									(isChargeClosed && x.isRetainedMediation) ||
									(!isChargeClosed && (x.isMediation || x.isRetainedMediation))
							);
							break;
						case DocTabs.F:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.F);
							break;
						case DocTabs.G:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.G);
							break;
						case DocTabs.H:
							this.dataSource.data = this.allDocuments.filter((value) => value.tabType === DocTabHeader.H && ((isChargeClosed && value.isRetainedMediation) ||
								(!isChargeClosed && (value.isMediation || value.isRetainedMediation))));
							break;
						case DocTabs.I:
							this.dataSource.data = filteredData.filter((value) => value.tabType === DocTabHeader.I);
							break;
						case DocTabs.J:
							this.dataSource.data = this.allDocuments.filter((value) => value.tabType === DocTabHeader.J);
							break;
						case DocTabs.K:
							this.dataSource.data = this.allDocuments.filter((value) => value.tabType === DocTabHeader.K && ((isChargeClosed && value.isRetainedMediation) ||
								(!isChargeClosed && (value.isMediation || value.isRetainedMediation))));
							break;
						case DocTabs.L:
							this.dataSource.data = this.allDocuments.filter((value) => value.tabType === DocTabHeader.L && ((isChargeClosed && value.isRetainedMediation) ||
								(!isChargeClosed && (value.isMediation || value.isRetainedMediation))));
							break;
						case DocTabs.M:
							this.dataSource.data = this.allDocuments.filter((value) => value.tabType === DocTabHeader.M);
							break;	
						case DocTabs.N:
							this.dataSource.data = this.allDocuments.filter((value) => value.tabType === DocTabHeader.N);
							break;
						case DocTabs.O:
							this.dataSource.data = this.allDocuments.filter((value) =>  value.tabType === DocTabHeader.O && value.documentType === "COMPMONT");
							break;
						default:
							this.dataSource.data = filteredData;
					}
					this.dataSource._updateChangeSubscription();
					this.isBusy = false;
				}
			},
			(error) => {
				this.alrtServ.error('Error while retrieving documents. Please contact system administrator.');
			}
		);
	}

	getLatestDocs() {
		this.docServ
			.getDocuments(
				this.caseDetail?.chargeNumber,
				this.caseDetail?.isFepa ? 'FEPA' : 'PRIVATE',
				this.userSelectedOfficeCode
			)
			.subscribe(
				(privateDocs) => {
					if (!privateDocs) {
						this.docServ.setDocumentsObservable({key: this.caseDetail?.chargeNumber, value: []});
					} else {
						this.docServ.setDocumentsObservable({key: this.caseDetail?.chargeNumber, value: privateDocs});
						this.parent.processDocuments(privateDocs);
					}
				},
				(error) => this.alrtServ.error('Error happened while getting documents. Please contact system administrator.')
			);
	}

	/**
	 * @description filter content on one column
	 * @param: filterValue filter query
	 * @param: column column to filter on
	 * @todo put this function in a reusable location
	 */
	applyColumnFilter(filterValue: string, column: string) {
		this.filters[column] = filterValue;
		this.dataSource.filter = this.filters;
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}

		// For aria alert when user uses filter
		this.ariaResultAlert = this.dataSource.filteredData.length;
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected?.length;
		const numRows = this.allDocuments?.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle(ref) {
		// if there is a selection then clear that selection
		if (this.isSomeSelected(ref)) {
			this.selection.clear();
			ref.checked = false;
		} else {
			this.isAllSelected()
				? this.selection.clear()
				: this.dataSource.filteredData.forEach((row) => this.selection.select(row));
		}
		this.checkMaxSize(this.selection?.selected);
	}

	isSomeSelected(ref?) {
		if (this.selection.selected.length > 0 && _.isEmpty(ref)) {
			this.checkMaxSize(this.selection?.selected);
		}
		return this.selection.selected.length > 0;
	}

	checkMaxSize(selected) {
		this.isFileSizeExceedsMaxSize = false;
		let size = 0;
		selected.forEach((file) => {
			size = size + file.contentSize;
		})
		if(size >= this.maxFileSize) {
			this.isFileSizeExceedsMaxSize = true;
			this.alrtServ.warn('Size of the files exceeds the max limit(1.5gb).Please unselect few files.');
		}
	}

	downloadDocument(selectedDocument) {
		this.imsLoader.show();
		this.docServ
			.downloadFile(
				selectedDocument.documentNodeId,
				this.isPrivateUser ? 'PRIVATE' : 'FEPA',
				this.userDetails.user_name,
				this.caseDetail?.chargeNumber,
				this.hasInspectorGeneralRole ? false : true
			)
			.subscribe(
				(res) => {
					const fileName = res.headers.get('file-name');
					saveAs(res.body, fileName); // if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
					this.imsLoader.hide();
				},
				(error) => {
					this.imsLoader.hide();
					if (error.status === 422) {
						this.alrtServ.error(error?.error);
					} else {
						this.alrtServ.error('Error while downloading the document. Please contact system administrator.');
					}
				}
			);
	}

	/**
	 * @description Setup matTable. Including dataSource initialization, binding of paginator and sorter,
	 * and setup default sort.
	 */
	setupMatTable() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		// setup default sort
		const sortState: Sort = { active: 'relatedDate', direction: 'desc' };
		this.sort.active = sortState.active;
		this.sort.direction = sortState.direction;
		this.dataSource.sortingDataAccessor = (item, property): string | number => {
			if (property === 'createdDate' || property === 'relatedDate') {
				return item[property] ? new Date(item[property]).getTime() : 0;
			} else {
				return item[property];
			}
		};
	}

	/**
	 * @description checks if all the rows are selected then takes the datasource filtered data. if not it will take selection.selected list: this is due to some bug in angualr-material table selection directive
	 * loops through the download array and appends all the docs id to call the service, and saves the response from service as a zip file.
	 */
	downloadAsZip() {
		let downLoadArray;
		if (this.isAllSelected()) {
			downLoadArray = this.dataSource.filteredData as any;
		} else {
			downLoadArray = this.selection.selected as any;
		}
		if (!downLoadArray || downLoadArray.length === 0) {
			this.alrtServ.info('Please select documents to download.');
			return;
		}
		// const documentsNotDownloadable = downLoadArray.filter(d => !d.allowDownload);
		// if (documentsNotDownloadable.length === downLoadArray.length) {
		//   this.alrtServ.error('You are not authorized to download the selected documents.');
		//   return;
		// }
		let docid = '';
		for (let index = 0; index < downLoadArray.length; index++) {
			// if (downLoadArray[index].allowDownload) {
			if (index !== 0) {
				docid = docid + '~';
			}
			docid = docid + downLoadArray[index].documentNodeId;
			// }
		}
		this.imsLoader.show();
		this.download(docid, this.isPrivateUser ? 'PRIVATE' : 'FEPA', this.userDetails.user_name);
	}

	download(docId, userType, userName) {
		const isLogActivity = this.hasInspectorGeneralRole ? false : true
		this.docServ.downloadZip(docId, userType, userName, isLogActivity).subscribe(
			(res) => {
				const fileName = res.headers.get('file-name');
				// const blob = new Blob([res.blob()], { type: res.blob().type });
				saveAs(res.body, fileName); // using file-saver : https://www.npmjs.com/package/file-saver
				let remaingDocId = res.headers.get('remaining-document-list');
				if (remaingDocId) {
					this.download(remaingDocId, userType, userName);
				} else {
					this.imsLoader.hide();
					this.alrtServ.success('document/s successfully downloaded');
				}
			},
			(error) => {
				this.imsLoader.hide();
				if (error.status === 422) {
					this.alrtServ.error(error?.error);
				} else {
					this.alrtServ.error(service_error);
				}
			}
		);
	}

	/**
	 *
	 * @param selectedDocument : selected document to delete
	 * validate the document and confirm reason for deletion
	 */

	confirmDelete(selectedDocument) {
		if (!DocumentsUtil.validateDelete(selectedDocument, this.allDocuments, UserType.Private, this.alrtServ)) {
			return;
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: this.clone(CONFIRM_DELETE_DOCUMENT),
			disableClose: true,
			width: '50%',
			scrollStrategy: ScrollStrategy,
			id: 'confirm-delete-document-dialog',
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((deleteReason) => {
				if (deleteReason) this.deleteDocument(selectedDocument, deleteReason);
			});
	}
	isDeleteDisabled(row) {
		return (
			(this.isPrivateUser &&
				(this.parent.readOnlyCharge ||
					(!['INQUIRY_SUBMITTED', 'CHARGE_PREPARED'].includes(this.caseDetail?.status?.code) &&
						(row.documentType == 'PRVCOD' ||
							row.documentType == 'PRVNOCPRFCTD' ||
							row.documentType == 'PRVNOCUNPRFCTD')) ||
					this.parent.isDocumentPublished(row))) ||
			(!this.isPrivateUser && (this.parent.readOnlyCharge || row.documentType == 'PRVCOD'))
		);
	}
	clone(obj) {
		return JSON.parse(JSON.stringify(obj));
	}
	/**
	 *
	 * @param selectedDocument : selected document to delete
	 * api request to delete the document
	 */

	deleteDocument(selectedDocument, deleteReason: string) {
		this.loader.show();
		this.docServ.deleteDocument(selectedDocument?.documentNodeId?.split(';')[0], deleteReason).subscribe(
			(res) => {
				this.loader.hide();
				this.alrtServ.success('Document deleted successfully');
				if (!this.isSystemic) {
					this.docServ.documentsChange$.next(true);
				} else {
					this.getSystemicDocuments();
				}
			},
			(error) => {
				this.loader.hide();
				if (error.status === 422) {
					this.alrtServ.error(error?.error);
				} else {
					this.alrtServ.error('Encountered an error while deleting documents.  Please contact system administrator.');
				}
			}
		);
	}

	/**
	 * @description assign age to charges and return it. Assignment is necessary for sort.
	 * @param element charges object
	 * @param date date serial
	 */
	getDataFields(element, x) {
		const date = new Date(element.x);
		element.x = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
		return element.x;
	}

	/**
	 * @description filters all documents according to the tab heading; maps the document object fields to sync with table and exports it as sheets in one workbook.
	 * @param $event
	 */
	exportToExcel($event) {
		this.loader.show();
		const fileName = this.caseDetail?.chargeNumber + '.xlsx';
		this.allDocumentsA = this.allDocuments.filter((value) => value.tabType === DocTabHeader.A);
		this.allDocumentsB = this.allDocuments.filter((value) => value.tabType === DocTabHeader.B);
		this.allDocumentsC = this.allDocuments.filter((value) => value.tabType === DocTabHeader.C);
		this.allDocumentsD = this.allDocuments.filter((value) => value.tabType === DocTabHeader.D);
		this.allDocumentsE = this.allDocuments.filter((value) => value.tabType === DocTabHeader.E);
		const workbook = new ExcelJS.Workbook();
		this.addSheet(workbook, this.allDocuments, 'All Documents');
		this.addSheet(workbook, this.allDocumentsA, 'A-Field Office Work Product');
		this.addSheet(workbook, this.allDocumentsB, 'B-Jurisdictional Items');
		this.addSheet(workbook, this.allDocumentsC, `C-Charging Party's Evidence`);
		this.addSheet(workbook, this.allDocumentsD, `D-Respondent's Evidence`);
		this.addSheet(workbook, this.allDocumentsE, 'E-Miscellaneous');
		this.loader.hide();
		workbook.xlsx.writeBuffer().then((buffer: any) => {
			const blob = new Blob([buffer], {
			  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
		  });
		  saveAs(blob, fileName);
		  });
		
	}

	private addSheet(workbook: ExcelJS.Workbook, data: any[], sheetName: string) {
		const worksheet = workbook.addWorksheet(sheetName);
		if ( data && data.length <= 0) {
			return;
		}
		const headers = Object.keys(data[0]);
		worksheet.addRow(headers);
		data.forEach((item) => {
			const row: any = [];
			headers.forEach((header) => {
				row.push(item[header]);
			});
			worksheet.addRow(row);
		});
	}

	/**
	 * @description maps all the objects to have the same properties as datasource
	 * @param docs: array of documents according to the tab.
	 */
	mapDataFieldsToTable(docs: any[]) {
		let documentTableArray = [];
		docs.forEach((x) => {
			const doc = {
				'Document Title': x.title,
				'Document Type': x.documentDescription,
				'Uploaded By': x.createdByFullName,
				'Received Date': x.relatedDate,
				'Created Date': this.cmnServ.displayEsttoLocale(x.createdDate),
				'Available To':
					!this.displayRelease(x) && x.availableTo && !(!this.enableSendToFepa && x.availableTo === 'FEPA')
						? x.availableTo
						: 'N/A',
			};
			documentTableArray.push(doc);
		});
		return documentTableArray;
	}

	setDeferralInfo() {
		if (this.parent?.deferralOffice?.deferralOfficeCode?.code !== '997') {
			this.enableSendToFepa = true;
		} else this.enableSendToFepa = false;
	}

	public isDisabled(document) {
		return (
			!this.hasRole ||
			(document.documentType === DocTypes.POS && this.allDocuments.some((x) => x.documentType === DocTypes.POS_REDACT) || (this.isSupervisor() && document.documentType === 'PRVCLOSNOT' && !this.caseDetail?.closureDate))
		);
	}

	public displayRelease(document) {
		let conditions = [
			document.documentType !== DocTypes.POS ||
				(document.documentType === DocTypes.POS &&
					!this.allDocuments.some((x) => x.documentType === DocTypes.POS_REDACT)),
			!document?.documentDescription?.toLowerCase()?.includes('notes'),
			!this.parent.isDocumentPublished(document),
			this.isPrivateUser,
			!this.isSystemic && !this.caseDetail.isFepa,
			document?.type?.isDownloadableByChargingParty || document?.type?.isDownloadableByRespondent,
			!this.parent.disableRelease,
		];
		return conditions.every((x) => x);
	}

	public isSupervisor = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(SupervisorRoleCode);
	public hasSupervisor = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(SupervisorRoleCode) ||
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(FepaSupervisorRoleCode);
	public hasGlobal = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(GlobalUserRoleCode);

	public hasOFPRole = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(OFPAdminRoleCode);

	public hasADRStaffMediator = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(AdrStaffMediatorRoleCode);

	public isAttorneySupervisor = (): boolean => {
		let roles = this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode);
		return roles.length == 1 && roles[0] == AttorneySupervisorRoleCode;
	};

	isDownloadDisabled(): boolean { 
		return !this.hasInspectorGeneralRole && this.parent?.readOnlyAccess; 
	}

	disableRetract(row): boolean {
		const hasCod = this.allDocuments.some((value) => value.documentType === DocTypes.COD);
		return ([DocTypes.COD_FORM5A, DocTypes.PCOD].includes(row.documentType) && !hasCod && (this.caseDetail?.nocServiceDate as any || this.rspLoginInfo.length > 0));
	}


	/**
	 * @description unsubcribes to any behaviour subject subscriptions if exists.
	 */
	ngOnDestroy(): void {
		if (this.userDetailsSubscription) {
			this.userDetailsSubscription.unsubscribe();
		}
		if (this.docSubscription) {
			this.docSubscription.unsubscribe();
		}
	}
	toggleDocumentDisclosable(row) {
		this.parent?.toggleDocumentDisclosable(row, this);
	}
	showRowEditDialog(row) {
		this.parent?.showRowEditDialog(row, this);
	}
}

