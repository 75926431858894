import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-close-mat-dialog',
	templateUrl: './close-mat-dialog.component.html',
	styleUrls: ['./close-mat-dialog.component.scss'],
})
export class CloseMatDialogComponent {
	@Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

	onClose() {
		this.closeDialog.emit();
	}
}
