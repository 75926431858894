import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from '../utility/utils';

@Component({
	selector: 'confrm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
	public alertSize = 'large';
	public confirmed = false;
	public options = ['Uploaded to wrong charge', 'Duplicate Document', 'Wrong Document Version'];
	constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit() {}

	disableContinueButton() {
		return this.data.confirmationMessage
			? !this.confirmed
			: this.data.textarea || this.data.showOptions
			? Utils.isEmptyCondition(this.data.reason?.trim())
			: false;
	}

	onClose() {
		this.dialogRef.close();
	}
}
