import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {	
	MsalBroadcastService,
	MsalGuardConfiguration,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalService,	
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DocumentsModule } from './features/modules/documents/documents.module';
import { ChargeDialogComponent } from './features/modules/dual-filing/charge-dialog/charge-dialog.component';
import { ChargeDocDialogComponent } from './features/modules/dual-filing/charge-doc-dialog/charge-doc-dialog.component';
import { DualFilingModule } from './features/modules/dual-filing/dual-filing.module';
import { EmployerRepresentativesModule } from './features/modules/employer-representatives/employer-representatives.module';
import { IIGNewInquiryModule } from './features/modules/iig-new-inquiry/igg-new-inquiry.module';
import { NewCaseModule } from './features/modules/new-case/new-case.module';
import { NewInquiryModule } from './features/modules/new-inquiry/new-inquiry.module';
import { ReportsModule } from './features/modules/reports/reports.module';
import { SearchModule } from './features/modules/search/search.module';
import { SltpContractsModule } from './features/modules/sltp-contracts/sltp-contracts.module';
import { UserManagementModule } from './features/modules/user-management/user-management.module';
import { msalConfig } from './msalConfig';
import { AuthGuard } from './services/auth.guard';
import { BASE_PATH as ECM_WS_BASE_PATH } from './services/EcmWebService';
import { BASE_PATH as EMAIL_WS_BASE_PATH } from './services/EmailWebService';
import { ExcelService } from './services/excel.service';
import { BASE_PATH as PREPA_BASE_PATH } from './services/gen';
import { BASE_PATH as LITIGATION_WS_BASE_PATH } from './services/LitigationWebService';
import { LoadingComponent } from './services/loading/loading.component';
import { LoadingService } from './services/loading/loading.service';
import { BASE_PATH as SEARCH_DATA_WS_BASE_PATH } from './services/SearchDataWebService';
import { BASE_PATH as TEMPLATE_MANAGEMENT_WS_BASE_PATH } from './services/TemplateMangementWebService';
import { BASE_PATH as USER_MANAGEMENT_WS_BASE_PATH } from './services/UserManagement/variables';
import { AppContactCardComponent } from './shared/app-contact-card/app-contact-card.component';
import { AppFooterComponent } from './shared/app-footer/app-footer.component';
import { AppHeaderComponent } from './shared/app-header/app-header.component';
import { AppIdleComponent } from './shared/app-idle/app-idle.component';
import { AppLoginDialogComponent } from './shared/app-login-dialog/app-login-dialog.component';
import { AppNotfoundComponent } from './shared/app-notfound/app-notfound.component';
import { AppStaffCardComponent } from './shared/app-staff-card/app-staff-card.component';
import { AppUpdateTokenComponent } from './shared/app-update-token/app-update-token.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';
import { ImsAlertsComponent } from './shared/ims-alerts/ims-alerts.component';
import { AlertService } from './shared/ims-alerts/ims-alerts.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { NaicsDialogComponent } from './shared/naics-dialog/naics-dialog.component';
import { ServiceErrorDialogComponent } from './shared/service-error-dialog/service-error-dialog.component';
import { SharedMaterialModule } from './shared/shared-material.module';
import { TimeOutMessageComponent } from './shared/time-out-message.component';
import { AppValidators } from './shared/validators/appValidators';
import { appReducer } from '../app/store/app.state';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResourceMap: [string, string[]][] = [['https://graph.microsoft.com/v1.0/me', ['user.read']]];

declare module "@angular/core" {
	interface ModuleWithProviders<T = any> {
	  ngModule: Type<T>;
    providers?: Array<Provider | EnvironmentProviders>;
	}
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
	  auth: {
		clientId: msalConfig.clientId,
		// clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
		authority: `https://login.microsoftonline.com/${msalConfig.tenantId}`,
		// authority: 'https://login.windows-ppe.net/common', // PPE testing environment
		redirectUri: environment.redirectUri,
		postLogoutRedirectUri:environment.postLogoutRedirectUri
	  },
	  cache: {
		cacheLocation: BrowserCacheLocation.SessionStorage,
		storeAuthStateInCookie: isIE, // set to true for IE 11
	  },
	  system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
		  loggerCallback,
		  logLevel: LogLevel.Info,
		  piiLoggingEnabled: false
		}
	  }
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
	// protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']); // PPE testing environment
  
	return {
	  interactionType: InteractionType.Redirect,
	  protectedResourceMap
	};
}

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return { interactionType: InteractionType.Redirect };
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedMaterialModule,
        HttpClientModule,
        FormsModule,
        NgIdleModule.forRoot(),
        CommonModule,
        DocumentsModule,
        StoreModule.forRoot(appReducer, { runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: true, // Restrict extension to log-only mode
        }),
 
        MsalModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalBroadcastService,
        {
            provide: PREPA_BASE_PATH,
            useValue: environment.imsnxgPrepaurl,
        },
        {
            provide: SEARCH_DATA_WS_BASE_PATH,
            useValue: environment.searchdata,
        },
        {
            provide: LITIGATION_WS_BASE_PATH,
            useValue: environment.litigation,
        },
        {
            provide: ECM_WS_BASE_PATH,
            useValue: environment.ecmurl,
        },
        {
            provide: USER_MANAGEMENT_WS_BASE_PATH,
            useValue: environment.umws,
        },
        {
            provide: EMAIL_WS_BASE_PATH,
            useValue: environment.emws,
        },
        {
            provide: TEMPLATE_MANAGEMENT_WS_BASE_PATH,
            useValue: environment.tms,
        },
        GlobalErrorHandler,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        LoadingService,
        AlertService,
        DatePipe,
        AppValidators,
        MsalService,
        ExcelService,
        AuthGuard,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
    declarations: [
        ImsAlertsComponent,
        ConfirmDialogComponent,
        AppHeaderComponent,
        AppFooterComponent,
        AppComponent,
        TimeOutMessageComponent,
        LoadingComponent,
        AppNotfoundComponent,
        AppIdleComponent,
        AppUpdateTokenComponent,
        AppLoginDialogComponent,
        AppStaffCardComponent,
        AppContactCardComponent,
        ServiceErrorDialogComponent,
        NaicsDialogComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

/**
 * SearchModule,
        EmployerRepresentativesModule,
        DualFilingModule,
        UserManagementModule,
        NewInquiryModule,
        SltpContractsModule,
        NewCaseModule,
        IIGNewInquiryModule,
         ReportsModule,
 */