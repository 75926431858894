import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { LookUpStatusCode, SupervisorRoleCode } from 'src/app/shared/common-structures/app.constants';
import * as _ from 'lodash';
import  moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { ActivityLogVO } from 'src/app/services/EmailWebService';
import { BaseCase, DeferralInfoVO, DocumentTypeVO, ImsDocumentRequest, LookupData } from 'src/app/services/gen';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { SharedCodeService } from 'src/app/services/shared-code.service';
import {
	CONFIRM_COD_DRAFT_MISSING,
	LookUpDataTypes,
	minimumDate,
	RELEASE_DOCUMENT,
	ScrollStrategy,
	SensitiveTypes,
	service_error,
} from 'src/app/shared/common-structures/app.constants';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { AlertService } from 'src/app/shared/ims-alerts/ims-alerts.service';
import { ArrayUtils } from 'src/app/shared/utility/arrayUtils';
import { StringUtils } from 'src/app/shared/utility/stringUtils';
import { Utils } from 'src/app/shared/utility/utils';
import { CaseStatus, DocTypes, Document, FileToUpload } from '../../../documents/document';
import { DocumentsConfig } from '../../../documents/documents.config';
import { DocumentsService } from '../../../documents/documents.service';
import { CaseDetailsService } from '../../case-details.service';
import { DocumentDialogComponent } from '../document-dialog/document-dialog.component';
import { SharedPartiesService } from '../../../shared-edit-forms/shared-parties.service';

@Component({
	selector: 'app-upload-documents',
	templateUrl: './upload-documents.component.html',
	styleUrls: ['./upload-documents.component.scss'],
})
export class UploadDocumentsComponent implements OnInit {
	@ViewChild('myFileInput', { static: false }) myFileInput;
	@Input() parent: any;
	@Input() documents: any[] = [];
	@Input() caseDetail: any;
	@Input() systemicCase: any = {};
	@Input() userSelectedOfficeCode: string;
	@Input() assignedStaffArray: any[] = [];
	@Input() allegationsCount: number = 0;
	@Input() readOnlyCharge: boolean;
	@Input() isAdrRoleHolder: boolean;
	@Input() isCaseManager: boolean = false;
	@Input() showSensitiveOption: boolean = false;
	@Input() deferralOffice: DeferralInfoVO;
	@Input() disableRelease: boolean = false;
	@Input() isSystemic: boolean = false;
	@Input() hasDocumentLogDownloadPermission = false;

	minDate = minimumDate;
	maxDate = new Date();
	accept: boolean = false;
	filesToUpload: FileToUpload[] = [];
	autoGeneratedIncludedDocTypes: DocumentTypeVO[] = [];
	autoGeneratedExcludedDocTypes: DocumentTypeVO[] = [];
	autocompleteCount: number;
	isModal: boolean = true;
	maxFileSize = 1610612736; // bytes (1.5GB)
	invalidFileTypes = ['exe', 'msi', 'gwi', 'lnk'];
	isRequestForInfoValid = true;

	@Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() docChange: EventEmitter<any> = new EventEmitter<any>();

	stdTemplates: string[] = ['CODCL', 'CODUCL', 'TRANSCASEDOJ', 'TRANSCASEDOJFORNRTS'];
	readOnly: boolean;
	adHocFields: any = {};
	adHocFieldsList: any[] = [];
	adHocFieldsIndexByField: any = {};

	docs: Document[];
	@Input() categoryTypes: any[] = [];
	counts: any = { all: 0, a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0, m: 0 };
	caseStatus: CaseStatus = { isShareDocsToFEPA: false };
	chargeOfDiscriminationDocument: Document;
	CODUnperfectedExist: Document;
	requestForInformationDocs: Document[];
	responseToReqForInfoDocs: Document[];
	positionStatement: Document;
	hasCod: boolean = false;
	posRedacted: Document;
	noticeOfChargeDocument: Document;
	relatedDateToBeEdited: Date;
	documentAfterEdit: Document;
	documentToResend: Document;
	documentDialogRef: MatDialogRef<DocumentDialogComponent>;
	isPrivate: boolean;
	@Input() documentsConfig: any;
	emailNotes: ActivityLogVO[];
	// subscriptions
	observer$ = new BehaviorSubject(true);
	hasClosureReason: boolean = false;
	isTemplateAdmin: boolean = false;
	isDisclosable: boolean = false;
	dialogRef: MatDialogRef<any>;
	allDocuments: Document[];
	docSubscription: Subscription;
	@Input() documentTypes: any[] = [];
	allDocumentTypes: any[] = [];
	replaceDoc: Document;
	replaceDialogRef: MatDialogRef<any>;
	replaceReasons: any[] = [];
	selectednoIntakeInterviewReason: string = '';
	noIntakeInterviewReasons: LookupData[] = [];
	@Output() onClose: EventEmitter<any> = new EventEmitter<any>();
	isIntakeNotes: boolean = false;
	isIntakeDocument: boolean = false;
	noIntakedialogRef: MatDialogRef<any>;
	noIntakeIntrialog: boolean;
	filteredDocumentTypes: any[] = [];
	constructor(
		public dialog: MatDialog,
		public cmnServ: CommonService,
		private alrtServ: AlertService,
		private docServ: DocumentsService,
		private imsLoader: LoadingService,
		private csDtlsServ: CaseDetailsService,
		private sharedCodeServ: SharedCodeService,
	) { }

	ngOnInit(): void { }

	public isSupervisorRoleHolder = (): boolean =>
		this.cmnServ.getOfficeBasedRoles(this.userSelectedOfficeCode).includes(SupervisorRoleCode);

	addDate(event, idx) {
		if (Utils.notNullCondition(this.filesToUpload)) {
			this.filesToUpload[idx].relatedDate = event;
		}
	}

	/**
	 * @description This function is to handle the keyboard interaction. With using
	 * 	onSelectionChange, it calls the function twice, so a check is needed for .selected
	 * 	to ensure a value is there
	 * @param evt Keyboard event
	 * @param idx param used for onDocumentTypeSelect Function
	 * @param documentCode param used for onDocumentTypeSelect Function
	 */
	onDocumentTypeSelectKeyboard(evt, idx, documentCode) {
		if (evt.source.selected) {
			this.onDocumentTypeSelect(idx, documentCode);
		}
	}

	/**
	 * @description reads the contents of the file uploaded and makes an array of files uploaded
	 * @param: e emitted while uploading a file
	 */
	uploadFile(e, drag: boolean = false) {
		const files = drag ? e : e.target.files;
		for (let file of files) {
			if (Utils.notNullCondition(file)) {
				const fileExtension = StringUtils.getFileExtension(file.name);
				this.isRequestForInfoValid = true;
				if (
					ArrayUtils.search(fileExtension, this.invalidFileTypes) === -1 &&
					file.size < this.maxFileSize &&
					file.name.lastIndexOf('.') >= 0
				) {
					const fr = new FileReader();
					const curTime = new Date();
					const curDate = new Date(curTime.getFullYear(), curTime.getMonth(), curTime.getDate());
					fr.readAsText(file);
					fr.addEventListener('load', () => {
						const file_: FileToUpload = {
							file: file,
							name: file.name,
							filename: file.name,
							size: file.size / 1000 + ' KB',
							uploadedDate: file.timeStamp,
							content: fr.result,
							doctype: '',
							description: '',
							tabtype: '',
							docExt: fileExtension,
							relatedDate: curDate,
							typeInvalid: false,
							filteredDocumentTypes: _.cloneDeep(this.autoGeneratedExcludedDocTypes),
						};
						this.filesToUpload.push(file_);
						this.getSharedCodes();
						this.checkIfNoIntakeInterview();
					});
				} else {
					this.alrtServ.error('Failed to open file. Unsupported file type or exceed maximum size limit.');
				}
			}
		}
		this.myFileInput.nativeElement.value = '';
	}

	onDocumentTypeSelect(idx, documentCode) {
		// get the corresponding categoryName from the list
		const selectedDocumentType = this.autoGeneratedExcludedDocTypes.find((item) => item.documentCode === documentCode);
		this.filesToUpload[idx].doctype = selectedDocumentType.documentCode;
		this.filesToUpload[idx].tabtype = selectedDocumentType.documentTab?.description;
		this.filesToUpload[idx].typeInvalid = false;
	}

	updateFileFolderTypes(idx, file) {
		if (!file.description) {
			this.filesToUpload[idx].doctype = null;
			this.filesToUpload[idx].tabtype = null;
			this.filesToUpload[idx].typeInvalid = true;
			this.filesToUpload[idx].filteredDocumentTypes = this.autoGeneratedExcludedDocTypes;
		}
	}

	onFolderSelect(idx) {
		this.filesToUpload[idx].description = '';
		this.filesToUpload[idx].doctype = '';
		this.filesToUpload[idx].filteredDocumentTypes = this.filesToUpload[idx].tabtype
			? this.autoGeneratedExcludedDocTypes.filter(
				(type) => type.documentTab?.description === this.filesToUpload[idx].tabtype
			)
			: this.autoGeneratedExcludedDocTypes;
		this.filesToUpload[idx].typeInvalid = true;
	}

	/**
	 * @description method to remove selected file from the list of file array
	 * @param: file that needs to be removed.
	 */
	removeUpload(file) {
		const index = ArrayUtils.search(file, this.filesToUpload);
		ArrayUtils.removeBySplicing(index, this.filesToUpload, 1);
	}

	/**
	 * @description removes all elements from uploaded files array
	 */
	removeAll() {
		this.filesToUpload = [];
	}

	public _filterDocTypesOnInput(file): string[] {
		const hasCpForm5A: boolean = this.documents.some((x) => x.documentType == DocTypes.FORM5A_CP);
		// Filter document types based on conditions
		if (this.parent?.isPrivate) {
			// If parent is private, use file.filteredDocumentTypes directly
			if(this.caseDetail?.status?.code === LookUpStatusCode.INQUIRY_SUBMITTED && !hasCpForm5A) {
			  this.filteredDocumentTypes = file?.filteredDocumentTypes;
			} else {
				this.filteredDocumentTypes = file?.filteredDocumentTypes.filter(x => x.documentCode !== DocTypes.FORM5A_CP);
			}
		} else {
			// Otherwise, filter based on condition
			this.filteredDocumentTypes = file.filteredDocumentTypes.filter(x => ![DocTypes.COD_FORM5A, DocTypes.FORM5A_CP].includes(x.documentCode));
		}

		// Check if case detail status is not in specified codes
		if (![LookUpStatusCode.INQUIRY_SUBMITTED, LookUpStatusCode.CHARGE_PREPARED].includes(this.caseDetail?.status?.code)) {
			// Filter further based on condition
			this.filteredDocumentTypes = this.filteredDocumentTypes.filter(type => ![DocTypes.COD_FORM5A, DocTypes.PCOD].includes(type?.documentCode));
		}
		if (!file.description) return this.filteredDocumentTypes;
		const filterValue = file.description.toLowerCase();
		const filteredResults = this.filteredDocumentTypes?.filter((el) =>
			el?.documentDescription?.toLowerCase()?.includes(filterValue)
		);
		this.autocompleteCount = filteredResults?.length;
		return filteredResults;
	}

	/**
	 * @description Get respondent info from web service
	 */
	hasRespondentInfo(): Observable<string> {
		const result = new Subject<string>();
		this.csDtlsServ.loadRespondent(this.caseDetail?.chargeInquiryId).subscribe(
			(res) => {
				if (!res) {
					result.next('Respondent is required');
				} else if (!res.employeeCount) {
					result.next('No Empoyee Count found. Please add Employee Count to respondent.');
				} else if (!res.type) {
					result.next('No Institution Type found. Please add Institution Type to respondent.');
				} else {
					result.next(null);
				}
				result.complete();
			},
			(error) => {
				result.next('Error occurred while getting the respondent details.');
				result.complete();
			}
		);
		return result;
	}

	upload(event) {
		if (this.validateUpload()) {
			this.uploadDocuments();
		}
	}

	/**
	 * validating the upload file
	 */
	validateUpload() {
		let notValid = false;
		let includesCOD = false;
		let includesForm5A = false;
		let includesNOC = false;
		let includesPOS = false;
		let includesRFI = false;
		this.isRequestForInfoValid = true;
		const hasAmendedCod = this.documents.some((x) => x.documentType == DocTypes.AmendedCOD);

		// user must provide doc type for all docs
		for (let file of this.filesToUpload) {
			if (!file.doctype) {
				notValid = true;
				file.typeInvalid = true;
			} else {
				file.typeInvalid = false;
			}
		}
		if (notValid) {
			return false;
		}

		for (let i = 0; i < this.filesToUpload.length; i++) {
			const curRelatedDate = new Date(this.filesToUpload[i].relatedDate);
			if (curRelatedDate < new Date('1/1/1990')) {
				this.alrtServ.warn('Received Date cannot be before 1990.');
				return false;
			}
			if (curRelatedDate > this.maxDate) {
				this.alrtServ.warn('Received Date cannot be in the future.');
				return false;
			}
			// check if there are any docs of type COD. Assuming only one COD exists
			// assuming only one "Position Statemnt" exists
			this.positionStatement = this.documents.find((value) => value.documentType === DocTypes.POS);
			this.hasCod = this.documents.some((value) => value.documentType === DocTypes.COD);
			// assuming only one "Position Statemnt Redacted" exists
			this.posRedacted = this.documents.find((value) => value.documentType === DocTypes.POS_REDACT);
			if (this.caseDetail?.formalizationDate && !this.hasCod &&  !hasAmendedCod && this.filesToUpload[i].doctype === DocTypes.AmendedCOD) {
				this.alrtServ.warn('Please upload COD before uploading Amended COD.');
				return;
			}
			if (this.filesToUpload[i].doctype === DocTypes.FORM5A_CP) {
				if (includesForm5A) {
					this.alrtServ.warn('There can only be one Form 5A to PCP Letter Uploaded.');
					return false;
				}
				includesForm5A = true;
			}
			if (this.filesToUpload[i].doctype === DocTypes.COD) {
				if (includesCOD) {
					this.alrtServ.warn('There can only be one Charge of Discrimination Letter.');
					return false;
				}
				// set includesCOD flag to true if it sees doctype as COD
				includesCOD = true;
				// if COD is already present in the existing documents
				if (this.chargeOfDiscriminationDocument) {
					this.alrtServ.warn(
						'There can only be one Charge of Discrimination Letter Uploaded. ' +
						'If you want to upload a new one please delete the existing one.'
					);
					return false;
				}
			}

			// cannot upload POS Redacted before POS
			if (this.filesToUpload[i].doctype === DocTypes.POS_REDACT) {
				if (!this.positionStatement) {
					this.alrtServ.warn('Please upload Position Statement before uploading Position Statement Redacted.');
					return false;
				}
			}

			if (
				this.filesToUpload[i].doctype === DocTypes.SUPPL_POS ||
				this.filesToUpload[i].doctype === DocTypes.SUPPL_POS_REDACT
			) {
				if (!this.positionStatement && !this.posRedacted) {
					this.alrtServ.warn('Please upload Position Statement before uploading Supplemental Position Statement.');
					return false;
				}
			}

			if (this.filesToUpload[i].doctype === DocTypes.NOC) {
				// return if the uploading documents have doctype as NOC more than once
				if (includesNOC) {
					this.alrtServ.warn('There can only be one Notice of Charge.');
					return false;
				}
				// set includesNOC flag to true if it sees doctype as NOC
				includesNOC = true;
				// if NOC is already present in the existing documents
				if (this.noticeOfChargeDocument) {
					this.alrtServ.warn(
						'There can only be one Notice of Charge Uploaded. ' +
						'If you want to upload a new one please delete the existing one.'
					);
					return false;
				}
			}
			if (this.filesToUpload[i].doctype === DocTypes.POS) {
				// return if the uploading documents have doctype as POS more than once
				if (includesPOS) {
					this.alrtServ.warn('There can only be one Position Statement.');
					return false;
				}
				// set includesCOD flag to true if it sees doctype as POS
				includesPOS = true;
				// if POS is already present in the existing documents
				if (this.positionStatement) {
					this.alrtServ.warn(
						'There can only be one Position Statement Uploaded. ' +
						'If you want to upload a new one please delete the existing one.'
					);
					return false;
				}
			}
			if (this.filesToUpload[i].doctype === DocTypes.RFI) {
				if (this.filesToUpload[i].docExt.toUpperCase() !== 'PDF') {
					this.isRequestForInfoValid = false;
					return false;
				}
			}
		}
		return true;
	}

	// method to upload files sequentially to avoid errors caused by
	// multiple service calls trying to create the same direectory in Alfresco
	private uploadDocuments() {
		if (this.isSystemic) return this.uploadSystamic(0);
		const isCodDraftExist: boolean = this.documents.some(
			(x) => x.documentType == DocTypes.COD_DRAFT || x.documentType == DocTypes.COD || x.documentType === DocTypes.COD_FORM5A
		);
		const isUploadingCod: boolean = this.filesToUpload.some((x) => x.doctype == DocTypes.COD);
		const isUploadingAmended: boolean = this.filesToUpload.some((x) => x.doctype == DocTypes.AmendedCOD);
		const isUploadingCodUnVerified: boolean = this.filesToUpload.some((x) => x.doctype == DocTypes.PCOD);
		const isUploadingCodForm5A: boolean = this.filesToUpload.some((x) => x.doctype == DocTypes.COD_FORM5A);
		const hasCodDraft: boolean = this.documents.some((x) => x.documentType == DocTypes.COD_DRAFT);

		let receivingCodes = ['RCVFRM-ADC', 'RCVFRM-DEPA', 'RCVFRM-PPC', 'RCVFRM-DADEA', 'RCVFRM-AP'];
		let directedCodes = ['RCVFRM-DEPA', 'RCVFRM-DADEA'];
		let receivingFormCode = this.caseDetail?.offlineInfo?.receivedFrom?.code ?? '';
		if (isUploadingCod && !this.parent?.isPrivate && this.allegationsCount == 0) {
			this.alrtServ.warn('No Allegations Found. Please add at least one allegation in order to formalize the charge.');
			return;
		}
		if (isUploadingAmended) {
			if (!isCodDraftExist && !directedCodes.includes(receivingFormCode)) {
				this.alrtServ.warn(
					'Please upload Charge of Discrimination before uploading the Amended Charge of Discrimination.'
				);
				return;
			}
		}
		if ((isUploadingCod || isUploadingCodUnVerified || isUploadingCodForm5A) && this.parent?.isPrivate) {
			this.hasRespondentInfo().subscribe((res) => {
				if (!res) {
					if (!isUploadingCodForm5A &&
						(
							(!receivingCodes.includes(receivingFormCode) && !isCodDraftExist) ||
							(isUploadingCodUnVerified && receivingFormCode !== 'RCVFRM-ADC' && !hasCodDraft)
						)
					) {
						this.dialog
							.open(ConfirmDialogComponent, {
								data: CONFIRM_COD_DRAFT_MISSING,
								width: '40%',
								disableClose: true,
								scrollStrategy: ScrollStrategy,
								id: 'confirm-cod-draft-missing-dialog',
							})
							.afterClosed()
							.pipe(take(1))
							.subscribe((result) => {
								if (result) {
									this.onTabChange.emit('CHARGE FINALIZATION');
								}
							});
					} else {
						this.uploadDocumentsRec(0, 0);
					}
				} else {
					this.alrtServ.warn(res);
				}
			});
		} else {
			this.uploadDocumentsRec(0, 0);
		}
	}
	uploadSystamic(index: number) {
		const fileToUpload = this.filesToUpload[0];
		const userFullName = this.cmnServ.userToken.userFullName;
		let encryption = undefined;

		if (fileToUpload.doctype === DocTypes.SDS) {
			encryption = 'ImsUpload';
		}
		let uploaded = 0;
		/** case folder param "additionalinfo" = "CASE" (Doc goes to Case folder) or null(Doc goes to Adr folder) */
		const docType = _.find(this.autoGeneratedIncludedDocTypes, { documentCode: fileToUpload.doctype });
		const additionalInfo =
			this.isAdrRoleHolder && (docType.isMediation || docType.isRetainedMediation) ? 'ADR' : 'CASE';
		this.imsLoader.show();
		this.docServ
			.uploadDocument(
				DocumentsConfig.appname,
				'SYSTEMIC',
				this.cmnServ.userToken.userEmail,
				this.cmnServ.userToken.userFirstName,
				this.cmnServ.userToken.userLastName,
				this.userSelectedOfficeCode,
				userFullName,
				fileToUpload.file,
				fileToUpload.doctype,
				fileToUpload.tabtype,
				fileToUpload.name,
				this.systemicCase.systemicCaseId,
				this.cmnServ.userToken.user_name,
				moment(new Date(fileToUpload.relatedDate)).format('MM-DD-YYYY'),
				encryption,
				additionalInfo,
				'N',
				!fileToUpload.isDisclosable,
				!this.parent?.isPrivate
					? 'N'
					: this.autoGeneratedIncludedDocTypes?.find((item) => item.documentCode === fileToUpload.doctype)
						?.isAutoPublished
						? 'Y'
						: 'N'
			)
			.subscribe(
				(res: any) => {
					if (res.status === 204) this.alrtServ.warn(res.headers.get('message'))
					else this.alrtServ.success(res?.body?.title + ' has been uploaded.');
					uploaded += 1;
					if (this.filesToUpload.length > 1) {
						// remove the first file and upload next one recursively
						this.filesToUpload.shift();
						this.uploadDocumentsRec(index + 1, uploaded);
					} else {
						// recurive ends, hid loader and refresh document list
						this.filesToUpload.shift();
						this.imsLoader.hide();
						if (res.status === 204) this.alrtServ.warn(res.headers.get('message'))
						else this.alrtServ.success(`Uploaded ${uploaded} file(s) successfully.`);
						this.docChange.emit(res?.body?.documentNodeId);
					}
					this.onClose.emit(true);
				},
				(error) => {
					this.imsLoader.hide();
					if (error?.status === 406 && error?.error instanceof Blob) {
						const blobErrorResponse = error?.error;
						var fileReader = new FileReader();

						// This fires after the blob has been read/loaded.
						fileReader.addEventListener('loadend', (e: any) => {
							const fileReaderResponse = e?.srcElement?.result;
							this.alrtServ.error(fileReaderResponse);
						});

						// Start reading the blob as text.
						fileReader.readAsText(blobErrorResponse);
					} else {
						if (error.status === 422) this.alrtServ.error(error?.error);
						else
							this.alrtServ.error(error?.error?.message || 'File upload failed. Please contact system administrator.');
					}
					this.docChange.emit(null);
				}
			);
	}
	/**
	 * uploading the document after validation
	 * @param index
	 * @param uploaded
	 */
	private uploadDocumentsRec(index: number, uploaded: number) {
		if (this.isSystemic) this.uploadSystamic(index);
		const fileToUpload = this.filesToUpload[0];
		const userFullName = this.cmnServ.userToken.userFullName;
		let encryption = undefined;

		if (fileToUpload.doctype === DocTypes.SDS) {
			encryption = 'ImsUpload';
		}
		let uploadServiceCall;
		/** case folder param "additionalinfo" = "CASE" (Doc goes to Case folder) or null(Doc goes to Adr folder) */
		const docType = _.find(this.autoGeneratedIncludedDocTypes, { documentCode: fileToUpload.doctype });
		const additionalInfo =
			this.isAdrRoleHolder && (docType.isMediation || docType.isRetainedMediation) ? 'ADR' : 'CASE';
		this.imsLoader.show();
		if (
			fileToUpload.doctype === DocTypes.COD ||
			fileToUpload.doctype === DocTypes.PCOD ||
			fileToUpload.doctype === DocTypes.AmendedCOD ||
			fileToUpload.doctype === DocTypes.SWR ||
			fileToUpload.doctype === DocTypes.SWR_DRAFT ||
			fileToUpload.doctype === DocTypes.COD_FORM5A
		) {
			const payload: ImsDocumentRequest = {
				caseId: this.caseDetail?.chargeInquiryId,
				documentType: fileToUpload.doctype,
				fileName: fileToUpload.name,
				docReceivedDate: moment(new Date(fileToUpload.relatedDate)).startOf('day').toDate(),
			};
			uploadServiceCall = this.docServ.uploadCOD(fileToUpload.file, payload);
		} else {
			uploadServiceCall = this.docServ.uploadDocument(
				DocumentsConfig.appname,
				this.documentsConfig.domain,
				this.cmnServ.userToken.userEmail,
				this.cmnServ.userToken.userFirstName,
				this.cmnServ.userToken.userLastName,
				this.userSelectedOfficeCode,
				userFullName,
				fileToUpload.file,
				fileToUpload.doctype,
				fileToUpload.tabtype,
				fileToUpload.name,
				this.caseDetail?.chargeNumber,
				this.cmnServ.userToken.user_name,
				moment(new Date(fileToUpload.relatedDate)).format('MM-DD-YYYY'),
				encryption,
				additionalInfo,
				this.parent?.isPrivate ? 'N' : 'Y',
				!fileToUpload.isDisclosable,
				!this.parent?.isPrivate
					? 'N'
					: this.autoGeneratedIncludedDocTypes?.find((item) => item.documentCode === fileToUpload.doctype)
						?.isAutoPublished
						? 'Y'
						: 'N'
			);
		}
		uploadServiceCall.subscribe(
			(res) => {
				uploaded += 1;
				if (this.filesToUpload.length > 1) {
					if (res?.body?.documentDescription?.indexOf('NRTS') > 0) {
						this.imsLoader.hide();
						if (this.parent?.isPrivate && !this.isSupervisorRoleHolder) {
							this.releaseDocument(res?.body, this.filesToUpload.length, uploaded, index);
						} else {
							if (res?.status === 204) this.alrtServ.warn(res.headers.get('message'))
							else this.alrtServ.success(res?.body?.title + ' has been uploaded.');
							this.filesToUpload.shift();
							this.uploadDocumentsRec(index + 1, uploaded);
						}
						this.docChange.emit(res?.body?.documentNodeId);
					} else {
						if (res?.body) {
							if (res?.status === 204) this.alrtServ.warn(res.headers.get('message'))
							else this.alrtServ.success(res?.body?.title + ' has been uploaded.');
						} else {
							this.alrtServ.success(res?.title + ' has been uploaded.');
						}
						// remove the first file and upload next one recursively
						this.filesToUpload.shift();
						this.uploadDocumentsRec(index + 1, uploaded);
					}
					// remove the first file and upload next one recursively
				} else {
					if (res?.body?.documentDescription?.indexOf('NRTS') > 0) {
						this.imsLoader.hide();
						if (this.parent?.isPrivate && !this.isSupervisorRoleHolder) {
							this.releaseDocument(res?.body, this.filesToUpload.length, uploaded, index);
						} else {
							if (res?.status === 204) this.alrtServ.warn(res.headers.get('message'))
							else {
								this.alrtServ.success(res?.body?.title + ' has been uploaded.');
								this.alrtServ.success(`Uploaded ${uploaded} file(s) successfully.`);
							}
						}
						this.filesToUpload.shift();
						this.imsLoader.hide();
						this.parent.getDocuments(res?.documentNodeId);
						this.parent?.onChange.emit(true);
						this.docChange.emit(res?.body?.documentNodeId);
					} else {
						// recurive ends, hid loader and refresh document list
						this.filesToUpload.shift();
						this.imsLoader.hide();
						if (res?.body) {
							this.docChange.emit(res?.body?.documentNodeId);
							this.alrtServ.success(res?.body?.title + ' has been uploaded.');
						} else {
							this.docChange.emit(res?.documentNodeId);
							this.alrtServ.success(res?.title + ' has been uploaded.');
						}
						this.alrtServ.success(`Uploaded ${uploaded} file(s) successfully.`);
					}
				}
			},
			(error) => {
				this.imsLoader.hide();
				if (error?.status === 406 && error?.error instanceof Blob) {
					const blobErrorResponse = error?.error;
					var fileReader = new FileReader();

					// This fires after the blob has been read/loaded.
					fileReader.addEventListener('loadend', (e: any) => {
						const fileReaderResponse = e?.srcElement?.result;
						this.alrtServ.error(fileReaderResponse);
					});

					// Start reading the blob as text.
					fileReader.readAsText(blobErrorResponse);
				} else {
					if (error.status === 422) this.alrtServ.error(error?.error);
					else this.alrtServ.error(error?.error?.message || 'File upload failed. Please contact system administrator.');
				}
				this.docChange.emit(null);
			}
		);
	}

	releaseDocument(res, length, uploaded, index) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: RELEASE_DOCUMENT,
			width: '40%',
			disableClose: true,
			scrollStrategy: ScrollStrategy,
			id: 'release-document-confirmation-dialog',
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((response) => {
				if (length > 1) {
					this.filesToUpload.shift();
					this.uploadDocumentsRec(index + 1, uploaded);
					this.alrtServ.success(res?.title + ' has been uploaded.');
					if (response) {
						this.parent.publishDocument(res);
					}
				} else {
					if (response) {
						this.parent.publishDocument(res);
					}
					this.alrtServ.success(res?.title + ' has been uploaded.');
					this.alrtServ.success(`Uploaded ${uploaded} file(s) successfully.`)

				}

			});

	}

	getCategoryTypes(file) {
		let data = file?.doctype ? this.categoryTypes?.filter((el) => el.value === file.tabtype) : this.categoryTypes;
		data.sort((a, b) => (a.tab > b.tab ? 1 : -1));
		return data;
	}

	openDialog(template: TemplateRef<any>) {
		//	this.accept = false;
		this.dialogRef = this.dialog.open(template, {
			width: '950px',
			scrollStrategy: ScrollStrategy,
			id: 'document-template-dialog',
		});
		this.dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((res) => {
				// this.selectedTemplate = null;
			});
	}


	ngOnChanges(changes) {
		this.isModal = false;
		if (changes.documents || changes.isSystamic || this.isSystemic || changes.documentTypes || this.documentTypes) {
			this.onDocumentsChange();
		}
	}
	applyFilterOnTypes() {
		if (this.isSystemic) {
			const excludeList = [DocTypes.COD, DocTypes.PCOD, DocTypes.AmendedCOD, DocTypes.SWR, DocTypes.SWR_DRAFT];
			this.documentTypes = this.documentTypes.filter((x) => !excludeList.includes(x.documentCode));
		}
	}
	onDocumentsChange() {
		if (this.documentTypes.length) {
			this.applyFilterOnTypes();
			this.documentTypes = _.sortBy(this.documentTypes, ['documentDescription']);
		}
		this.autoGeneratedIncludedDocTypes = [...this.documentTypes];
		const autoGeneratedExcludedDocTypes = [...this.documentTypes].filter((element) => !element.isAutoGenerated || element.documentCode === "NTCINTRECONSIDER");
		this.processDocumentsAndCategoryTypes([...autoGeneratedExcludedDocTypes]);
	}
	/**
	 * @description calls a web service to get list of document types. Manipulates the HTTP response to get
	 * document and unique category values.
	 */
	processDocumentsAndCategoryTypes(autoGeneratedExcludedDocTypes) {
		const tabArray = [];
		this.autoGeneratedExcludedDocTypes = autoGeneratedExcludedDocTypes.filter(
			(x) =>
				(!x.isMediation || (x.isMediation && this.isAdrRoleHolder)) &&
				((x.isUploadableByEeoc && this.parent?.isPrivate) || (x.isUploadableByFepa && !this.parent?.isPrivate)) &&
				(this.showSensitiveOption || (x.documentCode != 'THRDPYSENSITIVEDATA' && x.documentCode != 'RESPSENSTVDATASET'))
		);
		this.autoGeneratedExcludedDocTypes = this.autoGeneratedExcludedDocTypes.filter((element: DocumentTypeVO) => {
			if (!tabArray.includes(element.documentTab?.description)) tabArray.push(element.documentTab?.description);
			if (
				Utils.notNullCondition(element.documentTab) &&
				(this.isAdrRoleHolder || !element.documentTab?.description?.includes('ADR'))
			)
				return this.readOnly ? SensitiveTypes.includes(element.documentCode) : element;
		});
		this.categoryTypes = tabArray.map((item) => {
			return { value: item || '', tab: (item || '').split(':')[0] };
		});
	}
	cancel() {
		this.onClose.emit(false);
	}

	getSharedCodes() {
		this.sharedCodeServ.getSharedCodes(LookUpDataTypes.NO_INTAKE_INTR_RSN).subscribe(
			(res) => {
				this.noIntakeInterviewReasons = res;
			},
			(error) => {
				this.alrtServ.error('Error happened while getting shared code. Please contact system administrator.');
			}
		);
	}

	checkIfNoIntakeInterview() {
		this.csDtlsServ.getAllNotes(this.caseDetail?.chargeInquiryId, false).subscribe(
			(res) => {
				if (res) {
					const noteRes = res.find(e => e.subject === 'Intake Interview')
					this.isIntakeNotes = noteRes ? true : false;
				}
			},
			(error) => {
				this.alrtServ.error('Failed to get notes. Please contact system administrator', error);
			}
		);
		const docs = (this.documents || []).find(e => e.documentType === 'INTKINTNOTES');
		this.isIntakeDocument = docs ? true : false;
	}
	//no intake interview reason popup dialog
	openNoInterviewIdailog(template: TemplateRef<any>, event) {
		const isUploadingCod: boolean = this.filesToUpload.some((x) => x.doctype == DocTypes.COD);
		const isUploadingCodUnperfected: boolean = this.filesToUpload.some((x) => x.doctype == DocTypes.PCOD);
		if ((isUploadingCod || isUploadingCodUnperfected) && this.caseDetail?.noIntakeInterviewReason === null && this.caseDetail?.isFepa === false) {
			this.imsLoader.show();
			this.csDtlsServ.getAllNotes(this.caseDetail?.chargeInquiryId, false).subscribe(
				(res) => {
					this.imsLoader.hide();
					const noteRes = res?.find(e => e.subject === 'Intake Interview')
					this.isIntakeNotes = noteRes === (null || undefined) ? false : true;
					if (this.isIntakeNotes === false) {
						this.noIntakedialogRef = this.dialog.open(template, {
							width: '600px',
							height: '350px',
							disableClose: true,
							scrollStrategy: ScrollStrategy,
							data: {},
						});

						this.noIntakedialogRef.afterClosed().subscribe((res) => {
							if (this.noIntakeIntrialog === false) {
								const payload: BaseCase = {};
								payload.chargeInquiryId = this.caseDetail?.chargeInquiryId;
								payload.noIntakeInterviewReason = this.noIntakeInterviewReasons.find((e) => {
									return e.code === this.selectednoIntakeInterviewReason
								})
								this.csDtlsServ.updateChargeDetail(payload).subscribe(
									(res) => {
										this.imsLoader.hide();
										if (res) this.upload(event);
									},
									(error) => {
										this.imsLoader.hide();
										if (error.status === 422) {
											this.alrtServ.error(error?.error, error);
										} else {
											this.alrtServ.error(
												'An error occurred while processing your request, please contact the system administrator.', error
											);
										}
									}
								);
							}
						});
					}
					else {
						this.upload(event);
					}
				},
				(error) => {
					this.alrtServ.error('Failed to get notes. Please contact system administrator', error);
				}
			);
		}
		else {
			this.upload(event)
		}
	}
	saveNoIntrvReason() {
		this.noIntakeIntrialog = false;
		this.noIntakedialogRef.close();
	}

	cancelDialog() {
		this.noIntakeIntrialog = true;
		this.noIntakedialogRef.close();
		this.selectednoIntakeInterviewReason = '';
	}

	isDisabled(): boolean {
		return !this.hasDocumentLogDownloadPermission && this.readOnlyCharge;
	}

	print(selector) {
		const div = document.createElement('div');
		div.classList.add('only-print')
		div.innerHTML = document.querySelector(selector).innerHTML;
		document.body.appendChild(div);
		window.print();
		document.body.removeChild(div);
	}

}
