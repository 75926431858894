import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { MsalService} from '@azure/msal-angular'; // MSAL
import { AuthenticationResult } from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';
import { AppState } from './store/app.state';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { environment } from 'src/environments/environment';
import { msalConfig } from './msalConfig';
import { CommonService } from './services/common.service';
import { LookupData, PrepaLookupDataResourceService, Role } from './services/gen';
import { LoadingService } from './services/loading/loading.service';
import { SharedCodeService } from './services/shared-code.service';
import {
	EmlAdminRoleCode,
	EnvMaps,
	FepaRegistrationApproverRoleCode,
	FepaRegistrationManagerRoleCode,
	FoiaAdminRoleCode,
	GlobalUserRoleCode,
	LookUpDataTypes,
	OFPAdminRoleCode,
	RegistrationApproverRoleCode,
	RegistrationManagerRoleCode,
	SltpAdminRoleCode,
	SystemicAdminRoleCode,
} from './shared/common-structures/app.constants';
import { CommonParams, OauthToken } from './shared/common-structures/common-params';
import { OfficeRole } from './shared/common-structures/office-role';
import { TokenUserInfo } from './shared/common-structures/token-user-info';
import { AlertService } from './shared/ims-alerts/ims-alerts.service';
import { ServiceErrorDialogComponent } from './shared/service-error-dialog/service-error-dialog.component';
import { initializeDatadogRum } from './shared/utility/initializeDatadogRum';
import { Utils } from './shared/utility/utils';
import { Store} from '@ngrx/store';
import { getLoading } from 'src/app/store/shared/shared.selector';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	envMaps: any = EnvMaps;
	commonParams: CommonParams;
	usingKeyBoard: boolean;
	fepaComponents: string[] = ['documents', 'reports', 'fepadualfile'];
	enableNavBar: boolean;
	username: string;

	isIframe = false;
	msalToken: string;
	azureUsername: string;
	showLoading: Observable<boolean>;
	showOptions: boolean = true;
	loginForm: UntypedFormGroup;
	showTestLogin = false;
	isScrolled = false;
	environment = environment;

	public breadcrumbsList$: Observable<any>;
	breadcrumbsList = [];
	private readonly _destroying$ = new Subject<void>();

	// if user uses keyboard, the current focused item gets a cyan border to improve visual focus.
	@HostListener('mousedown') onMouseDown() {
		this.usingKeyBoard = false;
		this.cmnServ.setKeyboardInput(false);
	}

	/**
	 * @description accessibility skip to main content.
	 */
	@HostListener('keydown.alt.m') onAltM() {
		const curRoute = this.router.url;
		this.usingKeyBoard = true;
		this.cmnServ.setKeyboardInput(true);
		if (curRoute.includes('/dashboard') || curRoute.includes('/systemic-investigation')) {
			let ele: any = document.querySelector('.m-focus');
			if (ele != null) {
				ele.focus();
			}
			//  else {
			// 	const rawHtmlCollection = document.getElementsByTagName('a');
			// 	const caseLinks = Array.from(rawHtmlCollection).filter((i) => i.href.includes('case-details/'));
			// 	if (caseLinks && caseLinks.length > 0) {
			// 	 caseLinks[0].focus();
			// 	}
			// }
		}
		// more can be added
	}

	@HostListener('keydown', ['$event']) onKeyDown(event) {
		// 9 is the code for tab
		if (event.code === 'Tab' || event.keyCode === 9) {
			this.usingKeyBoard = true;
			this.cmnServ.setKeyboardInput(true);
		}
		// shortcut for developer for test purpose
		if (event.code === 'ControlRight' && this.showOptions) {
			this.loginForm.get('username').setValue('fepatestuser');
			this.handleExternalUsers();
		}
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(e) {
		if (window.scrollY > 80) {
			document.body.classList.add('fixed');
		} else {
			document.body.classList.remove('fixed');
		}

		// if user scrolls below header, the sticky menu header set to be appear.
		if (window.pageYOffset >= 50) {
			this.isScrolled = true;
			this.cmnServ.setStickyMenuHeader(true);
		} else {
			this.isScrolled = false;
			this.cmnServ.setStickyMenuHeader(false);
		}
	}

	/**
	 * @description injects all the dependencies and captures router events to get staff id
	 * @params dependencies
	 */
	constructor(
		public cmnServ: CommonService,
		private router: Router,
		private alert: AlertService,
		private loader: LoadingService,
		private formBuilder: UntypedFormBuilder,
		private msalService: MsalService,
		private store: Store<AppState>,
		private sharedCodeServ: SharedCodeService,
		private dialog: MatDialog,
		private lookUpServ: PrepaLookupDataResourceService
	) {
		initializeDatadogRum();
		this.breadcrumbsList$ = this.cmnServ.getBreadCrumbsList();
		this.cmnServ.onLogout.subscribe((res) => {
			if (res != null) {
				this.showOptions = res;
				this.dialog.closeAll();
			}
		});
		this.cmnServ.onServiceError.subscribe((res) => {
			if (res) {
				this.showServiceError();
			}
		});
	}

	showServiceError() {
		this.dialog.open(ServiceErrorDialogComponent, { width: '450px', height: '300px' });
	}

	/**
	 * @note only show loader while login. loader should be closed in target components.
	 */
	async ngOnInit(): Promise<any> {
		try{
			await this.msalService.instance.initialize()
			this.store.select(getLoading).subscribe((setLoading) => {
				if (setLoading) this.loader.show();
				else this.loader.hide();
			});
			this.isIframe = window.location !== window.parent.location;
			this.checkEnvironment();
			if (this.isIframe) {
				this.loader.show();
				this.handleIIGUsers();
			}
			this.initGuestLoginForm();

			// For automated test script purposes, grab the username from the URL param and log in
			if (
				environment.name === 'LOCAL' ||
				environment.name === 'DEV' ||
				environment.name === 'TEST' ||
				environment.name === 'TESTLIT' ||
				environment.name === 'UAT'
			) {
				const testUsername = Utils.getParameterByName('username', window.location.search);

				if (!Utils.isNullCondition(testUsername)) {
					this.getLoginInfo(testUsername, environment.testuserPassword, undefined);
				}
			}
			const sessionUrl = sessionStorage.getItem("prevPageVisitData");
			const sessionUrlObject = sessionUrl ?  JSON.parse(sessionUrl) : null;

			if (sessionUrlObject && !environment.redirectUri.includes(sessionUrlObject.pageUrl) && this.msalService.instance.getAllAccounts().length > 0) {
				this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
				this.getMSALToken();
				return;
			}
			this.msalService.instance.handleRedirectPromise().then((authResult: AuthenticationResult)=>{
				// Check if user signed in
				const account = authResult && authResult.account;
				if(account){
					// const account = authResult.payload.account;
					// this.loader.show();
					this.msalService.instance.setActiveAccount(account);
					//this.getMSALToken();
					this.azureUsername = account['userName'];
					this.msalToken = authResult.accessToken;
					this.sendMSALToken();
					return;
				}
			}).catch(err=>{
				// TODO: Handle errors
				this.loginPopup();
			});
		}
		catch{(err: any) => this.alert.error('An Error occured while processing your request', err)}
	}

	private handleIIGUsers = () => {
		const windowUrl = new URL(window.location.href);
		const username = windowUrl.searchParams.get('username');
		const password = windowUrl.searchParams.get('password') || environment.testuserPassword;
		const chargeNumber = windowUrl.searchParams.get('chargenumber');
		if (Utils.isEmptyCondition(username) || Utils.isEmptyCondition(password)) {
			this.errorHandler("User doesn't have valid role.");
		}
		this.getIIGLoginDetails(username, password, chargeNumber);
	};

	loginPopup() {

			this.msalService.loginPopup().pipe( takeUntil(this._destroying$))
			  .subscribe((authResult: AuthenticationResult) => {

					//this.msalToken = accessToken?.accessToken;
					//this.sendMSALToken();
					const account = authResult && authResult.account;
					if(account){
						// const account = authResult.payload.account;
						// this.loader.show();
						this.msalService.instance.setActiveAccount(account);
						 //this.getMSALToken();
						 this.azureUsername = account['userName'];
						 this.msalToken = authResult.accessToken;
						 this.sendMSALToken();
						   return;
					}
		  },
		(err: any) => {
			console.log(err);
		});
	}

	// Call oAuth
	getIIGLoginDetails(uname: string, pwd: string, chargeNumber?: string) {
		const oAuth$ = this.cmnServ.getOathToken(uname, pwd);
		oAuth$.subscribe(
			(oauthToken) => {
				if (Utils.notNullCondition(oauthToken)) {
					this.cmnServ.setOauthToken(oauthToken);
					this.setCommonParams(oauthToken, chargeNumber);
					// decode user info
					const tokenUser: TokenUserInfo = this.prepareTokenUser(oauthToken.access_token);
					//remove the prefix "ROLE_" from every entry
					const roleAssignments = tokenUser.authorities ? tokenUser.authorities.map((item) => item.slice(5)) : [];
					if (
						!roleAssignments.length ||
						!roleAssignments.find((roleItem) => roleItem.includes('PRIVATE_IIG_SUBMITTER'))
					) {
						this.errorHandler("User doesn't have valid role. Please contact system administrator.");
						return;
					}
					this.cmnServ.setUserDetails(tokenUser);
					this.cmnServ.setRoleAccess(roleAssignments);
					this.queryOfficesRolesList(roleAssignments, false);
					this.queryTimezonesLookup();
				} else {
					this.errorHandler('Invalid user details. Please contact system administrator.');
				}
			},
			(error) => {
				if (error?.error.error === 'invalid_grant') {
					this.errorHandler('Invalid login credentials. Please check your user name.');
				} else {
					this.errorHandler('Error Occurred while getting user details. Please contact system administrator.');
				}
			}
		);
	}

	redirectTo(uri: string) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
	}

	// Check if User logged in - MSAL
	/*
	getUserAccount() {
		return !!this.msalService.getAccount();
	}
	*/

	// get MSAL Token
	getMSALToken() {
		this.loader.show();
		const accessTokenRequest = {
			scopes: msalConfig.consentScopes,
		};
		this.msalService
			.acquireTokenSilent(accessTokenRequest).pipe( takeUntil(this._destroying$))
			.subscribe((accessTokenResponse) => {
				this.azureUsername = accessTokenResponse.account['userName'];
				this.msalToken = accessTokenResponse.accessToken;
				this.sendMSALToken();
			}), (error: any) => {
				this.msalService.acquireTokenPopup(accessTokenRequest).pipe( takeUntil(this._destroying$)).subscribe(
					(accessToken: any) => {
						this.msalToken = accessToken?.accessToken;
						this.sendMSALToken();
					},
					(err: any) => {
						console.log(err);
					}
				);
			};
	}

	// send to Auth endpoint
	sendMSALToken() {
		this.loader.show();
		// this.getLoginInfo(this.username, undefined);
		this.getLoginInfo(this.username, undefined, this.msalToken); // Enable this for original endpoint
	}

	// Call oAuth
	getLoginInfo(uname, pwd, token) {
		const oAuth$ = uname
			? this.cmnServ.getOathToken(uname, pwd)
			: this.cmnServ.getOathToken(undefined, undefined, token);
		oAuth$.subscribe(
			(oauthToken) => {
				let pattern = new RegExp('EEOC.GOV');
				this.cmnServ.userType = pattern.test(oauthToken.userEmail.toUpperCase()) ? 'Member' : 'Guest';

				if (Utils.notNullCondition(oauthToken)) {
					this.cmnServ.setOauthToken(oauthToken);
					this.setCommonParams(oauthToken);
					// decode user info
					const tokenUser: TokenUserInfo = this.prepareTokenUser(oauthToken.access_token);
					//remove the prefix "ROLE_" from every entry
					const roleAssignments = tokenUser.authorities ? tokenUser.authorities.map((item) => item.slice(5)) : [];
					if (roleAssignments.length === 0) {
						this.errorHandler('No active roles found. Please contact system administrator.');
						return;
					}
					this.cmnServ.setUserDetails(tokenUser);
					this.cmnServ.setRoleAccess(roleAssignments);
					this.queryOfficesRolesList(roleAssignments);
					this.queryTimezonesLookup();
				} else {
					this.errorHandler('Invalid user detail ID. Please contact system administrator.');
				}
			},
			(error) => {
				if (error?.error?.error === 'invalid_grant') {
					this.errorHandler('Invalid login credentials. Please check your user name.');
				} else {
					this.loader.hide();
					this.cmnServ.showServiceError();
				}
			}
		);
	}

	private initGuestLoginForm() {
		this.loginForm = this.formBuilder.group({
			username: new UntypedFormControl(null),
		});
	}

	// External Users
	handleExternalUsers() {
		const username = this.loginForm.get('username').value;
		if (Utils.isEmptyCondition(username)) {
			this.alert.warn('Please enter user name.');
		} else {
			this.loader.show();
			this.getLoginInfo(username, environment.testuserPassword, undefined);
		}
	}

	handleEEOCuser() {
		this.msalService.loginRedirect();
	}

	/**
	 * @description decode token user and add some fields
	 */
	private prepareTokenUser(token: string): TokenUserInfo {
		const tokenUser: TokenUserInfo = jwt_decode(token);
		const nameArray = tokenUser.userFullName.split(' ');
		tokenUser.userFirstName = nameArray[0];
		tokenUser.userLastName = nameArray[1];
		return tokenUser;
	}

	/**
	 * @description set common params to behavior subject
	 */
	private setCommonParams(oauthToken: OauthToken, chargeNumber?: string) {
		const currentRoute = window.location.search;
		this.commonParams = {
			userDetailId: Utils.getParameterByName('userdetailid', currentRoute) || oauthToken.userId,
			chargeNumber: Utils.getParameterByName('casenumber', currentRoute) || chargeNumber,
			readOnly: Utils.getParameterByName('readonly', currentRoute),
			context: Utils.getParameterByName('context', currentRoute),
		};
		this.cmnServ.setCommonParams(this.commonParams);
	}

	/**
	 * @description check if logged in user has registration roles
	 * @param roleAssignments
	 */
	private hasOnlyRegistrationRole(roleAssignments: string[]): boolean {
		const hasRegistrationsRole = roleAssignments?.some((x) =>
			[
				GlobalUserRoleCode,
				RegistrationApproverRoleCode,
				RegistrationManagerRoleCode,
				FepaRegistrationApproverRoleCode,
				FepaRegistrationManagerRoleCode,
			].some((r) => x.indexOf(r) >= 0)
		);
		const nonRegistrationRoles = roleAssignments?.filter(
			(element) =>
				!element.includes(GlobalUserRoleCode) &&
				!element.includes(RegistrationApproverRoleCode) &&
				!element.includes(RegistrationManagerRoleCode) &&
				!element.includes(FepaRegistrationApproverRoleCode) &&
				!element.includes(FepaRegistrationManagerRoleCode)
		);
		return !nonRegistrationRoles?.length && hasRegistrationsRole;
	}

	private isSystemicAdminRole(roleAssignments: string[]): boolean {
		return roleAssignments.some((x) => x.includes(SystemicAdminRoleCode));
	}

	private isFoiaAdmin(roleAssignments: string[]): boolean {
		return roleAssignments.some((x) => x.includes(FoiaAdminRoleCode));
	}

	private isEmlAdmin(roleAssignments: string[]): boolean {
		return roleAssignments.some((x) => x.includes(EmlAdminRoleCode));
	}

	/**
	 * @description Actions to take when error happens
	 */
	private errorHandler(msg: string) {
		this.loader.hide();
		this.alert.error(msg);
	}

	private checkEnvironment() {
		const envName = environment.name;
		if (envName === 'LOCAL' || envName === 'DEV' || envName === 'TEST' || envName === 'TESTLIT') {
			this.showTestLogin = true;
		}
	}

	private queryOfficesRolesList(loggedInUserRoleAssignments: string[], regularNavigation = true) {
		const eeocOfficesRequest = this.sharedCodeServ.getSharedCodes(LookUpDataTypes.OFFICE, null, 'PRIVATE');
		const fepaOfficesRequest = this.sharedCodeServ.getSharedCodes(LookUpDataTypes.OFFICE, null, 'FEPA', true );
		const rolesRequest = this.sharedCodeServ.getRoles();
		this.cmnServ._setPrivateOffices([]);
		this.cmnServ._setFepaOffices([]);
		this.cmnServ._setFepaOfficesIncludeObsolete([]);
		this.cmnServ._setRoles([]);
		this.cmnServ.setOfficeRoleArray([]);
		forkJoin({ eeocOfficesRequest, fepaOfficesRequest, rolesRequest }).subscribe(
			(response: { eeocOfficesRequest: LookupData[]; fepaOfficesRequest: LookupData[]; rolesRequest: Role[] }) => {
				this.loader.hide();
				this.cmnServ._setPrivateOffices(response?.eeocOfficesRequest?.sort(Utils.sortSharedCodes));
				this.cmnServ._setFepaOfficesIncludeObsolete(response?.fepaOfficesRequest?.sort(Utils.sortSharedCodes));
				this.cmnServ._setFepaOffices(response?.fepaOfficesRequest?.filter(
					(fepaOffice) => fepaOffice.obsolete === false).sort(Utils.sortSharedCodes));
				this.cmnServ._setRoles(response?.rolesRequest?.sort(Utils.sortRoles));
				if (
					response?.eeocOfficesRequest?.length &&
					response.fepaOfficesRequest?.length &&
					response.rolesRequest?.length
				)
					this.setUserOfficeRolesList(loggedInUserRoleAssignments);

				if (regularNavigation) {
					// navigate user
					let navigationUrl = this.hasOnlyRegistrationRole(loggedInUserRoleAssignments)
						? '/user-management'
						: this.isSystemicAdminRole(loggedInUserRoleAssignments)
						? '/systemic-investigation'
						: this.isEmlAdmin(loggedInUserRoleAssignments)
						? '/employer-representatives'
						:  (this.cmnServ.hasPrivateReadOnlyRole() || this.cmnServ.hasPrivateInspectorGeneralRole()) ? '/search' : '/dashboard';
					const fromUrl = this.cmnServ.fromUrl;
					if (fromUrl && fromUrl !== 'login' && fromUrl !== 'error') {
						// remove query parameters
						navigationUrl = fromUrl.split('?')[0];
						this.cmnServ.fromUrl = null;
					}
					this.enableNavBar = this.fepaComponents.includes(navigationUrl) ? false : true;
					const navigationExtras: NavigationExtras = {
						queryParams: { ...this.commonParams },
					};
					// Redirect the user
					this.showOptions = false;
					if (
						navigationUrl !== '/dashboard' ||
						(navigationUrl === '/dashboard' && !window.location.href.includes('/case-details/'))
					) {
						this.router.navigate([navigationUrl], navigationUrl !== '/error' ? navigationExtras : {});
					}
				} else {
					// navigate user
					let navigationUrl = '/iig-new-inquiry';
					const navigationExtras: NavigationExtras = {
						queryParams: { ...this.commonParams },
					};
					// Redirect the user
					this.showOptions = false;
					this.router.navigate([navigationUrl], navigationUrl !== '/error' ? navigationExtras : {});
				}
			},
			(error) => {
				this.loader.hide();
				if (error.status === 422) this.alert.error(error?.error, error);
				else
					this.alert.error(
						'Error happened while processing offices and roles service requests. Please contact system administrator.' , error
					);
			}
		);
	}

	/**
	 * @description Generate the dropdown list for office role selection
	 * @param loggedInUserRoleAssignments
	 */
	private setUserOfficeRolesList(loggedInUserRoleAssignments: string[]) {
		const officeDict = [...(this.cmnServ._getAllOffices() || [])];
		const roleDict = [...(this.cmnServ._getRoles() || [])];
		let officeRoles: OfficeRole[];
		let adminRoles = loggedInUserRoleAssignments?.filter(
			(el) => el.includes(GlobalUserRoleCode) || el.includes(OFPAdminRoleCode) || el.includes(SltpAdminRoleCode)
		);
		let updatedLoggedInUserRoleAssignments = loggedInUserRoleAssignments?.filter(
			(el) => !el.includes(GlobalUserRoleCode) && !el.includes(OFPAdminRoleCode) && !el.includes(SltpAdminRoleCode)
		);

		if (adminRoles?.length) {
			// add all offices for global users
			adminRoles?.forEach((el) => {
				const splitedPair = el.split(':');
				const _role = roleDict.find((item) => item.roleCode === splitedPair[0]);
				officeRoles = [
					...(officeRoles || []),
					...(officeDict.map((_office) => {
						const officeRole: OfficeRole = {
							office: _office,
							role: _role,
							description: `${_office?.description} - ${_role?.roleDisplayText}`,
						};
						return officeRole;
					}) || []),
				];
			});
		}
		if (updatedLoggedInUserRoleAssignments?.length) {
			// normal roles
			// input: `{roleCode}:{officeCode}:{unit}`, i.e. 'ROLE_PRIVATE_STAFF:430:Enforcement-1'
			// unit might be empty
			officeRoles = [
				...(officeRoles || []),
				...(updatedLoggedInUserRoleAssignments.map((pair) => {
					const splitedPair = pair.split(':');
					const _role = roleDict.find((item) => item.roleCode === splitedPair[0]);
					const _office =
						splitedPair[1] === 'ALL'
							? { code: 'ALL', description: 'All Offices' }
							: Utils.getLookupDataByCode(splitedPair[1], officeDict);
					if (_role) _role.unit = splitedPair[2];
					const officeRole: OfficeRole = {
						office: _office,
						role: _role,
						description: `${splitedPair[1] === 'ALL' ? 'All Offices' : _office?.description} - ${
							_role?.roleDisplayText
						}`,
					};
					return officeRole;
				}) || []),
			];
		}
    officeRoles = officeRoles.filter(x=>x.office);
		officeRoles = officeRoles.sort((a, b) => (a.description > b.description ? 1 : -1));
		this.cmnServ.setOfficeRoleArray(officeRoles);
	}

	private queryTimezonesLookup() {
		this.lookUpServ.getLookupDataByDomain('TMZ').subscribe(
			(response) => this.cmnServ._setTimezonesLookup([...(response || [])]?.sort()),
			(error) => {
				if (error?.status === 422) this.alert.error(error?.error, error);
				else this.alert.error('Error happened while getting time zones. Please contact system administrator.', error);
			}
		);
	}

	public scrollToTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	ngOnDestroy(): void {
		this._destroying$.next(null);
		this._destroying$.complete();
	}
}
