<app-header
	*ngIf="!isIframe && enableNavBar && commonParams && !showOptions"
	[commonParams]="commonParams"
></app-header>
<div *ngIf="isIframe" class="graphic-background">
	<h3 *ngIf="environment.name != 'PROD'" class="text-center env-name">
		{{ envMaps[environment.name] || environment.name }}
	</h3>
</div>

<div
	[ngClass]="{ 'keyboard-toogle': usingKeyBoard }"
	class="float-router-outlet padding-left-right-20"
	id="main-content"
>
	<ol class="breadcrumb" *ngIf="!isIframe">
		<li *ngFor="let breadcrumb of breadcrumbsList$ | async">
			<span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
				{{ breadcrumb.label }}
			</span>
		</li>
	</ol>
	<button
		mat-fab
		color="primary"
		aria-label="BackToTop"
		matTooltip="Back To Top"
		id="app-header-scrollToTop"
		class="mat-floating-button"
		id="app-header-scrollToTop"
		(debounceClick)="scrollToTop()"
		*ngIf="cmnServ.userToken && isScrolled"
		appDebounceClick
	>
		<mat-icon>keyboard_arrow_up</mat-icon>
	</button>
	<router-outlet></router-outlet>
</div>

<app-loading [loadingText]="'Loading ... Please wait'" [zIndex]="999"></app-loading>
<ims-alerts></ims-alerts>
<app-idle *ngIf="!showOptions"></app-idle>
<app-update-token></app-update-token>

<div *ngIf="showOptions" class="login-layout" [ngClass]="{ 'keyboard-toogle': usingKeyBoard }">
	<div class="login-padding-top"></div>
	<div class="login-card" *ngIf="!isIframe">
		<div class="eeoc-login">
			<img src="./assets/images/eeoc.jpg" class="login-img" alt="U.S. Equal Employment Opportunity Commission logo" />
			<h1 class="arc-h1 margin-top-20">Welcome to ARC</h1>
			<div class="margin-top-10 compliance-information alert-message">
				<div>
					ARC contains sensitive and confidential data. By using ARC, you agree to only access data needed to perform
					your official job duties. Your use of ARC will be recorded and audited by EEOC to ensure compliance with the
					Privacy Act of 1974 and other laws. Unauthorized use of this system is subject to penalty of law, including
					criminal liability and disciplinary action.
				</div>
			</div>
		</div>
		<div class="guest-login">
			<div class="guest-login-title">User Login</div>
			<div class="guest-login-info margin-bottom-10">
				This login is for all EEOC users as well as users from agencies outside of the EEOC.
			</div>
			<button
				class="input-full-width margin-top-20"
				mat-flat-button
				id="app-header-handleEEOCuser-btn"
				color="primary"
				id="app-header-handleEEOCuser-btn"
				(debounceClick)="handleEEOCuser()"
				appDebounceClick
			>
				LOGIN
			</button>
			<mat-card appearance="outlined" *ngIf="showTestLogin" class="mat-bordered-card margin-top-20">
				<div><strong>TEST LOGIN</strong></div>
				<div class="font-warn">**only available in DEV & TEST env**</div>
				<form [formGroup]="loginForm" (ngSubmit)="handleExternalUsers()">
					<mat-form-field appearance="outline" class="input-full-width">
						<mat-label>User Name</mat-label>
						<input matInput placeholder="Enter Username" formControlName="username" id="guest-username" />
					</mat-form-field>
					<button type="submit" class="guest-login-button" mat-flat-button>Login Test User</button>
				</form>
			</mat-card>
		</div>
	</div>
</div>
