import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { ActivityLogVO, EmailResourceService } from 'src/app/services/EmailWebService';
import * as converter from 'xml-js';
import {
	AdditionalAddressVO,
	AllegationClosureVO,
	AssessmentVO,
	BaseCase,
	CaseReviewAssignmentVO,
	CaseReviewResourceService,
	CaseStatusStaffAssignmentsVO,
	ChargeDeformalizationVO,
	ChargeDetailVO,
	ChargingPartyVO,
	ClassGroupResourceService,
	ClassGroupResponse,
	ComplianceOutcomeVO,
	ComplianceResourceService,
	ConciliationRequestVO,
	ConciliationResourceService,
	CreditEEOCResponseVO,
	CreditRequestResponse,
	CreditRequestVO,
	DeferralInfoVO,
	EnforcementConferenceRequestVO,
	EnforcementConferenceResourceService,
	EventLogResponse,
	EventVO,
	FactFindingVO,
	FepaContractVO,
	FepaCreditResourceService,
	FepContractResourceService,
	LookupData,
	NoteVO,
	OnsiteInterviewVO,
	PortalResourceService,
	PositionStatementVO,
	PrepaAllegationResourceService,
	PrepaDualFileResourceService,
	PrepaOfficeResourceService,
	PrepaParticularsResourceService,
	ReopenVO,
	RespondentVO,
	ReviewDetails,
	RfiGenerationRequestVO,
	RfiResourceService,
	StatuteBasisIssueResourceService,
	StatuteVO,
	SubpoenaVO,
	SuspendNocVO,
	SuspensionReasonVO,
	SystemicCaseChargeResponse,
	SystemicCaseResponse,
	SystemicChargeVO,
	SystemicResourceService,
} from 'src/app/services/gen';
import { LegalCaseResourceService } from 'src/app/services/gen/api/legalCaseResource.service';
import { PrepaAssessmentResourceService } from 'src/app/services/gen/api/prepaAssessmentResource.service';
import { PrepaCaseResourceService } from 'src/app/services/gen/api/prepaCaseResource.service';
import { PrepaClosureResourceService } from 'src/app/services/gen/api/prepaClosureResource.service';
import { PrepaEnforcementResourceService } from 'src/app/services/gen/api/prepaEnforcementResource.service';
import { PrepaEventLogResourceService } from 'src/app/services/gen/api/prepaEventLogResource.service';
import { PrepaMediationResourceService } from 'src/app/services/gen/api/prepaMediationResource.service';
import { PrepaNoteResourceService } from 'src/app/services/gen/api/prepaNoteResource.service';
import { PrepaReopenResourceService } from 'src/app/services/gen/api/prepaReopenResource.service';
import { PrepaSuspendNocResourceService } from 'src/app/services/gen/api/prepaSuspendNocResource.service';
import { PrepaSuspensionResourceService } from 'src/app/services/gen/api/prepaSuspensionResource.service';
import { AllegationVO } from 'src/app/services/gen/model/allegationVO';
import { BenefitGroupVO } from 'src/app/services/gen/model/benefitGroupVO';
import { ChargeSystemicDetailResponse } from 'src/app/services/Utils/chargeSystemicDetailResponse';
import { ClosureVO } from 'src/app/services/gen/model/closureVO';
import { EeocInvestigateDetails } from 'src/app/services/gen/model/eeocInvestigateDetails';
import { MediationResponseVO } from 'src/app/services/gen/model/mediationResponseVO';
import {
	AdditionalPartyVO,
	AmicusAppealCreation,
	AppealCourtFilingArgumentVO,
	AppealCourtFilingBriefVO,
	AppealCourtFilingDetail,
	AppealCourtFilingPetitionVO,
	AppealCourtFilingVO,
	AppealRecommendation,
	AppealResolution,
	ChargingPartyLightVO,
	ConsolidationRequest,
	ConsolidationResponse,
	DefensiveAppealCreation,
	DistrictAppealRecommendation,
	EnforcementAppealCreation,
	LitigationAllegationVO,
	LitigationAppealCourtFilingResourceService,
	LitigationAppealResourceService,
	LitigationAppealVO,
	LitigationAssignmentVO,
	LitigationBeneficiaryVO,
	LitigationBenefitDetailVO,
	LitigationCaseChargeVO,
	LitigationCaseVO,
	LitigationChargeAsscVO,
	LitigationDefendantResourceService,
	LitigationDocumentResourceService,
	LitigationEventLogResourceService,
	LitigationNoteResourceService,
	LitigationNoteVO,
	LitigationPetitionVO,
	LitigationPostResolutionVO,
	LitigationResolutionVO,
	LitigationStaffAsscVO,
	LitigationTrialVO,
	RespondentVO as LitigationRespondentVO,
	TransferRequest,
	TransferResponse,
} from 'src/app/services/LitigationWebService';
import { LitigationAllegationResourceService } from 'src/app/services/LitigationWebService/api/litigationAllegationResource.service';
import { User } from 'src/app/services/UserManagement';
import { ArcStatusCodes } from 'src/app/shared/common-structures/app.constants';
import { environment } from 'src/environments/environment';
import { EsEmployer } from '../../../shared/common-structures/employer-representatives';
import { Pageable } from './../../../services/gen/model/pageable';
import { SystemicCaseDetailResponse } from './../../../services/Utils/systemicCaseDetailResponse';
import { AdditionalPartyResourceService } from './../../../services/LitigationWebService/api/additionalPartyResource.service';
import { ChargingPartyResourceService } from './../../../services/LitigationWebService/api/chargingPartyResource.service';
import { LitigationBenefitResourceService } from './../../../services/LitigationWebService/api/litigationBenefitResource.service';
import { LitigationCaseAssignmentResourceService } from './../../../services/LitigationWebService/api/litigationCaseAssignmentResource.service';
import { LitigationCaseResourceService } from './../../../services/LitigationWebService/api/litigationCaseResource.service';
import { LitigationCourtFilingResourceService } from './../../../services/LitigationWebService/api/litigationCourtFilingResource.service';
import { LitigationPetitionEnforceConsentDecreeResourceService } from './../../../services/LitigationWebService/api/litigationPetitionEnforceConsentDecreeResource.service';
import { LitigationPostResolutionResourceService } from './../../../services/LitigationWebService/api/litigationPostResolutionResource.service';
import { LitigationResolutionResourceService } from './../../../services/LitigationWebService/api/litigationResolutionResource.service';
import { RoleAssignmentResourceService } from './../../../services/LitigationWebService/api/roleAssignmentResource.service';
import { ChargingPartyVO as litigationChargingPartyVO } from './../../../services/LitigationWebService/model/chargingPartyVO';
import { EnforceSubpoenaLitigationStatus } from './../../../services/LitigationWebService/model/enforceSubpoenaLitigationStatus';
import { LitigationCourtFilingVO } from './../../../services/LitigationWebService/model/litigationCourtFilingVO';
import { LitigationStatusVO } from './../../../services/LitigationWebService/model/litigationStatusVO';
import { PartyApiUrls } from './case-details.config';
import { PCOptions } from './case-details.model';

@Injectable({
	providedIn: 'root',
})
export class CaseDetailsService {
	currentAppeal$: BehaviorSubject<any> = new BehaviorSubject({});
	reloadAppeal$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
	litigationStatusBehaviourSubject = new BehaviorSubject('');
	chargingPartyBehaviourSubject = new BehaviorSubject<ChargingPartyVO>(null);
	respondentBehaviourSubject = new BehaviorSubject<RespondentVO>(null);
	additionalAddressesBehaviourSubject = new BehaviorSubject<AdditionalAddressVO[]>(null);
	emlRespondentBehaviourSubject = new BehaviorSubject<EsEmployer>(null);
	caseTypeBehaviourSubject = new BehaviorSubject<string>(null);
	noChangeDetactedBehaviourSubject = new BehaviorSubject<boolean>(true);
	pchpCodesObservable: Observable<PCOptions[]>;
	selectedSystemicItemName = new BehaviorSubject<string>(null);
	filteredSystemicItemName = new BehaviorSubject<string>(null);
	pchpAssessment: AssessmentVO;
	associatedChargedArrayBehaviorSubject = new BehaviorSubject<LitigationCaseChargeVO[]>(null);
	isLitigation: boolean = false;
	allDistrictOffices: Observable<any>;
	allOffciessLoading = false;
	subject: Subject<any> = new Subject();
  agenciesList: any;
  caseChanged: EventEmitter<BaseCase> = new EventEmitter<BaseCase>();
	constructor(
		private http: HttpClient,
		private caseServ: PrepaCaseResourceService,
		private notesServ: PrepaNoteResourceService,
		private prepaAssess: PrepaAssessmentResourceService,
		private particularServ: PrepaParticularsResourceService,
		private prepadualFileServ: PrepaDualFileResourceService,
		private prepaCloseServ: PrepaClosureResourceService,
		private prepaADRServ: PrepaMediationResourceService,
		private prepaEventLogServ: PrepaEventLogResourceService,
		private rfiServ: RfiResourceService,
		private litigationLogResourceServ: LitigationEventLogResourceService,
		private enforcementService: PrepaEnforcementResourceService,
		private conciliationProposalService: ConciliationResourceService,
		private conciliationConferenceService: EnforcementConferenceResourceService,
		private suspendService: PrepaSuspensionResourceService,
		private allegationserv: PrepaAllegationResourceService,
		private caseReviewServ: CaseReviewResourceService,
		private suspendNocServ: PrepaSuspendNocResourceService,
		private prepaReopenCaseServ: PrepaReopenResourceService,
		private fepaContractServ: FepContractResourceService,
		private fepaCreditServ: FepaCreditResourceService,
		private systemicServ: SystemicResourceService,
		private emailResourceService: EmailResourceService,
		private complianceServ: ComplianceResourceService,
		private legalCaseService: LegalCaseResourceService,
		private statuteBasisIssueServ: StatuteBasisIssueResourceService,
		private portalServ: PortalResourceService,
		private cmnServ: CommonService,
		private classGroupServ: ClassGroupResourceService,
		private litigationServ: LitigationCaseResourceService,
		private litigationAllegationServ: LitigationAllegationResourceService,
		private LitigationRoleAssignServ: RoleAssignmentResourceService,
		private litigationCaseAssignServ: LitigationCaseAssignmentResourceService,
		private eventLogServ: PrepaEventLogResourceService,
		private chargingPartyServ: ChargingPartyResourceService,
		private defendantServ: LitigationDefendantResourceService,
		private litAdditionalPartyServ: AdditionalPartyResourceService,
		private courtFilingServ: LitigationCourtFilingResourceService,
		private litResolutionServ: LitigationResolutionResourceService,
		private litPostResolutionServ: LitigationPostResolutionResourceService,
		private litPetitionServ: LitigationPetitionEnforceConsentDecreeResourceService,
		private litBenefitsServ: LitigationBenefitResourceService,
		private prepaOfficeServ: PrepaOfficeResourceService,
		private litNoteServ: LitigationNoteResourceService,
		private litDownloadServ: LitigationDocumentResourceService,
		private litAppealServ: LitigationAppealResourceService,
		private litAppealCourtFilingServ: LitigationAppealCourtFilingResourceService
	) {}

	/**
	 * @description returns updated pchpAssessment
	 */
	getSelectedPchpAssessment(): AssessmentVO {
		return this.pchpAssessment;
	}

	/**
	 * @description saves updated pchpAssessment
	 */
	setSelectedPchpAssessment(pchpAssessment: AssessmentVO) {
		this.pchpAssessment = pchpAssessment;
	}

	/**
	 * @description returns systemic item name as an observable
	 */
	getSelectedSystemicItemName(): Observable<string> {
		return this.selectedSystemicItemName.asObservable();
	}

	/**
	 * @description subscribes to the next emitted value of systemic item name
	 */
	setSelectedSystemicItemName(systemicItemName: string) {
		this.selectedSystemicItemName.next(systemicItemName);
	}

	/**
	 * @description returns systemic item name as an observable
	 */
	getSelectedFilteredSystemicItemName(): Observable<string> {
		return this.filteredSystemicItemName.asObservable();
	}

	/**
	 * @description subscribes to the next emitted value of systemic item name
	 */
	setSelectedFilteredSystemicItemName(systemicItemName: string) {
		this.filteredSystemicItemName.next(systemicItemName);
	}

	/**
	 * @description Build URL to gt charging based on case id
	 * @param caseId: case id of inquiry or charge
	 */
	loadChargingParty(caseId: number): Observable<ChargingPartyVO> {
		return this.caseServ.getChargingPartyByCaseId(caseId);
	}

	/**
	 * @description Build URL to gt respondent based on case id
	 * @param caseId: case id of inquiry or charge
	 */
	loadRespondent(caseId: number): Observable<RespondentVO> {
		return this.caseServ.getRespondentByChargeNumber(caseId);
	}

	/**
	 * @description returns charging party as an observable
	 */
	getChargingParty() {
		return this.chargingPartyBehaviourSubject.asObservable();
	}
	/**
	 * @description subscribes to the next emitted value of charging party
	 */
	setChargingParty(cp: ChargingPartyVO) {
		this.chargingPartyBehaviourSubject.next(cp);
	}
	/**
	 * @description returns respondent as an observable
	 */
	getRespondent() {
		return this.respondentBehaviourSubject.asObservable();
	}

	/**
	 * @description returns activity logs as an observable
	 */
	getLitigationActivityLog(caseId: number, isAppeal): Observable<any> {
		if (isAppeal) {
			return this.litigationLogResourceServ.getEventLogsForAppealId(caseId);
		}
		return this.litigationLogResourceServ.getEventLogsForCaseId(caseId);
	}

	/**
	 * @description returns litigation activity logs in for downlaod
	 */
	downloadLitigationActivityLog(caseId: number): Observable<any> {
		return this.litigationLogResourceServ.exportEventLogForCaseId(caseId, 'csv');
	}
		/**
	 * @description returns appeal activity logs in for downlaod
	 */
	downloadAppealActivityLog(appId: number): Observable<any>{
		return this.litigationLogResourceServ.exportEventLogForAppealId(appId, 'csv');
	}
	/**
	 * @description subscribes to the next emitted value of respondent
	 */
	setRespondent(rsp: RespondentVO) {
		this.respondentBehaviourSubject.next(rsp);
	}
	/**
	 * @description returns eml respondent as an observable
	 */
	getEmlRespondent() {
		return this.emlRespondentBehaviourSubject.asObservable();
	}

	/**
	 * @description subscribes to the next emitted value of eml respondent
	 */
	setEmlRespondent(rsp) {
		this.emlRespondentBehaviourSubject.next(rsp);
	}

	getCaseDetails(caseId: number): Observable<BaseCase> {
		return this.caseServ.getCaseDetails1(caseId);
	}

	getMediationDetailsForCase(caseId: number): Observable<MediationResponseVO> {
		return this.prepaADRServ.get(caseId);
	}

	/**
	 * @description subscribes to the next emitted value of charge detail
	 */
	setChargeDetail(charge) {
		this.chargingPartyBehaviourSubject.next(charge);
	}
	/**
	 * @description returns case type as an observable
	 */
	getCaseType() {
		return this.caseTypeBehaviourSubject.asObservable();
	}

	/**
	 * @description subscribes to the next emitted value of case type
	 */
	setCaseType(caseType: string) {
		this.caseTypeBehaviourSubject.next(caseType);
	}

	/**
	 * @description get reasons for closed inquiry
	 */
	getClosedInquiryReasons() {
		return this.prepaCloseServ.getInquiryClosureReasons();
	}

	/**
	 * @description resend closure email after 7 days
	 */
	reSendClosureEmail(caseId: number, documentType: string): Observable<any> {
		return this.prepaCloseServ.reSendClosureEmail(caseId, documentType);
	}

  toJson(res) {
    const json: any = JSON.parse(converter.xml2json(res));
    const result = [];
    ((json.elements || [{elements: []}])[0].elements || []).forEach(a=>{
      let agencyCode = ((a.elements||[]).find(x=>x.name=='agencyCode') || {elements: [{text:''}]}).elements[0].text;
      let agencyName = ((a.elements||[]).find(x=>x.name=='agencyName') || {elements: [{text:''}]}).elements[0].text;
      if(agencyCode && agencyName){
        result.push({agencyCode, agencyName});
      }
    });
    return result;
  }
	/**
	 * @description get list of federal agencies
	 */
	 getFederalAgencyList(): Observable<any> {
		 if (this.agenciesList) {
			 const result = this.agenciesList.reduce((unique, o) => {
				 if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
					 unique.push(o);
				 }
				 return unique;
			 }, []);
			 return of(result);
		 }
    const result = new Subject<any>();
		const url = `${environment.fedsApp}?opmlist=true`;
		this.http.get(url, { responseType: 'text' }).subscribe(res=>{
      let data = res.toLowerCase().includes('<collection') ?  this.toJson(res) :  JSON.parse(res);
      data =data.filter(x=>['DJ00', 'DLOF', 'EDEC'].includes(x.agencyCode))
		  data.sort((a, b) => (a.agencyName > b.agencyName ? 1 : -1));
      this.agenciesList = data;
      result.next(data);
      result.complete();
    }, err=>{
      result.error(err);
      result.complete();
    })
    return result;
	}

	/**
	 * @description Updates the case as closed with reasons
	 */
	updateClosedReasons(caseId: number, closeReasons: ClosureVO) {
		return this.prepaCloseServ.closeInquiry(caseId, closeReasons);
	}

	/**
	 * @description Updates inquiry as reopened
	 */
	saveInquiryReopen(caseId: number) {
		return this.prepaReopenCaseServ.reopenInquiry(caseId);
	}

	getAllegationSBI(): Observable<StatuteVO[]> {
		return this.statuteBasisIssueServ.getStatuteBasisIssueCombination();
	}

	/**
	 * @description get allegations by caseId
	 */
	getAllegations(caseId: number): Observable<AllegationVO[]> {
		return this.allegationserv.getAllegationsByCaseId(caseId);
	}

	/**
	 * @description update allegations list for a case
	 */
	modifyAllegations(allegations: AllegationVO[], caseId: number): Observable<AllegationVO[]> {
		return this.allegationserv.updateAllegations(caseId, allegations);
	}

	reopenAllegation(caseId: number, allegationId: number): Observable<AllegationVO[]> {
		return this.allegationserv.reopenAllegation(caseId, allegationId);
	}

	deLinkRespondent(caseId: number): Observable<boolean> {
		return this.caseServ.delinkEMLFromRespondent(caseId)

	}

	/**
	 * @description delete allegations  when passed as list
	 */
	deleteAllegations(allegationIds: number[], caseId: number): Observable<AllegationVO[]> {
		return this.allegationserv.removeAllegationsByCaseIdAndAllegationIds(caseId, allegationIds);
	}

	getClosureReasonsForAllegations(caseId: number, allegationIds: Array<number>) {
		return this.prepaCloseServ.getClosureReasonsForAllegations(caseId, allegationIds);
	}

	/**
	 * @description get saved code of a case from service
	 */
	getSavedCodes(caseId: number) {
		return this.prepaAssess.getAssessmentForCaseId(caseId);
	}

	/**
	 * @description save PCHP codes for the input caseId.
	 * @param caseId
	 * @param payload
	 */
	savePchpCodes(caseId: number, payload: AssessmentVO) {
		return this.prepaAssess.updateAssessment(caseId, payload);
	}

	/**
	 * @description Builds API call to load additional addresses related to a case.
	 */
	loadAdditionalAddresses(caseId: number) {
		return this.caseServ.getAdditionalAddressByChargeNumber(caseId);
	}
	/**
	 * @description returns additional addresses as an observable
	 */
	getAdditionalAddress(): Observable<any> {
		return this.additionalAddressesBehaviourSubject.asObservable();
	}
	/**
	 * @description subscribes to the next emitted value of additional addresses
	 */
	setAdditionalAddress(additionalAddresses: AdditionalAddressVO[]) {
		this.additionalAddressesBehaviourSubject.next(additionalAddresses);
	}

	/**
	 * @description Builds URL to save additional addresses to IMS.
	 */
	saveAdditionalAddresses(addOredit, caseId: number, data) {
		if (addOredit === 'Add') return this.caseServ.createAdditionalAddress(caseId, data);
		else return this.caseServ.updateAdditionalAddress(caseId, data.additionalAddressId, data);
	}

	/**
	 * @description builds delete URL for deleting an additional address.
	 * @param caseId: inquiry/charge id
	 * @param adrseq: sequence number of the additional address
	 */
	deleteAdditionalAddresses(caseId: number, adrseq: number) {
		return this.caseServ.deleteAdditionalAddress(caseId, adrseq);
	}
	/**
	 * @description build the save URL for saving respondent
	 * @param data: payLoad
	 * @param partyType: type of the party
	 */
	saveRespondent(caseId: number, payload: RespondentVO) {
		return this.caseServ.updateRespondent(caseId, payload);
	}

	/**
	 * @description builds the save URL for charging party
	 * @param data: payLoad
	 * @param partyEmail: charging party email
	 * @param partyType: types of the party
	 */
	saveChargingParty(caseId: number, payload: ChargingPartyVO) {
		return this.caseServ.updateChargingParty(caseId, payload);
	}

	/**
	 * @description: get case linked charges.
	 * @param caseId: charge inquiry id
	 */
	getRelatedCharges = (caseId: number): Observable<number[]> => this.caseServ.getRelatedCharges(caseId);

	/**
	 * @description: link related charges.
	 * @param caseId : charge inquiry id
	 * @param caseIds: linked charge inquiry ids
	 */
	relateCharges = (caseId: number, caseIds: number[]): Observable<boolean> =>
		this.caseServ.relateCharges(caseId, caseIds);

	/**
	 * @description: unlink related charges.
	 * @param caseId : charge inquiry id
	 * @param caseIds: linked charge inquiry ids
	 */
	unRelateCharges = (caseId: number, caseIds: number[]): Observable<boolean> =>
		this.caseServ.unRelateCharges(caseId, caseIds);

	/**
	 * @description: update charge details.
	 * @param payload: object holds the updated info
	 */
	updateChargeDetail(payload: BaseCase): Observable<BaseCase> {
		return this.caseServ.updateCaseProperties(payload.chargeInquiryId, payload);
	}

	// *******NOTES*******/
	getAllNotes(caseId: number, isSystemicRoute, isAdr?): Observable<NoteVO[]> {
		if (isAdr) {
			return this.getADRNotes(caseId);
		}
		if (!this.isLitigation) {
			if (!isSystemicRoute) {
				return this.notesServ.getNotesByCharge(caseId);
			} else {
				return this.systemicServ.getSystemicNotesById(caseId);
			}
		}
		return this.litNoteServ.getNotes(caseId);
	}

	// *******ALLEGATION NOTES*******/
	getAllegationNotes(caseId: number): Observable<NoteVO[]> {
		return this.notesServ.getNotesByCharge(caseId, 'ALLEGATION');
	}

	saveNote(caseId: number, note: NoteVO, isAdr: boolean, isSystemicRoute): Observable<NoteVO[]> {
		if (!isSystemicRoute) {
			if (note?.noteId) {
				if (!this.isLitigation) {
					if (isAdr) {
						return this.notesServ.updateMediationNote(caseId, note.noteId, note);
					}
					return this.notesServ.updateNote(caseId, note.noteId, note);
				}
				return this.litNoteServ.updateNote(caseId, note.noteId, note as LitigationNoteVO);
			} else {
				if (!this.isLitigation) {
					if (isAdr) {
						return this.notesServ.addMediationNote(caseId, note);
					}
					return this.notesServ.addNote(caseId, note);
				}
				return this.litNoteServ.addNote(caseId, note as LitigationNoteVO);
			}
		} else {
			if (note?.noteId) {
				return this.systemicServ.updateSystemicNote(caseId, note.noteId, note);
			} else {
				return this.systemicServ.addSystemicNote(caseId, note);
			}
		}
	}

	deleteNote(caseId: number, noteId: number, isAdr: boolean, isSystemicRoute: boolean): Observable<any> {
		if (!isSystemicRoute) {
			if (!this.isLitigation) {
				if (isAdr) {
					return this.notesServ.deleteMediationNote(caseId, noteId);
				}
				return this.notesServ.deleteNote(caseId, noteId);
			}
			return this.litNoteServ.deleteNote(caseId, noteId);
		} else {
			return this.systemicServ.deleteSystemicNote(caseId, noteId);
		}
	}

	getParticularNotes(caseId: number): Observable<NoteVO[]> {
		return this.notesServ.getParticularNotesByCharge(caseId);
	}

	/**** ADR Notes *****/
	getADRNotes(caseId: number): Observable<NoteVO[]> {
		return this.notesServ.getMediationNotesByCharge(caseId);
	}
	// *******NOTES END*******/
	/**
	 * @description child components set this flag to true is there's something changed but not saved.
	 * @param flag
	 */
	setNoChangeDetacted(flag: boolean) {
		this.noChangeDetactedBehaviourSubject.next(flag);
	}

	/**
	 * @description case detail component listens to this flag, which decides to show the leave confirmation or not.
	 */
	getNoChangDetacted(): Observable<boolean> {
		return this.noChangeDetactedBehaviourSubject.asObservable();
	}

	setAssociatedChargesArray(asscChargesArray: LitigationCaseChargeVO[]) {
		return this.associatedChargedArrayBehaviorSubject.next(asscChargesArray);
	}

	getAssociatedChargesArray(): Observable<LitigationCaseChargeVO[]> {
		return this.associatedChargedArrayBehaviorSubject.asObservable();
	}

	/**
	 * @description: Get activity logs by charge number
	 */
	getActivityLog(
		pageable: Pageable,
		caseId: number,
		isAdr?: boolean,
		isAssessment?: boolean,
		isIntake?: boolean,
		isaCharge?: boolean,
		isDualFile?: boolean,
		isClosure?: boolean,
		isAllegation?: boolean
	): Observable<EventLogResponse> {
		return this.prepaEventLogServ.getApplicationEventLogsBycaseId(
			pageable,
			caseId,
			isAdr,
			isAssessment,
			isIntake,
			isaCharge,
			isDualFile,
			isClosure,
			isAllegation
		);
	}

	public getEventLogsByCaseIdAndEventCode(caseId: number, eventCode: string): Observable<any> {
		return this.prepaEventLogServ.getEventLogsByCaseIdAndEventCode(caseId, eventCode);
	}

	/**
	 * @description: Get email activity logs by charge number
	 */
	getEmailNotesForCase(caseId: string): Observable<ActivityLogVO[]> {
		return this.emailResourceService.getEmailNotesForCaseNumber(caseId);
	}

	/**
	 * @description get charge particular from service
	 * @param caseId
	 */
	getChargeParticular(caseId: number): Observable<ChargeDetailVO> {
		return this.particularServ.getCaseParticulars(caseId);
	}

	/**
	 * @description Update charge particular notes with service
	 * @param caseId
	 * @param payload
	 */
	updateChargeParticularNotes(caseId: number, payload: ChargeDetailVO): Observable<ChargeDetailVO> {
		return this.particularServ.updateCaseParticulars(caseId, payload);
	}

	getAllowableCaseStatusOptions(caseId: number): Observable<LookupData[]> {
		return this.caseServ.getAllowableCaseStatusOptions(caseId);
	}

	updateDowngradeChargeStatus(caseId: number, status: LookupData): Observable<BaseCase> {
		return this.caseServ.updateCaseStatus(caseId, status);
	}

	public updateCaseStatusAndStaffAssignment(caseId: number, caseStatusStaffAssignmentsVO: CaseStatusStaffAssignmentsVO): Observable<any> {
		return this.caseServ.updateCaseStatusAndStaffAssignment(caseId, caseStatusStaffAssignmentsVO);
	}

	/**
	 * @description Call service to set the deferring intent of a case.
	 * @param caseId
	 * @param deferralInfo
	 */
	setDeferringIntent(caseId: number, deferralInfo: DeferralInfoVO): Observable<DeferralInfoVO> {
		return this.prepadualFileServ.saveCaseDeferralInfo(caseId, deferralInfo);
	}

	/**
	 * @description Call service to get the deferring intent of a case.
	 * @param caseId
	 */
	getDeferringIntent(caseId: number): Observable<DeferralInfoVO> {
		return this.prepadualFileServ.getCaseDeferralInfo(caseId);
	}


	getMediatorStaffList(officecode): Observable<any> {
		const url = `${environment.umws}` + '/users?assignedOfficeCodes=' + officecode + '&isAdrStaff=true';
		return this.http.get(url);
	}

	createAndUploadDraft(caseId: number, isAmended: boolean): Observable<HttpResponse<Blob>> {
		return this.caseServ.createAndUploadDraft(caseId, isAmended, 'response');
	}

	sendToCP(caseId: number, isAmended?: boolean): Observable<boolean> {
		return this.portalServ.sendToCP(caseId, isAmended);
	}

	getChargePartyComments(caseId: number): Observable<any> {
		return this.portalServ.getChargePartyComments(caseId);
	}

	getAssessmentApproverList(officeCodes: string[]): Observable<User[]> {
		return this.cmnServ.getActiveUsers({ assignedOfficeCodes: officeCodes }).pipe(
			map((rs: any) =>
				rs.filter((user) => {
					let isSupervisor = false;
					officeCodes.forEach((officeCode) => {
						const roleAssignments = user.roleAssignments[officeCode] ? user.roleAssignments[officeCode] : [];
						roleAssignments.forEach((item) => {
							if (item.roleCode.includes('SUPERVISOR') || item.roleCode.includes('LEGAL')) {
								isSupervisor = true;
							}
						});
					});
					return isSupervisor;
				})
			)
		);
	}

	generateRfi(caseId: number, payload?: RfiGenerationRequestVO): Observable<HttpResponse<Blob>> {
		return this.rfiServ.generateRfi(caseId, payload, 'response');
	}

	getRfiContent(caseId: number, instuction: boolean): Observable<string> {
		return this.rfiServ.getRfiContent(caseId, instuction);
	}

	assignAssessmentApproval(caseId: number, userdetailId: string): Observable<boolean> {
		return this.prepaAssess.assignAssessment(caseId, userdetailId);
	}

	approveAssessment(caseId: number, userdetailId: string): Observable<boolean> {
		return this.prepaAssess.approveAssessment(caseId, userdetailId);
	}

	/**
	 * @description call service to get the position statement data
	 * @param caseId
	 */
	getPositionStatement(caseId: number): Observable<PositionStatementVO> {
		return this.enforcementService.getPositionStatementData(caseId);
	}

	/**
	 * @description call service to add the position statement informtion
	 * @param caseId
	 * @param row to send all the selected dates
	 */
	savePositionStatement(caseId: number, row: any): Observable<PositionStatementVO> {
		return this.enforcementService.updatePositionStatementData(caseId, row);
	}

	/**
	 * @description call service to get the On Site data
	 * @param caseId
	 */
	getOnsiteData(caseId: number): Observable<OnsiteInterviewVO[]> {
		return this.enforcementService.getOnsiteData(caseId);
	}

	/**
	 * @description call service to update the On Site informtion
	 * @param caseId
	 * @param row to send updated information to service
	 */
	updateOnsiteData(caseId: number, row: any): Observable<OnsiteInterviewVO> {
		return this.enforcementService.updateOnsiteData(caseId, row);
	}

	/**
	 * @description call service to delete the slected On Site informtion
	 * @param caseId
	 * @param onSiteId
	 */
	deleteOnsiteData(caseId: number, onSiteId: any): Observable<OnsiteInterviewVO> {
		return this.enforcementService.deleteOnsiteData(caseId, onSiteId);
	}

	/**
	 * @description call service to add the On Site informtion
	 * @param caseId
	 * @param row
	 */
	addOnsiteData(caseId: number, row: any): Observable<OnsiteInterviewVO> {
		return this.enforcementService.addOnsiteData(caseId, row);
	}

	// /**
	// * @description call service to get the Communication Data
	// * @param caseId
	// */
	// getCommunicationData(caseId: number): Observable<CommunicationResponse> {
	//   return this.enforcementService.getCommunicationData(caseId);
	// }

	// /**
	// * @description call service to add the Communication data
	// * @param caseId
	// * @param row
	// */
	// addCommunicationData(caseId: number, row: any): Observable<CommunicationResponse> {
	//   return this.enforcementService.addCommunicationData(caseId, row);
	// }

	// /**
	// * @description call service to delete the communication row
	// * @param caseId
	// * @param communicationId
	// */
	// deleteCommunicationData(caseId: number, communicationId: number): Observable<CommunicationResponse> {
	//   return this.enforcementService.deleteCommunicationData(caseId, communicationId);
	// }

	// /**
	// * @description call service to update the communication row
	// * @param caseId
	// * @param row
	// */
	// updateCommunicationData(caseId: number, row: any): Observable<CommunicationResponse> {
	//   return this.enforcementService.updateCommunicationData(caseId, row);
	// }

	getFactFindingData(caseId: number): Observable<FactFindingVO[]> {
		return this.enforcementService.getFactFindingData(caseId);
	}

	getSubpoenaData(caseId: number) {
		return this.enforcementService.getSubpoenaData(caseId);
	}

	addSubpoenaData(caseId: number, subpoenadata: SubpoenaVO) {
		return this.enforcementService.addSubpoenaData(caseId, subpoenadata);
	}

	updateSubpoenaData(caseId: number, subpoenadata: SubpoenaVO) {
		return this.enforcementService.updateSubpoenaData(caseId, subpoenadata);
	}

	deleteSubpoenaData(caseId: number, subpoenaId: number): Observable<boolean> {
		return this.enforcementService.deleteSubpoenaData(caseId, subpoenaId);
	}

	addFactFindingData(caseId: number, factFindingVO: FactFindingVO): Observable<FactFindingVO> {
		return this.enforcementService.addFactFindingData(caseId, factFindingVO);
	}

	updateFactFindingData(caseId: number, factFindingVO: FactFindingVO): Observable<FactFindingVO> {
		return this.enforcementService.updateFactFindingData(caseId, factFindingVO);
	}

	deleteFactFindingData(caseId: number, factFindingId: number): Observable<FactFindingVO> {
		return this.enforcementService.deleteFactFindingData(caseId, factFindingId);
	}

	// conciliation conferences
	getConciliationConference(caseId: number): Observable<EnforcementConferenceRequestVO[]> {
		return this.conciliationConferenceService.getEnforcementConferences(caseId);
	}

	addConciliationConference(
		caseId: number,
		payload: EnforcementConferenceRequestVO
	): Observable<EnforcementConferenceRequestVO> {
		return this.conciliationConferenceService.createConference(caseId, payload);
	}

	updateConciliationConference(
		caseId: number,
		conferenceId: number,
		payload: EnforcementConferenceRequestVO
	): Observable<EnforcementConferenceRequestVO> {
		return this.conciliationConferenceService.updateConference(caseId, conferenceId, payload);
	}

	removeConciliationConference(caseId: number, conferenceId: number): Observable<EnforcementConferenceRequestVO> {
		return this.conciliationConferenceService.removeConferenceById(caseId, conferenceId);
	}

	//conciliation proposal
	getConciliationProposal(caseId: number): Observable<ConciliationRequestVO> {
		return this.conciliationProposalService.getConciliationProposal(caseId);
	}

	saveConciliationProposal(caseId: number, payload: ConciliationRequestVO): Observable<boolean> {
		return this.conciliationProposalService.updateConciliationProposal(caseId, payload);
	}

	/**
	 * @description call service to get all suspention reasons
	 * @param chargeInquiryId
	 */
	getSuspentionReasons() {
		return this.suspendService.getAvailableSuspensionReasons();
	}

	/**
	 * @description call service to suspend the case
	 * @param chargeInquiryId
	 * @param suspensionReasonVO
	 */
	caseSuspension(chargeInquiryId: string, suspensionReasonVO: SuspensionReasonVO) {
		return this.suspendService.caseSuspension(chargeInquiryId, suspensionReasonVO);
	}

	/**
	 * @description call service to update the reason text
	 * @param chargeInquiryId
	 * @param suspensionId
	 * @param suspensionReasonVO
	 */
	updateCaseSuspension(chargeInquiryId: string, suspensionId: string, suspensionReasonVO: SuspensionReasonVO) {
		return this.suspendService.caseSuspensionUpdateOtherReasonText(chargeInquiryId, suspensionId, suspensionReasonVO);
	}

	/**
	 * @description call service to remove the suspention
	 * @param chargeInquiryId
	 * @param suspensionId
	 */
	removeSuspension(chargeInquiryId: string, suspensionId: string) {
		return this.suspendService.removeCaseSuspension(chargeInquiryId, suspensionId);
	}

	/**
	 * @description call service to get the reason and ID
	 */
	getModifiedReasons(chargeInquiryId: string) {
		return this.suspendService.getSuspensionReason(chargeInquiryId);
	}

	/**
	 * @description call service to get the formalized case age
	 */
	getCaseAge(chargeInquiryId: string) {
		return this.suspendService.getCaseAge(chargeInquiryId);
	}

	updateAllegation(caseId: number, allegation: AllegationVO): Observable<AllegationVO> {
		return this.allegationserv.updateAllegation(caseId, allegation.allegationId, allegation);
	}

	/**
	 * @description service call for getting review types
	 */
	getReviews(caseId: number) {
		return this.caseReviewServ.getCaseReviewTypes(caseId);
	}

	/**
	 * @description service call for getting Assigned reviews for particular case
	 * @param caseId
	 */
	getCaseReviewAssignment(caseId: number) {
		return this.caseReviewServ.getCaseReviewAssignmentsForCaseId(caseId);
	}

	/**
	 * @description service call for assign and reassign a review
	 * @param caseId
	 * @param caseReviewAssignmentVO
	 */
	saveReview(caseId: number, caseReviewAssignmentVO: CaseReviewAssignmentVO) {
		return this.caseReviewServ.assignCaseReview(caseId, caseReviewAssignmentVO);
	}

	/**
	 * @description service call for removing a review
	 * @param caseId
	 * @param caseReviewAssignmentVO
	 */

	removeReview(caseId: number, assignmentId: number) {
		return this.caseReviewServ.removeCaseAssignmentReview(caseId, assignmentId);
	}

	getReviewAssignmentsByUserId(caseId: number, userDetailId: string) {
		return this.caseReviewServ.getCaseReviewAssignmentsForCaseIdAndUserId(caseId, userDetailId, ArcStatusCodes.PENDING);
	}

	approveOrRejectReview(caseId: number, reviewId: number, caseReviewAssignmentVO: CaseReviewAssignmentVO) {
		return this.caseReviewServ.approveOrRejectCaseReview(caseId, reviewId, caseReviewAssignmentVO);
	}

	getSuspendNocData(caseId: number) {
		return this.suspendNocServ.getSuspendNocDetails(caseId);
	}

	updateSuspendNocData(caseId: number, suspendNocVO: SuspendNocVO) {
		return this.suspendNocServ.suspendNoc(caseId, suspendNocVO);
	}

	/**
	 * @description call service to get the Communication Data
	 * @param caseId
	 */
	getCommunicationData(caseId: number) {
		return this.enforcementService.getCommunicationData(caseId);
	}

	/**
	 * @description call service to add the Communication data
	 * @param caseId
	 * @param row
	 */
	addCommunicationData(caseId: number, row: any) {
		return this.enforcementService.addCommunicationData(caseId, row);
	}

	/**
	 * @description call service to update the communication row
	 * @param caseId
	 * @param row
	 */
	updateCommunicationData(caseId: number, row: any) {
		return this.enforcementService.updateCommunicationData(caseId, row);
	}
	/**
	 * @description call service to delete the communication row
	 * @param caseId
	 * @param communicationId
	 */
	deleteCommunicationData(caseId: number, communicationId: number) {
		return this.enforcementService.deleteCommunicationData(caseId, communicationId);
	}

	/**
	 * @description call service to delete the communication row
	 * @param caseId
	 */

	getAdditionalRespondents(caseId: number) {
		return this.caseServ.getAdditionalRespondents(caseId);
	}

	/**
	 * @description call service to delete the communication row
	 * @param caseId
	 * @param respondentVO
	 */
	saveAdditionalRespondents(caseId: number, respondentVO: RespondentVO, isUpdate: boolean = false) {
		if (!isUpdate) {
			return this.caseServ.addAdditionalRespondent(caseId, respondentVO);
		}
		return this.caseServ.updateAdditionalRespondent(caseId, respondentVO);
	}

	deleteAdditionalRespondent(caseId: number, additionalRespondentId: number) {
		return this.caseServ.deleteAdditionalRespondent(caseId, additionalRespondentId);
	}

	getClosureReasonsForCharge(caseId: number) {
		return this.prepaCloseServ.getClosureReasonsForCharge(caseId);
	}

	getChargeClosureByFEPA(caseId: number): Observable<boolean> {
		return this.prepaCloseServ.getChargeClosureByFEPA(caseId);
	}

	closeAllegation(caseId: number, closureVO: AllegationClosureVO[]) {
		return this.prepaCloseServ.closeAllegations(caseId, closureVO);
	}
	closeCharge(caseId: number, closureVO: ClosureVO) {
		return this.prepaCloseServ.closeCharge(caseId, closureVO);
	}

	getAdrBenefitTypes() {
		return this.prepaCloseServ.getADRBenefitCodes();
	}

	/**
	 * Add/Update closure reason and details
	 * @param caseId
	 * @param closureVO
	 */
	updateChargeClosureReason(caseId: number, closureVO: ClosureVO) {
		return this.prepaCloseServ.updateChargeClosureReason(caseId, closureVO);
	}

	/**
	 * Assign benefits to an allegation
	 * Assign beneficiary(ies) and benefits to an allegation
	 * @param caseId
	 * @param allegationId
	 * @param beneficiaryVO
	 */
	assignBenefitsToAllegation(caseId: number, benefitGroupVO: Array<BenefitGroupVO>) {
		return this.prepaCloseServ.bulkAssignBenefitsToAllegations(caseId, benefitGroupVO);
	}

	/**
	 * Get benefits for an allegation
	 * Get beneficiary(ies) and benefits for an allegation
	 * @param caseId
	 * @param allegationId
	 */
	getBenefitsForAllegation(caseId: number, allegationIds: Array<number>) {
		return this.prepaCloseServ.getBenefitGroupsForAllegations(caseId, allegationIds);
	}

	deleteBenefitsForAllegation(caseId: number, beneficiaryId: number) {
		return this.prepaCloseServ.deleteBeneficiaryAndBenefitsByBeneficiaryID(caseId, beneficiaryId);
	}

	closeChargeWithoutReview(caseId: number, payload: ClosureVO) {
		return this.prepaCloseServ.closeChargeWithoutReview(caseId, payload);
	}

	deFormalizeCharge(caseId: number, chargeDeformalizationVO: ChargeDeformalizationVO) {
		return this.caseServ.deFormalizeCharge(caseId, chargeDeformalizationVO);
	}

	/**
	 * Get Charge Reopen Reasons and Authorities
	 */
	getChargeReopenReasons() {
		return this.prepaReopenCaseServ.getChargeReopenReasonsAndAuthorities();
	}

	getChargeClosureReasons(isFepa?: boolean, isAdr?: boolean): Observable<Array<LookupData>> {
		return this.prepaCloseServ.getChargeClosureReasons(isFepa, isAdr);
	}

	generateNrts(caseId: number, lookupData: LookupData): Observable<any> {
		return this.prepaCloseServ.generateNrts(caseId, lookupData);
	}

	reopenCase(caseId: number, reopenVO: ReopenVO) {
		return this.prepaReopenCaseServ.reopenCase(caseId, reopenVO);
	}

	getEeo1MultiData(unitNbr: string) {
		return this.caseServ.getEeo1MultiData(unitNbr);
	}

	getFepaContracts(
		eeocOfficeCodes?: Array<string>,
		fepaOfficeCodes?: Array<string>
	): Observable<Array<FepaContractVO>> {
		return this.fepaContractServ.getFepaContracts(eeocOfficeCodes, fepaOfficeCodes);
	}

	createCreditRequest(payload: CreditRequestVO): Observable<CreditRequestResponse> {
		return this.fepaCreditServ.createCreditRequest(payload);
	}

	public fetchFepaCreditRequests = (caseId: number): Observable<CreditRequestResponse> =>
		this.fepaCreditServ.getFepaCreditRequestById(caseId);

	public updateCreditRequest = (
		fepaCreditRequestId: number,
		creditRequestVO: CreditRequestVO
	): Observable<CreditRequestResponse> => this.fepaCreditServ.updateCreditRequest(fepaCreditRequestId, creditRequestVO);

	rescindCreditRequest(requestId: number): Observable<any> {
		return this.fepaCreditServ.rescindCreditRequest(requestId);
	}

	public addCreditEEOCResponse = (
		fepaCreditRequestId: number,
		eeocResponse: CreditEEOCResponseVO
	): Observable<CreditRequestResponse> => this.fepaCreditServ.addCreditEEOCResponse(fepaCreditRequestId, eeocResponse);

	public fetchSwrReviewDetails = (fepaCreditRequestId: number): Observable<ReviewDetails> =>
		this.fepaCreditServ.getSwrReviewDetails(fepaCreditRequestId);

	public createSwrReviewDetails = (
		fepaCreditRequestId: number,
		reviewDetails: ReviewDetails
	): Observable<ReviewDetails> => this.fepaCreditServ.createSwrReviewDetails(fepaCreditRequestId, reviewDetails);

	public updateSwrReviewDetails = (
		fepaCreditRequestId: number,
		reviewDetails: ReviewDetails
	): Observable<ReviewDetails> => this.fepaCreditServ.updateSwrReviewDetails(fepaCreditRequestId, reviewDetails);

	public generateSwr(caseId: number, reviewDetails: ReviewDetails): Observable<any> {
		return this.fepaCreditServ.generateSwr(caseId, reviewDetails);
	}

	public getSystemicCaseInformation(): Observable<Array<SystemicCaseResponse>> {
		return this.systemicServ.getAllSystemicCases();
	}

	public addSystemicCaseToCharges(
		id: number,
		systemicChargeVO: Array<SystemicChargeVO>
	): Observable<Array<SystemicCaseChargeResponse>> {
		return this.systemicServ.addSystemicCaseToCharges(id, systemicChargeVO);
	}

	public deleteSystemicCaseCharges(id: number, chargeIds: Array<number>): Observable<boolean> {
		return this.systemicServ.deleteSystemicCaseCharges(id, chargeIds);
	}

	public getChargeSystemicCaseDetails(chargeId: number): Observable<Array<ChargeSystemicDetailResponse>> {
		return this.systemicServ.getChargeSystemicCaseDetails(chargeId);
	}

	public getSystemicCaseDetails(systemicId: number): Observable<SystemicCaseDetailResponse> {
		return this.systemicServ.getSystemicCaseDetails(systemicId);
	}

	public setComplianceOutcome(caseId: number, complianceOutcome: ComplianceOutcomeVO[]) {
		return this.complianceServ.setComplianceOutcome(caseId, complianceOutcome);
	}

	public sendToLitigation(caseId: number, reasonId: number, isSuspended?: boolean): Observable<any> {
		return this.legalCaseService.sendToLitigation(caseId, reasonId, isSuspended);
	}

	public downloadEmailContentByCaseId(caseId: string): Observable<any> {
		return this.emailResourceService.downloadEmailContentByCaseId(caseId);
	}

	public downloadByCaseNumber(caseId: string, casenumber?: string): Observable<any> {
		return this.emailResourceService.downloadByCaseNumber(caseId, null, 'response');
	}

	public downloadActivityLog(caseId: number, docExt): Observable<any> {
		return this.prepaEventLogServ.downloadByCaseNumber(caseId, docExt);
	}

	getChargeClassGroupDetails(caseId: number): Observable<ClassGroupResponse> {
		return this.classGroupServ.getChargeClassGroupDetails(caseId);
	}

	getLitigationCase(litigationCaseId: number): Observable<LitigationCaseVO> {
		return this.litigationServ.getLitigationCase(litigationCaseId);
	}

	setRecommendationForCase(litigationCaseId: number, payload: any): Observable<LitigationCaseVO> {
		return this.litigationServ.setRecommendationForCase(litigationCaseId, payload);
	}

	updateLitigationCase(litigationCaseId: number, payload: LitigationStatusVO): Observable<boolean> {
		return this.litigationServ.updateCaseStatus(litigationCaseId, payload);
	}

	openLitigationCase(litigationCaseId: number, payLoad: any): Observable<boolean> {
		return this.litigationServ.reopenCase(litigationCaseId, payLoad);
	}

	setDeterminationForCase(litigationCaseId: number, payload: any): Observable<LitigationCaseVO> {
		return this.litigationServ.setDeterminationForCase(litigationCaseId, payload);
	}

	downloadRecommendation(litigationCaseId: number, payload: any): Observable<any> {
		return this.litDownloadServ.downloadDocumentFromTemplate(litigationCaseId, payload);
	}

	holdRecommendationForCase(litigationCaseId: number, payload: any): Observable<LitigationCaseVO> {
		return this.litigationServ.holdRecommendationForCase(litigationCaseId, payload);
	}

	withdrawRecommendationForCase(litigationCaseId: number, payload: any): Observable<LitigationCaseVO> {
		return this.litigationServ.withdrawRecommendationForCase(litigationCaseId, payload);
	}

	withdrawDeterminationForCase(litigationCaseId: number, payload: any): Observable<LitigationCaseVO> {
		return this.litigationServ.withdrawDeterminationForCase(litigationCaseId, payload);
	}

	updateCase(litigationCaseId: number, litigationCaseVO: LitigationCaseVO): Observable<any> {
		return this.litigationServ.updateCase(litigationCaseId, litigationCaseVO);
	}

	getLitigationCaseCharges(litigationCaseId: number): Observable<Array<LitigationCaseChargeVO>> {
		return this.litigationServ.getChargesForLitigationCase(litigationCaseId);
	}

	associateChargesToExistingCase(
		litigationCaseId: number,
		listOfChargeSeqId: Array<number>
	): Observable<Array<LitigationChargeAsscVO>> {
		return this.litigationServ.associateChargesToExistingCase(litigationCaseId, listOfChargeSeqId);
	}

	removeChargesToExistingCase(
		litigationCaseId: number,
		listOfChargeSeqId: Array<number>
	): Observable<Array<LitigationChargeAsscVO>> {
		return this.litigationServ.removeChargesFromExistingCase(litigationCaseId, listOfChargeSeqId);
	}

	addAllegationsForLitgation(
		caseId: number,
		litigationAllegationVO: LitigationAllegationVO[]
	): Observable<Array<LitigationAllegationVO>> {
		return this.litigationAllegationServ.createAllegationForCaseId(caseId, litigationAllegationVO);
	}

	/**
	 * @description get allegations by caseId
	 */
	getAllegationsForLitigation(caseId: number): Observable<Array<LitigationAllegationVO>> {
		return this.litigationAllegationServ.getAllegationsForCaseId(caseId);
	}

	/**
	 * @description delete allegations  when passed as list
	 */
	deleteLitAllegations(caseId: number, LitAllegationIds: number[]): Observable<Array<LitigationAllegationVO>> {
		return this.litigationAllegationServ.deleteAllegationForCaseId(caseId, LitAllegationIds);
	}

	/**
	 * @description update allegations list for a case
	 */
	modifyLitAllegations(
		caseId: number,
		LitAllegations: LitigationAllegationVO[]
	): Observable<Array<LitigationAllegationVO>> {
		return this.litigationAllegationServ.updateBulkAllegationsForCaseId(caseId, LitAllegations);
	}

	/**
	 * @description to get staff assigned to the litigation case
	 */
	getActiveCaseAssignments(litigationCaseId: number): Observable<Array<LitigationAssignmentVO>> {
		return this.litigationCaseAssignServ.getActiveCaseAssignments(litigationCaseId);
	}

	/**
	 * @description to assign new staff to the litigation case
	 */
	addCaseStaffAssignment(
		litigationCaseId: number,
		litigationAssignmentVO: LitigationAssignmentVO
	): Observable<LitigationAssignmentVO> {
		return this.litigationCaseAssignServ.addCaseAssignment(litigationCaseId, litigationAssignmentVO);
	}

	/**
	 * @description to remove staff assigned to the litigation case
	 */
	removeCaseAssignment(litigationCaseId: number, caseAssignmentId: number): Observable<any> {
		return this.litigationCaseAssignServ.endAssignment1(litigationCaseId, caseAssignmentId);
	}

	/**
	 * @description to get role of staff assigned to the litigation case
	 */
	getRoleAssignments(litigationCaseId: number): Observable<Array<LitigationStaffAsscVO>> {
		return this.LitigationRoleAssignServ.getActiveAssignments(litigationCaseId);
	}

	/**
	 * @description to add role for staff assigned to the litigation case
	 */
	addAssignment(
		litigationCaseId: number,
		litigationStaffAsscVO: LitigationStaffAsscVO
	): Observable<LitigationStaffAsscVO> {
		return this.LitigationRoleAssignServ.addAssignment(litigationCaseId, litigationStaffAsscVO);
	}

	/**
	 * @description to remove role of staff assigned to the litigation case
	 */
	removeRoleAssignment(litigationCaseId: number, roleAssignmentId: number): Observable<LitigationStaffAsscVO> {
		return this.LitigationRoleAssignServ.endAssignment(litigationCaseId, roleAssignmentId);
	}

	addEvents(caseId: number, eventVO: Array<EventVO>): Observable<any> {
		return this.eventLogServ.addEvents(caseId, eventVO);
	}

	/**
	 * Returns whether EEOC investigates or not
	 * @param caseId
	 */
	public isEeocInvestigating(caseId: number): Observable<EeocInvestigateDetails> {
		return this.portalServ.isEeocInvestigating(caseId);
	}

	/**
	 * @description to hard delete staff assigned with role to the litigation case
	 */
	deleteStaffAssignmentWithRole(litigationCaseId: number, roleAssignmentId: number): Observable<LitigationStaffAsscVO> {
		return this.LitigationRoleAssignServ.deleteRoleAssignment(litigationCaseId, roleAssignmentId);
	}

	/**
	 * Add a Charging Party to a Litigation Case
	 * Add a Charging Party to a Litigation Case. Creates a Charging party and associated with Case.
	 * @param litigationCaseId
	 * @param chargingPartyVO
	 */

	addChargingParty(
		litigationCaseId: number,
		chargingParty: litigationChargingPartyVO
	): Observable<litigationChargingPartyVO> {
		return this.chargingPartyServ.addChargingParty(litigationCaseId, chargingParty);
	}

	/**
	 * Retrieve the Charging Parties of a Litigation Case
	 * @param litigationCaseId
	 */
	getChargingParties(litigationCaseId: number): Observable<Array<ChargingPartyLightVO>> {
		return this.chargingPartyServ.getChargingParties(litigationCaseId);
	}

	/**
	 * Retrieve a single Charging Party of a Litigation Case
	 * @param litigationCaseId
	 * @param chargingPartyId
	 */
	public getIndividual(litigationCaseId: number, chargingPartyId: number): Observable<litigationChargingPartyVO> {
		return this.chargingPartyServ.getChargingParty(litigationCaseId, chargingPartyId);
	}

	/**
	 * Updates the Charging Party of a Litigation Case
	 * @param litigationCaseId
	 * @param chargingPartyVO
	 */
	updateChargingParty(
		litigationCaseId: number,
		chargingPartyVO: litigationChargingPartyVO
	): Observable<litigationChargingPartyVO> {
		return this.chargingPartyServ.updateChargingParty(litigationCaseId, chargingPartyVO);
	}

	/**
	 * Removes a list of Charging Parties associated with a Litigation Case
	 * @param litigationCaseId
	 * @param cp
	 */
	public removeChargingPartyFromCase(litigationCaseId: number, cp: Array<number>): Observable<any> {
		return this.chargingPartyServ.removeChargingPartyFromCase(litigationCaseId, cp);
	}

	/**
	 * @param litigationCaseId
	 * @param respondentVO
	 */
	public createDefendant(
		litigationCaseId: number,
		respondentVO: LitigationRespondentVO
	): Observable<LitigationRespondentVO> {
		return this.defendantServ.createDefendant(litigationCaseId, respondentVO);
	}

	/**
	 * deleting defendant
	 * @param litigationCaseId
	 * @param defendantId
	 */
	public deleteDefendant(litigationCaseId: number, defendantId: number): Observable<any> {
		return this.defendantServ.deleteDefendant(litigationCaseId, defendantId);
	}

	/**
	 * get defendants
	 * @param litigationCaseId
	 */
	public getDefendants(litigationCaseId: number): Observable<Array<LitigationRespondentVO>> {
		return this.defendantServ.getDefendants(litigationCaseId);
	}

	/**
	 * @param litigationCaseId
	 * @param defendantId
	 */
	public updateDefendant(
		litigationCaseId: number,
		defendantId: number,
		respondentVO: LitigationRespondentVO
	): Observable<LitigationRespondentVO> {
		return this.defendantServ.updateDefendant(litigationCaseId, defendantId, respondentVO);
	}

	/**
	 * @param litigationCaseId
	 * @param additionalPartyVO
	 */
	public addAdditionalParties(
		litigationCaseId: number,
		additionalPartyVO: AdditionalPartyVO
	): Observable<AdditionalPartyVO> {
		return this.litAdditionalPartyServ.addAdditionalParties(litigationCaseId, additionalPartyVO);
	}

	/**
	 * @param litigationCaseId
	 * @param additionalPartyId
	 */
	public deleteAdditionalParties(litigationCaseId: number, additionalPartyId: number): Observable<any> {
		return this.litAdditionalPartyServ.deleteAdditionalParties(litigationCaseId, additionalPartyId);
	}

	/**
	 * @param litigationCaseId
	 */
	public getAdditionalParties(litigationCaseId: number): Observable<Array<AdditionalPartyVO>> {
		return this.litAdditionalPartyServ.getAdditionalParties(litigationCaseId);
	}

	/**
	 * @param litigationCaseId
	 * @param additionalPartyVO
	 */
	public updateAdditionalParties(
		litigationCaseId: number,
		additionalPartyVO: AdditionalPartyVO
	): Observable<AdditionalPartyVO> {
		return this.litAdditionalPartyServ.updateAdditionalParties(litigationCaseId, additionalPartyVO);
	}

	/**
	 * @param litigationCaseId
	 * @param enforceSubpoenaLitigationStatus
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public setSubpoenaLitigationStatus(
		litigationCaseId: number,
		enforceSubpoenaLitigationStatus: EnforceSubpoenaLitigationStatus
	): Observable<LitigationCaseVO> {
		return this.litigationServ.setSubpoenaLitigationStatus(litigationCaseId, enforceSubpoenaLitigationStatus);
	}

	/**
	 * Court Filing----
	 */

	/**
	 * @param litigationCaseId
	 * @param litigationTrialVO
	 */
	public addTrialInformation(
		litigationCaseId: number,
		litigationTrialVO: LitigationTrialVO
	): Observable<Array<LitigationTrialVO>> {
		return this.courtFilingServ.addTrialInformation(litigationCaseId, litigationTrialVO);
	}

	/**
	 * @param litigationCaseId
	 * @param trialId
	 */
	public deleteTrialInformation(litigationCaseId: number, trialId: number): Observable<any> {
		return this.courtFilingServ.deleteTrialInformation(litigationCaseId, trialId);
	}

	/**
	 * @param litigationCaseId
	 */
	public getTrialInformation(litigationCaseId: number): Observable<Array<LitigationTrialVO>> {
		return this.courtFilingServ.getTrialInformation(litigationCaseId);
	}

	/**
	 * @param litigationCaseId
	 * @description removing all the complaint filing information when clearing the court filing date
	 */
	public removeCourtFilingInformation(litigationCaseId: number): Observable<boolean> {
		return this.courtFilingServ.removeCourtFilingInformation(litigationCaseId);
	}

	/**
	 * @param litigationCaseId
	 * @param litigationCourtFilingVO
	 */
	public setCourtFilingInformation(
		litigationCaseId: number,
		litigationCourtFilingVO: LitigationCourtFilingVO
	): Observable<LitigationCaseVO> {
		return this.courtFilingServ.setCourtFilingInformation(litigationCaseId, litigationCourtFilingVO);
	}

	/**
	 * @param litigationCaseId
	 * @param trialId
	 * @param litigationTrialVO
	 */
	public updateTrialInformation(
		litigationCaseId: number,
		trialId: number,
		litigationTrialVO: LitigationTrialVO
	): Observable<Array<LitigationTrialVO>> {
		return this.courtFilingServ.updateTrialInformation(litigationCaseId, trialId, litigationTrialVO);
	}

	/**
	 * @param transferRequest
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public tranferLitigationCase(transferRequest: TransferRequest): Observable<TransferResponse> {
		return this.litigationServ.tranferLitigationCase(transferRequest);
	}

	/**
	 * Get Resolutions associated with a Litigation Case for Defendants and allegations
	 * @param litigationCaseId
	 */
	public getResolutions(litigationCaseId: number): Observable<Array<LitigationResolutionVO>> {
		return this.litResolutionServ.getResolutions(litigationCaseId);
	}

	/**
	 * Add Resolutions associated with a Litigation Case for Defendants and allegations
	 * @param litigationCaseId
	 * @param litigationResolutionVO
	 */
	public addResolutions(
		litigationCaseId: number,
		litigationResolutionVO: Array<LitigationResolutionVO>
	): Observable<Array<LitigationResolutionVO>> {
		return this.litResolutionServ.addResolutions(litigationCaseId, litigationResolutionVO);
	}

	/**
	 * Removes Resolutions associated with a Litigation Case by resolution Id
	 * @param litigationCaseId
	 * @param resolutionId
	 */
	public removeResolutionById(litigationCaseId: number, resolutionId: number): Observable<boolean> {
		return this.litResolutionServ.removeResolutionById(litigationCaseId, resolutionId);
	}

	/**
	 * Update Resolutions associated with a Litigation Case for Defendants and allegations
	 * @param litigationCaseId
	 * @param resolutionId
	 * @param litigationResolutionVO
	 */
	public updateResolutions(
		litigationCaseId: number,
		resolutionId: number,
		litigationResolutionVO: LitigationResolutionVO
	): Observable<LitigationResolutionVO> {
		return this.litResolutionServ.updateResolutions(litigationCaseId, resolutionId, litigationResolutionVO);
	}

	// Post Resolutions
	/**
	 * Add post-resolutions associated with a Litigation Case
	 * @param litigationCaseId
	 * @param litigationPostResolutionVO
	 */
	public addPostResolutions(
		litigationCaseId: number,
		litigationPostResolutionVO: LitigationPostResolutionVO
	): Observable<Array<LitigationPostResolutionVO>> {
		return this.litPostResolutionServ.addPostResolutions(litigationCaseId, litigationPostResolutionVO);
	}

	/**
	 * Get post-resolutions associated with a Litigation Case
	 * @param litigationCaseId
	 */
	public getPostResolutions(litigationCaseId: number): Observable<Array<LitigationPostResolutionVO>> {
		return this.litPostResolutionServ.getPostResolutions(litigationCaseId);
	}

	/**
	 * Removes post-resolutions associated with a Litigation Case by post resolution Id
	 * @param litigationCaseId
	 * @param postResolutionId
	 */
	public removePostResolutionById(litigationCaseId: number, postResolutionId: number): Observable<boolean> {
		return this.litPostResolutionServ.removePostResolutionById1(litigationCaseId, postResolutionId);
	}

	public removePostResolutions(litigationCaseId: number): Observable<boolean> {
		return this.litPostResolutionServ.removePostResolutionById(litigationCaseId);
	}

	/**
	 * Update post-resolutions associated with a Litigation Case
	 * @param litigationCaseId
	 * @param postResolutionId
	 * @param litigationPostResolutionVO
	 */
	public updatePostResolutions(
		litigationCaseId: number,
		postResolutionId: number,
		litigationPostResolutionVO: LitigationPostResolutionVO
	): Observable<LitigationPostResolutionVO> {
		return this.litPostResolutionServ.updatePostResolutions(
			litigationCaseId,
			postResolutionId,
			litigationPostResolutionVO
		);
	}

	// PetitionEnforceConsentDecree

	/**
	 * Add Enforce Consent Decree details to a Litigation Case
	 * @param litigationCaseId
	 * @param litigationPetitionVO
	 */
	public addPetition(
		litigationCaseId: number,
		litigationPetitionVO: LitigationPetitionVO
	): Observable<LitigationPetitionVO> {
		return this.litPetitionServ.addPetition(litigationCaseId, litigationPetitionVO);
	}

	/**
	 * Get Enforce Consent Decree details to a Litigation Case
	 * @param litigationCaseId
	 */
	public getPetitions(litigationCaseId: number): Observable<Array<LitigationPetitionVO>> {
		return this.litPetitionServ.getPetitions(litigationCaseId);
	}

	/**
	 * Removes Enforce Consent Decree details to a Litigation Case
	 * @param litigationCaseId
	 * @param petitionId
	 */
	public removePetitionById(litigationCaseId: number, petitionId: number): Observable<boolean> {
		return this.litPetitionServ.removePetitionById(litigationCaseId, petitionId);
	}

	/**
	 * Update Enforce Consent Decree details to a Litigation Case
	 * @param litigationCaseId
	 * @param petitionId
	 * @param litigationPetitionVO
	 */
	public updatePetition(
		litigationCaseId: number,
		petitionId: number,
		litigationPetitionVO: LitigationPetitionVO
	): Observable<LitigationPetitionVO> {
		return this.litPetitionServ.updatePetition(litigationCaseId, petitionId, litigationPetitionVO);
	}

	// Litigation Benefits

	/**
	 * Creates benefits for a new or existing Beneficiary
	 * @param caseId
	 * @param litigationBeneficiaryVO
	 */
	public createBenefitsForBeneficiary(
		caseId: number,
		litigationBeneficiaryVO: LitigationBeneficiaryVO
	): Observable<LitigationBeneficiaryVO> {
		return this.litBenefitsServ.createBenefitsForBeneficiary(caseId, litigationBeneficiaryVO);
	}

	/**
	 * Delete a list of benefits
	 * @param caseId
	 * @param benefitid
	 */
	public deleteBenefits(caseId: number, benefitid: Array<number>): Observable<any> {
		return this.litBenefitsServ.deleteBenefits(caseId, benefitid);
	}

	/**
	 * Delete all benefits for a beneficiary and resolution
	 * @param caseId
	 * @param resolutionId
	 * @param beneficiaryId
	 */
	public deleteBenefitsForBeneficiaryAndResolution(
		caseId: number,
		resolutionId: number,
		beneficiaryId: number
	): Observable<any> {
		return this.litBenefitsServ.deleteBenefitsForBeneficiaryAndResolution(caseId, resolutionId, beneficiaryId);
	}

	/**
	 * Get all Beneficiaries and the benefits for a case
	 * @param caseId
	 */
	public getAllBeneficiariesAndBenefitsForCase(caseId: number): Observable<Array<LitigationBeneficiaryVO>> {
		return this.litBenefitsServ.getAllBeneficiariesAndBenefitsForCase(caseId);
	}

	/**
	 * Get all Beneficiaries and the benefits for a resolution in a case
	 * @param caseId
	 * @param resolutionId
	 */
	public getAllBeneficiariesAndBenefitsForResolution(
		caseId: number,
		resolutionId: number
	): Observable<Array<LitigationBeneficiaryVO>> {
		return this.litBenefitsServ.getAllBeneficiariesAndBenefitsForResolution(caseId, resolutionId);
	}

	/**
	 * Get all Beneficiaries (without benefits) for a case
	 * Get all Beneficiaries for a case. This can be used when benefits are not needed
	 * @param caseId
	 */
	public getAllBeneficiariesForCase(caseId: number): Observable<Array<LitigationBeneficiaryVO>> {
		return this.litBenefitsServ.getAllBeneficiariesForCase(caseId);
	}

	/**
	 * Get all the benefits for a Beneficiary of a Litigation case
	 * @param caseId
	 * @param beneficiaryId
	 */
	public getBenefitsForBeneficiary(caseId: number, beneficiaryId: number): Observable<LitigationBeneficiaryVO> {
		return this.litBenefitsServ.getBenefitsForBeneficiary(caseId, beneficiaryId);
	}

	/**
	 * Update the Beneficiary Metadata for a beneficiary of a case
	 * Update the Beneficiary Metadata for a beneficiary of a case. Benefits are not updated
	 * @param caseId
	 * @param litigationBeneficiaryVO
	 */
	public updateBeneficiaryMetadataForCase(
		caseId: number,
		litigationBeneficiaryVO: LitigationBeneficiaryVO
	): Observable<LitigationBeneficiaryVO> {
		return this.litBenefitsServ.updateBeneficiaryMetadataForCase(caseId, litigationBeneficiaryVO);
	}

	/**
	 * Update the benefits for a beneficiary and Resolution
	 * Update the benefits for a beneficiary and Resolution. Only send changed benefits (add, update, delete)
	 * @param caseId
	 * @param resolutionId
	 * @param beneficiaryId
	 * @param litigationBenefitDetailVO
	 */
	public updateBenefitsForBeneficiaryAndResolution(
		caseId: number,
		resolutionId: number,
		beneficiaryId: number,
		litigationBenefitDetailVO: Array<LitigationBenefitDetailVO>,
		numBenefitted?: number,
		litigationChargePartyAsscId?: number
	): Observable<any> {
		return this.litBenefitsServ.updateBenefitsForBeneficiaryAndResolution(
			caseId,
			resolutionId,
			beneficiaryId,
			litigationBenefitDetailVO,
			numBenefitted,
			litigationChargePartyAsscId
		);
	}

	/**
	 * Delete all benefits for a beneficiary
	 * @param caseId
	 * @param beneficiaryId s.
	 */
	public deleteBenefitsForBeneficiary(caseId: number, beneficiaryId: number): Observable<any> {
		return this.litBenefitsServ.deleteBenefitsForBeneficiary(caseId, beneficiaryId);
	}

	/**
	 * @param consolidationRequest
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public consolidateLitigationCases(consolidationRequest: ConsolidationRequest): Observable<ConsolidationResponse> {
		return this.litigationServ.consolidateLitigationCases(consolidationRequest);
	}

	/**
	 * Get All the district offices
	 * Get All the district offices. Includes Washington field office by default in the list
	 * @param includeWash
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllDistrictOffices(includeWash?: boolean): Observable<any> {
		if (this.allDistrictOffices) {
			return this.allDistrictOffices;
		}
		if (this.allOffciessLoading) {
			return this.subject;
		}
		this.allOffciessLoading = true;
		this.prepaOfficeServ.getAllDistrictOffices(includeWash).subscribe(
			(res) => {
				this.allDistrictOffices = of(res);
				this.subject.next(res);
				this.subject.complete();
			},
			(err) => {
				this.subject.error(err);
				this.subject.complete();
			}
		);
		return this.subject;
	}

	/**
	 * Add Notes Details for a specific case
	 * @param litigationCaseId
	 * @param litigationNoteVO
	 */
	public addLitNote(litigationCaseId: number, litigationNoteVO: LitigationNoteVO): Observable<Array<LitigationNoteVO>> {
		return this.litNoteServ.addNote(litigationCaseId, litigationNoteVO);
	}

	/**
	 * Delete Notes Details for a litigation Note
	 * @param litigationCaseId
	 * @param litNoteId
	 */
	public deleteLitNote(litigationCaseId: number, litNoteId: number): Observable<any> {
		return this.litNoteServ.deleteNote(litigationCaseId, litNoteId);
	}

	/**
	 * Get Notes Details for a specific case
	 * @param litigationCaseId
	 */
	public getLitNotes(litigationCaseId: number): Observable<Array<LitigationNoteVO>> {
		return this.litNoteServ.getNotes(litigationCaseId);
	}

	/**
	 * Update Notes Details for a litigation Note.
	 * @param litigationCaseId
	 * @param litNoteId
	 * @param litigationNoteVO
	 */
	public updateLitNote(
		litigationCaseId: number,
		litNoteId: number,
		litigationNoteVO: LitigationNoteVO
	): Observable<Array<LitigationNoteVO>> {
		return this.litNoteServ.updateNote(litigationCaseId, litNoteId, litigationNoteVO);
	}

	/**
	 * @param chargeInquiryId
	 */
	public findLitigationCasesForPrimaryCharge(chargeInquiryId: number): Observable<any> {
		return this.litigationServ.findLitigationCasesForPrimaryCharge(chargeInquiryId);
	}

	/**
	 * @param caseId
	 */
	public getRFIDetails(caseId: number): Observable<Array<any>> {
		return this.rfiServ.getRFIDetails(caseId);
	}

	/**
	 * Create an Enforcement Appeal for a Litigation Case
	 * @param caseId
	 * @param enforcementAppealCreation
	 */
	public createEnforcementAppeal(
		caseId: number,
		enforcementAppealCreation: EnforcementAppealCreation
	): Observable<LitigationAppealVO> {
		return this.litAppealServ.createEnforcementAppeal(caseId, enforcementAppealCreation);
	}

	/**
	 * Update an existing Enforcement Appeal for a Litigation Case
	 * @param caseId
	 * @param appealId
	 * @param enforcementAppealVO
	 */
	public updateEnforcementAppeal(
		caseId: number,
		appealId: number,
		litigationAppealVO: LitigationAppealVO
	): Observable<LitigationAppealVO> {
		return this.litAppealServ.updateEnforcementAppeal(caseId, appealId, litigationAppealVO);
	}

	/**
	 * Get all Enforcement Appeals for a Litigation Case
	 * @param caseId
	 */
	public getEnforcementAppealsForCase(caseId: number): Observable<Array<LitigationAppealVO>> {
		return this.litAppealServ.getEnforcementAppealsForCase(caseId);
	}

	/**
	 * Get an Appeal
	 * Get an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAppeal(appealId: number): Observable<LitigationAppealVO> {
		return this.litAppealServ.getAppeal(appealId);
	}

	/**
	 * Set recommendation information for a Litigation Appeal
	 * Set recommendation information for a Litigation Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 * @param appealRecommendation
	 */
	public setAppealRecommendation(
		appealId: number,
		appealRecommendation: AppealRecommendation
	): Observable<LitigationAppealVO> {
		return this.litAppealServ.setAppealRecommendation(appealId, appealRecommendation);
	}

	/**
	 * Add a court filing Oral Argument
	 * Add a court filing Oral Argument
	 * @param appealId
	 * @param appealCourtFilingArgumentVO
	 */
	public addCourtFilingArgument(
		appealId: number,
		appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO
	): Observable<AppealCourtFilingArgumentVO> {
		return this.litAppealCourtFilingServ.addCourtFilingArgument(appealId, appealCourtFilingArgumentVO);
	}

	/**
	 * Add a court filing Brief
	 * @param appealId
	 * @param appealCourtFilingBriefVO
	 */
	public addCourtFilingBrief(
		appealId: number,
		appealCourtFilingBriefVO: AppealCourtFilingBriefVO
	): Observable<AppealCourtFilingBriefVO> {
		return this.litAppealCourtFilingServ.addCourtFilingBrief(appealId, appealCourtFilingBriefVO);
	}

	/**
	 * Add a court filing Petition
	 * @param appealId
	 * @param appealCourtFilingPetitionVO
	 */
	public addCourtFilingPetition(
		appealId: number,
		appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO
	): Observable<AppealCourtFilingPetitionVO> {
		return this.litAppealCourtFilingServ.addCourtFilingPetition(appealId, appealCourtFilingPetitionVO);
	}

	/**
	 * Delete a court filing Oral Argument
	 * @param appealId
	 * @param argumentId
	 */
	public deleteCourtFilingArgument(appealId: number, argumentId: number): Observable<any> {
		return this.litAppealCourtFilingServ.deleteCourtFilingArgument(appealId, argumentId);
	}

	/**
	 * Delete a court filing Brief
	 * @param appealId
	 * @param briefId .
	 */
	public deleteCourtFilingBrief(appealId: number, briefId: number): Observable<any> {
		return this.litAppealCourtFilingServ.deleteCourtFilingBrief(appealId, briefId);
	}

	/**
	 * Delete a court filing Petition
	 * @param appealId
	 * @param petitionId
	 */
	public deleteCourtFilingPetition(appealId: number, petitionId: number): Observable<any> {
		return this.litAppealCourtFilingServ.deleteCourtFilingPetition(appealId, petitionId);
	}

	/**
	 * Get a Appeal Court Filing details along with briefs, oral arguments and Petitions
	 * @param appealId
	 */
	public getCourtFiling(appealId: number): Observable<AppealCourtFilingVO> {
		return this.litAppealCourtFilingServ.getCourtFiling(appealId);
	}

	/**
	 * Modify a court filing Oral Argument
	 * @param appealId
	 * @param appealCourtFilingArgumentVO
	 */
	public modifyCourtFilingArgument(
		appealId: number,
		appealCourtFilingArgumentVO: AppealCourtFilingArgumentVO
	): Observable<AppealCourtFilingArgumentVO> {
		return this.litAppealCourtFilingServ.modifyCourtFilingArgument(appealId, appealCourtFilingArgumentVO);
	}

	/**
	 * Modify a court filing Petition
	 * @param appealId
	 * @param appealCourtFilingPetitionVO
	 */
	public modifyCourtFilingPetition(
		appealId: number,
		appealCourtFilingPetitionVO: AppealCourtFilingPetitionVO
	): Observable<AppealCourtFilingPetitionVO> {
		return this.litAppealCourtFilingServ.modifyCourtFilingPetition(appealId, appealCourtFilingPetitionVO);
	}

	/**
	 * Create a Appeal Court Filing for an appeal
	 * @param appealId
	 * @param appealCourtFilingDetail .
	 */
	public setCourtFilingDetail(
		appealId: number,
		appealCourtFilingDetail: AppealCourtFilingDetail
	): Observable<LitigationAppealVO> {
		return this.litAppealCourtFilingServ.setCourtFilingDetail(appealId, appealCourtFilingDetail);
	}

	/**
	 * Update a court filing Brief
	 * @param appealId
	 * @param appealCourtFilingBriefVO
	 */
	public updateCourtFilingBrief(
		appealId: number,
		appealCourtFilingBriefVO: AppealCourtFilingBriefVO
	): Observable<AppealCourtFilingBriefVO> {
		return this.litAppealCourtFilingServ.updateCourtFilingBrief(appealId, appealCourtFilingBriefVO);
	}

	/**
	 * Delete Resolution details for an Appeal
	 * Delete Resolution details for an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 */
	public removeAppealResolution(appealId: number): Observable<LitigationAppealVO> {
		return this.litAppealServ.removeAppealResolution(appealId);
	}

	/**
	 * Set Resolution details for a Litigation Appeal
	 * Set Resolution details for a Litigation Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 * @param appealResolution
	 */
	public setAppealResolution(appealId: number, appealResolution: AppealResolution): Observable<LitigationAppealVO> {
		return this.litAppealServ.setAppealResolution(appealId, appealResolution);
	}

	/**
	 * Update an existing Defensive Appeal
	 */
	public updateDefensiveAppeal(
		appealId: number,
		litigationAppealVO: LitigationAppealVO
	): Observable<LitigationAppealVO> {
		return this.litAppealServ.updateDefensiveAppeal(appealId, litigationAppealVO);
	}

	/**
	 * Update an existing Amicus Appeal
	 * @param appealId
	 * @param litigationAppealVO
	 */
	public updateAmicusAppeal(appealId: number, litigationAppealVO: LitigationAppealVO): Observable<LitigationAppealVO> {
		return this.litAppealServ.updateAmicusAppeal(appealId, litigationAppealVO);
	}

	/**
	 * Create an Amicus Appeal
	 * @param amicusAppealCreation
	 */
	public createAmicusAppeal(amicusAppealCreation: AmicusAppealCreation): Observable<LitigationAppealVO> {
		return this.litAppealServ.createAmicusAppeal(amicusAppealCreation);
	}

	/**
	 * Create a Defensive Appeal
	 * @param defensiveAppealCreation
	 */
	public createDefensiveAppeal(defensiveAppealCreation: DefensiveAppealCreation): Observable<LitigationAppealVO> {
		return this.litAppealServ.createDefensiveAppeal(defensiveAppealCreation);
	}

	/**
	 * Close an Appeal
	 * Close an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 */
	public closeAppeal(appealId: number, closeRsnCode?, closeRsnTxt?, closedDate?): Observable<LitigationAppealVO> {
		return this.litAppealServ.closeAppeal(appealId,closeRsnCode, closeRsnTxt, closedDate);
	}

	/**
	 * Reopen an Appeal
	 * Reopen an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 */
	public reopenAppeal(appealId: number): Observable<LitigationAppealVO> {
		return this.litAppealServ.reopenAppeal(appealId);
	}

	/**
	 * Delete an Appeal
	 * Delete an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 */
	public deleteAppeal(appealId: number): Observable<LitigationAppealVO> {
		return this.litAppealServ.deleteAppeal(appealId);
	}

  public getAllCourtMappings(): Observable<any> {
    return this.litAppealServ.getAllCourtMappings();
  }

	/**
	 * Restore an Appeal
	 * Restore an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 */
	public restoreAppeal(appealId: number): Observable<LitigationAppealVO> {
		return this.litAppealServ.restoreAppeal(appealId);
	}

  public setDistrictAppealRecommendation(litigationCaseId: number, districtAppealRecommendation: DistrictAppealRecommendation): Observable<any> {
    return this.courtFilingServ.setDistrictAppealRecommendation(litigationCaseId, districtAppealRecommendation)
  }

  public generateInvestigativeReportZip(caseId: number, docExt: 'pdf' | 'csv', isFromPortal?: boolean): Observable<HttpResponse<Blob>> {
	return this.caseServ.generateInvestigativeReportZip(caseId, docExt, false, 'response');
  }

  public sendUnperfectedCodSignEmail(caseId: number): Observable<boolean> {
	return this.portalServ.sendUnperfectedCodSignEmail(caseId);
  }

  	/**
	 * Delete Recommendation for an Appeal
	 * Delete Recommendation for an Appeal (Enforcement, Amicus, Defensive)
	 * @param appealId
	 * @param recommendationId
	 */
	public removeAppealRecommendation(appealId: number, recommendationId: number): Observable<any> {
		return this.litAppealServ.removeRecommendationForAppeal(appealId, recommendationId);
	}
}
