import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appDragDrop]',
})
export class AppDragDropDirective {
	@Output() onFileDropped = new EventEmitter<any>();

	@HostBinding('style.background-color') private background = '#ffffff';

	//Dragover listener
	@HostListener('dragover', ['$event']) onDragOver(event) {
		event.preventDefault();
		event.stopPropagation();
		this.background = '#e2eef3';
	}

	//Dragleave listener
	@HostListener('dragleave', ['$event']) public onDragLeave(event) {
		event.preventDefault();
		event.stopPropagation();
		this.background = '#ffffff';
	}

	//Drop listener
	@HostListener('drop', ['$event']) public ondrop(event) {
		event.preventDefault();
		event.stopPropagation();
		this.background = '#ffffff';
		let files = event.dataTransfer.files;
		if (files.length > 0) {
			this.onFileDropped.emit(files);
		}
	}
}
