import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import  moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CaseDetailsService } from 'src/app/features/modules/case-details/case-details.service';
import { CommonService } from 'src/app/services/common.service';
import { BaseCase, DownloadRequestVO } from 'src/app/services/gen';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { TemplateResponse } from 'src/app/services/TemplateMangementWebService';
import {
	LocalTemplateHoverText,
	LookUpDataTypes,
	minimumDate,
	ScrollStrategy,
	StandardTemplateHoverText,
	TEMPLATE_ATTACHMENTS,
	TEMPLATE_CITATION,
} from 'src/app/shared/common-structures/app.constants';
import { AlertService } from 'src/app/shared/ims-alerts/ims-alerts.service';
import { Utils } from 'src/app/shared/utility/utils';
import { DocumentsService } from '../../documents/documents.service';
import { SharedCodeService } from './../../../../services/shared-code.service';
import { LookupData } from './../../../../services/UserManagement/model/lookupData';
import { SltpCoordinatorRoleCode, TEMPLATE_REQUESTFROM } from './../../../../shared/common-structures/app.constants';

export const MY_FORMATS = {
	parse: {
		dateInput: 'l',
	},
	display: {
		dateInput: 'l',
		monthYearLabel: 'l',
		dateA11yLabel: 'LLLL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-template-library',
	templateUrl: './template-library.component.html',
	styleUrls: ['./template-library.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class TemplateLibraryComponent implements OnInit {
	@ViewChild('docuemntTemplateList', { static: true }) docuemntTemplateList: TemplateRef<any>;

	@Input() userSelectedOfficeCode: string;
	@Input() caseDetail: BaseCase;
	@Input() readOnlyCharge: boolean;
	@Input() assignedStaffArray: any[] = [];
	@Input() isSystemic = false;

	stdTemplates: string[] = ['CODCL', 'CODUCL', 'TRANSCASEDOJ', 'TRANSCASEDOJFORNRTS'];
	readOnly: boolean;

	standardText = StandardTemplateHoverText;
	localText = LocalTemplateHoverText;
	filterType = 'All';
	filterTypeOptions: string[] = ['All', 'Standard', 'Local'];
	adHocFields: any = {};
	adHocFieldsList: any[] = [];
	adHocFieldsIndexByField: any = {};
	dropdownTemplatesData: TemplateResponse[] = [];
	hasClosureReason: boolean = false;

	selectedTemplate: any;
	minDate = minimumDate;
	maxDate = new Date();

	citationData: any[] = Utils.strArrayToSharedCodesArray(TEMPLATE_CITATION);
	requestFromData: any[] = Utils.strArrayToSharedCodesArray(TEMPLATE_REQUESTFROM);
	attachmentsData: any[] = Utils.strArrayToSharedCodesArray(TEMPLATE_ATTACHMENTS);
	standardTemplateData: any[] = [];
	localTemplateData: any = [];
	closureReasonData: LookupData[] = [];
	swrTypeData: LookupData[] = [];
	isAdHocFieldsListVisible: boolean;
	public getDescriptionByValue = Utils.getDescriptionByValue;

	orderMap = {
		assignedStaffName: 1,
		assignedStaffPhone: 2,
		assignedStaffTitle: 3,
		eeocContinueInvestigation: 4,
	};
	public dialogRef: MatDialogRef<TemplateLibraryComponent>;
	sltpCoordinatorNameData: LookupData[] = [];
	assignedStaffNameData: LookupData[] = [];
	attachementOptions: any[] = [];
	requestedByOptions: any[] = [];

	constructor(
		private docServ: DocumentsService,
		private imsLoader: LoadingService,
		public dialog: MatDialog,
		private alrtServ: AlertService,
		private sharedCodeServ: SharedCodeService,
		private cmnServ: CommonService,
		private csDtlsServ: CaseDetailsService
	) {}

	ngOnInit(): void {
		if (!this.isSystemic) {
			this.getSharedCode();
			this.getDocumentTemplates();
			this.getChargeClosureReasons();
			this.getSwrTypesList();
			this.getSltpCoordinators();
		}
	}

	getChargeClosureReasons() {
		this.csDtlsServ.getChargeClosureReasons().subscribe(
			(res) => {
				this.closureReasonData = (res || [])
					.filter((x) => ['DWNRTS', 'OTH'].includes(x.domain))
					.sort(Utils.sortSharedCodes);
			},
			(error) => {
				this.alrtServ.error('Error occurred while getting closure reasons.');
			}
		);
	}

	getChargeClosure() {
		this.csDtlsServ.getChargeClosureByFEPA(this.caseDetail?.chargeInquiryId).subscribe(
			(res) => {
				this.isAdHocFieldsListVisible = res;
			},
			(error) => {
				this.alrtServ.error('Error occurred while getting closure.');
			}
		);
	}

	onContactChange(name) {
		let staff = this.assignedStaffArray.find((x) => x.name == name);
		if (staff) {
			this.adHocFields['eeocContactName'] = staff.name;
			this.adHocFields['eeocContactPhone'] = staff.phone;
		}
	}

	label(field) {
		return field[0].toUpperCase() + field.substr(1).replace(/([A-Z])/g, ' $1');
	}

	checkReason(value, field) {
		if (field == 'closureReason') {
			if (value == 'OTH_1') {
				this.adHocFields['otherClosureReason'] = undefined;
			} else {
				delete this.adHocFields['otherClosureReason'];
			}
		}
	}
	getDocumentTemplates() {
		//	this.isTemplateAdmin = (this.cmnServ.userToken.authorities || []).some(x => x.split(':')[0] == 'ROLE_PRIVATE_TEMPLATE_ADMIN')
		this.imsLoader.show();
		const requests = [
			this.docServ.getTemplates('PRIVATE').pipe(catchError((err) => of(err))),
			this.docServ.getTemplates('', this.userSelectedOfficeCode).pipe(catchError((err) => of(err))),
		];

		forkJoin(requests).subscribe(
			(res: any) => {
				this.imsLoader.hide();
				this.standardTemplateData =  res[0] instanceof HttpErrorResponse ? [] : res[0].filter((x) => x.templateExtension !== 'txt');
				this.localTemplateData = res[1] instanceof HttpErrorResponse ? [] : res[1];
				
				this.applyFilter();
			},
			(error) => {
				this.imsLoader.hide();
				this.alrtServ.error(error?.error);
				this.standardTemplateData = [];
				this.localTemplateData = [];
			}
		);
	}

	getData() {		
		switch (this.filterType) {
			case 'Standard':
				return [...(this.standardTemplateData || [])];
			case 'Local':
				return [...(this.localTemplateData || [])];
			default:
				return [...(this.standardTemplateData || []), ...(this.localTemplateData || [])];
		}
	}

	applyFilter(filterType?) {
		if (filterType) {
			this.filterType = filterType;
		}
		if (
			typeof this.selectedTemplate == 'string' ||
			this.selectedTemplate == undefined ||
			this.selectedTemplate == null
		) {
			let filteredTemplate = (this.selectedTemplate || '').toLowerCase();
			this.dropdownTemplatesData = this.getData()
				.sort((a, b) => (a.templateName > b.templateName ? 1 : -1))
				.filter((x) => !filteredTemplate || x.templateName?.toLowerCase().includes(filteredTemplate));
		}
	}
	getDropDownData(item) {
		return this[item.field + 'Data'] || [];
	}
	public show(templateCode?: string) {
		this.filterType = 'All';
		this.selectedTemplate = undefined;
		this.applyFilter('All');
		//	this.accept = false;
		this.dialogRef = this.dialog.open(this.docuemntTemplateList, {
			width: '950px',
			scrollStrategy: ScrollStrategy,
		});
		this.dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((res) => {
				this.selectedTemplate = null;
			});

		if (templateCode) {
			let selectedTemplate = this.dropdownTemplatesData.find((x) => x.templateCode == templateCode);
			if (selectedTemplate) {
				this.selectedTemplate = selectedTemplate;
				this.onTemplateSelected(this.selectedTemplate);
			}
		}
	}

	/**
	 *
	 * @description we can add custom fields to certain templates
	 */
	processAdhocFields() {
		if (this.selectedTemplate.templateCode === 'FORM257') {
			let date = moment(new Date(this.adHocFields.requestDate)).format('MM/DD/YYYY');
			let data = { ...this.adHocFields, requestFrom: `${this.adHocFields.requestFrom}, dated ${date}` };
			date = moment(new Date(this.adHocFields.issuedOn)).format('MM/DD/YYYY');
			data.attachments = data.attachments
				.map((x) => (x == 'Cause Determination' ? `Cause Determination dated ${date}` : x))
				.join(', ');
			delete data.requestDate;
			delete data.issuedOn;
			return data;
		}
		return this.adHocFields;
	}

	openTemplate(ext) {
		this.imsLoader.show();
		let name = this.selectedTemplate.templateName.split('.');
		name.pop();

		if (this.adHocFields?.adoptFEPAClosure === 'null') this.adHocFields.adoptFEPAClosure = null;
		if (this.adHocFields?.eeocContinueInvestigation === 'null') this.adHocFields.eeocContinueInvestigation = null;
		const adhocData =  _.cloneDeep(this.processAdhocFields());
		const adhocDataFieldList = _.cloneDeep(this.adHocFieldsList)
		Object.keys(adhocDataFieldList).forEach(key=>{
			const fieldKey = adhocDataFieldList[key]
			if (fieldKey.type === 'date') adhocData[fieldKey?.field] = moment(new Date(adhocData[fieldKey?.field])).format('MM/DD/YYYY');
		})
		let payload: DownloadRequestVO = {
			templateCode: this.selectedTemplate.templateCode,
			officeCode: this.selectedTemplate.officeCode,
			resultDocumentType: ext,
			resultFileName: name.join('.'),
			adHocData: adhocData,
		};
		this.docServ.downloadDocument(this.caseDetail?.chargeInquiryId, payload).subscribe(
			(res) => {
				this.imsLoader.hide();
				let template: TemplateResponse = this.dropdownTemplatesData.find(
					(x) => x.templateCode == this.selectedTemplate.templateCode
				);
				saveAs(res, `${template.templateName}.${ext}`);
			},
			(error) => {
				this.imsLoader.hide();
				if (error.status === 422) {
					const blobErrorResponse = error?.error;
					var fileReader = new FileReader();

					// This fires after the blob has been read/loaded.
					fileReader.addEventListener('loadend', (e: any) => {
						const fileReaderResponse = e?.srcElement?.result;
						this.alrtServ.error(fileReaderResponse);
					});

					// Start reading the blob as text.
					fileReader.readAsText(blobErrorResponse);
				} else {
					this.alrtServ.error('Error happened while generating document');
				}
			}
		);
	}

	isDisabled() {
		return (
			//	!this.accept ||
			!this.selectedTemplate?.templateCode ||
			this.getKeys().some((k) => this.adHocFields[k] == undefined || this.adHocFields[k] === '') ||
			(this.adHocFields.closureReason == 'OTH_1' && !this.adHocFields.otherClosureReason)
		);
	}

	getKeys() {
		return this.adHocFieldsList.filter((x) => x.isVisible).map((x) => x.field);
	}

	onTemplateSelected(event) {
		if (this.selectedTemplate?.templateCode === 'PRVCLOSNOT') {
			this.getChargeClosure();
		}
		this.adHocFields = {};
		this.adHocFieldsList = [];
		this.adHocFieldsIndexByField = {};
		this.selectedTemplate.adHocFields = this.selectedTemplate.adHocFields || {};
		let keys = Object.keys(this.selectedTemplate.adHocFields);
		const additionals: any = {
			FORM257: [
				{
					field: 'requestDate',
					display: 'The Notice of Right to Sue was requested on:',
					type: 'date',
					isVisible: true,
					order: 99999,
				},
			],
		};
		const selectTypes = [
			'closureReason',
			'sltpCoordinatorName',
			'swrType',
			'assignedStaffName',
			'citation',
			'requestFrom',
			'attachments',
		];
		const maxLengths: any = { remarks: 1230 };
		const isVisibleMaps = {
			PRVCLOSNOT_eeocContinueInvestigation: false,
			PRVCLOSNOT_adoptFEPAClosure: false,
		};
		this.adHocFieldsList = keys.map((key) => {
			let display = this.selectedTemplate.adHocFields[key];
			let isSelectType = selectTypes.includes(key);
			return {
				field: key,
				display,
				maxLength: maxLengths[key],
				order: this.orderMap[key],
				isVisible: isVisibleMaps[`${this.selectedTemplate.templateCode}_${key}`] ?? true,
				type: display.toLowerCase().includes('(multi)')
					? 'multi'
					: display.toLowerCase().includes('(datetime)') || display.toLowerCase().includes('-datetime')
					? 'datetime'
					: display.toLowerCase().includes('(date)') || display.toLowerCase().includes('-date')
					? 'date'
					: display.toLowerCase().includes('(yes/no)')
					? 'radio'
					: isSelectType
					? 'select'
					: 'other',
			};
			/* this.selectedTemplate.adHocFields[key].includes(' ')
           ? this.selectedTemplate.adHocFields[key]
           : this.label(key),
       };*/
		});
		if (additionals[this.selectedTemplate.templateCode]) {
			this.adHocFieldsList.push(...additionals[this.selectedTemplate.templateCode]);
		}
		this.adHocFieldsList.sort((a, b) => ((a.order ? a.order > b.order : a.display > b.display) ? 1 : -1));
		this.adHocFieldsList.forEach((row, i) => {
			this.adHocFieldsIndexByField[row.field] = i;
		});
		let index = this.adHocFieldsList.findIndex((x) => x.field == 'closureReason');
		this.hasClosureReason = index != -1;
		if (this.hasClosureReason) {
			this.adHocFields['otherClosureReason'] = undefined;
		}
		if (this.selectedTemplate?.templateCode == 'CODCL' || this.selectedTemplate?.templateCode == 'CODUCL') {
			this.adHocFields['notaryReq'] = undefined;
		}
		if (this.selectedTemplate.templateCode !== 'RFIRES') {
			keys.forEach((key) => {
				this.adHocFields[key] = undefined;
			});
		}

		if (this.selectedTemplate.templateCode == 'TRANSCASEDOJFORNRTS') {
			let staff = this.assignedStaffArray.find((x) => x.name.includes('Primary'));
			if (staff) {
				this.adHocFields['eeocContactName'] = staff.name;
				this.adHocFields['eeocContactPhone'] = staff.phone;
			} else if (this.assignedStaffArray.length == 1) {
				staff = this.assignedStaffArray[0];
				this.adHocFields['eeocContactName'] = staff.name;
				this.adHocFields['eeocContactPhone'] = staff.phone;
			}
		}
	}

	public radioGroupDisplayLabel(label: string): string {
		return label?.replace('(Yes/No)', '');
	}

	public datePickerDisplayLabel(label: string): string {
		return label?.replace('(Date)', '');
	}

	public multiFieldDisplayLabel(label: string): string {
		return label?.replace('(multi)', '');
	}

	displayFn = (item) => {
		if (typeof item === 'string' || item instanceof String) {
			return item;
		}
		if (item) {
			return item.templateName;
		}
	};
	private getSharedCode() {
		this.imsLoader.show();
		const requests = [
			this.sharedCodeServ.getSharedCodes('NRTS_OPTQUAL').pipe(catchError((err) => of(err))),
			this.sharedCodeServ.getSharedCodes('ATTACHMENT_TYPE').pipe(catchError((err) => of(err))),
		];
		forkJoin(requests).subscribe(
			(res) => {
				this.imsLoader.hide();
				this.attachementOptions =  res[1] instanceof HttpErrorResponse ? [] : res[1];
				this.requestedByOptions =  res[0] instanceof HttpErrorResponse ? [] : res[0];;
			},
			(error) => {
				this.imsLoader.hide();
				this.alrtServ.error('Error happened while getting stages and statuses. Please contact help desk.');
				this.attachementOptions = [];
				this.requestedByOptions = [];
			}
		);
	}
	getSwrTypesList() {
		this.sharedCodeServ.getSharedCodes(LookUpDataTypes.FEPA_CREDIT_REQ).subscribe(
			(swrTypesResponse) =>
				(this.swrTypeData = [...swrTypesResponse]
					.filter((item) => ['A', 'O', 'M'].includes(item.code))
					.map((x) => {
						return { code: x.description, description: x.description };
					})),
			(error) => this.alrtServ.error('Error while getting swr types. Please contact system administrator.')
		);
	}
	getSltpCoordinators() {
		const pushedRows = [];
		this.sltpCoordinatorNameData = [];
		this.assignedStaffNameData = [];
		[...(this.cmnServ._getOfficeApprovedUsers() || [])].forEach((row) => {
			this.assignedStaffNameData.push({
				code: `${row.personName.firstName} ${row.personName.lastName}`,
				description: `${row.personName.firstName} ${row.personName.lastName}`,
			});
			this.assignedStaffNameData.sort(Utils.sortSharedCodes);
			Object.keys(row.roleAssignments).forEach((officeId) => {
				// if (officeId !== this.userSelectedOfficeCode || officeId !== 'ALL') return;
				let roles = row.roleAssignments[officeId];
				roles.forEach((role) => {
					if ([SltpCoordinatorRoleCode].includes(role.roleCode)) {
						if (pushedRows.indexOf(row.userDetailId) < 0) {
							pushedRows.push(row.userDetailId);
							this.sltpCoordinatorNameData.push({
								code: `${row.personName.firstName} ${row.personName.lastName}`,
								description: `${row.personName.firstName} ${row.personName.lastName}`,
							});
							this.sltpCoordinatorNameData.sort(Utils.sortSharedCodes);
						}
					}
				});
			});
		});
	}

	onRadioChange(key, value) {
		const mappers = { adeaStatute: 'epaStatute', epaStatute: 'adeaStatute' };
		if (mappers[key]) {
			this.adHocFields[mappers[key]] = !value;
		}
		if (key == 'eeocContinueInvestigation' && this.selectedTemplate.templateCode == 'PRVCLOSNOT') {
			let index = this.adHocFieldsIndexByField['adoptFEPAClosure'];
			if (['DWNRTS_8', 'DWNRTS_9'].includes(this.adHocFields.closureReason) && !value) {
				this.adHocFieldsList[index].isVisible = this.isAdHocFieldsListVisible;
			} else {
				this.adHocFieldsList[index].isVisible = false;
			}
		}
	}
	onSelectChange(key, value, ind) {
		setTimeout(() => {
			this._onSelectChange(key, value, ind);
		}, 50);
	}
	_onSelectChange(key, value, ind) {
		if (key == 'closureReason' && this.selectedTemplate.templateCode == 'PRVCLOSNOT') {
			this.adHocFields['adoptFEPAClosure'] = null;
			this.adHocFields['eeocContinueInvestigation'] = null;
			let index = this.adHocFieldsIndexByField['eeocContinueInvestigation'];
			this.adHocFieldsList[index].isVisible = ['DWNRTS_8', 'DWNRTS_9'].includes(value);
			index = this.adHocFieldsIndexByField['adoptFEPAClosure'];
			if (
				(this.adHocFieldsList[index].isVisible =
					(['DWNRTS_8', 'DWNRTS_9'].includes(value) && !(this.adHocFields.eeocContinueInvestigation ?? true)) ||
					!['DWNRTS_2', 'DWNRTS_8', 'DWNRTS_9'].includes(value))
			) {
				this.adHocFieldsList[index].isVisible = this.isAdHocFieldsListVisible;
			} else {
				this.adHocFieldsList[index].isVisible = false;
			}
		}
		if (key == 'attachments' && this.selectedTemplate.templateCode == 'FORM257') {
			let index = this.adHocFieldsList.findIndex((x) => x.field == 'issuedOn');
			if (value.includes('Cause Determination')) {
				if (index < 0) {
					this.adHocFieldsList.splice(ind + 1, 0, {
						type: 'date',
						field: 'issuedOn',
						display: 'Cause Determination Issue On:',
						isVisible: true,
						order: 9999,
					});
				}
			} else {
				if (index >= 0) {
					this.adHocFieldsList.splice(index, 1);
				}
			}
		}
	}
}
