import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-service-error-dialog',
	templateUrl: './service-error-dialog.component.html',
	styleUrls: ['./service-error-dialog.component.scss'],
})
export class ServiceErrorDialogComponent implements OnInit {
	constructor(private _matDialog: MatDialog, private cmnServ: CommonService, private router: Router) {}

	ngOnInit(): void {}

	logout() {
		this._matDialog.closeAll();
		localStorage.clear();
		sessionStorage.clear();
		this.router.navigateByUrl('/');
		this.cmnServ.onLogout.next(true);
	}
}
