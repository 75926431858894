<div class="header-theme">
	<h3 *ngIf="environment.name != 'PROD'" class="text-center env-name">
		{{ envMaps[environment.name] || environment.name }}
	</h3>
	<a class="skipNav" [routerLink]="[]" (click)="skipNav()">Skip to main content</a>
	<nav class="navbar navbar-expand-custom">
		<button
			class="navbar-toggler margin-20"
			type="button"
			data-toggle="collapse"
			data-target="#navbarNavDropdown"
			aria-controls="navbarNavDropdown"
			aria-expanded="false"
			aria-label="ARC Main Menu"
			id="app-header-arc-menu-btn"
			(click)="setDisplay()"
		>
			☰
		</button>

		<div class="collapse navbar-collapse" id="navbarNavDropdown">
			<ul class="navbar-nav mr-auto" role="menubar" aria-label="ARC Navigation Menu Bar">
				<li class="nav-item active" (click)="closeSubmenu()" role="none">
					<a
						class="nav-link"
						*ngIf="dashboard.isVisible"
						[routerLink]="dashboard.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						attr.aria-label="{{ dashboard.label }} button"
						role="menuitem"
						id="app-header-dashboard-btn"
					>
						<mat-icon class="icon-size">{{ dashboard.icon }}</mat-icon>
						<span class="margin-left-10">{{ dashboard.label }}</span>
					</a>
				</li>
				<li class="nav-item active" (click)="closeSubmenu()" role="none">
					<a
						class="nav-link"
						*ngIf="search.isVisible"
						[routerLink]="search.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						attr.aria-label="{{ search.label }} button"
						role="menuitem"
						id="app-header-search-btn"
					>
						<mat-icon class="icon-size">{{ search.icon }}</mat-icon>
						<span class="margin-left-10">{{ search.label }}</span>
					</a>
				</li>
				<li class="nav-item active dropdownModule max-z-index"				role="none" *ngIf="isMultipleNew()">
					<a class="nav-link dropbtn-outline" tabindex="0" (keypress)="openSubmenu('createMenu')"
					aria-controls="createNewDropDown"
					aria-label="New Menu. Hit enter to open/close menu. Use tab button to navigate" role="menuitem"
					aria-haspopup="true" [attr.aria-expanded]="toggleMenuNewDisplay()" id="app-header-create-New-btn">
					<mat-icon class="icon-size">create_new_folder</mat-icon>
					<span class="margin-left-10">CREATE NEW</span>
					<mat-icon>arrow_drop_down</mat-icon>
				</a>
				
					<div class="dropdownModule-content" id="createNewDropDown" role="menu" aria-label="CREATE NEW Menu">
						<div *ngFor="let item of menuForNew">
							<a class="d-flex align-items-center" *ngIf="item.isVisible" [routerLink]="item.link"
								routerLinkActive="active-list-item" #rla="routerLinkActive" queryParamsHandling="preserve"
								data-toggle="collapse" data-target=".navbar-collapse.show" (click)="closeSubmenu()"
								attr.aria-label="{{ item.consolidateLabel }} button" role="menuitem" id="app-header-create-new-btn-{{item.label}}">
								<span class="margin-left-10">
									{{ item.consolidateLabel }}
								</span>
							</a>
						</div>
					</div>
				</li>
				<li class="nav-item active" (click)="closeSubmenu()" role="none" *ngIf="!isMultipleNew()">
					<a
						class="nav-link"
						*ngIf="newInquiry.isVisible"
						[routerLink]="newInquiry.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						attr.aria-label="{{ newInquiry.label }} button"
						role="menuitem"
						id="app-header-new-inquiry-btn"
					>
						<mat-icon class="icon-size">{{ newInquiry.icon }}</mat-icon>
						<span class="margin-left-10">{{ newInquiry.label }}</span>
					</a>
				</li>

				<li class="nav-item active" (click)="closeSubmenu()" role="none" *ngIf="!isMultipleNew()">
					<a
						class="nav-link"
						*ngIf="newLitigationCases.isVisible"
						[routerLink]="newLitigationCases.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						attr.aria-label="{{ newLitigationCases.label }} button"
						role="menuitem"
						id="app-header-new-lit-case-btn"
					>
						<mat-icon class="icon-size">{{ newLitigationCases.icon }}</mat-icon>
						<span class="margin-left-10">{{ newLitigationCases.label }}</span>
					</a>
				</li>
				<li class="nav-item active" (click)="closeSubmenu()" role="none" *ngIf="!isMultipleNew()">
					<a
						class="d-flex align-items-center"
						*ngIf="litigationAppeals.isVisible"
						[routerLink]="litigationAppeals.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						(click)="closeSubmenu()"
						attr.aria-label="{{ litigationAppeals.label }} button"
						id="app-header-new-litigationAppeals-btn"
						role="menuitem"
						id="app-header-new-litigationAppeals-btn"
					>
						<mat-icon class="icon-size">{{ litigationAppeals.icon }}</mat-icon>
						<span class="margin-left-10">{{ litigationAppeals.label }}</span>
					</a>
				</li>
				<li class="nav-item active" (click)="closeSubmenu()" role="none">
					<a
						class="nav-link"
						*ngIf="!isFepa && systemic.isVisible"
						[routerLink]="systemic.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						attr.aria-label="{{ systemic.label }} button"
						role="menuitem"
						id="app-header-systemic-btn"
					>
						<mat-icon class="icon-size">{{ systemic.icon }}</mat-icon>
						<span class="margin-left-10">{{ systemic.label }}</span>
					</a>
				</li>
				<li
					class="nav-item active dropdownModule aditional-z-index"
					role="none"
					*ngIf="(!isFepa && dualFiling.isVisible) || sltpContracts.isVisible"
				>
					<a
						class="nav-link dropbtn-outline"
						aria-label="FEPA Menu. Hit enter to open/close menu. Use tab button to navigate"
						role="menuitem"
						aria-haspopup="true"
						[attr.aria-expanded]="fepaSubmenuDisplay"
						aria-controls="fepaDropDown"
						tabindex="0"
						(keypress)="openSubmenu('fepa')"
						id="app-header-fepa-menu-btn"
					>
						<mat-icon class="icon-size">shield</mat-icon>
						<span class="margin-left-10">FEPA</span>
						<mat-icon>arrow_drop_down</mat-icon>
					</a>

					<div class="dropdownModule-content" id="fepaDropDown" role="menu" aria-label="Dual Filing Menu">
						<a
							class="d-flex align-items-center"
							*ngIf="dualFiling.isVisible"
							[routerLink]="dualFiling.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ dualFiling.label }} button"
							role="menuitem"
							id="app-header-dual-filing-btn"
						>
							<span class="margin-left-10">{{ dualFiling.label }}</span>
						</a>
						<a
							class="d-flex align-items-center"
							*ngIf="sltpContracts.isVisible"
							[routerLink]="sltpContracts.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ sltpContracts.label }} button"
							role="menuitem"
							id="sltp-contracts-btn"
						>
							<span class="margin-left-10">{{ sltpContracts.label }}</span>
						</a>
					</div>
				</li>

				<li class="nav-item active" (click)="closeSubmenu()">
					<a
						class="d-flex align-items-center"
						*ngIf="isFepa && dualFiling.isVisible"
						[routerLink]="dualFiling.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						(click)="closeSubmenu()"
						attr.aria-label="{{ dualFiling.label?.toUpperCase() }} button"
						role="menuitem"
						id="app-header-dual-filing-btn-standalone"
					>
						<mat-icon class="icon-size">{{ dualFiling.icon }}</mat-icon>
						<span class="margin-left-10">{{ dualFiling.label?.toUpperCase() }}</span>
					</a>
				</li>
				<li class="nav-item active" (click)="closeSubmenu()">
					<a
						class="d-flex align-items-center"
						*ngIf="sbiCombo.isVisible"
						[routerLink]="sbiCombo.link"
						routerLinkActive="active-list-item"
						#rla="routerLinkActive"
						queryParamsHandling="preserve"
						data-toggle="collapse"
						data-target=".navbar-collapse.show"
						(click)="closeSubmenu()"
						attr.aria-label="{{ sbiCombo.label?.toUpperCase() }} button"
						role="menuitem"
						id="app-header-sbi-btn"
					>
						<mat-icon class="icon-size">{{ sbiCombo.icon }}</mat-icon>
						<span class="margin-left-10">{{ sbiCombo.label?.toUpperCase() }}</span>
					</a>
				</li>

				<li
					class="nav-item active dropdownModule"
					*ngIf="
						(empRepresentative.isVisible ||
						userMgnt.isVisible ||
						digitalFileCabinet.isVisible ||
						classCaseGroups.isVisible ||
						templateManagement.isVisible) &&
						!hasPrivateReadOnlyRole
					"
					role="none"
				>
					<a
						class="nav-link dropbtn-outline"
						tabindex="0"
						(keypress)="openSubmenu('utilities')"
						aria-label="UTILITIES Menu. Hit enter to open/close menu. Use tab button to navigate"
						role="menuitem"
						aria-haspopup="true"
						[attr.aria-expanded]="utilSubmenuDisplay"
						id="app-header-utilities-btn"
					>
						<mat-icon class="icon-size">settings</mat-icon>
						<span class="margin-left-10">UTILITIES</span>
						<mat-icon>arrow_drop_down</mat-icon>
					</a>

					<div class="dropdownModule-content" id="utilitiesDropDown" role="menu" aria-label="Utilities Menu">
						<a
							class="d-flex align-items-center"
							*ngIf="empRepresentative.isVisible"
							[routerLink]="empRepresentative.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ empRepresentative.label }} button"
							role="menuitem"
							id="app-header-emp-rep-btn"
						>
							<span class="margin-left-10">{{ empRepresentative.label }}</span>
						</a>
						<a
							class="d-flex align-items-center"
							*ngIf="userMgnt.isVisible"
							[routerLink]="userMgnt.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ userMgnt.label }} button"
							role="menuitem"
							id="app-header-user-manage-btn"
						>
							<span class="margin-left-10">{{ userMgnt.label }}</span>
						</a>
						<a
							class="d-flex align-items-center"
							*ngIf="digitalFileCabinet.isVisible"
							[routerLink]="digitalFileCabinet.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ digitalFileCabinet.label }} button"
							role="menuitem"
							id="app-header-dig-file-cab-btn"
						>
							<span class="margin-left-10">{{ digitalFileCabinet.label }}</span>
						</a>
						<a
							class="d-flex align-items-center"
							*ngIf="classCaseGroups.isVisible"
							[routerLink]="classCaseGroups.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ classCaseGroups.label }} button"
							role="menuitem"
							id="app-header-class-case-btn"
						>
							<span class="margin-left-10">{{ classCaseGroups.label }}</span>
						</a>
						<a
							class="d-flex align-items-center"
							*ngIf="templateManagement.isVisible"
							[routerLink]="templateManagement.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ templateManagement.label }} button"
							role="menuitem"
							id="app-header-temp-manage-btn"
						>
							<span class="margin-left-10">{{ templateManagement.label }}</span>
						</a>
						<a
							class="d-flex align-items-center"
							*ngIf="litigationReports.isVisible"
							[routerLink]="litigationReports.link"
							routerLinkActive="active-list-item"
							#rla="routerLinkActive"
							queryParamsHandling="preserve"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							(click)="closeSubmenu()"
							attr.aria-label="{{ litigationReports.label }} button"
							role="menuitem"
							id="app-header-lit-reports-btn"
						>
							<span class="margin-left-10">{{ litigationReports.label }}</span>
						</a>
					</div>
				</li>
				
			</ul>

			<div class="form-inline my-2 my-lg-0">
				<li class="nav-item dropdown pull-text-right">
					<button
						mat-button
						class="dropbtn mat-button-md d-flex align-items-center logoutHelpMenu pl-3 pr-3"
						tabindex="0"
						(keypress)="openSubmenu('logout')"
						id="app-header-logout-btn"
						[matMenuTriggerFor]="logoutHelpMenu"
						aria-label="ARC menu. Hit enter to open menu. Use arrow keys to navigate the menu."
					>
						<img src="assets/images/eeoc-menuicon.jpg" alt="eeoc-menuicon" class="margin-right-10 eeoc-icon" />
						<span>ARC</span>
						<mat-icon class="pl-1">arrow_drop_down</mat-icon>
					</button>
					<mat-menu #logoutHelpMenu="matMenu" class="dropdown-content" id="logoutDropDown">
						<button
							mat-menu-item
							[routerLink]="help.link"
							class="d-flex align-items-center logoutHelpItems"
							(click)="closeSubmenu()"
							id="app-header-logout-help-btn"
							attr.aria-label="{{ help.label }} button"
						>
							<mat-icon class="dropbtn-icon">{{ help.icon }}</mat-icon>
							{{ help.label }}
						</button>
						<button
							mat-menu-item
							class="d-flex align-items-center logoutHelpItems"
							(click)="onLogout()"
							id="app-header-onLogout-btn"
							attr.aria-label="{{ logout.label }} button"
						>
							<mat-icon class="dropbtn-icon">{{ logout.icon }}</mat-icon>
							{{ logout.label }}
						</button>
					</mat-menu>
				</li>
				<button
				    *ngIf="showClose"
					class="menu-close"
					type="button"
					data-toggle="collapse"
					data-target="#navbarNavDropdown"
					aria-controls="navbarNavDropdown"
					aria-expanded="false"
					id="app-header-navbarNavDropdown-btn"
					(click)="showClose = false"
				>
					&times;
				</button>
			</div>
		</div>
	</nav>
</div>
