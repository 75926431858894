import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { TokenUserInfo } from '../common-structures/token-user-info';

@Component({
	selector: 'app-update-token',
	templateUrl: './app-update-token.component.html',
	styleUrls: ['./app-update-token.component.css'],
})
export class AppUpdateTokenComponent implements OnInit, OnDestroy {
	tokenSubscription: Subscription;
	offeset: number = 60;

	constructor(private _matDialog: MatDialog, private router: Router, public cmnServ: CommonService) {}

	/**
	 * @description refresh the token every 4 hours
	 */
	ngOnInit() {
		// get expire interval
		this.tokenSubscription = this.cmnServ.getOauthToken().subscribe((_token) => {
			if (_token && _token.expires_in) {
				// start timer
				interval(1200000)
					.pipe(take(1))
					.subscribe(() => this.refreshToken());
			}
		});
	}

	refreshToken() {
		this.cmnServ
			.getOauthToken()
			.pipe(take(1))
			.subscribe((originalToken: any) => {
				const decodedToken: TokenUserInfo = jwt_decode(originalToken.access_token);
				if (new Date().getTime() < new Date(decodedToken.exp * 1000).getTime())
					this.cmnServ.getRefreshToken(originalToken.refresh_token).subscribe(
						(refresh_token) => this.cmnServ.setOauthToken(refresh_token),
						(error) => this.onLogout()
					);
				else this.onLogout();
			});
	}

	onLogout() {
		this._matDialog.closeAll();
		localStorage.clear();
		sessionStorage.clear();
		this.cmnServ.userToken = null;
		this.router.navigateByUrl('/');
		this.cmnServ.onLogout.next(true);
	}

	ngOnDestroy() {
		if (this.tokenSubscription) {
			this.tokenSubscription.unsubscribe();
		}
	}
}
