<ng-template #alertMessageTemplate>
	<div class="ims-alert-section" [class]="alertSize + '-alert'">
		<div class="symbol-section" [class]="alertType + '-' + alertSize">
			<div class="mat-icon-section">
				<mat-icon> {{ matIcon }} </mat-icon>
			</div>
		</div>

		<div class="ims-alert-content-section">
			<h5 class="arc-h5 margin-bottom-20">
				<div class="bold-font" [innerHTML]="header || displayMessage" role="alert"></div>
				<div *ngIf="header" class="margin-top-10" [innerHTML]="displayMessage" role="alert"></div>
			</h5>

			<div class="margin-top-40 margin-bottom-10 d-flex justify-content-end add-padding" *ngIf="alertSize === 'large'">
				<button mat-button type="button" class="mat-button-md" [mat-dialog-close]="false">OKAY</button>
			</div>
		</div>
	</div>
</ng-template>
