<button
	mat-button
	matTooltip="Close"
	appDebounceClick
	(debounceClick)="onClose()"
	aria-label="Close"
	id="close-dialog-btn"
>
	<mat-icon mat-dialog-close color="primary"> close </mat-icon>
</button>
