<ng-container *ngIf="!isPrivate">
	<mat-card appearance="outlined" class="private-documents-section">
			<div fxFlex="100%">
				<app-upload-documents
					[parent]="parent"
					[documents]="documents"
					[documentTypes]="documentTypes"
					[documentsConfig]="documentsConfig"
					[caseDetail]="caseDetail"
					[userSelectedOfficeCode]="userSelectedOfficeCode"
					[assignedStaffArray]="assignedStaffArray"
					[allegationsCount]="allegationsCount"
					[readOnlyCharge]="readOnlyCharge"
					[isAdrRoleHolder]="isAdrRoleHolder"
					[isCaseManager]="isCaseManager"
					[showSensitiveOption]="showSensitiveOption"
					[deferralOffice]="deferralOffice"
					[disableRelease]="disableRelease"
					[categoryTypes]="categoryTypes"
					(docChange)="getDocuments($event)"
					(onTabChange)="handleTabEvent($event)"
					[hasDocumentLogDownloadPermission]="hasDocumentLogDownloadPermission"
				></app-upload-documents>
				<br />
				<mat-card appearance="outlined">
					<mat-tab-group>
						<mat-tab label="ALL ADR DOCUMENTS ({{ counts.p }})" *ngIf="isAdrRoleHolder">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'All ADR Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="ad-mediation"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="ALL DOCUMENTS ({{ counts.all }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'All Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="all-documents-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="A-FIELD OFFICE WORK PRODUCT ({{ counts.a }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'A-Field Office Work Product'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="a-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="B-JURISDICTIONAL ITEMS ({{ counts.b }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'B-Jurisdictional Items'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="b-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="C-CHARGING PARTY'S EVIDENCE ({{ counts.c }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'C-Charging Party\'s Evidence'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="c-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="D-RESPONDENT'S EVIDENCE ({{ counts.d }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'D-Respondent\'s Evidence'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="d-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="E-MISCELLANEOUS ({{ counts.e }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[tabHeading]="'E-Miscellaneous'"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="e-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="F-CONCILIATION DOCUMENTS ({{ counts.f }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'F-Conciliation Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="f-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="G-LEGAL REVIEW DOCUMENTS ({{ counts.g }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'G-Legal Review Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="g-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="H-MEDIATION DOCUMENTS ({{ counts.h }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'H-Mediation Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="h-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>

						<mat-tab label="I-DRAFT DOCUMENTS ({{ counts.i }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'I-Draft Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="i-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="J-MEDIATION CASE FILE DOCUMENTS ({{ counts.j }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'J-Mediation Case File Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="j-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="K-MEDIATION CONFIDENTIAL DOCUMENTS ({{ counts.k }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'K-Mediation Confidential Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="k-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="L-MEDIATOR NOTES ({{ counts.l }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'L-Mediator Notes'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="l-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
					   <mat-tab label="M-OTHER ADR DOCUMENTS ({{ counts.m }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'M-Other ADR Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="m-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
						<mat-tab label="N-ADR DRAFT DOCUMENTS ({{ counts.n }})">
							<ng-template matTabContent>
								<app-document-list
									[parent]="parent"
									[documentTypes]="autoGeneratedIncludedDocTypes"
									[tabHeading]="'N-ADR Draft Documents'"
									[caseDetail]="caseDetail"
									[rspLoginInfo]="rspLoginInfo"
									[domain]="'PRIVATE'"
									id="n-tab"
									[userSelectedOfficeCode]="userSelectedOfficeCode"
								></app-document-list>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</mat-card>
			</div>
	</mat-card>
</ng-container>
<ng-container *ngIf="isPrivate">
  <mat-tab-group animationDuration="0ms" [(selectedIndex)]="tabIndex" (selectedTabChange)="tabChange($event)">
	<mat-tab label="Private Documents">
		<ng-template matTabContent>
			<mat-card appearance="outlined" class="private-documents-section">
				<div fxFlex="100%">
					<app-upload-documents
						[parent]="parent"
						[documents]="documents"
						[documentTypes]="documentTypes"
						[documentsConfig]="documentsConfig"
						[caseDetail]="caseDetail"
						[userSelectedOfficeCode]="userSelectedOfficeCode"
						[assignedStaffArray]="assignedStaffArray"
						[allegationsCount]="allegationsCount"
						[readOnlyCharge]="readOnlyCharge"
						[isAdrRoleHolder]="isAdrRoleHolder"
						[isCaseManager]="isCaseManager"
						[showSensitiveOption]="showSensitiveOption"
						[deferralOffice]="deferralOffice"
						[disableRelease]="disableRelease"
						[categoryTypes]="categoryTypes"
						(docChange)="getDocuments($event)"
						(onTabChange)="handleTabEvent($event)"
						[hasDocumentLogDownloadPermission]="hasDocumentLogDownloadPermission"
					></app-upload-documents>
					<br />
					<mat-card appearance="outlined">
						<mat-tab-group>
							<mat-tab label="ALL ADR DOCUMENTS ({{ counts.p }})" *ngIf="isAdrRoleHolder">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'All ADR Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="ad-mediation"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="ALL DOCUMENTS ({{ counts.all }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'All Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="all-documents-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab *ngIf ="caseDetail?.status?.code === 'COMPLIANCE_MONITORING'" label="COMPLIANCE MONITORING DOCUMENTS ({{ counts.o }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'O-Compliance Monitoring Documents'"
										[caseDetail]="caseDetail"
										[rspLoginInfo]="rspLoginInfo"
										[domain]="'PRIVATE'"
										id="p-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="A-FIELD OFFICE WORK PRODUCT ({{ counts.a }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'A-Field Office Work Product'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="a-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="B-JURISDICTIONAL ITEMS ({{ counts.b }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'B-Jurisdictional Items'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="b-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="C-CHARGING PARTY'S EVIDENCE ({{ counts.c }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'C-Charging Party\'s Evidence'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="c-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="D-RESPONDENT'S EVIDENCE ({{ counts.d }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'D-Respondent\'s Evidence'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="d-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="E-MISCELLANEOUS ({{ counts.e }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[tabHeading]="'E-Miscellaneous'"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="e-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="F-CONCILIATION DOCUMENTS ({{ counts.f }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'F-Conciliation Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="f-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="G-LEGAL REVIEW DOCUMENTS ({{ counts.g }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'G-Legal Review Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="g-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="H-MEDIATION DOCUMENTS ({{ counts.h }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'H-Mediation Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="h-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>

							<mat-tab label="I-DRAFT DOCUMENTS ({{ counts.i }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'I-Draft Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="i-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="J-MEDIATION CASE FILE DOCUMENTS ({{ counts.j }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'J-Mediation Case File Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="j-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="K-MEDIATION CONFIDENTIAL DOCUMENTS ({{ counts.k }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'K-Mediation Confidential Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="k-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="L-MEDIATOR NOTES ({{ counts.l }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'L-Mediator Notes'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="l-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
                           <mat-tab label="M-OTHER ADR DOCUMENTS ({{ counts.m }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'M-Other ADR Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="m-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab label="N-ADR DRAFT DOCUMENTS ({{ counts.n }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'N-ADR Draft Documents'"
										[caseDetail]="caseDetail"
										[domain]="'PRIVATE'"
										id="n-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
							<mat-tab *ngIf ="caseDetail?.status?.code !== 'COMPLIANCE_MONITORING'" label="O-COMPLIANCE MONITORING DOCUMENTS ({{ counts.o }})">
								<ng-template matTabContent>
									<app-document-list
										[parent]="parent"
										[documentTypes]="autoGeneratedIncludedDocTypes"
										[tabHeading]="'O-Compliance Monitoring Documents'"
										[caseDetail]="caseDetail"
										[rspLoginInfo]="rspLoginInfo"
										[domain]="'PRIVATE'"
										id="p-tab"
										[userSelectedOfficeCode]="userSelectedOfficeCode"
									></app-document-list>
								</ng-template>
							</mat-tab>
						</mat-tab-group>
					</mat-card>
				</div>
			</mat-card>
		</ng-template>
	</mat-tab>
	<mat-tab label="Systemic Documents">
		<ng-template matTabContent>
			<mat-card appearance="outlined" class="private-documents-section">
				<div class="pt-4" *ngIf="systemicCaseOptions.length > 0">
					<mat-form-field class="col-6 padding-right-0" appearance="outline" [floatLabel]="'never'">
						<mat-label>Select Systemic Investigation</mat-label>
						<mat-select [(ngModel)]="selectedSystemicCaseId" id="selected-systemic-case">
							<mat-option *ngFor="let item of systemicCaseOptions" [value]="item?.systemicCase?.systemicCaseId">
								{{ item?.systemicCase?.caseName }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="mb-2" *ngIf="selectedSystemicCaseId && hasSystemicUpload">
					<app-upload-documents
						[isSystemic]="true"
						[systemicCase]="selectedSystemicCase"
						[documentTypes]="documentTypes"
						(docChange)="docList?.getSystemicDocuments(2000)"
					></app-upload-documents>
				</div>
				<mat-card appearance="outlined">
					<app-document-list
						[parent]="parent"
						[documentTypes]="autoGeneratedIncludedDocTypes"
						[tabHeading]="'Systemic Documents'"
						[systemicCaseId]="selectedSystemicCaseId"
						[caseDetail]="caseDetail"
						[domain]="'PRIVATE'"
						[isSystemic]="true"
						id="a-tab"
						#docList
						[userSelectedOfficeCode]="userSelectedOfficeCode"
					></app-document-list>
				</mat-card>
			</mat-card>
		</ng-template>
	</mat-tab>
  </mat-tab-group>
</ng-container>

<ng-template #retractTmpl>
	<form #replForm="ngForm">
		<div fxLayout="row wrap m-0" fxLayoutAlign="space-between" class="border-bottom no-print">
			<h3 class="arc-h3 margin-20" matDialogTitle>Retract Document</h3>
			<div class="col-sm-2 text-right">
				<app-close-mat-dialog (closeDialog)="onCancel()"></app-close-mat-dialog>
			</div>
		</div>
		<mat-dialog-content>
			<div>
				<div class="button-row margin-bottom-10">
					<button
						type="button"
						mat-stroked-button
						class="mat-button-md margin-right-15"
						id="choose-button-file"
						appDebounceClick
						(debounceClick)="myFileInput2.click()"
					>
						CHOOSE FILE
					</button>
					<input
						name="file"
						#myFileInput2
						hidden
						type="file"
						(change)="uploadFile2($event)"
						placeholder="Upload file"
						id="choose-input-hidden2"
					/>
				</div>
				<div *ngIf="replaceForm.name">
					<div>
						<mat-form-field class="input-full-width" appearance="outline">
							<mat-label>Document Title</mat-label>
							<input matInput name="name" [(ngModel)]="replaceForm.name" id="document-title-input" required />
						</mat-form-field>
					</div>
					<div>
						<mat-form-field class="input-full-width" appearance="outline">
							<mat-label>Reason</mat-label>
							<mat-select
								[(ngModel)]="replaceForm.reason"
								id="retract-reason"
								name="reason"
								required
								aria-label="Reason {{
									replaceForm.reason ? 'list box with ' + replaceForm.reason + ' value selected' : ''
								}}"
							>
								<mat-option *ngFor="let item of replaceReasons" [value]="item.description">
									{{ item.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>
		</mat-dialog-content>
		<mat-divider></mat-divider>
		<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="margin-10 padding-10">
			<div class="">
				<button mat-button color="primary" class="text-left" matDialogClose>CANCEL</button>
			</div>
			<button
				mat-flat-button
				color="primary"
				[disabled]="replForm.invalid || !replaceForm.name"
				aria-label="Update Document"
				appDebounceClick
				(debounceClick)="onReplaceFile()"
				id="save-doc"
			>
				SAVE
			</button>
		</mat-dialog-actions>
	</form>
</ng-template>
