const StringUtils = {
	getFileExtension,
	formatPhoneNumber,
	dateOfBirthStringToDate,
	splitPhone,
	convertDateObjToString,
	convertTimeStampDateObjToString,
	splitPhoneAndExtension,
	breakString,
	concatName,
	renamefile,
	clearAbbreviation,
	capitalizeFirstLetter,
};

/**
 * @description Combine three parts into a full name.
 * @param lastName
 * @param middleInitial
 * @param firstName
 */
function concatName(
	lastName: string,
	middleInitial: string,
	firstName: string,
	prefix: string = null,
	suffix: string = null
) {
	const middle = (middleInitial || '') + (middleInitial ? ' ' : '');
	let name = `${firstName} ${middle}${lastName}`;
	if (prefix) {
		name = prefix + ' ' + name;
	}
	if (suffix) {
		name = name + ' ' + suffix;
	}
	return toTitleCase(name);
}

function toTitleCase(str) {
	return str
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
		.join(' ');
}

/**
 * @description splits the file extension of given file name and returns it
 * @param fileName file whose extension needs to be evaluated
 */
function getFileExtension(fileName) {
	const fileExtension = fileName.replace(/^.*\./, '');
	return fileExtension;
}

/**
 * @description formats any time of phone number into standard US format
 * @params phoneNumberString  @output (111) 111-1111
 */
function formatPhoneNumber(phoneNumberString, country = null) {
	if (country === 'USA') {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			let string = '';
			if (match[1]) {
				string = '(' + match[1] + ') ';
				if (match[2]) string += match[2];
				if (match[3]) string += '-' + match[3];
			} else if (match[2]) {
				string = '(' + match[2] + ') ';
				if (match[3]) string += '-' + match[3];
			} else if (match[3]) {
				string = '(' + match[3] + ') ';
			}
			return string;
		}
		return null;
	} else {
		return phoneNumberString;
	}
}

/**
 * @description will get date in string  - ex: input Wed 03, April, 1900 - output [04, 03, 1900]
 * @param val - string (Wed 03, April, 1900)
 * TODO move these methods to util
 */
function convertDateObjToString(val) {
	const d = new Date(val);
	let month = '' + (val.getMonth() + 1);
	let day = '' + val.getDate();
	const year = val.getFullYear();
	if (month.length < 2) {
		month = '0' + month;
	}
	if (day.length < 2) {
		day = '0' + day;
	}
	return [month, day, year].join('-');
}

/**
 * @description converts time stamp into mm-dd-yyy format
 * @param val: unix timestamp
 */
function convertTimeStampDateObjToString(val) {
	const d = new Date(val);
	let month = '' + (d.getMonth() + 1);
	let day = '' + d.getDate();
	const year = d.getFullYear();
	if (month.length < 2) {
		month = '0' + month;
	}
	if (day.length < 2) {
		day = '0' + day;
	}
	return [month, day, year].join('-');
}

/**
 * @description converts string to date.
 * @param dateString: date that is in string format.
 */
function dateOfBirthStringToDate(dateString) {
	if (dateString) {
		return new Date(dateString);
	}
	return null;
}

/**
 * @description helper function to split the phone number into phone number and ext number.
 * @params phone: the phone number string
 * @output a list of length 2 in the following order: phone number, ext number
 */
function splitPhone(phone: string) {
	if (phone) {
		if (phone.includes('() ') || phone.includes('null')) {
			return [null, null];
		}
		// numbers without ext
		if (phone.includes(' ()')) {
			return [phone.replace(' ()', ''), null];
		} else {
			// numbers with ext
			const phoneArray = phone.split(' (');
			if (phoneArray[1]) {
				phoneArray[1] = phoneArray[1].slice(0, -1);
			}
			return phoneArray;
		}
	} else {
		return [null, null];
	}
}

/**
 * @description slice the phone number and phone number extension from eml based on the index of 'x' character.
 * @param phoneNumber
 */
function splitPhoneAndExtension(phoneNumber: string, country: string = null) {
	const phoneArray = [];
	const index = phoneNumber.indexOf(':');
	if (index > -1) {
		const emlPhoneNumber = formatPhoneNumber(phoneNumber.slice(0, index), country);
		const emlPhoneExtension = phoneNumber.slice(index + 1, phoneNumber.length);
		phoneArray.push(emlPhoneNumber, emlPhoneExtension);
	} else {
		phoneArray.push(phoneNumber);
	}
	return phoneArray;
}

/**
 * @description break string by charactor limit
 * @param str
 * @param charLimit
 */
function breakString(str: string, charLimit: number) {
	return breakStringRec(str, charLimit, '');
}

function breakStringRec(str: string, charLimit: number, rs: string) {
	if (str.length <= charLimit) {
		return rs + str;
	} else {
		return breakStringRec(str.slice(charLimit), charLimit, rs + str.slice(0, charLimit) + ' ');
	}
}

function renamefile(filename: string, newName: string): string {
	const suffix = filename.split('.')[1];
	return suffix ? `${newName}.${suffix}` : newName;
}

function clearAbbreviation(input: string): string {
	if (input) {
		input = input.replace('w/o', 'without');
		input = input.replace('w/', 'with');
		input = input.replace('appt', 'appointment');
		// to be extended
	}
	return input;
}

function capitalizeFirstLetter(string: string): string {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return null;
}

export { StringUtils };
