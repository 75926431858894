import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const MY_FORMATS = {
	parse: {
		dateInput: 'l',
	},
	display: {
		dateInput: 'M/D/YY, h:mm:ss A',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LLLL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
@Component({
	selector: 'date-time-picker',
	template: `<mat-form-field class="input-full-width" appearance="outline">
		<mat-label>{{ label }}</mat-label>
		<input
			matInput
			[placeholder]="placeholder"
			id="dateTimePicker{{ ind }}"
			[min]="minDate"
			[(ngModel)]="value"
			[ngxMatDatetimePicker]="mediationSchedule2"
			required
			(ngModelChange)="onDateTimeChange($event)"
		/>
		<mat-datepicker-toggle
			matSuffix
			[for]="mediationSchedule2"
			id="mediation-schedule-date-picker-{{ ind }}"
		></mat-datepicker-toggle>
		<ngx-mat-datetime-picker
			class="date-picker"
			color="primary"
			showSpinners="true"
			showSeconds="false"
			[stepHour]="1"
			[stepMinute]="1"
			[stepSecond]="1"
			touchUi="false"
			enableMeridian="true"
			#mediationSchedule2
		>
		</ngx-mat-datetime-picker>
	</mat-form-field>`,
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class DateTimePickerComponent {
	value: any;
	@Input() ind: any = '1';
	@Input() label: any = '';
	@Input() maxDate: any;
	@Input() minDate: any;
	@Input() placeholder: string = '';
	@Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	onDateTimeChange(e) {
		this.dateChange.emit(e.format('YYYY-MM-DD hh:mm A'));
	}
}
