export interface litigationNewInquiryState {
    litigationNewInquiryData: null | any;
};

export const litigationNewInquiryIntialState: litigationNewInquiryState = {
    litigationNewInquiryData: null,
};

export interface litigationDistrictOfficesState {
    litigationDistrictOfficesData: null | any;
};

export const litigationDistrictOfficesIntialState: litigationDistrictOfficesState = {
    litigationDistrictOfficesData: null,
};

export interface litigationSharedCodeState {
    litigationSharedCode: any | null;
};

export const litigationSharedCodeIntialState: litigationSharedCodeState = {
    litigationSharedCode: null,
};