/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { User } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UserResourceService {

    protected basePath = 'https://dev.eeoc.gov/UserManagement';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(user: User, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<User>;
    public createUser(user: User, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<User>>;
    public createUser(user: User, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<User>>;
    public createUser(user: User, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling createUser.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<User>(`${this.configuration.basePath}/users`,
            user,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userDetailId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(userDetailId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<User>;
    public deleteUser(userDetailId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<User>>;
    public deleteUser(userDetailId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<User>>;
    public deleteUser(userDetailId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userDetailId === null || userDetailId === undefined) {
            throw new Error('Required parameter userDetailId was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<User>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userDetailId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userDetailId 
     * @param isADId 
     * @param isActive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserById(userDetailId: string, isADId?: boolean, isActive?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<User>;
    public getUserById(userDetailId: string, isADId?: boolean, isActive?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<User>>;
    public getUserById(userDetailId: string, isADId?: boolean, isActive?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<User>>;
    public getUserById(userDetailId: string, isADId?: boolean, isActive?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userDetailId === null || userDetailId === undefined) {
            throw new Error('Required parameter userDetailId was null or undefined when calling getUserById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isADId !== undefined && isADId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isADId, 'isADId');
        }
        if (isActive !== undefined && isActive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isActive, 'isActive');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<User>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userDetailId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userIds 
     * @param emailAddress 
     * @param firstName 
     * @param lastName 
     * @param userName 
     * @param homeOfficeCodes 
     * @param userType 
     * @param workPhone 
     * @param assignedOfficeCodes 
     * @param roleStatus 
     * @param assignedRoleCodes 
     * @param unit 
     * @param isAdrStaff 
     * @param userADIds 
     * @param isActive 
     * @param activeAndInactive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(userIds?: Array<string>, emailAddress?: string, firstName?: string, lastName?: string, userName?: string, homeOfficeCodes?: Array<string>, userType?: string, workPhone?: string, assignedOfficeCodes?: Array<string>, roleStatus?: 'Pending' | 'Approved' | 'Denied' | 'Deactivated', assignedRoleCodes?: Array<string>, unit?: number, isAdrStaff?: boolean, userADIds?: Array<string>, isActive?: boolean, activeAndInactive?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User>>;
    public getUsers(userIds?: Array<string>, emailAddress?: string, firstName?: string, lastName?: string, userName?: string, homeOfficeCodes?: Array<string>, userType?: string, workPhone?: string, assignedOfficeCodes?: Array<string>, roleStatus?: 'Pending' | 'Approved' | 'Denied' | 'Deactivated', assignedRoleCodes?: Array<string>, unit?: number, isAdrStaff?: boolean, userADIds?: Array<string>, isActive?: boolean, activeAndInactive?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User>>>;
    public getUsers(userIds?: Array<string>, emailAddress?: string, firstName?: string, lastName?: string, userName?: string, homeOfficeCodes?: Array<string>, userType?: string, workPhone?: string, assignedOfficeCodes?: Array<string>, roleStatus?: 'Pending' | 'Approved' | 'Denied' | 'Deactivated', assignedRoleCodes?: Array<string>, unit?: number, isAdrStaff?: boolean, userADIds?: Array<string>, isActive?: boolean, activeAndInactive?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User>>>;
    public getUsers(userIds?: Array<string>, emailAddress?: string, firstName?: string, lastName?: string, userName?: string, homeOfficeCodes?: Array<string>, userType?: string, workPhone?: string, assignedOfficeCodes?: Array<string>, roleStatus?: 'Pending' | 'Approved' | 'Denied' | 'Deactivated', assignedRoleCodes?: Array<string>, unit?: number, isAdrStaff?: boolean, userADIds?: Array<string>, isActive?: boolean, activeAndInactive?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (userIds) {
            userIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'userIds');
            })
        }
        if (emailAddress !== undefined && emailAddress !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>emailAddress, 'emailAddress');
        }
        if (firstName !== undefined && firstName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>firstName, 'firstName');
        }
        if (lastName !== undefined && lastName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastName, 'lastName');
        }
        if (userName !== undefined && userName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userName, 'userName');
        }
        if (homeOfficeCodes) {
            homeOfficeCodes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'homeOfficeCodes');
            })
        }
        if (userType !== undefined && userType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userType, 'userType');
        }
        if (workPhone !== undefined && workPhone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>workPhone, 'workPhone');
        }
        if (assignedOfficeCodes) {
            assignedOfficeCodes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'assignedOfficeCodes');
            })
        }
        if (roleStatus !== undefined && roleStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>roleStatus, 'roleStatus');
        }
        if (assignedRoleCodes) {
            assignedRoleCodes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'assignedRoleCodes');
            })
        }
        if (unit !== undefined && unit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>unit, 'unit');
        }
        if (isAdrStaff !== undefined && isAdrStaff !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isAdrStaff, 'isAdrStaff');
        }
        if (userADIds) {
            userADIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'userADIds');
            })
        }
        if (isActive !== undefined && isActive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isActive, 'isActive');
        }
        if (activeAndInactive !== undefined && activeAndInactive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activeAndInactive, 'activeAndInactive');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User>>(`${this.configuration.basePath}/users`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncUserProfiles(userType: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<boolean>;
    public syncUserProfiles(userType: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<boolean>>;
    public syncUserProfiles(userType: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<boolean>>;
    public syncUserProfiles(userType: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userType === null || userType === undefined) {
            throw new Error('Required parameter userType was null or undefined when calling syncUserProfiles.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/users/sync-profile/${encodeURIComponent(String(userType))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userDetailId 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserProfile(userDetailId: string, user: User, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<User>;
    public updateUserProfile(userDetailId: string, user: User, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<User>>;
    public updateUserProfile(userDetailId: string, user: User, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<User>>;
    public updateUserProfile(userDetailId: string, user: User, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userDetailId === null || userDetailId === undefined) {
            throw new Error('Required parameter userDetailId was null or undefined when calling updateUserProfile.');
        }
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling updateUserProfile.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<User>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userDetailId))}/profile_update`,
            user,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
