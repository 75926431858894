import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-notfound',
	templateUrl: './app-notfound.component.html',
	styleUrls: ['./app-notfound.component.css'],
})
export class AppNotfoundComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
