import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabTypePipe } from '../../../shared/pipes/tab-type.pipe';
import { SharedMaterialModule } from '../../../shared/shared-material.module';
import { TemplateLibraryModule } from '../case-details/template-library/template-library.module';
import { AppDragDropDirective } from './app-drag-drop.directive';
import { DocumentsRoutingModule } from './documents-routing.module';
import { PrivateDocumentsComponent } from './private-documents/private-documents.component';

@NgModule({
    declarations: [
        PrivateDocumentsComponent,
        AppDragDropDirective,
        TabTypePipe,
    ],
    imports: [
        CommonModule,
        RouterModule,
        DocumentsRoutingModule,
        SharedMaterialModule,
        TemplateLibraryModule,
        FormsModule,
    ],
    exports: [PrivateDocumentsComponent]
})
export class DocumentsModule {}
