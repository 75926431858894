<!-- <mat-card-title>Uploaded Documents</mat-card-title>
<p>Select documents using the checkboxes, and click the appropriate button to complete the action.</p> -->
<div style="overflow:hidden">
<div fxLayout="row" fxLayoutAlign="left" *ngIf="tabHeading">
	<h4 class="arc-h4 mt-4 mb-3">{{ tabHeading }}</h4>
</div>
<div class="button-row margin-bottom-10">
	<mat-checkbox
		#ref
		class="checkbox-min-width margin-checkbox text-center"
		(change)="$event ? masterToggle(ref) : null"
		[checked]="selection.hasValue() && isAllSelected()"
		[indeterminate]="selection.hasValue() && !isAllSelected()"
		id="select-all-checkbox"
		[disabled]="isDownloadDisabled()"
	>
		<span class="margin-right-10"> {{ selection?.selected?.length }} of {{ dataSource?.data?.length }} selected</span>
	</mat-checkbox>
	<button
		mat-flat-button
		color="primary"
		class="mat-button-sm"
		appDebounceClick
		(debounceClick)="downloadAsZip()"		
		id="download-button"
		[disabled]="(dataSource && dataSource.data.length === 0) || isFileSizeExceedsMaxSize || isDownloadDisabled()"
	>
		Download
	</button>
	<button
		mat-flat-button
		color="primary"
		class="mat-button-sm"
		appDebounceClick
		(debounceClick)="exportToExcel($event)"
		id="export-button"		
		[disabled]="(dataSource && dataSource.data.length === 0) || isDownloadDisabled()"
	>
		Export To Excel
	</button>
	<button
		*ngIf="!isSystemic"
		mat-flat-button
		color="primary"
		class="mat-button-sm"
		[disabled]="!enableSendToFepa || selection.selected.length === 0 || parent?.readOnlyAccess || hasInspectorGeneralRole"
		appDebounceClick
		(debounceClick)="transferDocuments()"
		id="send-to-fepa-button"
	>
		Send To {{ isPrivateUser ? 'FEPA' : 'EEOC' }}
	</button>
</div>

<div role="status" class="sr-only" aria-live="polite">{{ ariaResultAlert }} results found</div>
<div [style.overflow]="'auto'">
	<table mat-table #table [dataSource]="dataSource" matSort>
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef class="checkbox-min-width text-center">
				<mat-checkbox
					#ref
					hidden
					(change)="$event ? masterToggle(ref) : null"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()"
					id="select-all-checkbox"					
					aria-label="Check this box to select all"
					[disabled]="!hasInspectorGeneralRole || parent?.readOnlyAccess"
				>
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row; let i = index" class="checkbox-min-width">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null; isSomeSelected()"
					[checked]="selection.isSelected(row)"
					id="select-checkbox-{{ i }}"
					aria-label="Check this box to select this row"
				>
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container matColumnDef="title">
			<th mat-header-cell *matHeaderCellDef [style.width]="'15%'">
				<div mat-sort-header>Document Title</div>
				<input
					(click)="$event.stopPropagation()"
					(keyup)="applyColumnFilter($event.target.value, 'title')"
					id="title-column-filter"
					aria-label="Filter by document title"
				/>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<span *ngIf="parent?.readOnlyAccess && !hasInspectorGeneralRole">{{row.title}}</span>
				<a
					*ngIf="!parent?.readOnlyAccess || hasInspectorGeneralRole"
					href="javascript:void(0)"
					style="cursor: pointer"
					appDebounceClick
					(debounceClick)="downloadDocument(row)"
					matTooltip="Click to download the document"
					id="file-title-{{ i }}"
				>
					{{ row.title }}
				</a>	
			</td>
		</ng-container>
		<ng-container matColumnDef="documentDescription">
			<th mat-header-cell *matHeaderCellDef [style.width]="'20%'">
				<div mat-sort-header>Document Type</div>
				<input
					(click)="$event.stopPropagation()"
					id="type-column-filter-docDesc"
					(keyup)="applyColumnFilter($event.target.value, 'documentDescription')"
					aria-label="Filter by document type"
				/>
			</th>
			<td mat-cell *matCellDef="let row">{{ row.documentDescription || row.documentType }}</td>
		</ng-container>

		<ng-container matColumnDef="tabType">
			<th mat-header-cell *matHeaderCellDef [style.width]="'20%'">
				<div mat-sort-header>Category</div>
				<input
					(click)="$event.stopPropagation()"
					id="type-column-filter-tabtype"
					(keyup)="applyColumnFilter($event.target.value, 'tabType')"
					aria-label="Filter by category"
				/>
			</th>
			<td mat-cell *matCellDef="let row">
				{{ row.tabType || 'N/A' }}
			</td>
		</ng-container>

		<ng-container matColumnDef="createdByFullName">
			<th mat-header-cell *matHeaderCellDef [style.width]="'10%'">
				<div mat-sort-header>Uploaded By</div>
				<input
					(click)="$event.stopPropagation()"
					id="uploaded-by-column-filter"
					(keyup)="applyColumnFilter($event.target.value, 'createdByFullName')"
					aria-label="Filter by uploaded by"
				/>
			</th>
			<td mat-cell *matCellDef="let row">{{ row.createdByFullName }}</td>
		</ng-container>
		<ng-container matColumnDef="relatedDate">
			<th mat-header-cell *matHeaderCellDef [style.width]="'5%'">
				<div mat-sort-header>Doc/Rcvd Date</div>
				<input
					(click)="$event.stopPropagation()"
					id="related-date-column-filter"
					(keyup)="applyColumnFilter($event.target.value, 'relatedDate')"
					aria-label="Filter by doc/received date"
				/>
			</th>
			<td mat-cell *matCellDef="let row">
				{{ row?.relatedDate ? (row.relatedDate | date: 'M/d/yyyy') : 'N/A' }}
			</td>
		</ng-container>
		<ng-container matColumnDef="createdDate">
			<th mat-header-cell *matHeaderCellDef [style.width]="'10%'">
				<div mat-sort-header>Uploaded Date</div>
				<input
					(click)="$event.stopPropagation()"
					id="created-date-column-filter"
					(keyup)="applyColumnFilter($event.target.value, 'createdDate')"
					aria-label="Filter by uploaded date"
				/>
			</th>
			<td mat-cell *matCellDef="let row">{{ cmnServ.displayEsttoLocale(row.createdDate) }}</td>
		</ng-container>
		<ng-container matColumnDef="availableTo">
			<th mat-header-cell *matHeaderCellDef [style.width]="'5%'">
				<div mat-sort-header>
					Available To
					<mat-icon
						class="info-icon"
						matTooltip="This document was made accessible to certain external parties.
            You may undo this action by recalling the document via the actions menu."
						color="primary"
						tabindex="0"
						aria-label="Tooltip. This document was made accessible to certain external parties.
						You may undo this action by recalling the document via the actions menu."
						role="none"
					>
						info
					</mat-icon>
				</div>
				<input
					class="margin-0"
					(click)="$event.stopPropagation()"
					id="available-to-column-filter"
					(keyup)="applyColumnFilter($event.target.value, 'availableTo')"
					aria-label="Filter by available to"
				/>
			</th>
			<td mat-cell *matCellDef="let row; let i = index" class="pull-text-center">
				<button
					mat-flat-button
					color="primary"
					class="mat-button-sm"
					[title]="'Make document accessible to: ' + row.availableToTitle"
					*ngIf="displayRelease(row)"
					[disabled]="isDisabled(row)"
					appDebounceClick
					(debounceClick)="parent?.confirmPublishDocument(row, enableSendToFepa)"
					id="release-button-{{ i }}"
				>
					Release
				</button>
				<ng-container *ngIf="!displayRelease(row)">
					{{
						row.availableTo && !(!enableSendToFepa && row.availableTo === 'FEPA') ? row.availableTo : 'N/A'
					}}</ng-container
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="nonDisclosable">
			<th mat-header-cell *matHeaderCellDef class="mat-table-actions pull-text-center" [style.width]="'5%'">
				<div class="margin-bottom-30">
					Non-disclosable
					<mat-icon
						class="info-icon"
						matTooltip="A document (or other data) that is considered to be 'confidential' and cannot be provided to a party in response to that party's request for information under the Freedom of Information Act (FOIA) or Section 83 of the U.S. EEOC Compliance Manual."
						color="primary"
						tabindex="0"
						aria-label="A document (or other data) that is considered to be 'confidential' and cannot be provided to a party in response to that party's request for information under the Freedom of Information Act (FOIA) or Section 83 of the U.S. EEOC Compliance Manual."
						role="none"
					>
						info
					</mat-icon>
				</div>
			</th>

			<td mat-cell *matCellDef="let row" class="d-flex flex-row justify-content-center padding-10">
				<mat-icon color="primary" *ngIf="row?.isDisclosable === false">lock</mat-icon>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef class="mat-table-actions pull-text-center padding-right-7" [style.width]="'5%'">
				<div class="margin-bottom-30">Actions</div>
			</th>
			<td mat-cell *matCellDef="let row; let i = index" class="mat-table-actions pull-text-center icon-blue">
				<mat-icon color="primary" [matMenuTriggerFor]="menu">more_vert</mat-icon>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						color="primary"
						appDebounceClick
						(debounceClick)="showRowEditDialog(row)"
						title="edit"
						id="edit-button-{{ i }}"
						[disabled]="parent?.fileNotUpdatable(row) || parent?.readOnlyCharge"
					>
						Edit
					</button>
					<!-- <button
						mat-menu-item
						color="warn"
						appDebounceClick
						(debounceClick)="confirmDelete(row)"
						title="Delete"
						id="delete-button-{{ i }}"
						class="{{ row.documentType }}"
						[disabled]="isDeleteDisabled(row)"
					>
						Delete
					</button> -->
					<button
						*ngIf="!isSystemic"
						mat-menu-item
						appDebounceClick
						(debounceClick)="parent?.showResendDialog(row)"
						title="Re-send"
						id="resend-button-{{ i }}"
						[disabled]="!isPrivateUser || !row.isSendEnable || parent?.readOnlyCharge"
					>
						Send
					</button>
					<button
						mat-menu-item
						title="click to recall the document"
						*ngIf="parent?.hasRecall(row) && !['PRVNOC'].includes(row.documentType)"
						appDebounceClick
						(debounceClick)="parent?.confirmRecallDocument(row)"
						[disabled]="parent?.readOnlyCharge || isAttorneySupervisor()"
						id="recall-button-{{ i }}"
					>
						Recall
					</button>
					<button
						mat-menu-item
						appDebounceClick
						(debounceClick)="toggleDocumentDisclosable(row)"
						[disabled]="parent?.readOnlyCharge"
						id="disclosable-button"
					>
						Make {{ row.isDisclosable === true ? 'Non-disclosable' : 'Disclosable' }}
					</button>
					<button
						*ngIf="!parent?.hasRecall(row) && ((hasRetractRole && !['PRVNOC'].includes(row.documentType)) || (hasRetractRoleForADR && (((row.documentType).includes('ADR')) || ((row.documentDescription).includes('ADR')))))"
						mat-menu-item
						color="warn"
						appDebounceClick
						(debounceClick)="parent?.openReplaceDialog(row)"
						title="Retract"
						id="retract-button-{{ i }}"
						class="{{ row.documentType }}"
						[disabled]="disableRetract(row)"
					>
						Retract
					</button>
				</mat-menu>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
		<tr class="mat-table-row" mat-row *matRowDef="let row; columns: displayColumns"></tr>
	</table>
</div>
<div *ngIf="dataSource && dataSource.data.length === 0" class="margin-left-20">No records found.</div>
<div class="mat-loading-spinner" *ngIf="isBusy">
	<mat-progress-spinner color="primary" mode="indeterminate" [diameter]="70"> </mat-progress-spinner>
</div>
<mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 100, 250, 500]"></mat-paginator>
</div>
