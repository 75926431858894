<div fxLayout="row" fxLayoutAlign="center" class="container">
	<div fxFlex="90%">
		<h1 tabindex="0" role="heading" aria-label="service error" style="color: #3478cd; font-weight: bold">
			Service Error
		</h1>
		<h4
			role="presentation"
			tabindex="0"
			aria-label="There was a problem handling your request and an unspecified error has occurred"
		>
			There was a problem handling your request and an unspecified error has occurred.
		</h4>
		<h4
			role="presentation"
			tabindex="0"
			aria-label="Please click on a link in the above menu to navigate to another location or here to go back to the Dashboard"
		>
			Please navigate to another location
		</h4>
	</div>
</div>
