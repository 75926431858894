/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { PendingReportResponseSummary } from '../model/models';
import { ReportRequestParam } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ReportResourceService {

    protected basePath = 'https://dev.eeoc.gov/searchws';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param reportName 
     * @param reportReqParam 
     * @param startPosition 
     * @param recordsPerPage 
     * @param scrollId 
     * @param paginationIntent 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateEventReport(reportName: 'changeInDraft' | 'deinedApproval' | 'posUploaded' | 'cpRequestedPos' | 'nrtsUploaded', reportReqParam: ReportRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public generateEventReport(reportName: 'changeInDraft' | 'deinedApproval' | 'posUploaded' | 'cpRequestedPos' | 'nrtsUploaded', reportReqParam: ReportRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public generateEventReport(reportName: 'changeInDraft' | 'deinedApproval' | 'posUploaded' | 'cpRequestedPos' | 'nrtsUploaded', reportReqParam: ReportRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public generateEventReport(reportName: 'changeInDraft' | 'deinedApproval' | 'posUploaded' | 'cpRequestedPos' | 'nrtsUploaded', reportReqParam: ReportRequestParam, startPosition?: number, recordsPerPage?: number, scrollId?: string, paginationIntent?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (reportName === null || reportName === undefined) {
            throw new Error('Required parameter reportName was null or undefined when calling generateEventReport.');
        }
        if (reportReqParam === null || reportReqParam === undefined) {
            throw new Error('Required parameter reportReqParam was null or undefined when calling generateEventReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reportReqParam !== undefined && reportReqParam !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reportReqParam, 'reportReqParam');
        }

        let headers = this.defaultHeaders;
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }
        if (scrollId !== undefined && scrollId !== null) {
            headers = headers.set('scrollId', String(scrollId));
        }
        if (paginationIntent !== undefined && paginationIntent !== null) {
            headers = headers.set('paginationIntent', String(paginationIntent));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v1/report/${encodeURIComponent(String(reportName))}/event`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param reportName 
     * @param reqParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generatePendingInventoryReport(reportName: string, reqParam: ReportRequestParam, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PendingReportResponseSummary>>;
    public generatePendingInventoryReport(reportName: string, reqParam: ReportRequestParam, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PendingReportResponseSummary>>>;
    public generatePendingInventoryReport(reportName: string, reqParam: ReportRequestParam, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PendingReportResponseSummary>>>;
    public generatePendingInventoryReport(reportName: string, reqParam: ReportRequestParam, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (reportName === null || reportName === undefined) {
            throw new Error('Required parameter reportName was null or undefined when calling generatePendingInventoryReport.');
        }
        if (reqParam === null || reqParam === undefined) {
            throw new Error('Required parameter reqParam was null or undefined when calling generatePendingInventoryReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reqParam !== undefined && reqParam !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reqParam, 'reqParam');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PendingReportResponseSummary>>(`${this.configuration.basePath}/prepa/v1/report/${encodeURIComponent(String(reportName))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param filterName 
     * @param officeCode 
     * @param caAssignedTo 
     * @param startPosition 
     * @param recordsPerPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateReportByFilter(filterName: 'PENDING' | 'RECEIVED' | 'PENDING_RELEASED_TO_CP', officeCode: string, caAssignedTo?: string, startPosition?: number, recordsPerPage?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<{ [key: string]: object; }>>;
    public generateReportByFilter(filterName: 'PENDING' | 'RECEIVED' | 'PENDING_RELEASED_TO_CP', officeCode: string, caAssignedTo?: string, startPosition?: number, recordsPerPage?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<{ [key: string]: object; }>>>;
    public generateReportByFilter(filterName: 'PENDING' | 'RECEIVED' | 'PENDING_RELEASED_TO_CP', officeCode: string, caAssignedTo?: string, startPosition?: number, recordsPerPage?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<{ [key: string]: object; }>>>;
    public generateReportByFilter(filterName: 'PENDING' | 'RECEIVED' | 'PENDING_RELEASED_TO_CP', officeCode: string, caAssignedTo?: string, startPosition?: number, recordsPerPage?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (filterName === null || filterName === undefined) {
            throw new Error('Required parameter filterName was null or undefined when calling generateReportByFilter.');
        }
        if (officeCode === null || officeCode === undefined) {
            throw new Error('Required parameter officeCode was null or undefined when calling generateReportByFilter.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (caAssignedTo !== undefined && caAssignedTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>caAssignedTo, 'caAssignedTo');
        }

        let headers = this.defaultHeaders;
        if (startPosition !== undefined && startPosition !== null) {
            headers = headers.set('startPosition', String(startPosition));
        }
        if (recordsPerPage !== undefined && recordsPerPage !== null) {
            headers = headers.set('recordsPerPage', String(recordsPerPage));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<{ [key: string]: object; }>>(`${this.configuration.basePath}/prepa/v2/filter/${encodeURIComponent(String(filterName))}/office-code/${encodeURIComponent(String(officeCode))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
