import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import  moment from 'moment';
import { LookupData, User } from 'src/app/services/gen';
import * as _ from 'lodash';

// Functions in Utils can be called outside without injection
const Utils = {
	caseNumberConverter,
	caseTypeByCaseNumber,
	crossFieldValidation,
	isPhoneEmtpy,
	calculateAge,
	processPhoneNumber,
	getPathParameter,
	getParameterByName,
	notNullCondition,
	isNullCondition,
	validateEmail,
	isEmptyCondition,
	isEqual,
	isoStringToDate,
	formatPhone,
	crossFieldValidationEmpDb,
	evaluateNoOfEmployeeCode,
	isNumeric,
	getLookupDataByCode,
	getLookupDataById,
	getLookupDataBySize,
	getUserById,
	sortStaffByName,
	sortSharedCodes,
  sortSharedCodesByDisplayOrder,
	sortRoles,
	sortbyShortName,
	getDateString,
	combineDateAndTime,
	extractTimeStringFromDate,
	_getFormControl,
	_setRequiredValidators,
	_clearRequiredValidators,
	_isRequiredFormControl,
	_addFormControl,
	_removeFormControl,
	_compareFn,
	_sortSharedCodesIncludingOther,
	equalityCheck,
	getDescriptionByValue,
	strArrayToSharedCodesArray,
	getNestedDescriptionByValue,
	getSelectedDescriptionByValue,
	calculateDays,
	calculateDaysInNumber,
    cloneDeep,
    toDate,
    toIso,
    formatToPattern,
	hasAlphabeticCharacters
};

function caseNumberConverter(cn: string) {
	if (cn.endsWith('C') || cn.endsWith('N')) {
		return cn.slice(0, -1);
	} else {
		return cn;
	}
}

function caseTypeByCaseNumber(cn: string) {
	if (cn.endsWith('C') || cn.endsWith('N')) {
		return cn.slice(-1);
	}
}

function hasAlphabeticCharacters(str) {
	return /[a-zA-Z]/.test(str);
  }

function cloneDeep(obj: any): any {
  return _.cloneDeep(obj);
}

/**
 * @description calculate and return the difference between two dates
 * @param initDate: the initial date
 */
function calculateAge(initDate): number {
	if (!initDate) {
		return null;
	}
	const _initDate = new Date(initDate);
	const _curDate = new Date();
	const age = Math.floor((_curDate.getTime() - _initDate.getTime()) / 1000 / 3600 / 24);
	return isNaN(age) ? null : age;
}

/**
 * @description extracts the string in the given url after the specified name
 * @params name
 * @params url
 */
function getParameterByName(name, url) {
	name = name.replace(/[\[\]\\]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * @description returns true if the value is not empty
 * @params value
 */
function notNullCondition(value) {
	return value !== undefined && value !== null && value !== '' || ( typeof value == 'object' && value !== undefined && value !== null && Object.keys(value).length > 0 );
}

/**
 * @description returns true if the value is empty
 * @params value
 */
function isNullCondition(value) {
	return value === undefined || value === null;
}

/**
 * @description returns true if the value is undefined/ null/ empty string.
 * @params value
 */
function isEmptyCondition(value) {
	return isNullCondition(value) || value === '';
}

/**
 * @description returns true if the both objects are equal.
 * @params obj1, obj2
 */
function isEqual(obj1: any, obj2: any): boolean {
	let obj1Keys = Object.keys(obj1 || {});
	let obj2Keys = Object.keys(obj2 || {});

	if (obj1Keys.length !== obj2Keys.length) {
		return false;
	}

	for (let key of obj1Keys) {
		if (typeof obj1[key] == 'object') {
			if (obj1[key]?.getTime) {
				if (obj1[key].toString() !== (obj2[key] || '').toString()) {
					return false;
				}
			} else {
				if (!this.isEqual(obj1[key], obj2[key])) {
					return false;
				}
			}
		} else {
			if (obj1[key] !== obj2[key]) {
				return false;
			}
		}
	}

	return true;
}

//compare two objects

/**
 * @description get the path parameter by parameter index.
 * @param idx parameter index
 * @note Not currently in use. This method will be necessary to enable browser refresh & direct access by url
 * for the application.
 */
function getPathParameter(idx) {
	const url = window.location.href;
	return url.split('//')[1].split('/')[idx + 1].split('?')[0];
}

/**
 * @description convert iso string to Date type
 * @param dateStr
 */
function isoStringToDate(dateStr: string) {
	const d = new Date(dateStr);
	if (Object.prototype.toString.call(d) === '[object Date]') {
		// it is a date
		if (isNaN(d.getTime())) {
			// d.valueOf() could also work
			// date is not valid
			return '';
		} else {
			// date is valid
			return d;
		}
	} else {
		// not a date
		return '';
	}
}

function calculateDays(date, format) {
	
	return calculateDaysInNumber(date, format) + ' Days';
}
function calculateDaysInNumber(date, format) {
	if (!date) {
		return undefined;
	}
	let dob = moment(date.toString().substr(0, 10), format);
	return Math.floor(moment.duration(moment().diff(dob)).asDays());
}

/**
 * @description return true if one of the following fields is filled: email, home phone, cellphone, full address
 * @param group: the form group holding the information
 * @param thisRef: the reference to this holding the crossFieldInvalid flag.
 */
function crossFieldValidation(
	isRespondent: boolean,
	isAdditionalAddress: boolean,
	isChargingParty,
	email?,
	fax?,
	workPhone?,
	cellPhone?,
	homePhone?,
	addressLine1?,
	city?,
	state?,
	country?,
	zipCode?
): boolean {
	if (country === 'USA') {
		if (addressLine1 && state && city && zipCode) return true;
		if ((addressLine1 && !zipCode) || (zipCode && (!addressLine1 || !city || !state))) return false;
	} else {
		if (addressLine1 && city) return true;
		else return false;
	}

	if (email !== '' && email !== undefined && email !== null) {
		return true;
	}
	if (fax !== '()' && fax !== '' && fax !== undefined && fax !== null) {
		return true;
	}
	if (workPhone !== '()' && workPhone !== '' && workPhone !== undefined && workPhone !== null) {
		return true;
	}
	if (
		(isChargingParty || isAdditionalAddress) &&
		cellPhone !== '()' &&
		cellPhone !== '' &&
		cellPhone !== undefined &&
		cellPhone !== null
	)
		return true;
	if (
		(isChargingParty || isAdditionalAddress) &&
		homePhone !== '()' &&
		homePhone !== '' &&
		homePhone !== undefined &&
		homePhone !== null
	)
		return true;
	if (
		(isRespondent || isAdditionalAddress) &&
		workPhone !== '()' &&
		workPhone !== '' &&
		workPhone !== undefined &&
		workPhone !== null
	)
		return true;
	return false;
}

/**
 * @description check if formated phone number is empty
 * @param phone
 */
function isPhoneEmtpy(phone) {
	return !phone || /^\D{1}$/g.test(phone) || phone === '()';
}

/**
 * @description return true if one of the following fields is filled: email, home phone, cellphone, full address
 * @param group: the form group holding the information
 * @param thisRef: the reference to this holding the crossFieldInvalid flag.
 */
function crossFieldValidationEmpDb(group: UntypedFormGroup, thisRef, respondent: boolean = false): boolean {
	const res = group.value;
	if (res.email) {
		thisRef.crossFieldInvalid = false;
		return false;
	}
	if (respondent) {
		if (!isPhoneEmtpy(res.phone)) {
			thisRef.crossFieldInvalid = false;
			return false;
		}
	} else {
		if (!isPhoneEmtpy(res.workPhone) || !isPhoneEmtpy(res.cellPhoneNumber)) {
			thisRef.crossFieldInvalid = false;
			return false;
		}
	}

	if (res.country === 'USA' && res.addressLine1 && group.get('state').value && group.get('city').value && res.zip) {
		thisRef.crossFieldInvalid = false;
		return false;
	}
	if (res.country !== 'USA' && res.addressLine1 && res.city) {
		thisRef.crossFieldInvalid = false;
		return false;
	}

	thisRef.crossFieldInvalid = true;
	return true;
}

/**
 * @description converts the number into USA standard format
 * @param obj
 */
function formatPhone(obj) {
	let numbers = obj.replace(/\D/g, ''),
		char = { 0: '(', 3: ') ', 6: ' - ' };
	obj = '';
	for (let i = 0; i < numbers.length; i++) {
		obj += (char[i] || '') + numbers[i];
	}
	return obj;
}

/**
 * numbers returned by the directive may have one more digit. In that case, drop the last digit.
 * @param phone phone number to be processed.
 */
function processPhoneNumber(phone: string): string {
	if (!phone) {
		return null;
	}
	return phone.length === 14 ? phone : phone.slice(0, 14);
}

// ref https://material.angular.io/components/input/overview
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

function validateEmail(email) {
	// ref https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
	const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	return re.test(String(email).toLowerCase());
}

/**
 * @description check if the type of n is number.
 * @param n
 */
function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * @description changes the no of employees number format from Respondent portal into employeeCount shared code object
 * @param numOfEmployeeCountCode: numOfEmployeeCountCode property from empDb respondent
 */
function evaluateNoOfEmployeeCode(numOfEmployeeCountCode) {
	if (!numOfEmployeeCountCode) return numOfEmployeeCountCode;
	if (Number(numOfEmployeeCountCode) < 15) {
		numOfEmployeeCountCode = 'N';
	} else if (Number(numOfEmployeeCountCode) > 500) {
		numOfEmployeeCountCode = 'D';
	} else if (Number(numOfEmployeeCountCode) > 200 && Number(numOfEmployeeCountCode) <= 500) {
		numOfEmployeeCountCode = 'C';
	} else if (Number(numOfEmployeeCountCode) > 15 && Number(numOfEmployeeCountCode) <= 100) {
		numOfEmployeeCountCode = 'A';
	} else if (Number(numOfEmployeeCountCode) > 100 && Number(numOfEmployeeCountCode) <= 200) {
		numOfEmployeeCountCode = 'B';
	} else {
		numOfEmployeeCountCode = 'U';
	}
	return numOfEmployeeCountCode;
}

function getLookupDataById(id: number, lookupdataArray: LookupData[]): LookupData {
	if (id && lookupdataArray) {
		return lookupdataArray.filter((item) => item.id === id)[0];
	} else {
		return null;
	}
}
function getLookupDataByCode(code, lookupdataArray: LookupData[]): LookupData {
	if (code && lookupdataArray) {
		return lookupdataArray.filter((item) => item.code === code)[0];
	} else {
		return null;
	}
}
function getLookupDataBySize(size, lookupdataArray: LookupData[]): LookupData {
	if (size && lookupdataArray) {
		return lookupdataArray.filter((item) => {
			let ranges = item.description
				.split(' ')
				.filter((x) => !isNaN(Number(x)))
				.map((x) => Number(x));
			return ranges.length == 2
				? size > ranges[0] && size <= ranges[1]
				: ranges.length == 1
				? size >= ranges[0]
				: false;
		})[0];
	} else {
		return null;
	}
}

function getUserById(id, staffArray: Array<any>): User {
	if (id && staffArray) {
		return staffArray.filter((item) => item.userDetailId === id)[0];
	} else {
		return null;
	}
}

function sortStaffByName(a, b): number {
	return a.personName?.firstName > b.personName?.firstName ? 1 : -1;
}

function sortSharedCodes(a, b): number {
	return a.description > b.description ? 1 : -1;
}

function sortSharedCodesByDisplayOrder(a, b): number {
	return a.displayOrder > b.displayOrder ? 1 : -1;
}
function strArrayToSharedCodesArray(src: string[]): any[] {
	return src.map((x) => ({ code: x, description: x }));
}
function sortRoles(a, b): number {
	return a.roleDisplayText > b.roleDisplayText ? 1 : -1;
}

function sortbyShortName(a, b): number {
	return a.shortName > b.shortName ? 1 : -1;
}

function getDateString(date: Date): string {
	if (date) {
		const _month = date.getMonth() + 1;
		const _date = date.getDate();
		return `${date.getFullYear()}-${_month > 9 ? '' : '0'}${_month}-${_date > 9 ? '' : '0'}${_date}`;
	} else {
		return null;
	}
}

function combineDateAndTime(date, time: string): Date {
	if (date && time) {
		let dateString = getDateString(new Date(date));
		dateString = dateString + 'T' + time + ':00.000Z';
		return new Date(dateString);
	}
	return null;
}

function extractTimeStringFromDate(date: Date): string {
	if (date) {
		date = new Date(date);
		const hour = date.getHours();
		const min = date.getMinutes();
		return `${hour > 9 ? '' : '0'}${hour}:${min > 9 ? '' : '0'}${min}`;
	} else {
		return null;
	}
}

function _getFormControl(form: UntypedFormGroup, formControlName: string): AbstractControl {
	return form.get(formControlName);
}

function _setRequiredValidators(form: UntypedFormGroup, formControlName: string) {
	form.get(formControlName)?.setValidators(Validators.required);
	form.get(formControlName)?.updateValueAndValidity();
}

function _clearRequiredValidators(form: UntypedFormGroup, formControlName: string) {
	form.get(formControlName)?.clearValidators();
	form.get(formControlName)?.updateValueAndValidity();
}

function _addFormControl(form: UntypedFormGroup, formControlName: string, formValue = null, isRequired = false) {
	form.addControl(formControlName, new UntypedFormControl(formValue));
	if (isRequired) form.get(formControlName)?.setValidators(Validators.required);
}

function _removeFormControl(form: UntypedFormGroup, formControlName: string) {
	form.removeControl(formControlName);
}

function _isRequiredFormControl(abstractControl: AbstractControl): boolean {
	if (!abstractControl || typeof abstractControl?.validator !== 'function') return false;
	const validator = abstractControl?.validator({} as AbstractControl);
	if (validator && validator.required) {
		return true;
	}
}

function _compareFn(c1, c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
}

function _sortSharedCodesIncludingOther(a, b) {
	if (a.description?.toLowerCase()?.indexOf('other') === 0) return 1;
	if (a.description?.toLowerCase()?.indexOf('other') !== 0) return -1;
	return 0;
}

/**
 * @description Checks if the two passed in values are the same
 * @param a any
 * @param b any
 */
function equalityCheck(a, b) {
	if (isEmptyCondition(a) && isEmptyCondition(b)) {
		return true;
	} else {
		return a === b;
	}
}

/**
 * @description - retrieve the correct human readable value based on a value passed in
 * @param value - user selected value
 * @param obj - object that is being searched on
 * @returns - human readable value that is found, or undefined
 */
function getDescriptionByValue(value, obj) {
	if (obj) return obj.find((item) => item.code === value)?.description;
}

/**
 * @description - retrieve the correct human readable value based on a value passed in
 * @param value - user selected value
 * @param obj - object that is being searched on
 * @returns - human readable value that is found, or undefined
 */
function getNestedDescriptionByValue(value, nestedProp, obj) {
	if (obj && obj.length > 0) {
		return obj.find((item) => item[nestedProp]?.code === value)[nestedProp]?.description;
	}
}

/**
 * @description - Retrieve a description chosen by the user based off a passed in property name
 * @param value - id from user selected value
 * @param valProp - property to compare the passed in value to
 * @param obj - object that is being searched on
 * @param objProp - property to get from the passed in object
 * @returns - human readable value that is found, or undefined
 */
function getSelectedDescriptionByValue(value, valProp, obj, objProp) {
	if (obj) {
		let selectedDesc = obj.find((item) => item[valProp] === value)[objProp];
		if (!Utils.isNullCondition(selectedDesc)) {
			return selectedDesc;
		}
	}
}
function toDate(dateStr: string) {
  try {
  	const result = dateStr ? moment(dateStr, getDateFormat(dateStr)).toDate() : null;
	//.add(new Date().getTimezoneOffset(), 'm')
	return result;
  }catch(e){

  }
  return null
}

function toIso(date: string, format?: string): string {
  if (!date) {
    return date;
  }

  const dateFormat = getDateFormat(date);
  const momentDate = moment(date, dateFormat);

  return format ? momentDate.format(format) : momentDate.toISOString();
}

function getDateFormat(date: string): string {
  const separator = date.includes('/') ? '/' : '-';
  const isShortFormat = date.length === 10 && date[4] === separator;
  const dateFormat = isShortFormat ? `YYYY${separator}MM${separator}DD` : `MM${separator}DD${separator}YYYY`;

  return dateFormat;
}

/**
 * Formats a given string into the pattern 88-888888.
 * @param input if input is null or not a valid number, it returns null.
 * @returns if input is invalid returns null otherwise returns formatted string
 */

function formatToPattern(input: string | null): string | null {
	if(input === null || input === undefined || input === "") {
		return null;
	}
	if(input && input.length <=2) {
		return input;
	}
	const str = input.replace('-', '');
	// Extract the first two characters
	const firstPart = str.substring(0,2);
	// Extract the rest of the characters
	const secondPart = str.substring(2);

	// Return the formatted string
	return `${firstPart}-${secondPart}`;
}


export { Utils };
