/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreditEEOCResponseVO } from '../model/models';
import { CreditRequestResponse } from '../model/models';
import { CreditRequestVO } from '../model/models';
import { ReviewDetails } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FepaCreditResourceService {

    protected basePath = 'https://dev.eeoc.gov/prepa';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * FEPA Acknowledge an EEOC response
     * FEPA Acknowledge an EEOC response
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acknowledgeEeocResponse(requestBody: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CreditRequestResponse>>;
    public acknowledgeEeocResponse(requestBody: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CreditRequestResponse>>>;
    public acknowledgeEeocResponse(requestBody: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CreditRequestResponse>>>;
    public acknowledgeEeocResponse(requestBody: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling acknowledgeEeocResponse.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<CreditRequestResponse>>(`${this.configuration.basePath}/v1/credit-request/acknoledgment-of-response`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add EEOC Response to a FEPA Credit Request
     * Add EEOC Response to a FEPA Credit Request
     * @param requestId 
     * @param creditEEOCResponseVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCreditEEOCResponse(requestId: number, creditEEOCResponseVO: CreditEEOCResponseVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreditRequestResponse>;
    public addCreditEEOCResponse(requestId: number, creditEEOCResponseVO: CreditEEOCResponseVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreditRequestResponse>>;
    public addCreditEEOCResponse(requestId: number, creditEEOCResponseVO: CreditEEOCResponseVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreditRequestResponse>>;
    public addCreditEEOCResponse(requestId: number, creditEEOCResponseVO: CreditEEOCResponseVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling addCreditEEOCResponse.');
        }
        if (creditEEOCResponseVO === null || creditEEOCResponseVO === undefined) {
            throw new Error('Required parameter creditEEOCResponseVO was null or undefined when calling addCreditEEOCResponse.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<CreditRequestResponse>(`${this.configuration.basePath}/v1/credit-response/${encodeURIComponent(String(requestId))}`,
            creditEEOCResponseVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a FEPA Credit Request
     * Create a FEPA Credit Request
     * @param creditRequestVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCreditRequest(creditRequestVO: CreditRequestVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreditRequestResponse>;
    public createCreditRequest(creditRequestVO: CreditRequestVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreditRequestResponse>>;
    public createCreditRequest(creditRequestVO: CreditRequestVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreditRequestResponse>>;
    public createCreditRequest(creditRequestVO: CreditRequestVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (creditRequestVO === null || creditRequestVO === undefined) {
            throw new Error('Required parameter creditRequestVO was null or undefined when calling createCreditRequest.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreditRequestResponse>(`${this.configuration.basePath}/v1/credit-request`,
            creditRequestVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a FEPA Credit Request
     * Create a FEPA Credit Request
     * @param caseId 
     * @param creditRequestVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFEPACreditRequest(caseId: number, creditRequestVO: CreditRequestVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreditRequestResponse>;
    public createFEPACreditRequest(caseId: number, creditRequestVO: CreditRequestVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreditRequestResponse>>;
    public createFEPACreditRequest(caseId: number, creditRequestVO: CreditRequestVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreditRequestResponse>>;
    public createFEPACreditRequest(caseId: number, creditRequestVO: CreditRequestVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling createFEPACreditRequest.');
        }
        if (creditRequestVO === null || creditRequestVO === undefined) {
            throw new Error('Required parameter creditRequestVO was null or undefined when calling createFEPACreditRequest.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreditRequestResponse>(`${this.configuration.basePath}/v1/credit-request/${encodeURIComponent(String(caseId))}`,
            creditRequestVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a FEPA Credit Request SWR review data
     * Create a FEPA Credit Request SWR review data
     * @param requestId 
     * @param reviewDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReviewDetails>;
    public createSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReviewDetails>>;
    public createSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReviewDetails>>;
    public createSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling createSwrReviewDetails.');
        }
        if (reviewDetails === null || reviewDetails === undefined) {
            throw new Error('Required parameter reviewDetails was null or undefined when calling createSwrReviewDetails.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ReviewDetails>(`${this.configuration.basePath}/v1/credit-request/${encodeURIComponent(String(requestId))}/swr`,
            reviewDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param eeocOfficeCode 
     * @param fepaOfficeCode 
     * @param monthsToGet 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateDashboardReport(eeocOfficeCode?: string, fepaOfficeCode?: string, monthsToGet?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<object>>;
    public generateDashboardReport(eeocOfficeCode?: string, fepaOfficeCode?: string, monthsToGet?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<object>>>;
    public generateDashboardReport(eeocOfficeCode?: string, fepaOfficeCode?: string, monthsToGet?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<object>>>;
    public generateDashboardReport(eeocOfficeCode?: string, fepaOfficeCode?: string, monthsToGet?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (eeocOfficeCode !== undefined && eeocOfficeCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eeocOfficeCode, 'eeocOfficeCode');
        }
        if (fepaOfficeCode !== undefined && fepaOfficeCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fepaOfficeCode, 'fepaOfficeCode');
        }
        if (monthsToGet !== undefined && monthsToGet !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>monthsToGet, 'monthsToGet');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<object>>(`${this.configuration.basePath}/v1/credit-request/report`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate and return SWR document
     * Generates and returns the Substantial Weight Review document
     * @param caseId 
     * @param reviewDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateSwr(caseId: number, reviewDetails: ReviewDetails, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public generateSwr(caseId: number, reviewDetails: ReviewDetails, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public generateSwr(caseId: number, reviewDetails: ReviewDetails, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public generateSwr(caseId: number, reviewDetails: ReviewDetails, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling generateSwr.');
        }
        if (reviewDetails === null || reviewDetails === undefined) {
            throw new Error('Required parameter reviewDetails was null or undefined when calling generateSwr.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/review/generation-of-swr`,
            reviewDetails,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate and return SWR document
     * Generates and returns the Substantial Weight Review document
     * @param caseId 
     * @param requestId 
     * @param reviewDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateSwrForCredit(caseId: number, requestId: number, reviewDetails: ReviewDetails, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public generateSwrForCredit(caseId: number, requestId: number, reviewDetails: ReviewDetails, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public generateSwrForCredit(caseId: number, requestId: number, reviewDetails: ReviewDetails, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public generateSwrForCredit(caseId: number, requestId: number, reviewDetails: ReviewDetails, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling generateSwrForCredit.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling generateSwrForCredit.');
        }
        if (reviewDetails === null || reviewDetails === undefined) {
            throw new Error('Required parameter reviewDetails was null or undefined when calling generateSwrForCredit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/v1/cases/${encodeURIComponent(String(caseId))}/review/generation-of-swr/credit-request/${encodeURIComponent(String(requestId))}`,
            reviewDetails,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get FEPA Credit Request by Case Id
     * Get FEPA Credit Request by Case Id
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFepaCreditRequestById(caseId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreditRequestResponse>;
    public getFepaCreditRequestById(caseId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreditRequestResponse>>;
    public getFepaCreditRequestById(caseId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreditRequestResponse>>;
    public getFepaCreditRequestById(caseId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getFepaCreditRequestById.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CreditRequestResponse>(`${this.configuration.basePath}/v1/credit-request/${encodeURIComponent(String(caseId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get FEPA Credit Requests
     * Get FEPA Credit Requests
     * @param eeocOfficeCodes 
     * @param fepaOfficeCodes 
     * @param hasEeocResponse 
     * @param isFepaAcknowledged 
     * @param assignedToUserId 
     * @param creditStatus 
     * @param requestType 
     * @param monthsToGet 
     * @param ignoreApproved 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFepaCreditRequests(eeocOfficeCodes?: Array<string>, fepaOfficeCodes?: Array<string>, hasEeocResponse?: boolean, isFepaAcknowledged?: boolean, assignedToUserId?: string, creditStatus?: 'SUBMITTED' | 'RESUBMITTED' | 'APPEAL' | 'RESCIND' | 'APPROVED' | 'INELIGIBLE' | 'REJECTED' | 'REVIEW' | 'REJECTED_REWORK' | 'ELIGIBLE' | 'REQUESTED' | 'UNTIMELY_ADEA' | 'NON_CONTRACT' | 'DUP_CHARGE' | 'DENIED' | 'NON_JURISDICTIONAL' | 'OTHER' | 'UNTIMELY_SUB' | 'UNACCEPTABLE_CHARGE_AGE', requestType?: 'INTAKE' | 'ADMINISTRATIVE_CLOSURE' | 'MERIT_RESOLUTION' | 'NRTS_ON_REQUEST' | 'NO_CAUSE' | 'SUBSTANTIAL_WORK', monthsToGet?: number, ignoreApproved?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CreditRequestResponse>>;
    public getFepaCreditRequests(eeocOfficeCodes?: Array<string>, fepaOfficeCodes?: Array<string>, hasEeocResponse?: boolean, isFepaAcknowledged?: boolean, assignedToUserId?: string, creditStatus?: 'SUBMITTED' | 'RESUBMITTED' | 'APPEAL' | 'RESCIND' | 'APPROVED' | 'INELIGIBLE' | 'REJECTED' | 'REVIEW' | 'REJECTED_REWORK' | 'ELIGIBLE' | 'REQUESTED' | 'UNTIMELY_ADEA' | 'NON_CONTRACT' | 'DUP_CHARGE' | 'DENIED' | 'NON_JURISDICTIONAL' | 'OTHER' | 'UNTIMELY_SUB' | 'UNACCEPTABLE_CHARGE_AGE', requestType?: 'INTAKE' | 'ADMINISTRATIVE_CLOSURE' | 'MERIT_RESOLUTION' | 'NRTS_ON_REQUEST' | 'NO_CAUSE' | 'SUBSTANTIAL_WORK', monthsToGet?: number, ignoreApproved?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CreditRequestResponse>>>;
    public getFepaCreditRequests(eeocOfficeCodes?: Array<string>, fepaOfficeCodes?: Array<string>, hasEeocResponse?: boolean, isFepaAcknowledged?: boolean, assignedToUserId?: string, creditStatus?: 'SUBMITTED' | 'RESUBMITTED' | 'APPEAL' | 'RESCIND' | 'APPROVED' | 'INELIGIBLE' | 'REJECTED' | 'REVIEW' | 'REJECTED_REWORK' | 'ELIGIBLE' | 'REQUESTED' | 'UNTIMELY_ADEA' | 'NON_CONTRACT' | 'DUP_CHARGE' | 'DENIED' | 'NON_JURISDICTIONAL' | 'OTHER' | 'UNTIMELY_SUB' | 'UNACCEPTABLE_CHARGE_AGE', requestType?: 'INTAKE' | 'ADMINISTRATIVE_CLOSURE' | 'MERIT_RESOLUTION' | 'NRTS_ON_REQUEST' | 'NO_CAUSE' | 'SUBSTANTIAL_WORK', monthsToGet?: number, ignoreApproved?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CreditRequestResponse>>>;
    public getFepaCreditRequests(eeocOfficeCodes?: Array<string>, fepaOfficeCodes?: Array<string>, hasEeocResponse?: boolean, isFepaAcknowledged?: boolean, assignedToUserId?: string, creditStatus?: 'SUBMITTED' | 'RESUBMITTED' | 'APPEAL' | 'RESCIND' | 'APPROVED' | 'INELIGIBLE' | 'REJECTED' | 'REVIEW' | 'REJECTED_REWORK' | 'ELIGIBLE' | 'REQUESTED' | 'UNTIMELY_ADEA' | 'NON_CONTRACT' | 'DUP_CHARGE' | 'DENIED' | 'NON_JURISDICTIONAL' | 'OTHER' | 'UNTIMELY_SUB' | 'UNACCEPTABLE_CHARGE_AGE', requestType?: 'INTAKE' | 'ADMINISTRATIVE_CLOSURE' | 'MERIT_RESOLUTION' | 'NRTS_ON_REQUEST' | 'NO_CAUSE' | 'SUBSTANTIAL_WORK', monthsToGet?: number, ignoreApproved?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (eeocOfficeCodes) {
            eeocOfficeCodes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'eeocOfficeCodes');
            })
        }
        if (fepaOfficeCodes) {
            fepaOfficeCodes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'fepaOfficeCodes');
            })
        }
        if (hasEeocResponse !== undefined && hasEeocResponse !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hasEeocResponse, 'hasEeocResponse');
        }
        if (isFepaAcknowledged !== undefined && isFepaAcknowledged !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isFepaAcknowledged, 'isFepaAcknowledged');
        }
        if (assignedToUserId !== undefined && assignedToUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedToUserId, 'assignedToUserId');
        }
        if (creditStatus !== undefined && creditStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>creditStatus, 'creditStatus');
        }
        if (requestType !== undefined && requestType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestType, 'requestType');
        }
        if (monthsToGet !== undefined && monthsToGet !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>monthsToGet, 'monthsToGet');
        }
        if (ignoreApproved !== undefined && ignoreApproved !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ignoreApproved, 'ignoreApproved');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<CreditRequestResponse>>(`${this.configuration.basePath}/v1/credit-request`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the FEPA Credit Request SWR review data
     * Get the FEPA Credit Request SWR review data
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSwrReviewDetails(requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReviewDetails>;
    public getSwrReviewDetails(requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReviewDetails>>;
    public getSwrReviewDetails(requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReviewDetails>>;
    public getSwrReviewDetails(requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getSwrReviewDetails.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ReviewDetails>(`${this.configuration.basePath}/v1/credit-request/${encodeURIComponent(String(requestId))}/swr`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rescind a FEPA Credit Request
     * For FEPA user to rescind a FEPA Credit Request
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rescindCreditRequest(requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreditRequestResponse>;
    public rescindCreditRequest(requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreditRequestResponse>>;
    public rescindCreditRequest(requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreditRequestResponse>>;
    public rescindCreditRequest(requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling rescindCreditRequest.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<CreditRequestResponse>(`${this.configuration.basePath}/v1/credit-request/rescind/${encodeURIComponent(String(requestId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a FEPA Credit Request to Appeal/ReSubmit/Acknowledge
     * For FEPA uset to update a FEPA Credit Request status to Appeal/ReSubmit/Acknowledge
     * @param requestId 
     * @param creditRequestVO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCreditRequest(requestId: number, creditRequestVO: CreditRequestVO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreditRequestResponse>;
    public updateCreditRequest(requestId: number, creditRequestVO: CreditRequestVO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreditRequestResponse>>;
    public updateCreditRequest(requestId: number, creditRequestVO: CreditRequestVO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreditRequestResponse>>;
    public updateCreditRequest(requestId: number, creditRequestVO: CreditRequestVO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling updateCreditRequest.');
        }
        if (creditRequestVO === null || creditRequestVO === undefined) {
            throw new Error('Required parameter creditRequestVO was null or undefined when calling updateCreditRequest.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<CreditRequestResponse>(`${this.configuration.basePath}/v1/credit-request/${encodeURIComponent(String(requestId))}`,
            creditRequestVO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a FEPA Credit Request SWR review data
     * Update a FEPA Credit Request SWR review data
     * @param requestId 
     * @param reviewDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReviewDetails>;
    public updateSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReviewDetails>>;
    public updateSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReviewDetails>>;
    public updateSwrReviewDetails(requestId: number, reviewDetails: ReviewDetails, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling updateSwrReviewDetails.');
        }
        if (reviewDetails === null || reviewDetails === undefined) {
            throw new Error('Required parameter reviewDetails was null or undefined when calling updateSwrReviewDetails.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReviewDetails>(`${this.configuration.basePath}/v1/credit-request/${encodeURIComponent(String(requestId))}/swr`,
            reviewDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
