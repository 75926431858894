import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CaseDetailsService } from '../../features/modules/case-details/case-details.service';
import { CommonService } from '../../services/common.service';
import { LookupData } from '../../services/gen';
import {
	AdrNonStaffMediatorRoleCode,
	CommissionerRoleCode,
	DashboardScreenAccessRoleHolders,
	DualFilingScreenAccessRoleHolders,
	EmlScreenAccessRoleHolders,
	EnvMaps,
	FoiaAdminRoleCode,
	NewCaseScreenAccessRoleHolders,
	NewInquiryScreenAccessRoleHolders,
	OGCAdminRoleCode,
	GlobalUserRoleCode,
	ReportsRoleHolders,
	SearchScreenAccessRoleHolders,
	SltpScreenAccessRoleHolders,
	SystemicScreenAccessRoleHolders,
	TemplateManagementScreenAccessRoleHolders,
	UserManagementScreenAccessRoleHolders,
} from '../common-structures/app.constants';
import { CommonParams } from '../common-structures/common-params';
import { Utils } from '../utility/utils';
import { AlertService } from './../ims-alerts/ims-alerts.service';

@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
	@Input() commonParams: CommonParams;
	private routeObj: CommonParams;
	accountableOfficeList: LookupData[];
	isFepa: boolean = false;
	isLitigation: boolean = false;
	fepaSubmenuDisplay = false;
	utilSubmenuDisplay = false;
	showClose = false;
	createMenuSubmenuDisplay=false;
	userSelectedOfficeRole: any;
	userSelectedOfficeCode: string;
	userSelectedOfficeRoleCodeList: any;
	userOfficeCodes = [];
	// subscriptions
	observer$ = new BehaviorSubject(true);
	environment = environment;
	envMaps: any = EnvMaps;
	hasPrivateReadOnlyRole = false;
	dashboard = {
		label: 'DASHBOARD',
		link: './dashboard',
		icon: 'dashboard',
		isVisible: false,
	};
	search = {
		label: 'SEARCH CASES',
		link: './search',
		icon: 'find_in_page',
		isVisible: false,
	};
	empRepresentative = {
		label: 'EML',
		link: './employer-representatives',
		icon: 'person',
		isVisible: false,
	};
	userMgnt = {
		label: 'User Management',
		link: './user-management',
		icon: 'people',
		isVisible: false,
	};
	dualFiling = {
		label: 'Dual Filing',
		link: './dual-filing',
		icon: 'layers',
		isVisible: false,
	};
	sltpContracts = {
		label: 'SLTP Contracts',
		link: './sltp-contracts',
		icon: 'layers',
		isVisible: false,
	};
	newInquiry = {
		label: 'NEW INQUIRY',
		consolidateLabel: 'INQUIRY',
		link: './new-inquiry',
		icon: 'create_new_folder',
		isVisible: false,
	};
	newLitigationCases = {
		label: 'NEW CASE',
		consolidateLabel: 'CASE',
		link: './new-cases',
		icon: 'create_new_folder',
		isVisible: false,
	};
	litigationAppeals = {
		label: 'NEW APPEAL',
		consolidateLabel: 'APPEAL',
		link: './appeals',
		icon: 'create_new_folder',
		isVisible: false,
	};
	
	menuForNew = [this.newInquiry,this.newLitigationCases,this.litigationAppeals];
	systemic = {
		label: 'SYSTEMIC MODULE',
		link: './systemic-investigation',
		icon: 'create_new_folder',
		isVisible: false,
	};
	sbiCombo = {
		label: 'SBI Management',
		link: './sbi-combination',
		icon: 'settings_input_component',
		isVisible: false,
	};
	digitalFileCabinet = {
		label: 'Digital File Cabinet',
		link: './digital-file-cabinets',
		icon: 'note_add',
		isVisible: false,
	};
	classCaseGroups = {
		label: 'Class Case Groups',
		link: './class-case-groups',
		icon: 'note_add',
		isVisible: false,
	};
	templateManagement = {
		label: 'Template Management',
		link: './template-management',
		icon: 'note_add',
		isVisible: false,
	};
	litigationReports = {
		label: 'Reports',
		link: './reports',
		icon: 'summarize',
		isVisible: false,
	};
	
	help = {
		label: 'HELP',
		link: './guidance',
		icon: 'help',
	};
	logout = {
		label: 'LOGOUT',
		link: '',
		icon: 'logout',
	};

	constructor(
		private router: Router,
		private cmnServ: CommonService,
		private alert: AlertService,
		private caseDetailsServ: CaseDetailsService
	) {}

	/**
	 * @description setting up navigation links for tabs
	 */
	ngOnInit(): void {
		this.routeObj = this.commonParams;
		this.getUserDetails();
		this.getUserAccess();
		this.hasPrivateReadOnlyRole = this.cmnServ.hasPrivateReadOnlyRole() || this.cmnServ.hasPrivateInspectorGeneralRole();
		this.cmnServ.selectedDashboardRole$.subscribe((res) => {
			if (res) {
				this.userSelectedOfficeRole = res;
			}
		});
		this.cmnServ.selectedDashboardOffice$.subscribe((res) => {
			if (res?.code !== 'ALL') {
				this.userSelectedOfficeCode = res?.code;
				this.getUserAccess();
			}
		});
	}

	public getUserAccess() {
		if (Utils.notNullCondition(this.routeObj)) {
			this.cmnServ
				.getRoleAccess()
				.pipe(takeWhile(() => this.observer$.value))
				.subscribe((roleAssignments) => {
					if (roleAssignments) {
						const roles = roleAssignments.map((x) => x.split(':')[0]);
						this.litigationAppeals.isVisible =
							roles.includes(OGCAdminRoleCode) ||
							roles.includes('PRIVATE_GLOBAL_USER') ||
							roleAssignments.some(
								(x) =>
									x.split(':')[1] == '812' && ['LIT_APLETE_SUPERVISOR', 'LIT_APLETE_ATTORNEY'].includes(x.split(':')[0])
							);
						this.dashboard.isVisible = roleAssignments.some((x) =>
							DashboardScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
						this.search.isVisible = roleAssignments.some((x) =>
							SearchScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
						this.empRepresentative.isVisible = roleAssignments.some((x) =>
							EmlScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
						this.userMgnt.isVisible = roleAssignments.some((x) =>
							UserManagementScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
						this.dualFiling.isVisible = roleAssignments.some((x) =>
							DualFilingScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
						this.sltpContracts.isVisible = roleAssignments.some((x) =>
							SltpScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);

						this.newInquiry.isVisible = roleAssignments.some((x) =>
							NewInquiryScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);

						this.caseDetailsServ.getAllDistrictOffices(false).subscribe((res) => {
							this.isLitigation = false;
							this.accountableOfficeList = res;
							this.accountableOfficeList?.forEach((office) => {
								if (this.userSelectedOfficeRoleCodeList?.length) {
									this.userSelectedOfficeRoleCodeList.find((x) => {
										if (
											x.indexOf(office?.code) !== -1 &&
											['ROLE_PRIVATE_ATTORNEY_SPRVSR', 'ROLE_PRIVATE_ATTORNEY', 'ROLE_LIT_LEGAL_SUPPORT'].indexOf(
												x.split(':')[0]
											) !== -1
										) {
											this.isLitigation = true;
										}
									});
								}
							});

							this.newLitigationCases.isVisible = roleAssignments.some(
								(x) =>
									NewCaseScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0) &&
									(this.isLitigation || x.indexOf(OGCAdminRoleCode) >= 0 || x.indexOf(GlobalUserRoleCode) >= 0 )
							);
						});

						this.systemic.isVisible = roleAssignments.some((x) =>
							SystemicScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
						this.sbiCombo.isVisible = this.isFepa;
						this.digitalFileCabinet.isVisible = !this.isFepa && !this.voidUtilites(roleAssignments);
						this.classCaseGroups.isVisible = !this.isFepa && !this.voidUtilites(roleAssignments);
						this.templateManagement.isVisible = roleAssignments.some((x) =>
							TemplateManagementScreenAccessRoleHolders.some((r) => x.indexOf(r) >= 0)
						);

						this.litigationReports.isVisible = roleAssignments.some((x) =>
							ReportsRoleHolders.some((r) => x.indexOf(r) >= 0)
						);
					}
				});
		}
	}

	public openSubmenu(item) {
		if (item === 'createMenu') {
			if (document.getElementById('createNewDropDown')?.classList.contains('display')) {
				document.getElementById('createNewDropDown')?.classList.remove('display');
				this.createMenuSubmenuDisplay = false;
			} else {
				document.getElementById('createNewDropDown')?.classList.add('display');
				this.createMenuSubmenuDisplay = true;
			}
		}
		if (item === 'fepa') {
			if (document.getElementById('fepaDropDown')?.classList.contains('display')) {
				document.getElementById('fepaDropDown')?.classList.remove('display');
				this.fepaSubmenuDisplay = false;
			} else {
				document.getElementById('fepaDropDown')?.classList.add('display');
				this.fepaSubmenuDisplay = true;
			}
		}
		if (item === 'utilities') {
			if (document.getElementById('utilitiesDropDown')?.classList.contains('display')) {
				document.getElementById('utilitiesDropDown')?.classList.remove('display');
				this.utilSubmenuDisplay = false;
			} else {
				document.getElementById('utilitiesDropDown')?.classList.add('display');
				this.utilSubmenuDisplay = true;
			}
		}
		
	}

	setDisplay() {		
		this.fepaSubmenuDisplay = true;
		this.utilSubmenuDisplay = true;
		this.showClose = true;
	}

	public closeSubmenu() {
		if (document.getElementById('createNewDropDown')?.classList.contains('display')) {
			document.getElementById('createNewDropDown')?.classList.remove('display');
			this.createMenuSubmenuDisplay = false;
		}
		if (document.getElementById('fepaDropDown')?.classList.contains('display')) {
			document.getElementById('fepaDropDown')?.classList.remove('display');
			this.fepaSubmenuDisplay = false;
		}
	
		if (document.getElementById('utilitiesDropDown')?.classList.contains('display'))
			document.getElementById('utilitiesDropDown')?.classList.remove('display');
		this.utilSubmenuDisplay = false;
	}

	toggleMenuNewDisplay() {
		this.createMenuSubmenuDisplay = !this.createMenuSubmenuDisplay;
	}

	isMultipleNew(): boolean {
		return this.menuForNew.filter( item =>item.isVisible).length > 1;
	}

	private getUserDetails() {
		this.cmnServ
			.getUserDetails()
			.pipe(take(1))
			.subscribe(
				(res) => {
					if (res) {
						this.userSelectedOfficeRoleCodeList = res.authorities;
						if (res && res.domains && res.domains.find((item) => item === 'FEPA')) {
							this.isFepa = true;
						}
					}
				},
				(error) => {
					this.alert.error('Error occurred while getting user details', error);
				}
			);
	}

	onLogout() {
		this.closeSubmenu();
		localStorage.clear();
		sessionStorage.clear();
		this.cmnServ.userToken = null;
		this.router.navigateByUrl('/');
		this.cmnServ.onLogout.next(true);
	}

	/**
	 * @description function to handle the page scrolling to the correct location when skip nav button activated
	 */
	skipNav() {
		let ele = document.getElementById('main-content');
		if (ele !== undefined) {
			// Get the next tabbable sibling in the DOM and set the focus on it
			let nextSib = ele.querySelector('input, button:enabled, select, textarea, a[href], [tabindex]');
			(nextSib as HTMLElement).focus();
		}
	}

	public voidUtilites = (roleAssignments): boolean =>
		roleAssignments.some((x) =>
			[AdrNonStaffMediatorRoleCode, CommissionerRoleCode, FoiaAdminRoleCode].some((r) => x.indexOf(r) >= 0)
		) &&
		roleAssignments.some(
			(x) => ![AdrNonStaffMediatorRoleCode, CommissionerRoleCode, FoiaAdminRoleCode].some((r) => x.indexOf(r) >= 0)
		) === false;

	ngOnDestroy(): void {
		this.observer$.next(false);
	}
}
