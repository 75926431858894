import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoutesRecognized } from '@angular/router';
import jsPDF from 'jspdf';
import  moment from 'moment';
import 'moment-timezone';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { PrepaEventLogResourceService} from 'src/app/services/gen/api/prepaEventLogResource.service';
import { shareReplay } from 'rxjs/operators';
import { CaseRequestParam } from 'src/app/services/SearchDataWebService';
import { environment } from '../../environments/environment';
import { DocumentsConfig, GuidanceDocumentsConfig } from '../features/modules/documents/documents.config';
import {
  AppaletteAttorneyRoleCode,
  AppaletteAttorneySupervisorRoleCode,
	IBreadCrumbInterface,
	AppOathTokenCredential,
	CommissionerRoleCode,
	LoginUserInfo,
	momentTimezones,
	OFPAdminRoleCode,
	OGCAdminRoleCode,
	PrivateReadOnlyRole,
	PrivateInspectorGeneral,
} from '../shared/common-structures/app.constants';
import { CaseDetailV2 } from '../shared/common-structures/case-detail';
import { CommonParams, OauthToken } from '../shared/common-structures/common-params';
import { OfficeRole } from '../shared/common-structures/office-role';
import { TokenUserInfo } from '../shared/common-structures/token-user-info';
import { Utils } from '../shared/utility/utils';
import { LoadingService } from './loading/loading.service';
import { SearchDataService, WsCasesResponseInterface } from './search-data.service';
import { LookupData, Role, User, UserResourceService } from './UserManagement';
import { EmailResourceService } from 'src/app/services/EmailWebService';
import { AppealAssignmentVO } from './LitigationWebService/model/appealAssignmentVO';

export interface GetUserServiceQueryObject {
	userIds?: string[];
	emailAddress?: string;
	firstName?: string;
	lastName?: string;
	userName?: string;
	homeOfficeCodes?: string[];
	userType?: string;
	workPhone?: string;
	assignedOfficeCodes?: string[];
	roleStatus?: 'Pending' | 'Approved' | 'Denied' | 'Deactivated';
	assignedRoleCodes?: string[];
	unit?: number;
	isAdrStaff?: boolean;
	userADIds?: string[];
}

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	// private securityToken: any;
	private stickyMenuHeaderBehaviorSubject = new BehaviorSubject<boolean>(null);
	private commonParamsBehaviorSubject = new BehaviorSubject<CommonParams>(null);
	private securityTokenBehaviorSubject = new BehaviorSubject<any>(null);
	private oauthTokenBehaviorSubject = new BehaviorSubject<OauthToken>({});
	private keyBoardInputBehaviorSubject = new BehaviorSubject<boolean>(false);
	private userInfoBehaviorSubject = new BehaviorSubject<TokenUserInfo>(null);
	private caseInformationBehaviorSubject = new BehaviorSubject<CaseDetailV2>(null);
	private caseFolderBehaviorSubject = new BehaviorSubject<any>(null);
	private caseCabinetsBehaviorSubject = new BehaviorSubject<any>(null);
	private roleAccessBehaviorSubject = new BehaviorSubject<string[]>(null);
	private searchCriteriaPrivateSubject = new Subject<any>();
	private searchCriteriaLitigationSubject = new Subject<any>();
	private searchResponseFlagBehaviorSubject = new BehaviorSubject<boolean>(false);
	private litigationSearchResponseFlagBehaviorSubject = new BehaviorSubject<boolean>(false);
	private savedSearchFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedAppealSortDirectionBehaviorSubject = new BehaviorSubject<any>({});
	private savedNrtsFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedPrivateSupervisorDashboardFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedPrivateDashboardFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private privateDashboardFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedPrivateAdrDashboardFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedAdrDashboardSpotlightFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedPrivateSpotlightFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedLitigationDashboardFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedPrivateADRFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedPrivateFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private savedLitigationFiltersBehaviorSubject = new BehaviorSubject<any>({});
	private officeRoleArrayBehaviorSubject = new BehaviorSubject<OfficeRole[]>(null);
	private officeRoleSelectionBehaviorSubject = new BehaviorSubject<OfficeRole>(null);
	private breadCrumbsListBehaviorSubject = new BehaviorSubject<IBreadCrumbInterface[]>(null);
	private guidence$: Observable<any>;
	private savedReportingDetailsBehaviorSubject = new BehaviorSubject<any>({});
	public previousRoute: RoutesRecognized;	
	private searchCriteriaPrivate = {ciIsFepa: 'N'};	
	private searchCriteriaLitigation = {};

	public userType: string;
	public fromUrl: string;
	public onLogout = new BehaviorSubject<boolean>(null);
	public onOfficeChange = new BehaviorSubject<boolean>(null);
	public onServiceError = new BehaviorSubject<boolean>(null);
	public userToken: TokenUserInfo = null;
	private timeZonesLookup: LookupData[] = [];
	private privateOfficesList: LookupData[] = [];
	private fepaOfficesList: LookupData[] = [];
	private fepaOfficesIncludeObsoleteList: LookupData[] = [];
	private rolesList: Role[] = [];
	private approvedOfficeUsers: User[] = [];
	selectedDashboardOffice$ = new BehaviorSubject<any>({ code: 'ALL' });
	selectedDashboardRole$ = new BehaviorSubject<any>(null);

	constructor(
		private http: HttpClient,
		private userServ: UserResourceService,
		private prepaEventLogServ: PrepaEventLogResourceService,
		private searchDataServ: SearchDataService,
		private  emailResourceService: EmailResourceService
	) {}

	public _setPrivateOffices = (privateOfficesList: LookupData[]) =>
		(this.privateOfficesList = [...(privateOfficesList || [])]);
	public _getPrivateOffices = (): LookupData[] => this.privateOfficesList || [];

	public _setFepaOffices = (fepaOfficesList: LookupData[]) => (this.fepaOfficesList = [...(fepaOfficesList || [])]);
	public _getFepaOffices = (): LookupData[] => this.fepaOfficesList || [];

	public _setFepaOfficesIncludeObsolete = (fepaOfficesIncludeObsoleteList: LookupData[]) => (this.fepaOfficesIncludeObsoleteList = [...(fepaOfficesIncludeObsoleteList || [])]);
	public _getFepaOfficesIncludeObsolete = (): LookupData[] => this.fepaOfficesIncludeObsoleteList || [];

	public _getAllOffices = (): LookupData[] => [...(this.privateOfficesList || []), ...(this.fepaOfficesList || [])];

	public _setRoles = (rolesList: Role[]) => (this.rolesList = [...(rolesList || [])]);
	public _getRoles = (domainName: string = null): Role[] =>
		domainName ? [...(this.rolesList || [])]?.filter((el) => el.domain?.name === domainName) : this.rolesList || [];

	public _setTimezonesLookup(timeZones: LookupData[]) {
		this.timeZonesLookup = [...(timeZones || [])];
	}

	public _getTimezonesLookup() {
		return this.timeZonesLookup;
	}

	public _setOfficeApprovedUsers = (approvedOfficeUsers: User[]) =>
		(this.approvedOfficeUsers = [...(approvedOfficeUsers || [])]);
	public _getOfficeApprovedUsers = (): User[] => [...(this.approvedOfficeUsers || [])].sort(Utils.sortStaffByName);

	public _getUserProfileTimezoneCode() {
		return this.timeZonesLookup?.find((el) => el.description === this.userToken?.timeZonePreference)?.code || 'EST';
	}

	public toTitleCase(str) {
		return str
			? str?.replace(/\b\w+/g, function (s) {
					return s?.charAt(0)?.toUpperCase() + s?.substr(1)?.toLowerCase();
			  })
			: '';
	}

	/**
	 *
	 * @param timeStamp is required
	 * @param format is optional
	 * @returns convert given timestamp string to est timezone
	 */
	public convertLocaleToEst = (timeStamp) =>
		timeStamp
			? moment(this.buildTimeStamp(timeStamp)).tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
			: timeStamp;

	/**
	 *
	 * @param timeStamp is required
	 * @param format is optional
	 * @returns consider given timestamp in est timezone and converts to user browser timezone
	 */
	public convertEsttoLocale = (timeStamp) =>
		timeStamp
			? moment
					.tz(timeStamp, null, 'America/New_York')
					.tz(momentTimezones[this._getUserProfileTimezoneCode()])
					.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
			: timeStamp;

	public displayEsttoLocale = (intimeStamp, showTime: boolean = true) => {
		if (!intimeStamp) {
			return '';
		}
		let timeStamp =
			typeof intimeStamp === 'number'
				? moment(intimeStamp).format('YYYY-MM-DD HH:mm:ss')
				: intimeStamp.toString().substr(4, 1) == '-'
				? intimeStamp
				: moment(intimeStamp, intimeStamp.length > 10 ? 'MM-DD-YYYY HH:mm:ss' : 'MM-DD-YYYY').format(
						'YYYY-MM-DD HH:mm:ss'
				  );
		return (
			moment
				.tz(timeStamp, null, 'America/New_York')
				.tz(momentTimezones[this._getUserProfileTimezoneCode()])
				.format(showTime ? 'M/D/yyyy HH:mm:ss' : 'M/D/yyyy') +
			(showTime ? ' ' + this._getUserProfileTimezoneCode() : '')
		);
	};
	public displayTimeZonetoLocale(timeStamp, timeZoneCode): string {
    try {
		if (timeStamp) {
			if (timeZoneCode !== this._getUserProfileTimezoneCode()) {
				const date = timeStamp.split(' ')?.length && timeStamp.split(' ')[0];
				const time = timeStamp.split(' ')?.length && timeStamp.split(' ')[1];
				const offset = this.timeZonesLookup?.find((el) => el.code === timeZoneCode)?.shortName?.replace('UTC', '');
				if (!date || date === '' || !time || time === '' || !offset || offset === '') return '';
				const datetime =
					new Date(date).getFullYear() +
					'-' +
					('0' + (new Date(date).getMonth() + 1)).slice(-2) +
					'-' +
					('0' + new Date(date).getDate()).slice(-2) +
					'T' +
					time +
					offset +
					':00';
				return moment(datetime).isDST()
					? moment(datetime)
							.tz(momentTimezones[this._getUserProfileTimezoneCode()])
							.subtract(1, 'h')
							.format('M/D/yyyy HH:mm:ss') +
							(' ' + this._getUserProfileTimezoneCode())
					: moment(datetime).tz(momentTimezones[this._getUserProfileTimezoneCode()]).format('M/D/yyyy HH:mm:ss') +
							(' ' + this._getUserProfileTimezoneCode());
			} else return moment(timeStamp).format('M/D/yyyy HH:mm:ss') + (' ' + this._getUserProfileTimezoneCode());
		} else return '';
  }catch(err){
      console.log(`Timestamp to Local conversion error ${timeStamp}`, err);
  }
  return '';
	}

	public sliceIntoChunks(array: any[], chunkSize: number) {
		const resultSet = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			const chunk = array.slice(i, i + chunkSize);
			resultSet.push(chunk);
		}
		return resultSet;
	}

	/**
	 *
	 * @param timeStamp
	 * @returns converts given date only to date timestamp
	 */
	public buildTimeStamp = (timeStamp) => {
		const _m = moment();
		return timeStamp && !new Date(timeStamp).getHours()
			? moment(
					moment(timeStamp).add({
						hours: _m.hour(),
						minutes: _m.minute(),
						seconds: _m.second(),
					})
			  ).format()
			: timeStamp;
	};

	// This method is used to calcuate exact days difference between two dates
	calculateDateDiffDays(startDate: Date, endDate: Date): number {
		let diff = 0;
		// making sure none of the dates are null
		if (startDate && endDate) {
			const start = moment(startDate);
			const end = moment(endDate);
		    diff = Math.round(moment.duration(end.diff(start)).asDays());
		}
		return diff;
	}

	public stringBuilder(arrayValues: string[]): string {
		let string = '';
		for (const value of arrayValues) {
			string += string && value ? ', ' + value : value;
		}
		return string || '';
	}

	/**
	 * @description returns boolean as observable
	 */
	getStickyMenuHeader(): Observable<boolean> {
		return this.stickyMenuHeaderBehaviorSubject.asObservable();
	}

	public getUserRoles(): string[] {
		const userRoles = [];
		(this.userToken?.authorities || []).forEach((role) => {
			userRoles.push(role.split(':')[0].replace('ROLE_', ''));
		});
		return userRoles;
	}

	public getOfficeBasedRoles(userSelectedOfficeCode: string, includeAll: boolean = true): string[] {
		const userRoles = [];
		(this.userToken?.authorities || []).forEach((role) => {
			if ((includeAll ? ['ALL', userSelectedOfficeCode] : [userSelectedOfficeCode]).includes(role.split(':')[1]))
				userRoles.push(role.split(':')[0].replace('ROLE_', ''));
		});
		return userRoles;
	}

	showServiceError() {
		this.onServiceError.next(true);
	}

	/**
	 * @description subscribes to next emited value of boolean based on scroll position
	 * @params sticky header value
	 */
	setStickyMenuHeader(stickyMenuHeader: boolean) {
		this.stickyMenuHeaderBehaviorSubject.next(stickyMenuHeader);
	}

	/**
	 * @description returns common params as observable
	 */
	getCommonParams(): Observable<CommonParams> {
		return this.commonParamsBehaviorSubject.asObservable();
	}

	getSearchEvent(caseId, payload): Observable<boolean> {
		return this.prepaEventLogServ.addEvents(caseId, payload);

	}

	/**
	 * @description subscribes to next emited value of params
	 * @params params
	 */
	setCommonParams(params: CommonParams) {
		this.commonParamsBehaviorSubject.next(params);
	}

	/**
	 * @description returns oauth token as observable
	 */
	getOauthToken(): Observable<OauthToken> {
		return this.oauthTokenBehaviorSubject.asObservable();
	}

	getOauthTokenBehaviorSubject(): BehaviorSubject<OauthToken> {
		return this.oauthTokenBehaviorSubject;
	}

	/**
	 * @description subscribes to next emitted value of oauth token
	 * @params oauth token
	 */
	setOauthToken(oauthToken: OauthToken) {
		this.oauthTokenBehaviorSubject.next(oauthToken);
	}

	/**
	 * @description returns security token as observable
	 */
	getSecurityToken() {
		return this.securityTokenBehaviorSubject.asObservable();
	}

	/**
	 * @description returns breadcrumbs list as observable
	 */
	setBreadCrumbsList(breadCrumbsList: IBreadCrumbInterface[]) {
		this.breadCrumbsListBehaviorSubject.next(breadCrumbsList);
	}

	getBreadCrumbsList() {
		return this.breadCrumbsListBehaviorSubject.asObservable();
	}

	setSearchCriteriaPrivate(searchCriteria: any) {
		this.searchCriteriaPrivate = searchCriteria;
	}

	getSearchCriteriaPrivateObserver() {
		return this.searchCriteriaPrivateSubject.asObservable();
	}

	getSearchCriteriaPrivate() {		
		this.searchCriteriaPrivateSubject.next(this.searchCriteriaPrivate);
	}

	setSearchCriteriaLitigation(searchCriteria: any) {
		this.searchCriteriaLitigation = searchCriteria;	
	}

	getSearchCriteriaLitigation() {
		this.searchCriteriaLitigationSubject.next(this.searchCriteriaLitigation);
	}

	getSearchCriteriaLitigationObserver() {
		return this.searchCriteriaLitigationSubject.asObservable();
	}

	setSearchResponseFlag(searchResponse: boolean) {
		this.searchResponseFlagBehaviorSubject.next(searchResponse);
	}
	

	getLitigationSearchResponseFlag() {
		return this.litigationSearchResponseFlagBehaviorSubject.asObservable();
	}

	setLitigationSearchResponseFlag(litigationSearchResponse: boolean) {
		this.litigationSearchResponseFlagBehaviorSubject.next(litigationSearchResponse);
	}


	getSearchResponseFlag() {
		return this.searchResponseFlagBehaviorSubject.asObservable();
	}

	getSavedPrivateFilters() {
		return this.savedPrivateSupervisorDashboardFiltersBehaviorSubject.asObservable();
	}

	setSavedPrivateFilters(filters: any) {
		this.savedPrivateSupervisorDashboardFiltersBehaviorSubject.next(filters);
	}

	getSavedPrivateSupervisorFilters() {
		return this.savedPrivateDashboardFiltersBehaviorSubject.asObservable();
	}

	setSavedPrivateSupervisorFilters(filters: any) {
		this.savedPrivateDashboardFiltersBehaviorSubject.next(filters);
	}

	getPrivateSupervisorFilters() {
		return this.privateDashboardFiltersBehaviorSubject.asObservable();
	}

	setPrivateSupervisorFilters(filters: any) {
		this.privateDashboardFiltersBehaviorSubject.next(filters);
	}

	getSavedLitigationFilters() {
		return this.savedLitigationDashboardFiltersBehaviorSubject.asObservable();
	}

	setSavedLitigationFilters(filters: any) {
		this.savedLitigationDashboardFiltersBehaviorSubject.next(filters);
	}

	getSavedSearchFilters() {
		return this.savedSearchFiltersBehaviorSubject.asObservable();
	}

	setSavedSearchFilters(filters: any) {
		this.savedSearchFiltersBehaviorSubject.next(filters);
	}

	getSavedNrtsFilters() {
		return this.savedNrtsFiltersBehaviorSubject.asObservable();
	}

	setSavedNrtsFilters(filters: any) {
		this.savedNrtsFiltersBehaviorSubject.next(filters);
	}

	getPrivateFilters() {
		return this.savedPrivateFiltersBehaviorSubject.asObservable();
	}

	setPrivateFilters(filters: any) {
		this.savedPrivateFiltersBehaviorSubject.next(filters);
	}


	getLitigationFilters() {
		return this.savedLitigationFiltersBehaviorSubject.asObservable();
	}

	setLitigationFilters(filters: any) {
		this.savedLitigationFiltersBehaviorSubject.next(filters);
	}

	getPrivateAdrFilters() {
		return this.savedPrivateADRFiltersBehaviorSubject.asObservable();
	}

	setPrivateAdrFilters(filters: any) {
		this.savedPrivateADRFiltersBehaviorSubject.next(filters);
	}

	getSavedPrivateAdrFilters() {
		return this.savedPrivateAdrDashboardFiltersBehaviorSubject.asObservable();
	}

	setSavedPrivateAdrFilters(filters: any) {
		this.savedPrivateAdrDashboardFiltersBehaviorSubject.next(filters);
	}

	getSavedSpotlightPrivateAdrFilters() {
		return this.savedAdrDashboardSpotlightFiltersBehaviorSubject.asObservable();
	}

	setSavedSpotlightPrivateAdrFilters(filters: any) {
		this.savedAdrDashboardSpotlightFiltersBehaviorSubject.next(filters);
	}

	getSavedSpotlightPrivateFilters() {
		return this.savedPrivateSpotlightFiltersBehaviorSubject.asObservable();
	}

	setSavedSpotlightPrivateFilters(filters: any) {
		this.savedPrivateSpotlightFiltersBehaviorSubject.next(filters);
	}


	setsavedReportingDetails(details: any) {
		this.savedReportingDetailsBehaviorSubject.next(details);
	}

	getSavedReportingDetails() {
		return this.savedReportingDetailsBehaviorSubject.asObservable();
	}

	setOfficeRoleArray(officeRoleArray: OfficeRole[]) {
		this.officeRoleArrayBehaviorSubject.next(officeRoleArray);
	}

	getOfficeRoleArray(): Observable<OfficeRole[]> {
		return this.officeRoleArrayBehaviorSubject.asObservable();
	}

	setOfficeRoleSelection(officeRole: OfficeRole) {
		this.officeRoleSelectionBehaviorSubject.next(officeRole);
	}

	getOfficeRoleSelection(): Observable<OfficeRole> {
		return this.officeRoleSelectionBehaviorSubject.asObservable();
	}

	getSavedAppealSortDirection() {
		return this.savedAppealSortDirectionBehaviorSubject.asObservable();
	}

	setSavedAppealSortDirection(filters: any) {
	this.savedAppealSortDirectionBehaviorSubject.next(filters);
	}


	/**
	 * @description subscribes to next emitted value of token
	 * @params security token
	 */
	setSecurityToken(securityToken) {
		this.securityTokenBehaviorSubject.next(securityToken);
	}

	/**
	 * @description returns role access as observable
	 */
	getRoleAccess(): Observable<string[]> {
		return this.roleAccessBehaviorSubject.asObservable();
	}

	/**
	 * @description subscribes to next emitted value of role access
	 */
	setRoleAccess(roleAssignmnets: string[]) {
		this.roleAccessBehaviorSubject.next(roleAssignmnets);
	}

	/**
	 * @description returns keyboardInput as observable
	 */
	getKeyboardInput(): Observable<boolean> {
		return this.keyBoardInputBehaviorSubject.asObservable();
	}

	/**
	 * @description subscribes to next emitted value of keyboardInput
	 * @params keyboardInput
	 */
	setKeyboardInput(keyboardInput: boolean) {
		this.keyBoardInputBehaviorSubject.next(keyboardInput);
	}

	/**
	 * @description returns case information as observable
	 */
	getCaseInformation(): Observable<CaseDetailV2> {
		return this.caseInformationBehaviorSubject.asObservable();
	}

	getCaseFolders(): Observable<any> {
		return this.caseFolderBehaviorSubject.asObservable();
	}

	getCaseCabinets(): Observable<any> {
		return this.caseCabinetsBehaviorSubject.asObservable();
	}

	/**
	 * @description subscribes to next emitted value of case information
	 * @params security token
	 */
	setCaseInformation(caseInfo) {
		this.caseInformationBehaviorSubject.next(caseInfo);
	}

	setCaseFolders(caseFolders) {
		this.caseFolderBehaviorSubject.next(caseFolders);
	}

	setCaseCabinets(caseCabinets) {
		this.caseCabinetsBehaviorSubject.next(caseCabinets)
	}



	/**
	 * @description Call web service to get the oauth token
	 * @param username
	 * @param password
	 */
	getOathToken(username?: string, password?: string, token?: string): Observable<OauthToken> {
		// if AD Token - just pass the token else pass username and password
		const url = token
			? `${environment.oauthws}/token?grant_type=password&adtoken=${token}`
			: `${environment.oauthws}/token?grant_type=password&username=${username}&password=${password}`;
		const mockCredential = btoa(AppOathTokenCredential);
		const mockloginInfo = 'Basic ' + mockCredential;
		const headers = new HttpHeaders().set('Authorization', mockloginInfo);
		return this.http.post(url, null, { headers }) as Observable<OauthToken>;
	}

	/**
	 * @description Call web service to get the refresh token
	 * @param refresh_token
	 */
	getRefreshToken(refresh_token: string) {
		const url = `${environment.oauthws}/token` + `?grant_type=refresh_token&refresh_token=${refresh_token}`;
		const mockCredential = btoa(AppOathTokenCredential);
		const mockloginInfo = 'Basic ' + mockCredential;
		const headers = new HttpHeaders().set('Authorization', mockloginInfo);
		return this.http.post(url, null, { headers }) as Observable<OauthToken>;
	}

	/**
	 * @description wrapper method for openAPI getUserById method
	 */
	getUserById(userDetailId: string): Observable<User> {
		return this.userServ.getUserById(userDetailId);
	}

	public getActiveUsers(queryObject: GetUserServiceQueryObject = {}): Observable<User[]> {
		return this.userServ.getUsers(
			queryObject?.userIds || [],
			queryObject?.emailAddress || null,
			queryObject?.firstName || null,
			queryObject?.lastName || null,
			queryObject?.userName || null,
			queryObject?.homeOfficeCodes || [],
			queryObject?.userType || null,
			queryObject?.workPhone || null,
			queryObject?.assignedOfficeCodes || [],
			queryObject?.roleStatus || 'Approved',
			queryObject?.assignedRoleCodes || [],
			queryObject?.unit || null,
			queryObject?.isAdrStaff || null,
			queryObject?.userADIds || [],
			true,
			false
		);
	}

	getUsersByIds(ids: Array<string>){
		return this.userServ.getUsers(
			ids,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			true
			)	;
	}

	/**
	 * @description get user by last name starts with
	 * @param name
	 */
	getAssigneeListByName(name: string): Observable<User[]> {
		if (name && typeof name === 'string') {
			return concat(
				this.userServ.getUsers(
					null,
					null,
					null,
					name,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					true
				),
				this.userServ.getUsers(null, null, name, null, null, null, null, null, null, null, null, null, null, null, true)
			);
		} else {
			return of([]);
		}
	}

	getAssigneeListByNameOffice(assignedOfficeCodes: string, name: string , assignedRoleCodes: string []): Observable<User[]> {
		if (name && typeof name === 'string') {
			return concat(
				this.userServ.getUsers(
					null,
					null,
					name,
					null,
					null,
					null,
					null,
					null,
					[assignedOfficeCodes],
					null,
					assignedRoleCodes,
					null,
					null,
					null,
					true
				),
				this.userServ.getUsers(null, null, null, name, null, null , null, null, [assignedOfficeCodes], null, assignedRoleCodes, null, null, null, true)
			);
		} else {
			return of([]);
		}
	}

	getAssigneeListByNameAndUserType(queryObject: GetUserServiceQueryObject = {}): Observable<User[]> {
		if (queryObject?.firstName && typeof queryObject?.firstName === 'string') {
			return concat(
				this.userServ.getUsers(
					null,
					null,
					queryObject.firstName || null,
	                null,
					null,
					null,
					null,
					null,
					null,
					null,
					queryObject?.assignedRoleCodes || [],
					null,
					null,
					null,
					true
				),
				this.userServ.getUsers(null, null,null, queryObject.lastName, null, null, null, null, null, null, queryObject?.assignedRoleCodes || [], null, null, null, true)
			);
		} else {
			return of([]);
		}
	}

	/**
	 * @description get all guidance documents.
	 * @params domain
	 * @params staffEmail
	 */
	getGuidanceDocuments(domain: string): Observable<any> {
		if (!this.guidence$) {
			const url = environment.ecmurl + DocumentsConfig.documentsUrl;
			const params = new HttpParams()
				.set('appname', GuidanceDocumentsConfig.appname)
				.set('domain', domain)
				.set('foldername', GuidanceDocumentsConfig.foldername);
			const headers = new HttpHeaders().set('Accept', 'application/json');
			this.guidence$ = this.http.get(url, { headers, params }).pipe(shareReplay(1));
		}
		return this.guidence$;
	}

	/**
	 * @description download guidance documents with web service.
	 * @params docId
	 * @params domain
	 * @params staffOracleUserId
	 */
	downloadGuidance(docId: string, domain: string, staffOracleUserId: string): Observable<Blob> {
		const url = environment.ecmurl + DocumentsConfig.downloadDocUrl;
		const params = new HttpParams()
			.set('appname', GuidanceDocumentsConfig.appname)
			.set('username', staffOracleUserId)
			.set('domain', domain)
			.set('docid', docId);
		return this.http.get(url, {
			params: params,
			responseType: 'blob',
		});
	}

	downloadFile(url: string): Observable<HttpResponse<Blob>> {
		const headers = new HttpHeaders()
			.set('Cache-Control', 'no-cache')
			.set('Cache-Control', 'no-store')
			.set('Expires', '0')
			.set('Pragma', 'no-cache');
		return this.http.get(url, {
			headers,
			observe: 'response',
			responseType: 'blob',
		});
	}

	/**
	 *
	 * @type {HttpHeaders}
	 */
	private httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
	protected options = {
		headers: this.httpHeaders,
		search: {},
	};

	downloadUserGuide(docId: string, staffOracleId: string): Observable<HttpResponse<Blob>> {
		const url = environment.ecmurl + '/v2/documents/download';
		const headers = new HttpHeaders();
		const params = new HttpParams()
			.set('appname', 'IMSNXG')
			.set('username', staffOracleId)
			.set('domain', 'PRIVATE')
			.set('docid', docId);

		return this.http.get(url, {
			headers,
			params,
			observe: 'response',
			responseType: 'blob',
		});
	}

	/**
	 * @description getter for CommonParams behaviorSubject
	 */
	getUserDetails(): Observable<TokenUserInfo> {
		return this.userInfoBehaviorSubject.asObservable();
	}

	/**
	 * @description setter for security token behaviorSubject
	 * @params params
	 */
	setUserDetails(staff: TokenUserInfo) {
		this.userToken = staff;
		this.userInfoBehaviorSubject.next(staff);
	}

	/**
	 * @description check if user have super admin roles
	 * check 'GLOBAL_USER' role currently, few more added later
	 */
	public isGlobalUser = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf('GLOBAL_USER') >= 0);

	public isOFPAdminRole = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf(OFPAdminRoleCode) >= 0);
	public hasPrivateReadOnlyRole = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf(PrivateReadOnlyRole) >= 0);
	public hasPrivateInspectorGeneralRole = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf(PrivateInspectorGeneral) >= 0);
	public isOGCAdminRole = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf(OGCAdminRoleCode) >= 0);
  	public isAppelletteSprvsrRole = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf(AppaletteAttorneySupervisorRoleCode) >= 0);
  	public isAppelleteAttorneyRole = (): boolean => this.userToken?.authorities?.some((x) => x.indexOf(AppaletteAttorneyRoleCode) >= 0);
  	public hasAppealModificationRole = (appealAssignments:  AppealAssignmentVO[]): boolean => this.isOGCAdminRole()|| this.isGlobalUser() || this.isAppelletteSprvsrRole() || (appealAssignments.some(x=>x.userDetailId==this.userToken.userId) && this.isAppelleteAttorneyRole())
	public isCommissionerAdminRole = (): boolean =>
		this.userToken?.authorities?.some((x) => x.indexOf(CommissionerRoleCode) >= 0);

	/**
	 * Checks if user only has a single role at the passed in office
	 * @params roleCode - role to check for
	 * @params officeCode - office checking against
	 */
	public isOnlyRoleHolder(roleCode: string, officeCode: string) {
		const roleCodeRoles = this.getOfficeBasedRoles(officeCode)?.filter((item) => item.includes(roleCode));
		const nonRoleCodeRoles = this.getOfficeBasedRoles(officeCode)?.filter((item) => !item.includes(roleCode));
		return roleCodeRoles.length && !nonRoleCodeRoles?.length;
	}


/**
	 * used for posting the data to server
	 * @params url
	 * @params requestBody
	 * @params token
	 */

	public downloadEmailContentByLogId(caseId: string, logId: number): Observable<any> {
		return this.emailResourceService.downloadEmailContentByLogId(caseId, logId);
	}

	/**
	 * used for posting the data to server
	 * @params url
	 * @params requestBody
	 * @params token
	 */
	postData(url: any, requestBody: any): Observable<any> {
		const body = JSON.stringify(requestBody);
		let headers: HttpHeaders;
		headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(url, body, { headers });
	}

	putData(url: any, requestBody: any): Observable<any> {
		const body = JSON.stringify(requestBody);
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.put(url, body, { headers });
	}

	/**
	 * @description Fetches all the information with get call
	 */
	getWithUrl(url): any {
		return this.http.get(url);
	}

	/**
	 * @description SearchData Service call
	 */
	searchCaseDetail(reqParam: CaseRequestParam, sortRequired = true): Observable<CaseDetailV2[]> {
		const payload: CaseRequestParam = { isExactMatch: 'Y' };
		for (let entry of Object.entries(reqParam)) {
			if (entry[1]) {
				payload[entry[0]] = entry[1];
			}
		}
		return this.searchDataServ.searchCaseDetailV2(payload, sortRequired);
	}

	searchLog(payload): Observable<any[]> {
		return this.searchDataServ.searchLogDetail(payload);
	}

	getCasesByCaseNumber(caseNumbers: string[]): Observable<WsCasesResponseInterface> {
		const payload: CaseRequestParam = {
			isExactMatch: 'Y',
			ciChargeNumber: caseNumbers.join('~'),
			ciIsFepa: 'N',
		};
		return this.searchDataServ.searchCaseDetails(payload, null, caseNumbers?.length);
	}

	exportPdf(selector, filename, loderServ?: LoadingService) {
		const result = new Subject<any>();
		if (loderServ) {
			loderServ?.show();
		}
		const pdf = new jsPDF({ orientation: 'p', format: 'a4' })


		let pdfjs = document.querySelector(selector);
		const contentHeight = pdfjs.offsetHeight;

		// Set the maximum height of each page (adjust as needed)
		const maxHeightPerPage = 800; // For example, assuming each page can hold up to 800px of content

		// Calculate the number of pages needed
		const totalPages = Math.ceil(contentHeight / maxHeightPerPage);



		// Loop through the pages
		for (let i = 0; i < totalPages - 1; i++) {
			if (i > 0) {
				pdf.addPage(); // Add a new page for subsequent pages
			}
		}
		setTimeout(() => {
			result.next(false);
			result.complete();
		}, 500);
		pdf.html(pdfjs, {
			callback: function (doc) {
				doc.save(filename);
				if (loderServ) {
					loderServ?.hide();
				}
			},
			x: 0,
			y: 0,
			margin: [4, 4, 4, 4], // mm
			width: 208,
			windowWidth: 786,
			html2canvas: {
				logging: false,
				windowWidth: 786
			}
		});
		return result;
	}

	filterClosureReasonsBaseOnCase(caseDetail:any, closureReasons: any[]): any[] {
		if (caseDetail?.offlineInfo?.receivedBy?.code  === 'EFUPT' && caseDetail.offlineInfo?.receivedFrom?.code === 'RCVFRM-ADC' 
			&& caseDetail?.formalizationDate ) {
				return closureReasons;
		} else {
			return closureReasons.filter(item => item.code !== 'DWNRTS_16');
		}
	}

	getFiscalYears(from: number = 2018) {
		const result = [];
		const to = new Date().getFullYear() + ((new Date().getMonth() < 9) ? 0 : 1);
		for (let i = from; i <= to; i++) {
			result.push(i);
		}
		return result;
	}
}
