import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType } from '../common-structures/ims-alerts';
import {GlobalErrorHandler} from '../error-handler/global-error-handler'
@Injectable({
	providedIn: 'root',
})
export class AlertService {
	public subject = new Subject<Alert[]>();
	private keepAfterRouteChange = false;
	public inQueueMessages: Alert[] = [];

	constructor(private router: Router,
				private errorHandler: GlobalErrorHandler
		) {
		// clear alert messages on route change unless 'keepAfterRouteChange' flag is true
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterRouteChange) {
					// only keep for a single route change
					this.keepAfterRouteChange = false;
				} else {
					// clear alert messages
					this.clear();
				}
			}
		});
	}

	getAlert(): Observable<any> {
		return this.subject.asObservable();
	}

	success(message: string, header?: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Success, message, header, keepAfterRouteChange);
	}

	deactivation(message: string, header?: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Deactivation, message, header, keepAfterRouteChange);
	}

	error(message: string, error?: any, header?: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Error, message, header, keepAfterRouteChange);
		this.errorHandler.handleError(error);
	}

	info(message: string, header?: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Info, message, header, keepAfterRouteChange);
	}

	warn(message: string, header?: string, keepAfterRouteChange = false) {
		this.alert(AlertType.Warning, message, header, keepAfterRouteChange);
	}

	alert(type: AlertType, message: string, header?: string, keepAfterRouteChange = false) {
		this.keepAfterRouteChange = keepAfterRouteChange;
		this.inQueueMessages.push({ type, message, header });
		this.subject.next(this.inQueueMessages as Alert[]);
	}

	clear() {
		// clear alerts
		this.subject.next([]);
	}
}
