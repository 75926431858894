import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private token;
	constructor(public cmnServ: CommonService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.cmnServ
			.getOauthToken()
			.pipe(take(1))
			.subscribe((response) => {
				this.token = response.access_token;
			});

		// refresh token request
		if (request.url.includes('oauth')) {
			return next.handle(request);
		}

		if (this.token) {
			const url = request.url;
			// legacy services, oauth token not required
			if (url.includes('imsnxg') || url.toLowerCase().includes('fedsep')) {
				request = request.clone({
					setHeaders: {
						// Disable browser caching
						'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
						Pragma: 'no-cache',
						Expires: '0',
					},
				});
			} else {
				request = request.clone({
					setHeaders: {
						Authorization: 'Bearer ' + this.token,
						// Disable browser caching
						'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
						Pragma: 'no-cache',
						Expires: '0',
					},
				});
			}
		}
		return next.handle(request);
	}
}
