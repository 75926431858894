export interface appealDataState {
    appealData: any | null;
};

export const appealIntialState: appealDataState = {
    appealData: null,
};

export interface resolutionDataState {
    resolutionData: any | null;
};

export const resolutionIntialState: resolutionDataState = {
    resolutionData: null,
};

export interface defendantDataState {
    defendantData: any | null;
};

export const defendantIntialState: defendantDataState = {
    defendantData: null,
};

export interface appealStatusState {
    appealStatus: any | null;
};

export const appealStatusIntialState: appealStatusState = {
    appealStatus: null,
};

export interface courtInformationState {
    courtInformation: any | null;
};

export const courtInformationIntialState: courtInformationState = {
    courtInformation: null,  
};

export interface appealSharedCodeState {
    appealSharedCode: any | null;
};

export const appealSharedCodeIntialState: appealSharedCodeState = {
    appealSharedCode: null,  
};


