export interface litigationSearchState {
    litigationSearchData: any | null;
};

export interface privateSearchState {
    privateSearchData: any | null;
};

export const litigationIntialState: litigationSearchState = {
    litigationSearchData: null,
};


export const privateIntialState: privateSearchState = {
    privateSearchData: null,
};
