export const DocumentsConfig = {
	appname: 'IMSNXG',
	securitycode: 'tdfvawu$2dfdXRExl34',
	documentsUrl: '/v3/documents',
	copyDocumentUrl: '/v3/documents/copy',
	downloadDocUrl: '/v3/documents/download',
	downloadZipUrl: '/v3/documents/download/zip',
	activityLogUrl: '/inquiry/writetolog',
	emailDocsUrl: '/inquiry/email/documents',
	caseStatus: '/inquiry/casestatus',
};

export const PrivateDocumentsConfig = {
	domain: 'PRIVATE',
	getDocumentsUrl: '/inquiry/private/documents',
	getDocumentTypeListUrl: '/inquiry/private/doc/types',
	getDocumentPermissionsUrl: '/inquiry/private/doc/permissions',
	emailNotificationUrl: '/inquiry/send/posnotification',
	generateNOCUrl: '/inquiry/generate/ng/noc',
	downloadLogUrl: '/inquiry/private/document/log/',
	generateInquiryDetailUrl: '/v1/portal/intake/inquiry/generate/onlineinquiryreport',
	resendEmailUrl: '/inquiry/private/email/noc',
	docUploadNotifyUrl: '/inquiry/private/send/docuploadnotification',
	updateG9ActionUrl: '/inquiry/update/g9',
	NOC: 'Notice of Charge',
	COD: 'Charge of Discrimination',
	ACOD: 'Amended Charge of Discrimination',
	POS: 'Position Statement',
	POS_REDACT: 'Position Statement - Redacted',
	POS_ATTMNT_NON_CONF: 'Position Statement Attachments – Non-Confidential',
	SUPPL_POS: 'Supplemental Position Statement',
	SUPPL_POS_REDACT: 'Supplemental Position Statement-Redacted',
	RFI: 'Request for Information',
	RRFI: 'Response to Request for Information',
	SDS: 'Sensitive Data Set',
};

export const FepaDocumentsConfig = {
	alfrescoUserName: 'fepa.user',
	domain: 'FEPA',
	privateDomain: 'PRIVATE',
	getDocumentTypeListUrl: '/inquiry/fepa/info',
	downloadTrainingVideoUrl: '/imsnxgag2/fepa-training.wmv',
};

export const GuidanceDocumentsConfig = {
	appname: 'IMSNXG',
	foldername: 'ARC Guidance Documents',
};

/*export let PRIVATE_DOCUMENTS_CONFIG = new InjectionToken('privateDocuments.config');

export let FEPA_DOCUMENTS_CONFIG = new InjectionToken('fepaDocuments.config');

export let DOCUMENTS_CONFIG = new InjectionToken('documents.config');*/

export class Document {
	fileName?: string;
	documentNodeId?: string;
	documentType?: string;
	createdDate?: any;
	lastModifiedDate?: any;
	title?: string;
	createdBy?: string;
	lastModifiedBy?: string;
	notes?: string;
	isPublishable?: boolean;
	isPublished?: boolean;
	docReleasedTo?: string;
	isSecure?: string;
	createByLastName?: string;
	createdByFirstName?: string;
	tabType?: string;
	subCategory?: string;
	relatedDate?: any;
	eeocTrackingNumber?: string;
	agencyTrackingNumber?: string;
	complainantName?: string;
	fedRelatedDate?: any;
	year?: string;
	agnecy?: string;
	accountabilityOfficeCode?: string;
	encryptionType?: string;
	createdByFullName?: string;
	modifiedByFullName?: string;
	viewableBy?: string;
	checked?: boolean;
	allowEmail?: boolean;
	allowDownload?: boolean;
	allowEdit?: boolean;
	allowDelete?: boolean;
	availableTo?: string;
	relationShipId?: string;
	deleteReason?: boolean;
}
