import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRowKeyboardSelectionDirective } from './mat-row-keyboard-selection.directive';

@NgModule({
	declarations: [MatRowKeyboardSelectionDirective],
	exports: [MatRowKeyboardSelectionDirective],
	imports: [CommonModule],
})
export class MatRowKeyboardSelectionModule {}
