import { createReducer, on } from "@ngrx/store";
import { setErrorMessage, setLoadingSpinner } from "./shared.actions";
import { intialState } from "./shared.state";
import { AppState } from '../interface';
import { ActionReducerMap } from '@ngrx/store';
import { litigationSearchReducer , privateSearchReducer} from '../../features/modules/search/search-state/search.reducer';



const _sharedReducer = createReducer(
    intialState,
    on(setLoadingSpinner, (state, action) => {
        return {
            ...state,
            showLoading: action.status
        };

    }),
    on(setErrorMessage, (state, action) => {
        return {
            ...state,
            errorMessage: action.message,

        };
    })
);

export function sharedReducer(state, action) {
    return _sharedReducer(state, action);
}