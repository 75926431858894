import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DebounceClickModule } from '../directives/debounce-click/debounce-click.module';
import { CloseMatDialogComponent } from './close-mat-dialog.component';

@NgModule({
	declarations: [CloseMatDialogComponent],
	exports: [CloseMatDialogComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, DebounceClickModule],
})
export class CloseMatDialogModule {}
