import { appealIntialState, resolutionIntialState, defendantIntialState , appealStatusIntialState, courtInformationIntialState, appealSharedCodeIntialState} from './case-details.state';
import { createReducer, on } from '@ngrx/store';
import { appealDataSuccess, resolutionDataSuccess , defendantDataSuccess, appealStatusSuccess, courtInformationSuccess, appealSharedCodeSuccess} from './case-details.actions';

const _appealDataStateReducer = createReducer(appealIntialState,
    on(appealDataSuccess, (state, action) => {
        return {
            ...state,
            appealData: action.appealData
        };
    })
);

export const APPEAL_STATE_NAME = "appealDataFeatureKey";

export function appealDataReducer(state, action) {
    return _appealDataStateReducer(state, action);
}

const _resolutionDataStateReducer = createReducer(resolutionIntialState,
    on(resolutionDataSuccess, (state, action) => {
        return {
            ...state,
            resolutionData: action.resolutionData
        };
    })
);

export const RESOLUTION_STATE_NAME = "resolutionDataFeatureKey";

export function resolutionDataReducer(state, action) {
    return _resolutionDataStateReducer(state, action);
}

const _defendantDataStateReducer = createReducer(defendantIntialState,
    on(defendantDataSuccess, (state, action) => {
        return {
            ...state,
            defendantData: action.defendantData
        };
    })
);

export const DEFENDANT_STATE_NAME = "defendantDataFeatureKey";

export function defendantDataReducer(state, action) {
    return _defendantDataStateReducer(state, action);
}


const _appealStatusStateReducer = createReducer(appealStatusIntialState,
    on(appealStatusSuccess, (state, action) => {
        return {
            ...state,
            appealStatus: action.appealStatus
        };
    })
);

const _courtInformationStateReducer = createReducer(courtInformationIntialState,
    on(courtInformationSuccess, (state, action) => {
        return {
            ...state,
            courtInformation: action.courtInformation
        };
    })
);

export const APPEAL_STATUS_NAME = "appealStatusFeatureKey";

export function appealStatusStateReducer(state, action) {
    return _appealStatusStateReducer(state, action);
}

export const COURT_INFORMATION = "courtInformationFeatureKey";

export function courtInformationStateReducer(state, action) {
    return _courtInformationStateReducer(state, action);
}

const _appealSharedCodeStateReducer = createReducer(appealSharedCodeIntialState,
    on(appealSharedCodeSuccess, (state, action) => {
        return {
            ...state,
            appealSharedCode: action.appealSharedCode
        };
    })
);

export const APPEAL_SHARED = "appealSharedFeatureKey";

export function appealSharedCodeStateReducer(state, action) {
    return _appealSharedCodeStateReducer(state, action);
}
