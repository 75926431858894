export const environment = {
	name: 'PROD',
	production: true,
	appInsights: {
		instrumentationKey: '9035d7b2-8035-4480-8426-54c6936dd1d2',
	},
	datadog: {
		applicationId: '15d59155-63f3-4554-a796-add704cbb639',
		clientToken: 'pubf1077ef618b6614213943685ed2c7be1',
		site: 'ddog-gov.com',
		service: 'arcui',
		env: 'prod',
		allowedTracingUrls: [
			'https://arc.eeoc.gov/prepa',
			'https://arc.eeoc.gov/oauth',
			'https://arc.eeoc.gov/searchws',
			'https://arc.eeoc.gov/UserManagement',
			'https://arc.eeoc.gov/tms/v1',
			'https://arc.eeoc.gov/ews/v2',
			'https://arc.eeoc.gov/litigation',
			'https://arc.eeoc.gov/ecm',
		],
	},

	AppOathTokenCredential: 'ecm:D6dDvQe=7MpL',
	ecmurl: 'https://arc.eeoc.gov/ecm',
	empdb: 'https://arc.eeoc.gov/empdb',
	fedsApp: 'https://egov.eeoc.gov/FedSep/rest/webservice/public/agency/all',
	//New services
	imsnxgPrepaurl: 'https://arc.eeoc.gov/prepa',
	oauthws: 'https://arc.eeoc.gov/oauth',
	searchdata: 'https://arc.eeoc.gov/searchws',
	umws: 'https://arc.eeoc.gov/UserManagement',
	tms: 'https://arc.eeoc.gov/tms/v1',
	redirectUri: 'https://arc.eeoc.gov/',
	litigation: 'https://arc.eeoc.gov/litigation',
	postLogoutRedirectUri: 'https://arc.eeoc.gov/',
	azureAdUserUri: 'https://arc.eeoc.gov/azuread/v1/',
	emws: 'https://arc.eeoc.gov/ews/v2',
	rsp: 'https://arc.eeoc.gov/rsp/jsf/chargenotice.jsf?chargenum=',
	publicPortalGatewayToken:
		'https://arc.eeoc.gov/gateway/portal/v1/ains-token?appid=PP_AINS',
	ainsServer: 'https://publicportal.eeoc.gov/Portal/eeoc',
	testuserPassword: ''
};
