<div fxLayout="row wrap" [class.mt-3]="isSystemic" fxLayoutAlign="space-between center" class="ims-subheader-container">
	<div fxFlex="50" fxFlex.md="50" fxFlex.sm="100" fxFlex.xs="100">
		<div [class.pl-4]="isSystemic">
			<h2 class="arc-h2 margin-0">Documents</h2>
			<label class="arc-subtitle-1 margin-bottom-20">Upload and manage all documents for this case.</label>
		</div>
	</div>
	<div
		fxFlex="50"
		fxFlex.md="50"
		fxFlex.sm="100"
		fxFlex.xs="100"
		class="button-row pull-text-right margin-bottom-20 pr-3"
		*ngIf="isModal"
	>
		<button type="button" class="float-right" mat-icon-button color="secondary" [mat-dialog-close]="false">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div
		fxFlex="50"
		fxFlex.md="50"
		fxFlex.sm="100"
		fxFlex.xs="100"
		class="button-row pull-text-right margin-bottom-20"
		*ngIf="!isSystemic"
	>
		<!-- <button mat-flat-button color="primary" *ngIf="commonParams?.from === 'reports'" appDebounceClick (debounceClick)="goBackToFepaReports()" title="click to go back to Reports" id="back-to-report-button">Back to Report</button> -->
		<button
			mat-button
			color="primary"
			title="Generate document templates"
			id="document-template-button"
			appDebounceClick
			(debounceClick)="templateLibrary.show()"
			[disabled]="cmnServ.hasPrivateInspectorGeneralRole() || parent?.readOnlyAccess"
		>
			Template Library
		</button>
		<button
			mat-button
			color="primary"
			appDebounceClick
			(debounceClick)="openDialog(documentLog)"
			title="download documents log"
			[disabled]="isDisabled()"
			id="document-log-button"
		>
			Document Log
		</button>
	</div>
</div>
<mat-card
	appearance="outlined"
	[class.mb-3]="isSystemic"
	*ngIf="isCaseManager || isSystemic"
	appDragDrop
	(onFileDropped)="uploadFile($event, true)"
>
	<mat-card-header>
		<mat-card-title class="arc-h4">Upload Files</mat-card-title>
		<mat-card-subtitle>
			Click on Choose to select a file(s) from the system or Drag and drop the file(s). Supported file types are text,
			pdf, microsoft office files and images . Max file size is 1.5gb.
		</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div class="button-row margin-left-15 margin-bottom-10">
			<button
				type="button"
				mat-stroked-button
				class="mat-button-md margin-right-15"
				id="choose-button"
				appDebounceClick
				(debounceClick)="myFileInput.click()"
				[disabled]="readOnly"
			>
				CHOOSE FILES
			</button>
			<input
				#myFileInput
				hidden
				type="file"
				multiple
				(change)="uploadFile($event)"
				placeholder="Upload file"
				id="choose-input-hidden"
			/>
			<button
				mat-flat-button
				color="primary"
				[hidden]="filesToUpload?.length === 0 || readOnly"
				appDebounceClick
				(debounceClick)="isIntakeDocument ? upload($event) : openNoInterviewIdailog(interviewIntakeTemplate, $event)"
				class="mat-button-md"
				id="upload-button"
			>
				UPLOAD
			</button>

			<button
				mat-button
				[hidden]="filesToUpload?.length === 0 || readOnly"
				appDebounceClick
				color="primary"
				(debounceClick)="removeAll()"
				class="mat-button-md"
				id="cancel-button"
			>
				CANCEL
			</button>
		</div>

		<div
			fxLayout="row wrap"
			fxLayoutAlign="space-between stretch"
			class="margin-bottom-10"
			*ngIf="filesToUpload?.length"
		>
			<ng-container *ngFor="let file of filesToUpload; let i = index">
				<div>
					<div fxFlex="19" fxFlex.md="19" fxFlex.sm="50" fxFlex.xs="50" class="margin-top-10 margin-left-15">
						<mat-form-field class="input-95-width" appearance="outline">
							<mat-label>File Name</mat-label>
							<input matInput [(ngModel)]="file.name" id="file-name-input-{{ i }}" required />
						</mat-form-field>
					</div>

					<div fxFlex="24" fxFlex.md="24" fxFlex.sm="50" fxFlex.xs="50" class="margin-top-10">
						<mat-form-field class="input-95-width" appearance="outline">
							<mat-label>Select File Type</mat-label>
							<input
								type="text"
								id="file-type-select-{{ i }}"
								placeholder="Select File Type"
								aria-label="fileType"
								[(ngModel)]="file.description"
								(change)="updateFileFolderTypes(i, file)"
								[matAutocomplete]="auto"
								matInput
								required
							/>
							<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
								<mat-option
									class="mat-option-width"
									*ngFor="let fileType of _filterDocTypesOnInput(file); let j = index"
									[value]="fileType.documentDescription"
									id="file-option-{{ i }}-{{ j }}"
									[matTooltip]="fileType.documentDescription"
									(click)="onDocumentTypeSelect(i, fileType.documentCode)"
									(onSelectionChange)="onDocumentTypeSelectKeyboard($event, i, fileType.documentCode)"
								>
									{{ fileType.documentDescription }}
								</mat-option>
							</mat-autocomplete>
							<mat-hint class="error-message" *ngIf="file.typeInvalid"> Please select a file Type </mat-hint>
							<mat-hint
								class="error-message"
								*ngIf="file.doctype === 'Request for Information' && !isRequestForInfoValid"
							>
								Request for Information should be in pdf format
							</mat-hint>
							<div role="status" class="sr-only" aria-live="polite">
								<span *ngIf="autocompleteCount > 0"
									>There are {{ autocompleteCount }} results available. Use the up and down arrows to navigate between
									options.</span
								>
								<span *ngIf="autocompleteCount === 0">0 results available</span>
							</div>
						</mat-form-field>
					</div>

					<div fxFlex="19" fxFlex.md="19" fxFlex.sm="50" *ngIf="!isSystemic" fxFlex.xs="50" class="margin-top-10">
						<mat-form-field class="input-95-width" appearance="outline">
							<mat-label>Select Folder</mat-label>
							<mat-select
								(selectionChange)="onFolderSelect(i)"
								[(ngModel)]="file.tabtype"
								id="file-folder-select-{{ i }}"
								required
								aria-label="Select folder {{
									file?.tabtype ? 'list box with ' + file?.tabtype + ' value selected' : ''
								}}"
							>
								<mat-option [value]="null">Select One</mat-option>
								<mat-option
									*ngFor="let folderType of getCategoryTypes(file); let j = index"
									[value]="folderType.value"
									[matTooltip]="folderType?.value"
									id="folder-option-{{ i }}-{{ j }}"
								>
									{{ folderType.value }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div
						fxFlex="{{ isSystemic ? 20 : 12 }}"
						fxFlex.md="{{ isSystemic ? 20 : 12 }}"
						fxFlex.sm="50"
						fxFlex.xs="50"
						class="margin-top-10"
					>
						<mat-form-field class="input-95-width" appearance="outline">
							<mat-label>Received Date</mat-label>
							<input
								matInput
								[matDatepicker]="picker3"
								placeholder="Received Date"
								[min]="minDate"
								[max]="maxDate"
								(dateChange)="addDate($event.value, i)"
								[value]="file.relatedDate"
								id="file-date-input-{{ i }}"
							/>
							<mat-datepicker-toggle
								matSuffix
								[for]="picker3"
								id="private-received-date-picker"
							></mat-datepicker-toggle>
							<mat-datepicker #picker3></mat-datepicker>
						</mat-form-field>
					</div>

					<div
						fxFlex="{{ isSystemic ? 20 : 13 }}"
						fxFlex.md="{{ isSystemic ? 20 : 13 }}"
						fxFlex.sm="50"
						fxFlex.xs="50"
						class="margin-top-10"
					>
						<mat-checkbox
							class="h-60"
							id="isDisclosable{{ i }}"
							name="isDisclosable{{ i }}"
							matSuffix
							[(ngModel)]="file.isDisclosable"
						>
							Non-disclosable
							<mat-icon
								matTooltip="A document (or other data) that is considered to be 'confidential' and cannot be provided to a party in response to that party's request for information under the Freedom of Information Act (FOIA) or Section 83 of the U.S. EEOC Compliance Manual."
								[inline]="true"
								color="primary"
								>help</mat-icon
							>
						</mat-checkbox>
					</div>

					<div
						fxFlex="{{ isSystemic ? 12 : 7 }}"
						fxFlex.md="{{ isSystemic ? 12 : 7 }}"
						fxFlex.sm="50"
						fxFlex.xs="50"
						class="margin-top-10"
					>
						<mat-form-field class="input-95-width" appearance="outline">
							<mat-label>File Size</mat-label>
							<input matInput [(ngModel)]="file.size" id="file-size-input-{{ i }}" />
						</mat-form-field>
					</div>

					<div fxFlex="3" fxFlex.md="3" fxFlex.sm="50" fxFlex.xs="50" class="margin-top-10">
						<button
							class="margin-bottom-10"
							mat-icon-button
							aria-label="button to delete the uploaded file"
							appDebounceClick
							(debounceClick)="removeUpload(file)"
							id="delete-file-{{ i }}"
							[disabled]="readOnly"
						>
							<mat-icon matTooltip="cancel" class="icon-red">cancel</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</mat-card-content>
</mat-card>

<app-template-library
	#templateLibrary
	[isSystemic]="isSystemic"
	[userSelectedOfficeCode]="userSelectedOfficeCode"
	[caseDetail]="caseDetail"
	[assignedStaffArray]="assignedStaffArray"
	[readOnlyCharge]="readOnlyCharge"
></app-template-library>
<ng-template #documentLog>
	<div fxLayout="row wrap m-0" fxLayoutAlign="space-between" class="border-bottom no-print">
		<h3 class="arc-h3 margin-20" matDialogTitle>Document Log</h3>
		<div class="col-sm-2 text-right">
			<app-close-mat-dialog (closeDialog)="dialogRef.close()"></app-close-mat-dialog>
		</div>
	</div>
	<mat-dialog-content class="page-top">
		<div class="float-right margin-10">
			<mat-icon matTooltip="Print" appDebounceClick (debounceClick)="print('#print-content')" class="icon-blue no-print"
				>print
			</mat-icon>
		</div>
		<div id="print-content" class="p-2 margin-top-20">
			<div class="row">
				<div class="col-3 d-flex flex-column">
					<label class="margin-0 arc-subtitle-1"><strong>EEOC Number</strong></label>
					<label class="arc-subtitle-1">{{ caseDetail?.chargeNumber }}</label>
				</div>
				<div class="col-3 d-flex flex-column">
					<label class="margin-0 arc-subtitle-1"><strong>Charging Party Name</strong></label>
					<label class="arc-subtitle-1">
						{{ caseDetail?.chargingParty?.personName?.firstName }}
						{{ caseDetail?.chargingParty?.personName?.lastName }}
					</label>
				</div>
				<div class="col-3 d-flex flex-column">
					<label class="margin-0 arc-subtitle-1"><strong>Respondent Party Name</strong></label>
					<label class="d-flex arc-subtitle-1">{{ caseDetail?.respondent?.name }} </label>
				</div>
			</div>
			<table class="table table-background table-striped log-table">
				<thead>
					<tr>
						<th>Type</th>
						<th>Title</th>
						<th>Received Date</th>
						<th>Available To</th>
						<th>Uploaded On</th>
						<th>Uploaded By</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let doc of documents">
						<td>{{ doc?.documentDescription || 'N/A' }}</td>
						<td>{{ doc?.title || 'N/A' }}</td>
						<td>{{ doc?.relatedDate ? (doc?.relatedDate | date: 'M/d/yyyy') : 'N/A' }}</td>
						<td>{{ doc?.availableTo || 'N/A' }}</td>
						<td>{{ doc?.createdDate ? cmnServ.displayEsttoLocale(doc?.createdDate) : 'N/A' }}</td>
						<td>{{ doc?.createdByFullName || 'N/A' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</mat-dialog-content>
	<mat-divider class="no-print"></mat-divider>
	<mat-dialog-actions class="mb-0 no-print">
		<div class="p-3">
			<button mat-button class="text-left" color="primary" matDialogClose>CANCEL</button>
		</div>
	</mat-dialog-actions>
</ng-template>

<ng-template #interviewIntakeTemplate>
	<div class="pb-2 pt-2">
		<div fxLayout="row wrap" fxLayoutAlign="space-between" class="border-bottom">
			<h3 class="arc-h2 margin-20" matDialogTitle>Choose a Reason</h3>
			<div class="col-sm-2 text-right">
				<app-close-mat-dialog (closeDialog)="cancelDialog()"></app-close-mat-dialog>
			</div>
		</div>
		<mat-dialog-content>
				<div class="p-4">
					<div class="row">
						<div class="col-md-12 col-xm-12 align-items-center">
							<mat-hint class="align-items-center p-4"> You must Select a reason why an Intake Interview was not held</mat-hint>
							<mat-form-field class="col-12" appearance="outline" [floatLabel]="'never'">
								<mat-label>Reason No Intake Interview Held</mat-label>
								<mat-select
									name="nointrRsn"
									id="no-intrv-int-rsn"
									[(ngModel)] = "selectednoIntakeInterviewReason"
									required
								>
									<mat-option *ngFor="let item of noIntakeInterviewReasons" [value]="item.code">
										{{ item.description }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</div>
		</mat-dialog-content>

		<mat-divider></mat-divider>
		<mat-dialog-actions class="space-between">
			<div>
				<button id="intake-interview-cancelButton" mat-button color="primary" class="text-left ml-2" appDebounceClick (debounceClick)="cancelDialog()">
					CANCEL
				</button>
			</div>
			<div class="text-right p-3">
				<button
					id="intake-interview-save-btn"
					class="text-right ml-2"
					mat-flat-button
					color="primary"
					appDebounceClick
					(debounceClick)="saveNoIntrvReason()"
					[disabled]="!selectednoIntakeInterviewReason"
				>
					SAVE
				</button>
			</div>
		</mat-dialog-actions>
	</div>
</ng-template>