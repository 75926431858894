import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';

export const initializeDatadogRum = () => {
	// Initialize Datadog RUM
	datadogRum.init({
		applicationId: environment.datadog?.applicationId,
		clientToken: environment.datadog?.clientToken,
		site: environment.datadog?.site,
		service: environment.datadog?.service,
		env: environment.datadog?.env,

		// Specify a version number to identify the deployed version of your application in Datadog
		version: '2.0.0-20230623',
		sessionReplaySampleRate: 20,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
		useSecureSessionCookie:true,
	});

	datadogRum.startSessionReplayRecording();
};
