import { litigationSharedCodeIntialState, litigationNewInquiryIntialState, litigationDistrictOfficesIntialState } from './new-case.state';
import { createReducer, on } from '@ngrx/store';
import { litigationSharedCodeSuccess, litigationNewInquiryDataSuccess, litigationDistrictOfficesSuccess } from './new-case.action';

const _getLitigationSharedCodeState = createReducer(litigationSharedCodeIntialState,
    on(litigationSharedCodeSuccess, (state, action) => {
        return {
            ...state,
            litigationSharedCode: action.litigationSharedCode
        };
    })
);

export const LITIGATION_SHARED_CODE = "litigationSharedCodeFeatureKey";

export function litigationSharedCodeReducer(state, action) {
    return _getLitigationSharedCodeState(state, action);
}

const _getLitigationNewInquiryState = createReducer(litigationNewInquiryIntialState,
    on(litigationNewInquiryDataSuccess, (state, action) => {
        return {
            ...state,
            litigationNewInquiryData: action.litigationNewInquiryData
        };
    })
);

export const LITIGATION_NEW_INQUIRY = "litigationNewInquiryFeatureKey";

export function litigationNewInquiryReducer(state, action) {
    return _getLitigationNewInquiryState(state, action);
}

const _getLitigationDistrictOfficesState = createReducer(litigationDistrictOfficesIntialState,
    on(litigationDistrictOfficesSuccess, (state, action) => {
        return {
            ...state,
            litigationDistrictOfficesData: action.litigationDistrictOfficesData,
        };
    })
);

export const LITIGATION_DISTRICT_OFFICES = "litigationDistrictOfficesFeatureKey";

export function litigationDistrictOfficesReducer(state, action) {
    return _getLitigationDistrictOfficesState(state, action);
}

