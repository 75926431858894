import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
	constructor() {}

	
	exportToExcel(data: any[], fileName: string, sheetName= 'Sheet1') {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(sheetName);

        // Add headers
		if ( data && data.length > 0) {
			const headers = Object.keys(data[0]);
			worksheet.addRow(headers);
	
			// Add data
			data.forEach((item) => {
			  const row:any = [];
			  headers.forEach((header) => {
				row.push(item[header]);
			  });
			  worksheet.addRow(row);
			});
		}
        workbook.xlsx.writeBuffer().then((buffer: any) => {
          const blob = new Blob([buffer], {
			type: EXCEL_TYPE,
		});
          FileSaver(blob, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
      }
}
